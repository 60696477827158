import { LOAN_APPLICATION_STATUSES } from "../../../src/utilities/constants";
import { useLocation, useNavigate } from "react-router-dom";

const ResultsStatusFilter = ({ targetURL, statusFilterArr }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const getstatusIDValue = () => {
    const statusID = new URLSearchParams(location.search).get("statusID");
    return statusID;
  };

  return (
    <select
      style={{ width: "160px" }}
      value={getstatusIDValue()?getstatusIDValue():""}
      onChange={(e) => {
        // if (e.target.value) {
          navigate(`${targetURL}${e.target.value}`);
        // }
      }}
    >
      <option value={""}>All Status</option>
      {LOAN_APPLICATION_STATUSES &&
        LOAN_APPLICATION_STATUSES.map(
          (s, i) =>
            statusFilterArr.includes(s.id) && (
              <option value={s.id}>{s.title}</option>
            )
        )}
    </select>
  );
};

export default ResultsStatusFilter;
