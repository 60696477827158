import { useEffect } from "react";
import PageTitle from "../../components/common/PageTitle";
import { NavLink } from "react-router-dom";
import { DEFAULT_PER_PAGE } from "../../utilities/constants";
import { useUI } from "../../context/ui";
import ButtonTextOnly from "../../components/ui/ButtonTextOnly";
import { useParams } from "react-router-dom";
import { CiWarning } from "react-icons/ci";

const AddPreInstallmentPaymentFailure = () => {
  const { id } = useParams();
  const { setSegmentTitle } = useUI();

  useEffect(() => {
    setSegmentTitle("Payment Failed");
  }, []);

  return (
    <>
      <section>
        <PageTitle title="Payment Failed" />
        <section
          className="flex center-center"
          style={{
            height: "100vh",
            width: "100%",
          }}
        >
          <div>
            <CiWarning
              className="color-secondary"
              style={{
                height: "80px",
                width: "auto",
                margin: "0 auto",
                display: "block",
              }}
            />
            <br />
            <div className="t-a-c">
              <h4 className="color-primary" style={{ marginBottom: "10px" }}>
                Payment Failed!
              </h4>
              <p>
                We could not pay your pre-installment at the moment.
                <br />
                Please try again later.
              </p>
              <br />
              <div>
                <NavLink to={`/te-credit-accounts/${id}`}>
                  <ButtonTextOnly title="Return Back" />
                </NavLink>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default AddPreInstallmentPaymentFailure;
