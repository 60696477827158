const FullPageLoader = (props) => {
	const { isActive } = props;

	return (
		<>
			{isActive && (
				<section id='rs-loader-overlay'>
					<div className='inner'>
						<div className='content'>
							<span className='spinner b-r-100'></span>
						</div>
					</div>
				</section>
			)}
		</>
	);
};

export default FullPageLoader;
