import { lazy, Suspense, useEffect, useState } from "react";
import { API_URL } from "../../utilities/constants";
import FullPageLoader from "../../components/ui/FullPageLoader";
import PageTitle from "../../components/common/PageTitle";
import { useUI } from "../../context/ui";
import { useFetch } from "../../hooks/useFetch";
import {
	CiCamera,
	CiCircleCheck,
	CiLocationArrow1,
	CiSaveUp1,
	CiViewList,
} from "react-icons/ci";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import { decodeRolesFromToken } from "../../utilities/helpers";
import PopUpModal from "../../components/common/PopUpModal";
import { useOutsideClick } from "../../utilities/hooks";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import FileInput from "../../components/ui/FileInput";
import LoaderIcon from "../../components/ui/LoaderIcon";
import { countries } from "../../utilities/countries";
import { useNavigate } from "react-router-dom";
const Overview = lazy(() => import("../../components/common/profile/Overview"));

const SectionLoader = () => {
	return (
		<section className='flex center-center' style={{ height: "480px" }}>
			<LoaderIcon
				style={{ height: "32px", width: "32px", opacity: "0.2" }}
			/>
		</section>
	);
};

const Profile = () => {
	const [show, setShow] = useState(false);
	const {
		setNewNotification,
		setShowNotification,
		addNotification,
		setSegmentTitle,
		addToastMessage,
	} = useUI();
	const navigate = useNavigate();
	const [apiLoading, setApiLoading] = useState(false);
	const [profilePictureFile, setProfilePictureFile] = useState(null);
	const [profilePictureUploadedURL, setProfilePictureUploadedURL] =
		useState("");
	const [coverPictureFile, setCoverPictureFile] = useState(null);
	const [coverPictureUploadedURL, setCoverPictureUploadedURL] = useState("");


	const { data, error, loading } = useFetch(
		API_URL + "/api/1.0.0/rs/profile",
		{
			method: "GET",
		}
	);

	useEffect(() => {
		setSegmentTitle("Profile");
	}, []);

	const hasRole = (sourceArray, targetArray) => {
		return targetArray.some((value) => sourceArray.includes(value));
	};

	const checkIfRoleIdExist = (allowedRoles) => {
		const roles = decodeRolesFromToken();
		return hasRole(allowedRoles, roles) ? true : false;
	};
	const isTenant = checkIfRoleIdExist([8]);

	const getRespectiveUI = () => {
		const me = decodeRolesFromToken();
		const myRoleId = me[0];
		switch (myRoleId) {
			case 1:
				return "Rent Savvy Super Admin";
			case 2:
				return "Rent Savvy Subadmin";
			case 3:
				return data?.data.metaData?.name + " - Agency";
			case 4:
				return (
					data?.data.firstName +
					" " +
					data?.data.lastName +
					" - Broker (" +
					data?.data.metaData?.name +
					")"
				);
			case 5:
				return (
					data?.data.firstName +
					" " +
					data?.data.lastName +
					" - Broker"
				);
			case 6:
				return data?.data.metaData?.name + " - Credit Provider";
			case 7:
				return (
					data?.data.firstName +
					" " +
					data?.data.lastName +
					" - Approver (" +
					data?.data.metaData?.name +
					")"
				);
			case 8:
				return (
					data?.data.firstName +
					" " +
					data?.data.lastName +
					" - Tenant"
				);
			case 9:
				return (
					data?.data.firstName +
					" " +
					data?.data.lastName +
					" - Reviewer (" +
					data?.data.metaData?.name +
					")"
				);
			default:
				return "Rent Savvy User";
		}
	};

	// const checkIfRoleIdExist = (roleIds) => {
	// 	const userRoleId = getUserRoleId(); // Assuming this function retrieves the user's role ID
	// 	console.log("User Role ID:", userRoleId); // Check the role ID here
	// 	return roleIds.includes(userRoleId);
	//   };

	const [box] = useOutsideClick({
		state: true,
		callBack: () => {
			setShow(false);
		},
	});

	const handleProfilePictureUpload = async () => {
		setApiLoading(true);
		try {
			const formdata = new FormData();
			formdata.append(
				"file",
				profilePictureFile,
				profilePictureFile.name
			);
			formdata.append("type", "USER_PROFILE_PICTURE");
			const resp = await fetch(API_URL + `/api/1.0.0/uploadFile`, {
				method: "POST",
				headers: {
					"X-JWT-Assertion": localStorage.getItem("token"),
				},
				body: formdata,
				redirect: "follow",
			});
			if (resp.ok) {
				const responseData = await resp.json();
				if (responseData.result.responseCode === 200) {
					setProfilePictureUploadedURL(responseData.data);
					addToastMessage({
						status: true,
						message: "User profile picture successfully uploaded.",
					});
				}
			} else {
				const errorData = await resp.json();
				const errorMessage = errorData.responseDescription;
				addToastMessage({
					status: false,
					message:
						errorMessage ||
						"Something went wrong while uploading profile picture. Please try again.",
				});
			}
		} catch (e) {
			console.log("Error", e);
			setNewNotification(true);
			setShowNotification(true);
			addNotification({
				id: Date.now(),
				message: "Could not upload the profile picture at the moment.",
				status: false,
				archive: false,
			});
		} finally {
			setApiLoading(false);
		}
	};
	const handleCoverPictureUpload = async () => {
		setApiLoading(true);
		try {
			const formdata = new FormData();
			formdata.append("file", coverPictureFile, coverPictureFile.name);
			formdata.append("type", "USER_PROFILE_PICTURE");
			const resp = await fetch(API_URL + `/api/1.0.0/uploadFile`, {
				method: "POST",
				headers: {
					"X-JWT-Assertion": localStorage.getItem("token"),
				},
				body: formdata,
				redirect: "follow",
			});
			if (resp.ok) {
				const responseData = await resp.json();
				if (responseData.result.responseCode === 200) {
					setCoverPictureUploadedURL(responseData.data);
					addToastMessage({
						status: true,
						message:
							"Cover picture has been successfully uploaded.",
					});
				}
			} else {
				const errorData = await resp.json();
				const errorMessage = errorData.responseDescription;
				addToastMessage({
					status: false,
					message:
						errorMessage ||
						"Something went wrong while uploading a cover picture. Please try again.",
				});
			}
		} catch (e) {
			console.log("Error", e);
			setNewNotification(true);
			setShowNotification(true);
			addNotification({
				id: Date.now(),
				message: "Could not upload the cover picture at the moment.",
				status: false,
				archive: false,
			});
		} finally {
			setApiLoading(false);
		}
	};

	const [initialValues, setInitialValues] = useState({
		firstName: "",
		lastName: "",
		bio: "",
		websiteURL: "",
		alternateContact: "",
		dateOfBirth: "",
		maritalStatus: "",
		citizenshipStatus: "",
		uaePass: "",
		annualIncome: "",
	});

	const getValidationSchema = (isTenant) => Yup.object().shape({
		firstName: Yup.string().required('First name is required')
		.matches(/^[A-Za-z\s-]+$/, "First name should contain only letters!"),
		lastName: Yup.string().required('Last name is required')
		.matches(/^[A-Za-z\s-]+$/, "Last name should contain only letters!"),
		bio: Yup.string().required("Bio is required!"),
		websiteURL: Yup.string()
			.matches(
				/((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
				"Enter a correct URL!"
			),
		// alternateContact: Yup.string()
		// .matches(
		// 	/^5\d{8}$/,
		// 	"The number should start from 5 followed by 8 digits"
		// )
		// .required("Number is required!")
		// .min(9, "The number should start from 5 followed by 8 digits")
		// .max(9, "The number should start from 5 followed by 8 digits"),

		...(isTenant && {
			dateOfBirth: Yup.string()
				.required("Date of Birth is required")
				.test(
					"age",
					"You must be at least 18 years old",
					function (value) {
						if (!value) return false;
						const today = new Date();
						const birthDate = new Date(value);
						const age = today.getFullYear() - birthDate.getFullYear();
						const monthDiff = today.getMonth() - birthDate.getMonth();
						const dayDiff = today.getDate() - birthDate.getDate();
						return (
							age > 18 ||
							(age === 18 && (monthDiff > 0 || (monthDiff === 0 && dayDiff >= 0)))
						);
					}
				),
			citizenshipStatus: Yup.string().required('Citizenship status is required'),
			maritalStatus: Yup.string().required('Marital status is required'),
			uaePass: Yup.string()
				.required("Emirates ID is required")
				.matches(/^784\d{0,12}$/, "Emirates ID must start with '784' and be 15 digits long"),

			annualIncome: Yup.string()
				.matches(/^[1-9]\d{4,}$/, "Annual income must be a minimum of 5 digits and cannot start with zero")
				.required("Annual income is required"),
		}),
	});


	console.log("isTenant:", isTenant);

	useEffect(() => {
		if (data?.data) {
			setInitialValues({
				firstName: data.data.firstName ? data.data.firstName : "",
				lastName: data.data.lastName ? data.data.lastName : "",
				bio: data.data.bio ? data.data.bio : "",
				websiteURL: data.data.websiteURL ? data.data.websiteURL : "",
				alternateContact: data.data.alternateContact
					? data.data.alternateContact
					: "",
				dateOfBirth: data.data?.additionalDetails?.dateOfBirth
					? data.data?.additionalDetails?.dateOfBirth
					: "",
				maritalStatus: data.data?.additionalDetails
					? data.data?.additionalDetails?.maritalStatus
						? data.data?.additionalDetails?.maritalStatus
						: false
					: "",
				citizenshipStatus: data.data?.additionalDetails
					?.citizenshipStatus
					? data.data?.additionalDetails?.citizenshipStatus
					: "",
				uaePass: data.data?.additionalDetails?.uaePass
					? data.data?.additionalDetails?.uaePass
					: "",
				annualIncome: data.data?.additionalDetails?.annualIncome
					? data.data?.additionalDetails?.annualIncome
					: "",
			});
			setProfilePictureUploadedURL(data.data.profilePicUrl || "");
			setCoverPictureUploadedURL(data.data.coverPicUrl || "")
		}
	}, [data]);

	const handleUpdateProfile = async (d) => { };

	return (
		<section>
			<PageTitle title='Profile' />
			<FullPageLoader isActive={loading || apiLoading} />
			{data && (
				<section id='profile-page'>
					<div
						className='cover-image b-r-10 default-image'
						style={{
							backgroundImage: data.data.coverPictureURL
								? `url(${data.data.coverPictureURL})`
								: null,
						}}>
						<ButtonTextIcon
							onClick={() => setShow(true)}
							title={`${data.data.coverPictureURL
								? "Change Cover Photo"
								: "Add Cover Photo"
								}`}
							icon={<CiCamera />}
						/>
					</div>
					<div className='user-info'>
						<div
							className='default-image user-picture b-r-100 pos-rel'
							style={{
								backgroundImage: data.data.profilePictureURL
									? `url(${data.data.profilePictureURL})`
									: null,
							}}>
							<div
								className='add-picture b-r-100 of-hid flex center-center cursor-pointer color-primary hover-color-secondary'
								onClick={() => setShow(true)}>
								<CiCamera className='transition' />
							</div>
						</div>
						<div className='t-a-l'>
							<div
								className='flex bottom-left'
								style={{ gap: "8px" }}>
								<h5 className='color-primary l-h-1'>
									{data.data.firstName} {data.data.lastName}
								</h5>
								<p
									className='color-light f-w-300'
									style={{ fontSize: "11px" }}>
									({getRespectiveUI()})
								</p>
							</div>
							{data.data.bio ? (
								<p className='color-light font-size-14'>
									{data.data.bio}
								</p>
							) : (
								<p className='color-light font-size-14'>
									<span
										className='transition cursor-pointer color-primary hover-color-secondary'
										onClick={() => setShow(true)}>
										Click to add bio
									</span>
								</p>
							)}
							{data.data.websiteURL ? (
								<p className='color-light font-size-12'>
									<a
										className='color-secondary transition hover-color-primary'
										href={`${data.data.websiteURL}`}
										rel='noreferrer'
										target='_blank'>
										{data.data.websiteURL}
									</a>
								</p>
							) : (
								<p className='color-light font-size-12'>
									<span
										className='transition cursor-pointer color-primary hover-color-secondary'
										onClick={() => setShow(true)}>
										Click to add website
									</span>
								</p>
							)}
						</div>
						<div className='mar-l-a'>
							<ButtonTextIcon
								onClick={() => setShow(true)}
								title='Edit Profile'
								icon={<CiViewList />}
							/>
						</div>
					</div>
					<div className='rs-tabs'>
						<ul className='tab-selector'>
							<li className='active'>About</li>
						</ul>
						<div className='tab-content'>
							<div className='about-content'>
								<div className='left'>
									<p className='color-primary f-w-700'>
										About
									</p>
									<br />
									<ul>
										<li
											className={`font-size-14 b-r-5  active`}>
											Overview
										</li>
									</ul>
								</div>
								<div className='right'>
									<Suspense fallback={<SectionLoader />}>
										<Overview data={data} />
									</Suspense>
								</div>
							</div>
						</div>
					</div>
				</section>
			)}
			{show && (
				<PopUpModal>
					<section id='rs-modal'>
						<div className='inner'>
							<div className='box' ref={box}>
								<div
									style={{
										height: "auto",
										width: "600px",
										padding: "40px",
									}}>
									<h5 className='color-primary'>
										Update Profile
									</h5>
									<br />
									<Formik
										enableReinitialize={true}
										initialValues={initialValues}
										validationSchema={getValidationSchema(isTenant)}
										onSubmit={async (
											values,
											{ validateForm }
										) => {
											await handleUpdateProfile(values);
											setApiLoading(true);
											try {
												let body = {
													firstName: values.firstName,
													lastName: values.lastName,
													address: "",
													profilePictureURL:
														profilePictureUploadedURL
															? profilePictureUploadedURL
															: "",
													coverPictureURL:
														coverPictureUploadedURL
															? coverPictureUploadedURL
															: "",
													websiteURL:
														values.websiteURL,
													alternateContact:
														values.alternateContact,
													bio: values.bio,
												};
												if (checkIfRoleIdExist([8])) {
													body.tenantDetails = {
														dateOfBirth:
															values.dateOfBirth,
														maritalStatus:
															values.maritalStatus,
														citizenshipStatus:
															values.citizenshipStatus,
														uaePass: values.uaePass,
														annualIncome:
															values.annualIncome,
													};
												}
												const resp = await fetch(
													API_URL +
													"/api/1.0.1/user/update",
													{
														method: "PUT",
														headers: {
															"Content-Type":
																"application/json",
															"X-JWT-Assertion":
																localStorage.getItem(
																	"token"
																),
														},
														body: JSON.stringify(
															body
														),
													}
												);

												if (resp.ok) {
													addToastMessage({
														status: true,
														message:
															"You have successfully updated your profile, Thank You!",
													});
													setNewNotification(true);
													addNotification({
														id: Date.now(),
														message:
															"You have successfully updated your profile!",
														status: true,
														archive: false,
													});
													// navigate('/profile')
													window.location.reload();
												}
												else {
													const errorData = await resp.json();
													const errorMessage = errorData.responseDescription;
													addToastMessage({
														status: false,
														message:
															errorMessage ||
															"Something went wrong while updating your profile. Please try again.",
													});
												}
											} catch (e) {
												console.log("Error", e);
											} finally {
												setApiLoading(false);
											}
										}}>
										{({
											values,
											errors,
											touched,
											validateForm,
											setFieldValue,
											resetForm,
											handleSubmit,
										}) => (
											<Form>
												<table>
													<thead></thead>
													<tbody>
														<tr>
															<td width='33%'>
																<p className='font-size-12 color-primary'>
																	First name
																</p>
																<Field
																	name='firstName'
																	type='text'
																	placeholder='Eg: John'
																	className={
																		"col-1-1 " +
																		(errors.firstName &&
																			touched.firstName
																			? " error"
																			: "")
																	}
																/>
															</td>
															<td width='33%'>
																<p className='font-size-12 color-primary'>
																	Last name
																</p>
																<Field
																	name='lastName'
																	type='text'
																	placeholder='Eg: Smith'
																	className={
																		"col-1-1 " +
																		(errors.lastName &&
																			touched.lastName
																			? " error"
																			: "")
																	}
																/>
															</td>
															<td width='33%'>
																<p
																	className='font-size-12 color-primary'
																	style={{
																		marginTop:
																			"5px",
																	}}>
																	Alternate
																	number
																</p>
																<Field
																	name='alternateContact'
																	type='text'
																	maxLength='9'
																	placeholder='Eg: 590000000'
																	className={
																		"col-1-1 " +
																		(errors.alternateContact &&
																			touched.alternateContact
																			? " error"
																			: "")
																	}
																/>
															</td>
														</tr>
														<tr>
															<td colSpan='2'>
																<p
																	className='font-size-12 color-primary'
																	style={{
																		marginTop:
																			"5px",
																	}}>
																	Bio
																</p>
																<Field
																	name='bio'
																	type='text'
																	maxLength='200'
																	placeholder='Enter your short bio'
																	className={
																		"col-1-1 " +
																		(errors.bio &&
																			touched.bio
																			? " error"
																			: "")
																	}
																/>
															</td>
															<td>
																<p
																	className='font-size-12 color-primary'
																	style={{
																		marginTop:
																			"5px",
																	}}>
																	Website URL
																</p>
																<Field
																	name='websiteURL'
																	type='text'
																	maxLength='200'
																	placeholder='Eg: https://yourwebsite.com'
																	className={
																		"col-1-1 " +
																		(errors.websiteURL &&
																			touched.websiteURL
																			? " error"
																			: "")
																	}
																/>
															</td>
														</tr>
														{checkIfRoleIdExist([
															8,
														]) && (
																<>
																	<tr>
																		<td>
																			<p
																				className='font-size-12 color-primary'
																				style={{
																					marginTop:
																						"5px",
																				}}>
																				DOB
																			</p>
																			<Field
																				name='dateOfBirth'
																				type='date'
																				placeholder='DOB *'
																				className={
																					"col-1-1 " +
																					(errors.dateOfBirth &&
																						touched.dateOfBirth
																						? " error"
																						: "")
																				}
																			/>
																		</td>
																		<td>
																			<p
																				className='font-size-12 color-primary'
																				style={{
																					marginTop:
																						"5px",
																				}}>
																				Marital
																				Status
																			</p>
																			<Field
																				as='select'
																				name='maritalStatus'
																				className={
																					"col-1-1 " +
																					(errors.maritalStatus &&
																						touched.maritalStatus
																						? " error"
																						: "")
																				}>
																				<option value=''>
																					Select
																				</option>
																				<option value='true'>
																					Married
																				</option>
																				<option value='false'>
																					Unmarried
																				</option>
																			</Field>
																		</td>
																		<td>
																			<p
																				className='font-size-12 color-primary'
																				style={{
																					marginTop:
																						"5px",
																				}}>
																				Nationality
																			</p>
																			<Field
																				as='select'
																				name='citizenshipStatus'
																				className={
																					"col-1-1 " +
																					(errors.citizenshipStatus &&
																						touched.citizenshipStatus
																						? " error"
																						: "")
																				}>
																				<option value=''>
																					Select
																					Nationality
																				</option>
																				{countries.map(
																					(
																						c,
																						i
																					) => (
																						<option
																							key={
																								i
																							}
																							value={
																								c.name
																							}>
																							{
																								c.name
																							}
																						</option>
																					)
																				)}
																			</Field>
																		</td>
																	</tr>
																	<tr>
																		<td>
																			<p
																				className='font-size-12 color-primary'
																				style={{
																					marginTop:
																						"5px",
																				}}>
																				Emirates
																				ID
																			</p>
																			<Field
																				name='uaePass'
																				type='text'
																				maxLength='15'
																				placeholder='Eg: 784************ '
																			/>
																			<ErrorMessage
																				name='uaePass'
																				component='p'
																				className='error-messages'
																			/>
																		</td>
																		<td colSpan='2'>
																			<p
																				className='font-size-12 color-primary'
																				style={{
																					marginTop:
																						"5px",
																				}}>
																				{data
																					.data
																					?.additionalDetails
																					?.employmentType ===
																					"Self Employed"
																					? "Annual Revenue"
																					: "Annual Income"}
																			</p>
																			<Field
																				name='annualIncome'
																				placeholder='AED [120000]*'
																				className={
																					"col-1-1 " +
																					(errors.annualIncome &&
																						touched.annualIncome
																						? " error"
																						: "")
																				}
																			/>
																		</td>
																	</tr>
																</>
															)}
														<tr>
															<td colSpan='4'>
																<p
																	className='font-size-12 color-primary'
																	style={{
																		margin: "10px 0 5px 0",
																	}}>
																	Profile
																	photo
																</p>
																<div
																	className='flex center-left'
																	style={{
																		gap: "12px",
																	}}>
																	<div
																		className='default-image b-r-100 of-hid'
																		style={{
																			height: "40px",
																			width: "40px",
																			border: "solid 1px #f0f0f0",
																			backgroundImage:
																				data
																					.data
																					.profilePictureURL
																					? `url(${data.data.profilePictureURL})`
																					: null,
																		}}></div>
																	<div
																		className='flex center-center'
																		style={{
																			flex: 1,
																			gap: "4px",
																			
																		}}>
																		<FileInput
																			defaultText='Select profile photo'
																			accept='image/jpeg, image/jpg, image/png'
																			maxSizeAllowed={
																				2 *
																				1024 *
																				1024
																			}
																			callback={(
																				f
																			) => {
																				setProfilePictureFile(
																					f
																				);
																			}}
																		/>
																		<ButtonLightTextIcon
																			type='button'
																			style={{ width:"300px"}}
																			data-name={
																				profilePictureUploadedURL
																					? "verified"
																					: ""
																			}
																			title={
																				profilePictureUploadedURL
																					? "Uploaded"
																					: "Click to Upload"
																			}
																			disabled={
																				apiLoading ||
																				!profilePictureFile
																			}
																			icon={
																				<>
																					{apiLoading ? (
																						<LoaderIcon />
																					) : (
																						<>
																							{profilePictureUploadedURL ? (
																								<CiCircleCheck />
																							) : (
																								<CiLocationArrow1 />
																							)}
																						</>
																					)}
																				</>
																			}
																			onClick={
																				handleProfilePictureUpload
																			}
																		/>
																	</div>
																</div>
															</td>
														</tr>
														<tr>
															<td colSpan="6" >
																<p className="status f-w-200 l-h-1 font-size-12">
																	NOTE: Please upload files in JPG, PNG, or JPEG format, up to 2MB. Files exceeding these limits won't be accepted.
																</p>
															</td>
														</tr>
														<tr>
															<td colSpan='4'>
																<p
																	className='font-size-12 color-primary'
																	style={{
																		margin: "10px 0 5px 0",
																	}}>
																	Cover photo
																</p>
																<div
																	className='flex center-left'
																	style={{
																		gap: "12px",
																	}}>
																	<div
																		className='default-image b-r-100 of-hid'
																		style={{
																			height: "40px",
																			width: "40px",
																			border: "solid 1px #f0f0f0",
																			backgroundImage:
																				data
																					.data
																					.coverPictureURL
																					? `url(${data.data.coverPictureURL})`
																					: null,
																		}}></div>
																	<div
																		className='flex center-center'
																		style={{
																			flex: 1,
																			gap: "4px",
																		}}>
																		<FileInput
																			defaultText='Select cover photo'
																			accept='image/jpeg, image/jpg, image/png'
																			maxSizeAllowed={
																				2 *
																				1024 *
																				1024
																			}
																			callback={(
																				f
																			) => {
																				setCoverPictureFile(
																					f
																				);
																			}}
																		/>
																		<ButtonLightTextIcon
																			type='button'
																			style={{ width:"300px"}}
																			data-name={
																				coverPictureUploadedURL
																					? "verified"
																					: ""
																			}
																			title={
																				coverPictureUploadedURL
																					? "Uploaded"
																					: "Click to Upload"
																			}
																			disabled={
																				apiLoading ||
																				!coverPictureFile
																			}
																			icon={
																				<>
																					{apiLoading ? (
																						<LoaderIcon />
																					) : (
																						<>
																							{coverPictureUploadedURL ? (
																								<CiCircleCheck />
																							) : (
																								<CiLocationArrow1 />
																							)}
																						</>
																					)}
																				</>
																			}
																			onClick={
																				handleCoverPictureUpload
																			}
																		/>
																	</div>
																</div>
															</td>
														</tr>
														<tr>
															<td colSpan="6" >
																<p className="status f-w-200 l-h-1 font-size-12">
																	NOTE: Please upload files in JPG, PNG, or JPEG format, up to 2MB. Files exceeding these limits won't be accepted.
																</p>
															</td>
														</tr>
														<tr>
															<td colSpan='3'>
																<br />
															</td>
														</tr>
														<tr>
															<td
																colSpan='3'
																className='t-a-r'>
																<div
																	className='flex center-right'
																	style={{
																		gap: "8px",
																	}}>
																	<ButtonLightTextIcon
																		onClick={() =>
																			setShow(
																				false
																			)
																		}
																		type='button'
																		title='Cancel'
																	/>
																	<ButtonTextIcon
																		type='submit'
																		disabled={
																			apiLoading ||
																			loading
																		}
																		icon={
																			apiLoading ? (
																				<LoaderIcon />
																			) : (
																				<CiSaveUp1 />
																			)
																		}
																		title='Update Profile'
																	/>
																</div>
															</td>
														</tr>
													</tbody>
												</table>
											</Form>
										)}
									</Formik>
								</div>
							</div>
						</div>
					</section>
				</PopUpModal>
			)}
		</section>
	);
};
export default Profile;



