import { useEffect, useState } from "react";
import FullPageLoader from "../../components/ui/FullPageLoader";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import { API_URL } from "../../utilities/constants";
import Pagination from "../../components/common/Pagination";
import PageTitle from "../../components/common/PageTitle";
import ResultsPerPage from "../_common/ResultsPerPage";
import { useUI } from "../../context/ui";
import { formatDateTime, formatServerDate } from "../../utilities/helpers";
import { CiViewList } from "react-icons/ci";

const MyCreditScoreHistory = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [data, setData] = useState(null);
  const { setSegmentTitle } = useUI();

  const callListApi = async () => {
    setLoading(true);
    const pageNo = new URLSearchParams(location.search).get("pageNo");
    const pageSize = new URLSearchParams(location.search).get("pageSize");
    const fromDate = new URLSearchParams(location.search).get("fromDate");
    const toDate = new URLSearchParams(location.search).get("toDate");
    try {
      const resp = await fetch(
        API_URL +
          `/api/1.0.0/tenant/credit-score-history?pageNo=${pageNo}&pageSize=${pageSize}&sortOrder=desc`,
        {
          method: "GET",
          headers: {
            "X-JWT-Assertion": localStorage.getItem("token"),
          },
          redirect: "follow",
        }
      );
      if (resp.ok) {
        const responseData = await resp.json();
        if (responseData.result.responseCode === 200) {
          setData(responseData.data);
        }
      }
    } catch (e) {
      console.log("Error", e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (location.search) {
      callListApi();
      setSegmentTitle("My Credit Score History");
    }
  }, [location.search]);

  return (
    <section className="pos-rel">
      <PageTitle title="My Credit Score History" />
      <FullPageLoader isActive={loading} />
      <section>
        <div
          className="flex center-left"
          style={{ padding: "20px", gap: "12px" }}
        >
          <ResultsPerPage targetURL={`/te-credit-score-history?pageNo=0&pageSize=`} />
          <div className="t-a-l" style={{ marginLeft: "auto" }}>
          <Link to="/credit-score">
            <ButtonTextIcon title="Check Credit Score" icon={<CiViewList />} />
          </Link>
        </div>
        </div>
        <div className="results">
          <div className="rs-table-holder">
            <table className="rs-table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Credit Score</th>
                  <th>Exp Date</th>
                  <th>Checked At</th>
                  <th>Status</th>
                </tr>
              </thead>
              {data && (
                <tbody>
                  {data?.creditScoreHistory?.map((d, i) => (
                    <tr key={i}>
                      <td>
                        <p>{i + 1}.</p>
                      </td>
                      <td>
                        <p className=" color-primary">{d.creditScore}</p>
                      </td>
                      <td>
                        <p>{formatServerDate(d.expDate)}</p>
                      </td>
                      <td>
                        <p>{formatDateTime(d.createdAt)}</p>
                      </td>
                      <td>
                        <div className="flex center-left">
                          {d.isActive ? (
                            <div
                            className="b-r-20"
                              style={{
                                background: "green",
                                padding: "12px 24px",
                              }}
                            >
                              <p className="color-white l-h-1">Active</p>
                            </div>
                          ) : (
                            <div
                            className="b-r-20"
                              style={{
                                background: "red",
                                padding: "12px 24px",
                              }}
                            >
                              <p className="color-white l-h-1">Expired</p>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
              {((!data && !loading) ||
                (data &&
                  data?.creditScoreHistory?.length === 0 &&
                  !loading)) && (
                <tbody>
                  <tr>
                    <td
                      colSpan={5}
                      style={{ textAlign: "center", padding: "10px" }}
                    >
                      <p> No records found. </p>
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </div>
        {data && (
          <Pagination
            totalRecords={data.totalRecords}
            pageNo={data.pageNo}
            pageSize={data.pageSize}
            baseUrl="/te-credit-score-history"
          />
        )}
      </section>
    </section>
  );
};
export default MyCreditScoreHistory;
