import { useEffect, useState } from "react";
import { CiCircleCheck, CiCircleRemove } from "react-icons/ci";

const SingleToast = ({ data, removeToast }) => {
	const [visible, setVisible] = useState(false);

	useEffect(() => {
		if (data) {
			setVisible(true);

			const timer = setTimeout(() => {
				setVisible(false);
				removeToast(data.id);
			}, 4000);

			return () => {
				clearTimeout(timer);
			};
		}
	}, [data]);

	if (visible) {
		return (
			<div
				className='single-toast'
				onClick={() => {
					removeToast(data.id);
				}}>
				<div
					className={`icon ${
						data.status ? "icon-success" : "icon-failure"
					}`}>
					{data.status ? (
						<CiCircleCheck color='#27ae60' />
					) : (
						<CiCircleRemove color='#e74c3c' />
					)}
				</div>
				<p className='color-white'>{data.message}</p>
				<hr />
			</div>
		);
	} else {
		return null;
	}
};

export default SingleToast;
