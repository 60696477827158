import { useEffect } from "react";
import PageTitle from "../../components/common/PageTitle";
import { NavLink } from "react-router-dom";
import { DEFAULT_PER_PAGE } from "../../utilities/constants";
import { useUI } from "../../context/ui";
import ButtonTextOnly from "../../components/ui/ButtonTextOnly";
import { useParams } from "react-router-dom";
import { CiCircleCheck } from "react-icons/ci";

const AddPreInstallmentPaymentSuccess = () => {
  const { id } = useParams();
  const { setSegmentTitle } = useUI();

  useEffect(() => {
    setSegmentTitle("Payment Success");
  }, []);

  return (
    <>
      <section>
        <PageTitle title="Payment Success" />
        <section
          className="flex center-center"
          style={{
            height: "100vh",
            width: "100%",
          }}
        >
          <div>
            <CiCircleCheck
              className="color-secondary"
              style={{
                height: "80px",
                width: "auto",
                margin: "0 auto",
                display: "block",
              }}
            />
            <br />
            <div className="t-a-c">
              <h4 className="color-primary" style={{ marginBottom: "10px" }}>
                Payment Success!
              </h4>
              <p>
                Your payment was processed successfully.
                <br />
                Thank you for your transaction.
              </p>
              <br />
              <div>
                <NavLink to={`/te-credit-accounts/${id}`}>
                  <ButtonTextOnly title="Return Back" />
                </NavLink>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default AddPreInstallmentPaymentSuccess;
