import {
	CiGrid2H,
	CiGrid41,
	CiLogout,
	CiUser,
	CiViewList,
} from "react-icons/ci";
import RentSavvyLogo from "../common/RentSavvyLogo";
import { decodeRolesFromToken } from "../../utilities/helpers";
import { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import {
	API_URL,
	APP_ROLES,
	DEFAULT_PER_PAGE,
	RS_VERSION,
} from "../../utilities/constants";
import { useUI } from "../../context/ui";
import { useFetch } from "../../hooks/useFetch";

const UserCard = () => {
	const { handleLogout } = useUI();
	const { data, error, loading } = useFetch(
		API_URL + "/api/1.0.0/rs/profile",
		{
			method: "GET",
		}
	);

	if (error) {
		if (error?.status === 401) {
			handleLogout();
		}
	}

	const getRespectiveUI = () => {
		const me = decodeRolesFromToken();
		const myRoleId = me[0];
		switch (myRoleId) {
			case 1:
				return "Rent Savvy Super Admin";
			case 2:
				return "Rent Savvy Subadmin";
			case 3:
				return data?.data.metaData?.name + " - Agency";
			case 4:
				return (
					data?.data.firstName +
					" " +
					data?.data.lastName +
					" - Broker (" +
					data?.data.metaData?.name +
					")"
				);
			case 5:
				return (
					data?.data.firstName +
					" " +
					data?.data.lastName +
					" - Broker"
				);
			case 6:
				return data?.data.metaData?.name + " - Credit Provider";
			case 7:
				return (
					data?.data.firstName +
					" " +
					data?.data.lastName +
					" - Approver (" +
					data?.data.metaData?.name +
					")"
				);
			case 8:
				return (
					data?.data.firstName +
					" " +
					data?.data.lastName +
					" - Tenant"
				);
			case 9:
				return (
					data?.data.firstName +
					" " +
					data?.data.lastName +
					" - Reviewer (" +
					data?.data.metaData?.name +
					")"
				);
			default:
				return "Rent Savvy User";
		}
	};

	return (
		<div className='user-card'>
			<div className='icon flex center-center b-r-100'>
				<CiUser />
			</div>
			{data && (
				<div className='definition flex center-left'>
					<div>
						<p className='l-h-1 color-primary username'>
							<strong>{data?.data.emailId}</strong>
						</p>
						<p
							className='l-h-1 color-primary'
							style={{ fontSize: "12px" }}>
							{getRespectiveUI()}
						</p>
					</div>
				</div>
			)}
		</div>
	);
};

const MenuItem = ({ allowedRoles, url, title, icon, activeUrl }) => {
	const { pathname } = useLocation();
	const [isAllowed, setIsAllowed] = useState(false);
	const hasRole = (sourceArray, targetArray) => {
		return targetArray.some((value) => sourceArray.includes(value));
	};

	const checkIfRoleIdExist = () => {
		const roles = decodeRolesFromToken();
		setIsAllowed(hasRole(allowedRoles, roles) ? true : false);
	};

	useEffect(() => {
		checkIfRoleIdExist();
	}, []);

	const checkIfStringExists = (arr) => {
		if (arr) {
			return arr.includes(pathname);
		} else {
			return false;
		}
	};

	if (!isAllowed) {
		return <></>;
	} else {
		return (
			<li
				className={
					pathname === url || checkIfStringExists(activeUrl)
						? "active"
						: ""
				}>
				<NavLink to={url}>
					{icon} {title}
				</NavLink>
			</li>
		);
	}
};

const getDashboardURL = (roleId) => {
	const role = APP_ROLES.find(role => role.roleId === roleId);
	return role ? role.defaultURL : "/";
};


const Aside = () => {
	const { setShowLogout } = useUI();
	const navigate = useNavigate();
	const handleLogoClick = () => {
	
		const roles = decodeRolesFromToken();
		if (roles && roles.length > 0) {
			const roleId = roles[0]; 
			const url = getDashboardURL(roleId);
			navigate(url); 
		}
	};

	return (
		<aside>
			<div className='top'>
				<div className='logo' onClick={handleLogoClick}>
					<NavLink to="#"><RentSavvyLogo /></NavLink>
				</div>
				<UserCard />
			</div>
			{/* <div className='top'>
				<div className='logo'>
					<RentSavvyLogo />
					<NavLink to="/"><RentSavvyLogo /></NavLink>
				</div>
				<UserCard />
			</div> */}
			<div className='middle'>
				<nav>
					<ul>
						{/* =================== RoleId: 1 ================== */}

						<MenuItem
							allowedRoles={[1]}
							url='/sa-dashboard'
							title='Dashboard'
							icon={<CiGrid41 />}
						/>
						<MenuItem
							allowedRoles={[1]}
							url={`/sa-agency?pageNo=0&pageSize=${DEFAULT_PER_PAGE}`}
							activeUrl={["/sa-agency", "/sa-agency/create"]}
							title='Agency'
							icon={<CiViewList />}
						/>
						<MenuItem
							allowedRoles={[1]}
							url={`/sa-credit-provider?pageNo=0&pageSize=${DEFAULT_PER_PAGE}`}
							activeUrl={[
								"/sa-credit-provider",
								"/sa-credit-provider/create",
							]}
							title='Credit Provider'
							icon={<CiViewList />}
						/>

						{/* =================== RoleId: 2 ================== */}

						{/* =================== RoleId: 3 ================== */}

						<MenuItem
							allowedRoles={[3]}
							url='/basa-dashboard'
							title='Dashboard'
							icon={<CiGrid41 />}
						/>
						<MenuItem
							allowedRoles={[1, 3]}
							url={`/basa-brokers?pageNo=0&pageSize=${DEFAULT_PER_PAGE}`}
							activeUrl={["/basa-brokers", "/basa-brokers/add"]}
							title='Brokers'
							icon={<CiUser />}
						/>

						{/* =================== RoleId: 4 ================== */}

						<MenuItem
							allowedRoles={[4]}
							url='/ab-dashboard'
							title='Dashboard'
							icon={<CiGrid41 />}
						/>
						<MenuItem
							allowedRoles={[3, 4]}
							url={`/ab-properties?pageNo=0&pageSize=${DEFAULT_PER_PAGE}`}
							activeUrl={["/ab-properties", "/ab-properties/add"]}
							title='Properties'
							icon={<CiViewList />}
						/>
						<MenuItem
							allowedRoles={[3, 4]}
							url={`/ab-loan-applications?pageNo=0&pageSize=${DEFAULT_PER_PAGE}`}
							activeUrl={[
								"/ab-loan-applications",
								"/ab-loan-applications/create",
							]}
							title='Applications'
							icon={<CiViewList />}
						/>

						{/* =================== RoleId: 6 ================== */}

						<MenuItem
							allowedRoles={[6]}
							url='/cpsa-dashboard'
							title='Dashboard'
							icon={<CiGrid41 />}
						/>
						<MenuItem
							allowedRoles={[]}
							url={`/cpsa-credit-provider?pageNo=0&pageSize=${DEFAULT_PER_PAGE}`}
							activeUrl={[
								"/cpsa-credit-provider",
								"/cpsa-credit-provider/add",
							]}
							title='Users'
							icon={<CiViewList />}
						/>
						<MenuItem
							allowedRoles={[6, 7]}
							url={`/cpsa-loan-application-list?pageNo=0&pageSize=${DEFAULT_PER_PAGE}`}
							activeUrl={["/cpsa-loan-application-list"]}
							title='Applications'
							icon={<CiViewList />}
						/>
						<MenuItem
							allowedRoles={[6]}
							url={`/cpsa-credit-accounts?pageNo=0&pageSize=${DEFAULT_PER_PAGE}`}
							activeUrl={["/cpsa-credit-accounts"]}
							title='Credit Accounts'
							icon={<CiViewList />}
						/>
						{/* =================== RoleId: 7 ================== */}

						<MenuItem
							allowedRoles={[7]}
							url='/cpua-dashboard'
							title='Dashboard'
							icon={<CiGrid41 />}
						/>
						{/* =================== RoleId: 8 ================== */}

						<MenuItem
							allowedRoles={[8]}
							url='/te-dashboard'
							title='Dashboard'
							icon={<CiGrid41 />}
						/>
						<MenuItem
							allowedRoles={[8]}
							url={`/te-my-applications?pageNo=0&pageSize=${DEFAULT_PER_PAGE}`}
							activeUrl={["/te-my-applications"]}
							title='Applications'
							icon={<CiGrid2H />}
						/>
						<MenuItem
							allowedRoles={[8]}
							url={`/te-credit-accounts?pageNo=0&pageSize=${DEFAULT_PER_PAGE}`}
							activeUrl={["/te-credit-accounts"]}
							title='Credit Accounts'
							icon={<CiViewList />}
						/>
						<MenuItem
							allowedRoles={[8]}
							url={`/te-credit-score-history?pageNo=0&pageSize=${DEFAULT_PER_PAGE}`}
							activeUrl={["/te-credit-score-history"]}
							title='Credit Score History'
							icon={<CiViewList />}
						/>
						<MenuItem
							allowedRoles={[8]}
							url={`/te-offer`}
							activeUrl={["/te-offer"]}
							title='My Rental Offer'
							icon={<CiViewList />}
						/>


						{/* =================== RoleId: 8 ================== */}

						<MenuItem
							allowedRoles={[9]}
							url='/cpur-dashboard'
							title='Dashboard'
							icon={<CiGrid41 />}
						/>
						<MenuItem
							allowedRoles={[9]}
							url={`/cpur-loan-list?pageNo=0&pageSize=${DEFAULT_PER_PAGE}`}
							activeUrl={["/cpur-loan-list"]}
							title='Applications'
							icon={<CiViewList />}
						/>
						<MenuItem
							allowedRoles={[9]}
							url={`/cpur-my-task?pageNo=0&pageSize=${DEFAULT_PER_PAGE}`}
							activeUrl={["/cpur-my-task"]}
							title='My Task'
							icon={<CiViewList />}
						/>
						{/* =================== Common ================== */}

						<MenuItem
							allowedRoles={[1, 8]}
							url={`/transaction-history?pageNo=0&pageSize=${DEFAULT_PER_PAGE}`}
							activeUrl={[
								"/transaction-history",
							]}
							title='Transaction History'
							icon={<CiViewList />}
						/>
						<MenuItem
							allowedRoles={[1, 2, 3, 4, 5, 6, 7, 8, 9]}
							url='/profile'
							activeUrl={[
								"/profile",
							]}
							title='Profile'
							icon={<CiUser />}
						/>
					</ul>
				</nav>
			</div>
			<div className='bottom'>
				<div className='logout' onClick={() => setShowLogout(true)}>
					<p className='l-h-1-2 color-primary'>Log out</p>
					<CiLogout />
				</div>
				<div className='version'>
					<p className='l-h-1-2'>www.rent-savvy.com</p>
					<p className='l-h-1-2'>v{RS_VERSION}</p>
				</div>
			</div>
		</aside>
	);
};

export default Aside;
