import { useEffect, useRef, useState } from "react";
import FullPageLoader from "../../components/ui/FullPageLoader";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import {
	CiBag1,
	CiBarcode,
	CiBookmark,
	CiCalculator1,
	CiCircleCheck,
	CiClock2,
	CiHeart,
	CiHome,
	CiHospital1,
	CiLink,
	CiLocationOn,
	CiCircleRemove,
	CiMail,
	CiMoneyBill,
	CiPhone,
	CiStickyNote,
	CiUser,
	CiLocationArrow1,
	CiBoxList,
	CiChat1,
	CiTimer,
} from "react-icons/ci";
import RSModal from "../../components/ui/RSModal";
import FileInput from "../../components/ui/FileInput";
import LoaderIcon from "../../components/ui/LoaderIcon";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import { API_URL, LOAN_APPLICATION_STATUSES } from "../../utilities/constants";
import PageTitle from "../../components/common/PageTitle";
import { useUI } from "../../context/ui";
import TransparentImage from "../../images/icons/4x2.5.png";
import {
	formatDateTime,
	formatServerDate,
} from "../../../src/utilities/helpers";
import ApplicationHistoryView from "../../components/loan/ApplicationHistoryView";
import { formatAmountValue } from "../../utilities/helpers"

const AgencyBrokerLoanApplicationDetail = () => {
	const { id } = useParams();
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState(null);
	const {
		setNewNotification,
		setSegmentTitle,
		setShowNotification,
		addNotification,
		addToastMessage,
	} = useUI();
	const navigate = useNavigate();
	const [tenancyAgreementUploadURL, setTenancyAgreementUploadURL] =
		useState("");
	const [tenancyAgreementFile, setTenancyAgreementFile] = useState(null);
	const FieldTitle = ({ title }) => {
		return (
			<p
				className='title f-w-700 color-primary'
				style={{ marginBottom: "5px", fontSize: "14px" }}>
				{title}
			</p>
		);
	};

	const handleReject = async (id) => {
		setLoading(true);
		try {
			const response = await fetch(
				`${API_URL}/api/1.0.0/loan-application/reject-loan/${id}`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
						"X-JWT-Assertion": localStorage.getItem("token"),
					},
				}
			);
			if (response.ok) {
				const data = await response.json();
				if (data) {
					callApi(id);
				}
			} else {
				const errorData = await response.json();
				throw new Error(
					errorData.result.responseDescription || "API request failed"
				);
			}
		} catch (e) {
			console.error("Error:", e);
			setNewNotification(true);
			setShowNotification(true);
			addNotification({
				id: Date.now(),
				message:
					"An error occurred while processing your acceptance. Please try again.",
				status: false,
				archive: false,
			});
			setLoading(false);
		}
	};
	const handleAccept = async (id) => {
		setLoading(true);
		try {
			const request = {
				tenancyDocumentURL: tenancyAgreementUploadURL,
			};
			const response = await fetch(
				`${API_URL}/api/1.0.0/loan-application/agreement-documents/${id}`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
						"X-JWT-Assertion": localStorage.getItem("token"),
					},
					body: JSON.stringify(request),
				}
			);
			if (response.ok) {
				const data = await response.json();
				if (data) {
					callApi(id);
				}
			} else {
				const errorData = await response.json();
				throw new Error(
					errorData.result.responseDescription || "API request failed"
				);
			}
		} catch (e) {
			console.error("Error:", e);
			setNewNotification(true);
			setShowNotification(true);
			addNotification({
				id: Date.now(),
				message:
					"An error occurred while processing your acceptance. Please try again.",
				status: false,
				archive: false,
			});
			setLoading(false);
		}
	};

	const handleTenancyAgreementUpload = async () => {
		setLoading(true);
		try {
			const formdata = new FormData();
			formdata.append(
				"file",
				tenancyAgreementFile,
				tenancyAgreementFile.name
			);
			formdata.append("type", "TENANCY_AGREEMENT");
			const resp = await fetch(API_URL + `/api/1.0.0/uploadFile`, {
				method: "POST",
				headers: {
					"X-JWT-Assertion": localStorage.getItem("token"),
				},
				body: formdata,
				redirect: "follow",
			});
			if (resp.ok) {
				const responseData = await resp.json();
				if (responseData.result.responseCode === 200) {
					setTenancyAgreementUploadURL(responseData.data);
					addToastMessage({
						status: true,
						message: "Tenancy agreement uploaded Successfully!",
					});
				}
			} else {
				const errorData = await resp.json();
				const errorMessage = errorData.responseDescription;
				addToastMessage({
					status: false,
					message:
						errorMessage ||
						"Something went wrong while uploading a tenancy agreement. Please try again.",
				});
			}
		} catch (e) {
			console.log("Error", e);
			setNewNotification(true);
			setShowNotification(true);
			addNotification({
				id: Date.now(),
				message:
					"Could not upload the tenancy agreement at the moment.",
				status: false,
				archive: false,
			});
		} finally {
			setLoading(false);
		}
	};

	const callApi = async (id) => {
		setLoading(true);
		try {
			const resp = await fetch(
				API_URL + `/api/1.0.0/loan-application/${id}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						"X-JWT-Assertion": localStorage.getItem("token"),
					},
				}
			);
			if (resp.ok) {
				const response = await resp.json();
				setData(response.data);
			}
		} catch (e) {
			console.log("Error", e);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (id) {
			callApi(id);
			setSegmentTitle("Application Detail");
		}
	}, [id]);

	const getStatusDetails = (id) => {
		const o = LOAN_APPLICATION_STATUSES.find((obj) => obj.id === id);
		return o
			? o
			: {
				title: "Not found",
				color: "#5dc6b3",
			};
	};

	const downloadTenancyAgreementPDF = async (e, id) => {
		e.preventDefault();
		const apiUrl = `${API_URL}/api/1.0.0/loan-application/tenancy-document/${id}`;
		try {
			const response = await fetch(apiUrl, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					"X-JWT-Assertion": localStorage.getItem("token"),
				},
			});
			const data = await response.json();
			if (data.result.responseCode === 200) {
				window.location.href = data.data;
			} else {
				const errorData = await response.json();
				throw new Error(
					errorData.result.responseDescription || "API request failed"
				);
			}
		} catch (e) {
			console.log("Error", e);
			setNewNotification(true);
			setShowNotification(true);
			addNotification({
				id: Date.now(),
				message:
					"We are currently unable to download the tenancy agreement. Please try again later.",
				status: false,
				archive: false,
			});
		}
	};

	const copyRef = useRef();

	const copyToClipboard = (str, ref) => {
		navigator.clipboard.writeText(str).then(
			function () {
				console.log(str);
				ref.current.innerHTML = "Copied.";
				setTimeout(() => {
					ref.current.innerHTML = "Click to copy";
				}, 400);
			},
			function (err) {
				console.error("Async: Could not copy text: ", err);
				ref.current.innerHTML = "Could not copy";
			}
		);
	};

	return (
		<section className='pos-rel'>
			<PageTitle title='Application Detail' />
			<FullPageLoader isActive={loading} />
			{data && (
				<section>
					<section className='property-detail pad-20'>
						<div className='flex center-left'>
							<p
								className='color-primary f-w-700'
								style={{
									width: "180px",
									overflow: "hidden",
									whiteSpace: "nowrap",
									textOverflow: "ellipsis",
								}}>
								<span>#</span>&nbsp;{data.applicationNo}
							</p>
							&nbsp;&nbsp;
							<div
								className='b-r-20  cursor-pointer'
								style={{
									background: "#f0f0f0",
									padding: "5px 20px",
								}}
								onClick={() => {
									copyToClipboard(
										data.applicationLink,
										copyRef
									);
								}}>
								<div className='flex center-center'>
									<p className='font-size-12' ref={copyRef}>
										Click to copy
									</p>
								</div>
							</div>
							<div
								className='mar-l-a b-r-20'
								style={{
									background: getStatusDetails(
										data.applicationStatus
									).color,
									padding: "12px 24px",
								}}>
								<p className='color-white l-h-1'>
									{
										getStatusDetails(data.applicationStatus)
											.title
									}
								</p>
							</div>
						</div>
						<br />
						<div className='wrap pad-20 pos-rel'>
							{/* <div
								className='credit-score-application-detail b-r-100 flex center-center'
								title='Credit Score'>
								{data.creditScore ? data.creditScore : "-"}
							</div> */}
							<table className='col-1-1'>
								<thead></thead>
								<tbody>
									<tr>
										<td colSpan='2'>
											<p className='f-w-600 color-primary'>
												Tenant Info:
											</p>
										</td>
									</tr>
									<tr>
										<td colSpan='2'>
											<hr />
										</td>
									</tr>
									<tr>
										<td>
											<div className='flex center-left'>
												<CiUser className='color-faded' />
												&nbsp;
												<p className='font-size-14 color-primary'>
													{data.tenant.firstName}{" "}
													{data.tenant.lastName}
												</p>
											</div>
										</td>
										<td>
											<div className='flex center-left'>
												<CiPhone className='color-faded' />
												&nbsp;
												<p className='font-size-14 color-primary'>
													{`(+971) ${data.tenant.phoneNo}`}
												</p>
											</div>
										</td>
									</tr>
									<tr>
										<td colSpan='2'>
											<div className='flex center-left'>
												<CiMail className='color-faded' />
												&nbsp;
												<p className='font-size-14 color-primary'>
													{data.tenant.email}
												</p>
											</div>
										</td>
									</tr>
									<tr>
										<td colSpan='2'>
											<br />
										</td>
									</tr>
									<tr>
										<td colSpan='2'>
											<p className='f-w-600 color-primary'>
												Agreement:
											</p>
										</td>
									</tr>
									<tr>
										<td colSpan='2'>
											<hr />
										</td>
									</tr>
									<tr>
										<td>
											<div className='flex center-left'>
												<CiBookmark className='color-faded' />
												&nbsp;
												<p className='font-size-14 color-faded'>
													Type
												</p>
												&nbsp;
												<p className='font-size-14 color-primary'>
													{data.agreementType}
												</p>
											</div>
										</td>
										<td>
											<div className='flex center-left'>
												<CiMoneyBill className='color-faded' />
												&nbsp;
												<p className='font-size-14 color-faded'>
													Rent
												</p>
												&nbsp;
												<p className='font-size-14 color-primary'>
													{`${formatAmountValue(data.rentalValue)}/- AED`}
												</p>
											</div>
										</td>
									</tr>
									<tr>
										<td colSpan='2'>
											<div className='flex top-left'>
												<CiCircleCheck className='color-faded' />
												&nbsp;
												<p className='font-size-14 color-faded'>
													Terms
												</p>
												&nbsp;
												<p className='font-size-14 color-primary flex-1'>
													{data.rentalTerms}
												</p>
											</div>
										</td>
									</tr>
									<tr>
										<td colSpan='2'>
											<br />
										</td>
									</tr>
									<tr>
										<td colSpan='2'>
											<p className='f-w-600 color-primary'>
												Income Info:
											</p>
										</td>
									</tr>
									<tr>
										<td colSpan='2'>
											<hr />
										</td>
									</tr>
									<tr>
										<td>
											<div className='flex center-left'>
												<CiClock2 className='color-faded' />
												&nbsp;
												<p className='font-size-14 color-faded'>
													DoB
												</p>
												&nbsp;
												<p className='font-size-14 color-primary'>
													{data.dateOfBirth
														? formatServerDate(
															data.dateOfBirth
														)
														: "-"}
												</p>
											</div>
										</td>
										<td>
											<div className='flex center-left'>
												<CiHeart className='color-faded' />
												&nbsp;
												<p className='font-size-14 color-faded'>
													Marital Status
												</p>
												&nbsp;
												<p className='font-size-14 color-primary'>
													{data.maritalStatus ===
														null ? (
														<>
															<span>-</span>
														</>
													) : (
														<>
															{data.maritalStatus
																? "Married"
																: "Unmarried"}
														</>
													)}
												</p>
											</div>
										</td>
									</tr>
									<tr>
										<td>
											<div className='flex center-left'>
												<CiBookmark className='color-faded' />
												&nbsp;
												<p className='font-size-14 color-faded'>
													Citizenship
												</p>
												&nbsp;
												<p className='font-size-14 color-primary'>
													{data.citizenshipStatus
														? data.citizenshipStatus
														: "-"}
												</p>
											</div>
										</td>
										<td>
											<div className='flex center-left'>
												<CiStickyNote className='color-faded' />
												&nbsp;
												<p className='font-size-14 color-faded'>
													Emirates ID
												</p>
												&nbsp;
												<p className='font-size-14 color-primary'>
													{data.uaePass
														? data.uaePass
														: "-"}
												</p>
											</div>
										</td>
									</tr>
									<tr>
										<td>
											<div className='flex center-left'>
												<CiMoneyBill className='color-faded' />
												&nbsp;
												<p className='font-size-14 color-faded'>
													Employment
												</p>
												&nbsp;
												<p className='font-size-14 color-primary'>
													{data.employmentType
														? data.employmentType
														: "-"}
												</p>
											</div>
										</td>
										<td>
											<div className='flex center-left'>
												<CiMoneyBill className='color-faded' />
												&nbsp;
												<p className='font-size-14 color-faded'>
													{" "}
													{data.employmentType ===
														"Self Employed"
														? "Annual Revenue"
														: "Annual Income"}
												</p>
												&nbsp;
												<p className='font-size-14 color-primary'>
													{data.annualIncome
														? `${formatAmountValue(data.annualIncome)}/- AED`
														: "-"}
												</p>
											</div>
										</td>
									</tr>
									<tr>
										<td>
											<div className='flex center-left'>
												<CiHospital1 className='color-faded' />
												&nbsp;
												<p className='font-size-14 color-faded'>
													{data.employmentType ===
														"Self Employed"
														? "Business Type"
														: "Company Type"}
												</p>
												&nbsp;
												<p className='font-size-14 color-primary'>
													{data.companyType
														? data.companyType
														: "-"}
												</p>
											</div>
										</td>
										<td>
											<div className='flex center-left'>
												<CiHospital1 className='color-faded' />
												&nbsp;
												<p className='font-size-14 color-faded'>
													{data.employmentType ===
														"Self Employed"
														? "Business Name"
														: "Company Name"}
												</p>
												&nbsp;
												<p className='font-size-14 color-primary'>
													{data.companyName
														? data.companyName
														: "-"}
												</p>
											</div>
										</td>
									</tr>
									<tr>
										<td colSpan='2'>
											<div className='flex center-left'>
												<CiLocationOn className='color-faded' />
												&nbsp;
												<p className='font-size-14 color-faded'>
													{data.employmentType ===
														"Self Employed"
														? "Business Address"
														: "Company Address"}
												</p>
												&nbsp;
												<p className='font-size-14 color-primary'>
													{data.companyAddress
														? data.companyAddress
														: "-"}
												</p>
											</div>
										</td>
									</tr>
									<tr>
										<td colSpan='2'>
											<div className='flex center-left'>
												<CiBag1 className='color-faded' />
												&nbsp;
												<p className='font-size-14 color-faded'>
													{data.employmentType ===
														"Self Employed"
														? "Nature of Business"
														: "Job Title"}
												</p>
												&nbsp;
												<p className='font-size-14 color-primary'>
													{data.title
														? data.title
														: "-"}
												</p>
											</div>
										</td>
									</tr>
									<tr>
										<td colSpan='2'>
											<div className='flex center-left'>
												<CiMail className='color-faded' />
												&nbsp;
												<p className='font-size-14 color-faded'>
													Work Email
												</p>
												&nbsp;
												<p className='font-size-14 color-primary'>
													{data.workEmail
														? data.workEmail
														: "-"}
												</p>
											</div>
										</td>
									</tr>
									<tr>
										<td colSpan='2'>
											<hr />
										</td>
									</tr>
									<tr>
										<td>
											<div className='flex top-left'>
												<CiHospital1 className='color-fade' />
												&nbsp;
												<p className='font-size-14 color-faded'>
													{data.employmentType ===
														"Self Employed"
														? "Recent Tax Returns"
														: "Payslip"}
												</p>
												&nbsp;
												<div className='flex-1'>
													{data.s3PayslipList.map(
														(p, i) => (
															<p
																className='font-size-12 color-primary'
																key={i}>
																<a
																	className='hover-color-secondary'
																	href={p}
																	target='_blank'
																	rel='noreferrer'>
																	Click to
																	View
																</a>
															</p>
														)
													)}
												</div>
											</div>
										</td>
										<td>
											<div className='flex center-left'>
												<CiHospital1 className='color-faded' />
												&nbsp;
												<p className='font-size-14 color-faded'>
													{data.employmentType ===
														"Self Employed"
														? "Incorparation Certificate"
														: "Salary Certificate"}
												</p>
												&nbsp;
												{data.salaryCertificate ? (
													<p className='font-size-12 color-primary flex-1'>
														<a
															className='hover-color-secondary'
															href={
																data.s3SalaryCertificate
															}
															target='_blank'
															rel='noreferrer'>
															Click to view
														</a>
													</p>
												) : (
													<p className='font-size-14 color-primary'>
														-
													</p>
												)}
											</div>
										</td>
									</tr>
									<tr>
										<td colSpan='2'>
											<div className='flex center-left'>
												<CiHospital1 className='color-faded' />
												&nbsp;
												<p className='font-size-14 color-faded'>
													Bank Statement
												</p>
												&nbsp;
												{data.s3BankStatementList ? (
													<p className='font-size-12 color-primary flex-1'>
														<a
															className='hover-color-secondary'
															href={
																data.s3BankStatementList
															}
															target='_blank'
															rel='noreferrer'>
															Click to view
														</a>
													</p>
												) : (
													<p className='font-size-14 color-primary'>
														-
													</p>
												)}
											</div>
										</td>
									</tr>
									<tr>
										<td colSpan='2'>
											<hr />
										</td>
									</tr>
									<tr>
										<td colSpan='2'>
											<p className='f-w-600 color-primary'>
												Bank Info:
											</p>
										</td>
									</tr>
									<tr>
										<td colSpan='2'>
											<hr />
										</td>
									</tr>
									<tr>
										<td colSpan='2'>
											<div className='flex center-left'>
												<CiHome className='color-faded' />
												&nbsp;
												<p className='font-size-14 color-faded'>
													Name
												</p>
												&nbsp;
												<p className='font-size-14 color-primary'>
													{data.bankName
														? data.bankName
														: "-"}
												</p>
											</div>
										</td>
									</tr>
									<tr>
										<td colSpan='2'>
											<div className='flex center-left'>
												<CiHome className='color-faded' />
												&nbsp;
												<p className='font-size-14 color-faded'>
													Account No
												</p>
												&nbsp;
												<p className='font-size-14 color-primary'>
													{data.bankAccountNumber
														? data.bankAccountNumber
														: "-"}
												</p>
											</div>
										</td>
									</tr>
									<tr>
										<td colSpan='2'>
											<div className='flex center-left'>
												<CiBarcode className='color-faded' />
												&nbsp;
												<p className='font-size-14 color-faded'>
													SWIFT Code
												</p>
												&nbsp;
												<p className='font-size-14 color-primary'>
													{data.swiftCode
														? data.swiftCode
														: "-"}
												</p>
											</div>
										</td>
									</tr>
									<tr>
										<td colSpan='2'>
											<br />
										</td>
									</tr>
									<tr>
										<td colSpan='2'>
											<p className='f-w-600 color-primary'>
												Broker Info:
											</p>
										</td>
									</tr>
									<tr>
										<td colSpan='2'>
											<hr />
										</td>
									</tr>
									<tr>
										<td colSpan='1'>
											<div className='flex center-left'>
												<div className='flex center-left'>
													<CiUser className='color-faded' />
													&nbsp;
													<p className='font-size-14 color-faded'>
														Name
													</p>
													&nbsp;
													<p className='font-size-14 color-primary'>
														{
															data.agencyBrokerDTO
																.firstName
														}{" "}
														{
															data.agencyBrokerDTO
																.lastName
														}
													</p>
												</div>
											</div>
										</td>
										<td colSpan='1'></td>
									</tr>
									<tr>
										<td colSpan='1'>
											<div className='flex center-left'>
												<div className='flex center-left'>
													<CiMail className='color-faded' />
													&nbsp;
													<p className='font-size-14 color-faded'>
														Email
													</p>
													&nbsp;
													<p className='font-size-14 color-primary'>
														{
															data.agencyBrokerDTO
																.email
														}
													</p>
												</div>
											</div>
										</td>
										<td colSpan='1'>
											<div className='flex center-left'>
												<div className='flex center-left'>
													<CiPhone className='color-faded' />
													&nbsp;
													<p className='font-size-14 color-faded'>
														Phone
													</p>
													&nbsp;
													<p className='font-size-14 color-primary'>
														{`(+971) ${data.agencyBrokerDTO.phoneNo}`}
													</p>
												</div>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</section>
					<section className='property-detail pad-20'>
						<div className='wrap b-r-5 of-hid'>
							<div
								className='default-image of-hid'
								style={
									data.property.s3PicturesList
										? {
											backgroundImage: `url(${data.property.s3PicturesList[0]})`,
										}
										: {}
								}>
								<img
									className='col-1-1'
									style={{
										height: "auto",
										display: "block",
									}}
									src={TransparentImage}
								/>
							</div>
							<br />
							<div className='pad-20'>
								<table className='col-1-1'>
									<thead></thead>
									<tbody>
										<tr>
											<td colSpan='2'>
												<p className='f-w-600 color-primary'>
													{data.property.propertyName}
												</p>
											</td>
										</tr>
										<tr>
											<td colSpan='2'>
												<p className='font-size-14 color-primary'>
													{data.property.description}
												</p>
											</td>
										</tr>
										<tr>
											<td colSpan='2'>
												<hr />
											</td>
										</tr>
										<tr>
											<td colSpan='2'>
												<div className='flex center-left'>
													<CiCalculator1 className='color-faded' />
													&nbsp;
													<p className='font-size-14 color-faded'>
														#RERA
													</p>
													&nbsp;
													<p className='font-size-14 color-primary'>
														{
															data.property
																.reraNumber
														}
													</p>
												</div>
											</td>
										</tr>
										<tr>
											<td colSpan='2'>
												<div className='flex center-left'>
													<CiLocationOn className='color-faded' />
													&nbsp;
													<p className='font-size-14 color-faded'>
														Address
													</p>
													&nbsp;
													<p className='font-size-14 color-primary'>
														{
															data.property
																.propertyAddress
														}
													</p>
												</div>
											</td>
											{/* <td>
                        <div className="flex center-left">
                          <CiLocationOn className="color-faded" />
                          &nbsp;
                          <p className="font-size-14 color-faded">PIN</p>
                          &nbsp;
                          <p className="font-size-14 color-primary">
                            {data.pinCode}
                          </p>
                        </div>
                      </td> */}
										</tr>
										<tr>
											<td>
												<div className='flex center-left'>
													<CiHome className='color-faded' />
													&nbsp;
													<p className='font-size-14 color-faded'>
														Size
													</p>
													&nbsp;
													<p className='font-size-14 color-primary'>
														{
															data.property
																.propertySize
														}{" "}
														sq.ft.
													</p>
												</div>
											</td>
											<td>
												<div className='flex center-left'>
													<CiHome className='color-faded' />
													&nbsp;
													<p className='font-size-14 color-faded'>
														Type
													</p>
													&nbsp;
													<p className='font-size-14 color-primary'>
														{
															data.property
																.propertyType
														}
													</p>
												</div>
											</td>
										</tr>
										<tr>
											<td colSpan='2'>
												<hr />
											</td>
										</tr>
										<tr>
											<td colSpan='2'>
												<div className='flex center-left'>
													<CiUser className='color-faded' />
													&nbsp;
													<p className='font-size-14 color-faded'>
														Owner
													</p>
													&nbsp;
													<p className='font-size-14 color-primary'>
														{
															data.property.owner
																.fullName
														}
													</p>
												</div>
											</td>
										</tr>
										<tr>
											<td colSpan='2'>
												<div className='flex center-left'>
													<CiLocationOn className='color-faded' />
													&nbsp;
													<p className='font-size-14 color-primary'>
														{
															data.property.owner
																.address
														}
													</p>
													<p className='font-size-14 color-faded'>
														,
													</p>
													&nbsp;
													<p className='font-size-14 color-primary'>
														{
															data.property.owner
																.pinCode
														}
													</p>
												</div>
											</td>
										</tr>
										<tr>
											<td colSpan='2'>
												<div className='flex center-left'>
													<CiMail className='color-faded' />
													&nbsp;
													<p className='font-size-14 color-primary'>
														{
															data.property.owner
																.email
														}
													</p>
												</div>
											</td>
										</tr>
										<tr>
											<td colSpan='2'>
												<div className='flex center-left'>
													<CiPhone className='color-faded' />
													&nbsp;
													<p className='font-size-14 color-primary'>
														{
															data.property.owner
																.phoneNumber
														}
													</p>
												</div>
											</td>
										</tr>
										<tr>
											<td colSpan='2'>
												<hr />
											</td>
										</tr>
										<tr>
											<td>
												<a
													href={
														data.property
															.deedCopySignUrl
													}
													target='_new'
													rel='noreferrer'>
													<div className='flex center-left'>
														<CiLink className='color-faded' />
														&nbsp;
														<p className='transition font-size-14 color-faded hover-color-secondary'>
															Deed Copy
														</p>
													</div>
												</a>
											</td>
											<td>
												<a
													href={
														data.property
															.propertyUrl
													}
													target='_blank'
													rel='noreferrer'>
													<div className='flex center-right'>
														<CiLink className='color-primary' />
														&nbsp;
														<p className='transition font-size-14 color-faded hover-color-secondary'>
															Open URL
														</p>
													</div>
												</a>
											</td>
										</tr>
										<tr>
											<td colSpan='2'>
												<br />
											</td>
										</tr>
										<tr>
											<td colSpan='2'>
												<div
													className='flex center-right'
													style={{ gap: "8px" }}>
													<RSModal
														selector={
															<ButtonTextIcon
																title='View History'
																icon={
																	<CiBoxList />
																}
															/>
														}
														content={
															<ApplicationHistoryView
																loanApplicationHistory={
																	data.loanApplicationHistory
																}
															/>
														}
													/>
												</div>
											</td>
										</tr>
										{data.actionRequired && (
											<>
												<tr>
													<td colSpan='2'>
														<br />
													</td>
												</tr>
												<tr>
													<td colSpan='2'>
														<p className=' font-size-14 color-primary '>
															NOTE: Please
															download the tenancy
															agreement, owner
															sign it, and upload
															it to complete the
															process.
														</p>
													</td>
												</tr>
												<tr>
													<td colSpan='2'>
														<hr />
													</td>
												</tr>
												<tr>
													<td colSpan='2'>
														<a
															href='#'
															onClick={(e) =>
																downloadTenancyAgreementPDF(
																	e,
																	data.id
																)
															}>
															<div className='flex center-left'>
																<CiLink className='color-primary' />
																&nbsp;
																<p className='transition font-size-14 color-faded hover-color-secondary'>
																	Click here
																	to download
																	tenancy
																	agreement
																</p>
															</div>
															<br />
														</a>
													</td>
												</tr>

												<tr>
													<td colSpan="">
														<FieldTitle title="Tenancy Agreement" />
														<FileInput
															defaultText="Select Tenancy Agreement"
															accept="application/pdf"
															maxSizeAllowed={2 * 1024 * 1024}
															callback={(f) => {
																setTenancyAgreementFile(
																	f
																);
															}}
														/>
														{tenancyAgreementFile && (
															<p
																className="cursor-pointer"
																style={{
																	fontSize: "12px",
																	color: "red",
																	marginTop: "10px",
																}}
																onClick={() => {
																	setTenancyAgreementFile(
																		null
																	);
																	setTenancyAgreementUploadURL(
																		""
																	);
																}}
															>
																Delete tenancy agreement file
															</p>
														)}
													</td>
													<td  className="t-a-r" style={{alignItems:"right"}}>
														<p>&nbsp;</p>
														<ButtonTextIcon
															type="button"
															style={{width:"200px"}}
															title={tenancyAgreementUploadURL ? "Uploaded" : "Click to upload"}
															disabled={loading || !tenancyAgreementFile}
															icon={
																<>
																	{loading ? (
																		<LoaderIcon />
																	) : (
																		<>
																			{tenancyAgreementUploadURL ? <CiCircleCheck /> : <CiLocationArrow1 />}
																		</>
																	)}
																</>
															}
															onClick={handleTenancyAgreementUpload}
														/>

														{tenancyAgreementFile && (
															<p
																style={{
																	fontSize: "12px",
																	color: "red",
																	marginTop: "10px",
																}}
															>
																*Click to upload
															</p>
														)}
													</td>
												</tr>
												{/* <tr>
													<td colSpan='1'>
														<FieldTitle title='Tenancy Agreement' />
														<FileInput
															defaultText='Select Tenancy Agreement'
															accept='application/pdf'
															maxSizeAllowed={
																2 * 1024 * 1024
															}
															callback={(f) => {
																setTenancyAgreementFile(
																	f
																);
															}}
														/>
													</td>
													<td
														colSpan='1'
														style={{
															paddingLeft: "10px",
														}}>
														<p>&nbsp;</p>
														<ButtonTextIcon
															type='button'
															title={
																tenancyAgreementUploadURL
																	? "Uploaded"
																	: "Upload"
															}
															disabled={
																loading ||
																!tenancyAgreementFile
															}
															icon={
																<>
																	{loading ? (
																		<LoaderIcon />
																	) : (
																		<>
																			{tenancyAgreementUploadURL ? (
																				<CiCircleCheck />
																			) : (
																				<CiLocationArrow1 />
																			)}
																		</>
																	)}
																</>
															}
															onClick={
																handleTenancyAgreementUpload
															}
														/>
													</td>
												</tr>
												{tenancyAgreementFile && (
													<tr>
														<td colSpan='2'>
															<p
																className='cursor-pointer'
																style={{
																	fontSize:
																		"12px",
																	color: "red",
																}}
																onClick={() => {
																	setTenancyAgreementFile(
																		null
																	);
																	setTenancyAgreementUploadURL(
																		""
																	);
																}}>
																Delete tenancy
																agreement file
															</p>
														</td>
													</tr>
												)} */}
												<tr>
													<td colSpan="6" >
														<p className="status f-w-200 l-h-1 font-size-12">
															NOTE: Please upload files in PDF format, up to 2MB. Files exceeding these limits won't be accepted.
														</p>
													</td>
												</tr>
												<tr>
													<td colSpan='2'>
														<br />
													</td>
												</tr>
												<tr>
													<td colSpan='2'>
														<div
															className='flex center-center'
															style={{
																gap: "12px",
															}}>
															{/* <RSModal
                                selector={
                                  <ButtonTextIcon
                                    disabled={loading}
                                    style={{ background: "red" }}
                                    type="button"
                                    title="Reject"
                                    icon={<CiCircleRemove />}
                                  />
                                }
                                content={
                                  <ConfirmStatusChange
                                    text="reject"
                                    callback={() => {
                                      handleReject(data.id);
                                    }}
                                  />
                                }
                              /> */}
															<RSModal
																selector={
																	<ButtonTextIcon
																		style={{
																			background:
																				"green",
																		}}
																		disabled={
																			loading ||
																			!tenancyAgreementFile
																		}
																		type='button'
																		title='Accept'
																		icon={
																			<CiCircleCheck />
																		}
																	/>
																}
																content={
																	<ConfirmStatusChange
																		text='accept'
																		disabled={
																			!tenancyAgreementFile
																		}
																		callback={() => {
																			handleAccept(
																				data.id
																			);
																		}}
																	/>
																}
															/>
														</div>
													</td>
												</tr>
											</>
										)}
									</tbody>
								</table>
							</div>
						</div>
					</section>
				</section>
			)}
		</section>
	);
};

const ConfirmStatusChange = ({ callback, setShow, text }) => {
	const [loading, setLoading] = useState(false);
	const handleActionChange = async () => {
		setLoading(true);
		try {
			await callback();
			setShow(false);
		} catch (e) {
			console.log("Error", e);
		} finally {
			setLoading(false);
		}
	};
	return (
		<div
			style={{
				height: "auto",
				width: "486px",
				padding: "40px",
				position: "relative", // Make the container relative for positioning the cross icon
				border: "1px solid #ddd", // Optional: for better visibility of the div
				borderRadius: "8px", // Optional: rounded corners for a polished look
				backgroundColor: "#fff", // Optional: white background for better contrast
			}}>
			<CiCircleRemove
				onClick={() => setShow(false)}
				style={{
					position: "absolute",
					top: "10px",
					right: "10px", // Positioning it to the top right within the div
					cursor: "pointer",
					fontSize: "24px",
					color: "grey",
				}}
			/>
			<h4 className='color-primary'>Confirm {text.toLowerCase()}</h4>
			<br />
			<p>
				Are you sure you want to {text.toLowerCase()}? Please make sure
				you understand the consequences of this action.
			</p>
			<br />
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "flex-end",
					gap: "12px",
				}}>
				<ButtonLightTextIcon
					title='Go back'
					icon={
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='26.562'
							height='17.809'
							viewBox='0 0 26.562 17.809'>
							<path d='M0,8.88,10.686,0l1.937,2.293L6.453,7.4H26.562v3H6.454l6.166,5.108-1.934,2.3Z' />
						</svg>
					}
					onClick={() => setShow(false)}
				/>
				{loading ? (
					<ButtonTextIcon
						type='button'
						title='Updating...'
						disabled={loading}
						icon={<LoaderIcon />}
					/>
				) : (
					<ButtonTextIcon
						type='button'
						title='Confirm'
						icon={<CiCircleCheck />}
						onClick={() => handleActionChange()}
					/>
				)}
			</div>
		</div>
	);
};

export default AgencyBrokerLoanApplicationDetail;
