import {
	CiBarcode,
	CiChat2,
	CiCirclePlus,
	CiFlag1,
	CiFloppyDisk,
	CiGrid41,
	CiLock,
	CiMusicNote1,
} from "react-icons/ci";
import PageTitle from "../../components/common/PageTitle";
import SearchBar from "../../components/ui/SearchBar";
import SampleChart from "../rs-superadmin/SampleChart";
import { useEffect } from "react";
import { useUI } from "../../context/ui";

const AgencySuperadminDashboard = () => {
	const { setSegmentTitle } = useUI();

	useEffect(() => {
		setSegmentTitle("Dashboard");
	}, []);

	return (
		<section>
			<PageTitle title='Dashboard' />
			<section className='dashboard'>
				<div className='quick-links'>
					<div className='one flex center-left'>
						<div
							className='flex center-left'
							style={{ gap: "10px" }}>
							<CiCirclePlus
								className='color-secondary'
								style={{ height: "60px", width: "auto" }}
							/>
							<div>
								<h5 className='color-primary f-w-700 l-h-1-2'>
									2468
								</h5>
								<p className='color-primary f-w-400 l-h-1-2'>
									Rent-Savvy Tenants
								</p>
							</div>
						</div>
					</div>
					<div className='one flex center-left'>
						<div
							className='flex center-left'
							style={{ gap: "10px" }}>
							<CiBarcode
								className='color-secondary'
								style={{ height: "60px", width: "auto" }}
							/>
							<div>
								<h5 className='color-primary f-w-700 l-h-1-2'>
									200
								</h5>
								<p className='color-primary f-w-400 l-h-1-2'>
									Broker Agencies
								</p>
							</div>
						</div>
					</div>
					<div className='one flex center-left'>
						<div
							className='flex center-left'
							style={{ gap: "10px" }}>
							<CiMusicNote1
								className='color-secondary'
								style={{ height: "60px", width: "auto" }}
							/>
							<div>
								<h5 className='color-primary f-w-700 l-h-1-2'>
									2468
								</h5>
								<p className='color-primary f-w-400 l-h-1-2'>
									Rent-Savvy Tenants
								</p>
							</div>
						</div>
					</div>
					<div className='one flex center-left'>
						<div
							className='flex center-left'
							style={{ gap: "10px" }}>
							<CiChat2
								className='color-secondary'
								style={{ height: "60px", width: "auto" }}
							/>
							<div>
								<h5 className='color-primary f-w-700 l-h-1-2'>
									200
								</h5>
								<p className='color-primary f-w-400 l-h-1-2'>
									Broker Agencies
								</p>
							</div>
						</div>
					</div>
					<div className='one flex center-left'>
						<div
							className='flex center-left'
							style={{ gap: "10px" }}>
							<CiFlag1
								className='color-secondary'
								style={{ height: "60px", width: "auto" }}
							/>
							<div>
								<h5 className='color-primary f-w-700 l-h-1-2'>
									200
								</h5>
								<p className='color-primary f-w-400 l-h-1-2'>
									Broker Agencies
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className='grid-view'>
					<div className='one'>
						<div className='flex center-left'>
							<h6 className='color-primary f-w-700'>
								Brokers Signups:
							</h6>
							<CiGrid41
								className='color-primary mar-l-a'
								style={{ height: "28px", width: "auto" }}
							/>
						</div>
						<br />
						<div className='chart'>
							<SampleChart />
						</div>
					</div>
					<div className='one'>
						<div className='flex center-left'>
							<h6 className='color-primary f-w-700'>
								Agency Signups:
							</h6>
							<CiCirclePlus
								className='color-primary mar-l-a'
								style={{ height: "28px", width: "auto" }}
							/>
						</div>
						<br />
						<div className='chart'>
							<SampleChart />
						</div>
					</div>
					<div className='one'>
						<div className='flex center-left'>
							<h6 className='color-primary f-w-700'>
								Credit Providers:
							</h6>
							<CiFloppyDisk
								className='color-primary mar-l-a'
								style={{ height: "28px", width: "auto" }}
							/>
						</div>
						<br />
						<div className='chart'>
							<SampleChart />
						</div>
					</div>
					<div className='one'>
						<div className='flex center-left'>
							<h6 className='color-primary f-w-700'>
								Tenants Signup:
							</h6>
							<CiLock
								className='color-primary mar-l-a'
								style={{ height: "28px", width: "auto" }}
							/>
						</div>
						<br />
						<div className='chart'>
							<SampleChart />
						</div>
					</div>
				</div>
			</section>
		</section>
	);
};

export default AgencySuperadminDashboard;
