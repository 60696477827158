const ButtonLightTextIcon = ({ icon, title, ...rest }) => {
	return (
		<button className='light' {...rest}>
			<span>
				{icon}
				<span>{title}</span>
			</span>
		</button>
	);
};

export default ButtonLightTextIcon;
