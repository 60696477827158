export const countries = [
	{
		name: "Afghanistan",
		dialingCode: "93",
		iso3Code: "AFG",
		iso2Code: "AF",
	},
	{
		name: "Albania",
		dialingCode: "355",
		iso3Code: "ALB",
		iso2Code: "AL",
	},
	{
		name: "Algeria",
		dialingCode: "213",
		iso3Code: "DZA",
		iso2Code: "DZ",
	},
	{
		name: "American Samoa",
		dialingCode: "0",
		iso3Code: "ASM",
		iso2Code: "AS",
	},
	{
		name: "Andorra",
		dialingCode: "376",
		iso3Code: "AND",
		iso2Code: "AD",
	},
	{
		name: "Angola",
		dialingCode: "244",
		iso3Code: "AGO",
		iso2Code: "AO",
	},
	{
		name: "Anguilla",
		dialingCode: "0",
		iso3Code: "AIA",
		iso2Code: "AI",
	},
	{
		name: "Antarctica",
		dialingCode: "0",
		iso3Code: "ATA",
		iso2Code: "AQ",
	},
	{
		name: "Antigua and Barbuda",
		dialingCode: "0",
		iso3Code: "ATG",
		iso2Code: "AG",
	},
	{
		name: "Argentina",
		dialingCode: "54",
		iso3Code: "ARG",
		iso2Code: "AR",
	},
	{
		name: "Armenia",
		dialingCode: "374",
		iso3Code: "ARM",
		iso2Code: "AM",
	},
	{
		name: "Aruba",
		dialingCode: "297",
		iso3Code: "ABW",
		iso2Code: "AW",
	},
	{
		name: "Australia",
		dialingCode: "61",
		iso3Code: "AUS",
		iso2Code: "AU",
	},
	{
		name: "Austria",
		dialingCode: "43",
		iso3Code: "AUT",
		iso2Code: "AT",
	},
	{
		name: "Azerbaijan",
		dialingCode: "994",
		iso3Code: "AZE",
		iso2Code: "AZ",
	},
	{
		name: "Bahamas",
		dialingCode: "0",
		iso3Code: "BHS",
		iso2Code: "BS",
	},
	{
		name: "Bahrain",
		dialingCode: "973",
		iso3Code: "BHR",
		iso2Code: "BH",
	},
	{
		name: "Bangladesh",
		dialingCode: "880",
		iso3Code: "BGD",
		iso2Code: "BD",
	},
	{
		name: "Barbados",
		dialingCode: "0",
		iso3Code: "BRB",
		iso2Code: "BB",
	},
	{
		name: "Belarus",
		dialingCode: "0",
		iso3Code: "BLR",
		iso2Code: "BY",
	},
	{
		name: "Belgium",
		dialingCode: "32",
		iso3Code: "BEL",
		iso2Code: "BE",
	},
	{
		name: "Belize",
		dialingCode: "501",
		iso3Code: "BLZ",
		iso2Code: "BZ",
	},
	{
		name: "Benin",
		dialingCode: "229",
		iso3Code: "BEN",
		iso2Code: "BJ",
	},
	{
		name: "Bermuda",
		dialingCode: "0",
		iso3Code: "BMU",
		iso2Code: "BM",
	},
	{
		name: "Bhutan",
		dialingCode: "975",
		iso3Code: "BTN",
		iso2Code: "BT",
	},
	{
		name: "Bolivia",
		dialingCode: "591",
		iso3Code: "BOL",
		iso2Code: "BO",
	},
	{
		name: "Bosnia and Herzegovina",
		dialingCode: "387",
		iso3Code: "BIH",
		iso2Code: "BA",
	},
	{
		name: "Botswana",
		dialingCode: "267",
		iso3Code: "BWA",
		iso2Code: "BW",
	},
	{
		name: "Bouvet Island",
		dialingCode: "0",
		iso3Code: "BVT",
		iso2Code: "BV",
	},
	{
		name: "Brazil",
		dialingCode: "55",
		iso3Code: "BRA",
		iso2Code: "BR",
	},
	{
		name: "British Indian Ocean Territory",
		dialingCode: "0",
		iso3Code: "IOT",
		iso2Code: "IO",
	},
	{
		name: "Brunei Darussalam",
		dialingCode: "673",
		iso3Code: "BRN",
		iso2Code: "BN",
	},
	{
		name: "Bulgaria",
		dialingCode: "359",
		iso3Code: "BGR",
		iso2Code: "BG",
	},
	{
		name: "Burkina Faso",
		dialingCode: "226",
		iso3Code: "BFA",
		iso2Code: "BF",
	},
	{
		name: "Burundi",
		dialingCode: "257",
		iso3Code: "BDI",
		iso2Code: "BI",
	},
	{
		name: "Cabo Verde",
		dialingCode: "238",
		iso3Code: "CPV",
		iso2Code: "CV",
	},
	{
		name: "Cambodia",
		dialingCode: "855",
		iso3Code: "KHM",
		iso2Code: "KH",
	},
	{
		name: "Cameroon",
		dialingCode: "237",
		iso3Code: "CMR",
		iso2Code: "CM",
	},
	{
		name: "Canada",
		dialingCode: "1",
		iso3Code: "CAN",
		iso2Code: "CA",
	},
	{
		name: "Cayman Islands",
		dialingCode: "0",
		iso3Code: "CYM",
		iso2Code: "KY",
	},
	{
		name: "Central African Republic",
		dialingCode: "236",
		iso3Code: "CAF",
		iso2Code: "CF",
	},
	{
		name: "Chad",
		dialingCode: "235",
		iso3Code: "TCD",
		iso2Code: "TD",
	},
	{
		name: "Chile",
		dialingCode: "56",
		iso3Code: "CHL",
		iso2Code: "CL",
	},
	{
		name: "China",
		dialingCode: "86",
		iso3Code: "CHN",
		iso2Code: "CN",
	},
	{
		name: "Christmas Island",
		dialingCode: "0",
		iso3Code: "CXR",
		iso2Code: "CX",
	},
	{
		name: "Cocos (Keeling) Islands",
		dialingCode: "0",
		iso3Code: "CCK",
		iso2Code: "CC",
	},
	{
		name: "Colombia",
		dialingCode: "57",
		iso3Code: "COL",
		iso2Code: "CO",
	},
	{
		name: "Comoros",
		dialingCode: "269",
		iso3Code: "COM",
		iso2Code: "KM",
	},
	{
		name: "Congo",
		dialingCode: "243",
		iso3Code: "COG",
		iso2Code: "CG",
	},
	{
		name: "Congo (the Democratic Republic of the)",
		dialingCode: "242",
		iso3Code: "COD",
		iso2Code: "CD",
	},
	{
		name: "Cook Islands",
		dialingCode: "682",
		iso3Code: "COK",
		iso2Code: "CK",
	},
	{
		name: "Costa Rica",
		dialingCode: "506",
		iso3Code: "CRI",
		iso2Code: "CR",
	},
	{
		name: "Croatia",
		dialingCode: "385",
		iso3Code: "HRV",
		iso2Code: "HR",
	},
	{
		name: "Cuba",
		dialingCode: "53",
		iso3Code: "CUB",
		iso2Code: "CU",
	},
	{
		name: "Cyprus",
		dialingCode: "357",
		iso3Code: "CYP",
		iso2Code: "CY",
	},
	{
		name: "Czechia",
		dialingCode: "420",
		iso3Code: "CZE",
		iso2Code: "CZ",
	},
	{
		name: "Denmark",
		dialingCode: "45",
		iso3Code: "DNK",
		iso2Code: "DK",
	},
	{
		name: "Djibouti",
		dialingCode: "253",
		iso3Code: "DJI",
		iso2Code: "DJ",
	},
	{
		name: "Dominica",
		dialingCode: "0",
		iso3Code: "DMA",
		iso2Code: "DM",
	},
	{
		name: "Dominican Republic",
		dialingCode: "0",
		iso3Code: "DOM",
		iso2Code: "DO",
	},
	{
		name: "Ecuador",
		dialingCode: "593",
		iso3Code: "ECU",
		iso2Code: "EC",
	},
	{
		name: "Egypt",
		dialingCode: "20",
		iso3Code: "EGY",
		iso2Code: "EG",
	},
	{
		name: "El Salvador",
		dialingCode: "503",
		iso3Code: "SLV",
		iso2Code: "SV",
	},
	{
		name: "Equatorial Guinea",
		dialingCode: "240",
		iso3Code: "GNQ",
		iso2Code: "GQ",
	},
	{
		name: "Eritrea\t",
		dialingCode: "291",
		iso3Code: "ERI",
		iso2Code: "ER",
	},
	{
		name: "Estonia",
		dialingCode: "372",
		iso3Code: "EST",
		iso2Code: "EE",
	},
	{
		name: "Eswatini",
		dialingCode: "268",
		iso3Code: "SWZ",
		iso2Code: "SZ",
	},
	{
		name: "Ethiopia",
		dialingCode: "251",
		iso3Code: "ETH",
		iso2Code: "ET",
	},
	{
		name: "Falkland Islands[Malvinas]",
		dialingCode: "0",
		iso3Code: "FLK",
		iso2Code: "FK",
	},
	{
		name: "Faroe Islands",
		dialingCode: "298",
		iso3Code: "FRO",
		iso2Code: "FO",
	},
	{
		name: "Fiji",
		dialingCode: "679",
		iso3Code: "FJI",
		iso2Code: "FJ",
	},
	{
		name: "Finland",
		dialingCode: "358",
		iso3Code: "FIN",
		iso2Code: "FI",
	},
	{
		name: "France",
		dialingCode: "33",
		iso3Code: "FRA",
		iso2Code: "FR",
	},
	{
		name: "French Guiana",
		dialingCode: "594",
		iso3Code: "GUF",
		iso2Code: "GF",
	},
	{
		name: "French Polynesia",
		dialingCode: "689",
		iso3Code: "PYF",
		iso2Code: "PF",
	},
	{
		name: "French Southern Territories",
		dialingCode: "0",
		iso3Code: "ATF",
		iso2Code: "TF",
	},
	{
		name: "Gabon",
		dialingCode: "241",
		iso3Code: "GAB",
		iso2Code: "GA",
	},
	{
		name: "Gambia",
		dialingCode: "220",
		iso3Code: "GMB",
		iso2Code: "GM",
	},
	{
		name: "Georgia",
		dialingCode: "995",
		iso3Code: "GEO",
		iso2Code: "GE",
	},
	{
		name: "Germany",
		dialingCode: "49",
		iso3Code: "DEU",
		iso2Code: "DE",
	},
	{
		name: "Ghana",
		dialingCode: "233",
		iso3Code: "GHA",
		iso2Code: "GH",
	},
	{
		name: "Gibraltar",
		dialingCode: "350",
		iso3Code: "GIB",
		iso2Code: "GI",
	},
	{
		name: "Greece",
		dialingCode: "30",
		iso3Code: "GRC",
		iso2Code: "GR",
	},
	{
		name: "Greenland",
		dialingCode: "299",
		iso3Code: "GRL",
		iso2Code: "GL",
	},
	{
		name: "Grenada",
		dialingCode: "0",
		iso3Code: "GRD",
		iso2Code: "GD",
	},
	{
		name: "Guadeloupe",
		dialingCode: "590",
		iso3Code: "GLP",
		iso2Code: "GP",
	},
	{
		name: "Guam",
		dialingCode: "0",
		iso3Code: "GUM",
		iso2Code: "GU",
	},
	{
		name: "Guatemala",
		dialingCode: "502",
		iso3Code: "GTM",
		iso2Code: "GT",
	},
	{
		name: "Guernsey",
		dialingCode: "0",
		iso3Code: "GGY",
		iso2Code: "GG",
	},
	{
		name: "Guinea",
		dialingCode: "224",
		iso3Code: "GIN",
		iso2Code: "GN",
	},
	{
		name: "Guinea-Bissau",
		dialingCode: "245",
		iso3Code: "GNB",
		iso2Code: "GW",
	},
	{
		name: "Guyana",
		dialingCode: "592",
		iso3Code: "GUY",
		iso2Code: "GY",
	},
	{
		name: "Haiti",
		dialingCode: "509",
		iso3Code: "HTI",
		iso2Code: "HT",
	},
	{
		name: "Heard Island and McDonald Islands",
		dialingCode: "0",
		iso3Code: "HMD",
		iso2Code: "HM",
	},
	{
		name: "Holy See",
		dialingCode: "379",
		iso3Code: "VAT",
		iso2Code: "VA",
	},
	{
		name: "Honduras",
		dialingCode: "504",
		iso3Code: "HND",
		iso2Code: "HN",
	},
	{
		name: "Hong Kong",
		dialingCode: "852",
		iso3Code: "HKG",
		iso2Code: "HK",
	},
	{
		name: "Hungary",
		dialingCode: "36",
		iso3Code: "HUN",
		iso2Code: "HU",
	},
	{
		name: "Iceland",
		dialingCode: "354",
		iso3Code: "ISL",
		iso2Code: "IS",
	},
	{
		name: "India",
		dialingCode: "91",
		iso3Code: "IND",
		iso2Code: "IN",
	},
	{
		name: "Indonesia",
		dialingCode: "62",
		iso3Code: "IDN",
		iso2Code: "ID",
	},
	{
		name: "Iran",
		iso3Code: "IRN",
		iso2Code: "IR",
	},
	{
		name: "Iraq",
		dialingCode: "964",
		iso3Code: "IRQ",
		iso2Code: "IQ",
	},
	{
		name: "Ireland",
		dialingCode: "353",
		iso3Code: "IRL",
		iso2Code: "IE",
	},
	{
		name: "Isle of Man",
		dialingCode: "0",
		iso3Code: "IMN",
		iso2Code: "IM",
	},
	{
		name: "Israel",
		dialingCode: "972",
		iso3Code: "ISR",
		iso2Code: "IL",
	},
	{
		name: "Italy",
		dialingCode: "39",
		iso3Code: "ITA",
		iso2Code: "IT",
	},
	{
		name: "Jamaica",
		dialingCode: "0",
		iso3Code: "JAM",
		iso2Code: "JM",
	},
	{
		name: "Japan",
		dialingCode: "81",
		iso3Code: "JPN",
		iso2Code: "JP",
	},
	{
		name: "Jersey",
		dialingCode: "0",
		iso3Code: "JEY",
		iso2Code: "JE",
	},
	{
		name: "Jordan",
		dialingCode: "962",
		iso3Code: "JOR",
		iso2Code: "JO",
	},
	{
		name: "Kazakhstan",
		dialingCode: "7",
		iso3Code: "KAZ",
		iso2Code: "KZ",
	},
	{
		name: "Kenya",
		dialingCode: "254",
		iso3Code: "KEN",
		iso2Code: "KE",
	},
	{
		name: "Kiribati",
		dialingCode: "686",
		iso3Code: "KIR",
		iso2Code: "KI",
	},
	{
		name: "Kuwait",
		dialingCode: "965",
		iso3Code: "KWT",
		iso2Code: "KW",
	},
	{
		name: "Kyrgyzstan",
		dialingCode: "996",
		iso3Code: "KGZ",
		iso2Code: "KG",
	},
	{
		name: "Lao People Democratic Republic",
		dialingCode: "856",
		iso3Code: "LAO",
		iso2Code: "LA",
	},
	{
		name: "Latvia",
		dialingCode: "371",
		iso3Code: "LVA",
		iso2Code: "LV",
	},
	{
		name: "Lebanon",
		dialingCode: "961",
		iso3Code: "LBN",
		iso2Code: "LB",
	},
	{
		name: "Lesotho",
		dialingCode: "266",
		iso3Code: "LSO",
		iso2Code: "LS",
	},
	{
		name: "Liberia",
		dialingCode: "231",
		iso3Code: "LBR",
		iso2Code: "LR",
	},
	{
		name: "Libya",
		dialingCode: "218",
		iso3Code: "LBY",
		iso2Code: "LY",
	},
	{
		name: "Liechtenstein",
		dialingCode: "423",
		iso3Code: "LIE",
		iso2Code: "LI",
	},
	{
		name: "Liechtenstein",
		dialingCode: "370",
		iso3Code: "LTU",
		iso2Code: "LT",
	},
	{
		name: "Luxembourg",
		dialingCode: "352",
		iso3Code: "LUX",
		iso2Code: "LU",
	},
	{
		name: "Macao",
		dialingCode: "853",
		iso3Code: "MAC",
		iso2Code: "MO",
	},
	{
		name: "Madagascar",
		dialingCode: "261",
		iso3Code: "MDG",
		iso2Code: "MG",
	},
	{
		name: "Malawi",
		dialingCode: "265",
		iso3Code: "MWI",
		iso2Code: "MW",
	},
	{
		name: "Malaysia",
		dialingCode: "60",
		iso3Code: "MYS",
		iso2Code: "MY",
	},
	{
		name: "Maldives",
		dialingCode: "960",
		iso3Code: "MDV",
		iso2Code: "MV",
	},
	{
		name: "Mali",
		dialingCode: "223",
		iso3Code: "MLI",
		iso2Code: "ML",
	},
	{
		name: "Malta",
		dialingCode: "356",
		iso3Code: "MLT",
		iso2Code: "MT",
	},
	{
		name: "Marshall Islands",
		dialingCode: "692",
		iso3Code: "MHL",
		iso2Code: "MH",
	},
	{
		name: "Martinique",
		dialingCode: "596",
		iso3Code: "MTQ",
		iso2Code: "MQ",
	},
	{
		name: "Mauritania",
		dialingCode: "222",
		iso3Code: "MRT",
		iso2Code: "MR",
	},
	{
		name: "Mauritius",
		dialingCode: "230",
		iso3Code: "MUS",
		iso2Code: "MU",
	},
	{
		name: "Mayotte",
		dialingCode: "262",
		iso3Code: "MYT",
		iso2Code: "YT",
	},
	{
		name: "Mexico",
		dialingCode: "52",
		iso3Code: "MEX",
		iso2Code: "MX",
	},
	{
		name: "Micronesia",
		dialingCode: "691",
		iso3Code: "FSM",
		iso2Code: "FM",
	},
	{
		name: "Moldova",
		dialingCode: "373",
		iso3Code: "MDA",
		iso2Code: "MD",
	},
	{
		name: "Monaco",
		dialingCode: "377",
		iso3Code: "MCO",
		iso2Code: "MC",
	},
	{
		name: "Mongolia",
		dialingCode: "976",
		iso3Code: "MNG",
		iso2Code: "MN",
	},
	{
		name: "Montenegro",
		dialingCode: "382",
		iso3Code: "MNE",
		iso2Code: "ME",
	},
	{
		name: "Montserrat",
		dialingCode: "0",
		iso3Code: "MSR",
		iso2Code: "MS",
	},
	{
		name: "Morocco",
		dialingCode: "212",
		iso3Code: "MAR",
		iso2Code: "MA",
	},
	{
		name: "Mozambique",
		dialingCode: "258",
		iso3Code: "MOZ",
		iso2Code: "MZ",
	},
	{
		name: "Myanmar",
		dialingCode: "95",
		iso3Code: "MMR",
		iso2Code: "MM",
	},
	{
		name: "Namibia",
		dialingCode: "264",
		iso3Code: "NAM",
		iso2Code: "NA",
	},
	{
		name: "Nauru",
		dialingCode: "674",
		iso3Code: "NRU",
		iso2Code: "NR",
	},
	{
		name: "Nepal",
		dialingCode: "977",
		iso3Code: "NPL",
		iso2Code: "NP",
	},
	{
		name: "Netherlands",
		dialingCode: "31",
		iso3Code: "NLD",
		iso2Code: "NL",
	},
	{
		name: "New Caledonia",
		dialingCode: "687",
		iso3Code: "NCL",
		iso2Code: "NC",
	},
	{
		name: "New Zealand",
		dialingCode: "64",
		iso3Code: "NZL",
		iso2Code: "NZ",
	},
	{
		name: "Nicaragua",
		dialingCode: "505",
		iso3Code: "NIC",
		iso2Code: "NI",
	},
	{
		name: "Niger",
		dialingCode: "227",
		iso3Code: "NER",
		iso2Code: "NE",
	},
	{
		name: "Nigeria",
		dialingCode: "234",
		iso3Code: "NGA",
		iso2Code: "NG",
	},
	{
		name: "Niue",
		dialingCode: "683",
		iso3Code: "NIU",
		iso2Code: "NU",
	},
	{
		name: "Norfolk Island",
		dialingCode: "0",
		iso3Code: "NFK",
		iso2Code: "NF",
	},
	{
		name: "North Korea",
		dialingCode: "850",
		iso3Code: "PRK",
		iso2Code: "KP",
	},
	{
		name: "Northern Mariana Islands",
		dialingCode: "0",
		iso3Code: "MNP",
		iso2Code: "MP",
	},
	{
		name: "Norway",
		dialingCode: "47",
		iso3Code: "NOR",
		iso2Code: "NO",
	},
	{
		name: "Oman",
		dialingCode: "968",
		iso3Code: "OMN",
		iso2Code: "OM",
	},
	{
		name: "Pakistan",
		dialingCode: "92",
		iso3Code: "PAK",
		iso2Code: "PK",
	},
	{
		name: "Palau",
		dialingCode: "680",
		iso3Code: "PLW",
		iso2Code: "PW",
	},
	{
		name: "Palestine",
		dialingCode: "0",
		iso3Code: "PSE",
		iso2Code: "PS",
	},
	{
		name: "Panama",
		dialingCode: "507",
		iso3Code: "PAN",
		iso2Code: "PA",
	},
	{
		name: "Papua New Guinea",
		dialingCode: "675",
		iso3Code: "PNG",
		iso2Code: "PG",
	},
	{
		name: "Paraguay",
		dialingCode: "595",
		iso3Code: "PRY",
		iso2Code: "PY",
	},
	{
		name: "Peru",
		dialingCode: "51",
		iso3Code: "PER",
		iso2Code: "PE",
	},
	{
		name: "Philippines",
		dialingCode: "63",
		iso3Code: "PHL",
		iso2Code: "PH",
	},
	{
		name: "Pitcairn",
		dialingCode: "0",
		iso3Code: "PCN",
		iso2Code: "PN",
	},
	{
		name: "Poland",
		dialingCode: "48",
		iso3Code: "POL",
		iso2Code: "PL",
	},
	{
		name: "Portugal",
		dialingCode: "351",
		iso3Code: "PRT",
		iso2Code: "PT",
	},
	{
		name: "Puerto Rico",
		dialingCode: "0",
		iso3Code: "PRI",
		iso2Code: "PR",
	},
	{
		name: "Qatar",
		dialingCode: "974",
		iso3Code: "QAT",
		iso2Code: "QA",
	},
	{
		name: "Republic of North Macedonia",
		dialingCode: "389",
		iso3Code: "MKD",
		iso2Code: "MK",
	},
	{
		name: "Romania",
		dialingCode: "40",
		iso3Code: "ROU",
		iso2Code: "RO",
	},
	{
		name: "Russian Federation",
		dialingCode: "7",
		iso3Code: "RUS",
		iso2Code: "RU",
	},
	{
		name: "Rwanda",
		dialingCode: "250",
		iso3Code: "RWA",
		iso2Code: "RW",
	},
	{
		name: "Réunion",
		dialingCode: "262",
		iso3Code: "REU",
		iso2Code: "RE",
	},
	{
		name: "Saint Barthélemy",
		dialingCode: "0",
		iso3Code: "BLM",
		iso2Code: "BL",
	},
	{
		name: "Saint Kitts and Nevis",
		dialingCode: "0",
		iso3Code: "KNA",
		iso2Code: "KN",
	},
	{
		name: "Saint Lucia",
		dialingCode: "0",
		iso3Code: "LCA",
		iso2Code: "LC",
	},
	{
		name: "Saint Martin",
		dialingCode: "0",
		iso3Code: "MAF",
		iso2Code: "MF",
	},
	{
		name: "Saint Pierre and Miquelon",
		dialingCode: "508",
		iso3Code: "SPM",
		iso2Code: "PM",
	},
	{
		name: "Saint Vincent and the Grenadines",
		dialingCode: "0",
		iso3Code: "VCT",
		iso2Code: "VC",
	},
	{
		name: "Samoa",
		dialingCode: "685",
		iso3Code: "WSM",
		iso2Code: "WS",
	},
	{
		name: "San Marino",
		dialingCode: "378",
		iso3Code: "SMR",
		iso2Code: "SM",
	},
	{
		name: "Sao Tome and Principe",
		dialingCode: "239",
		iso3Code: "STP",
		iso2Code: "ST",
	},
	{
		name: "Saudi Arabia",
		dialingCode: "966",
		iso3Code: "SAU",
		iso2Code: "SA",
	},
	{
		name: "Senegal",
		dialingCode: "221",
		iso3Code: "SEN",
		iso2Code: "SN",
	},
	{
		name: "Serbia",
		dialingCode: "381",
		iso3Code: "SRB",
		iso2Code: "RS",
	},
	{
		name: "Seychelles",
		dialingCode: "248",
		iso3Code: "SYC",
		iso2Code: "SC",
	},
	{
		name: "Sierra Leone",
		dialingCode: "232",
		iso3Code: "SLE",
		iso2Code: "SL",
	},
	{
		name: "Singapore",
		dialingCode: "65",
		iso3Code: "SGP",
		iso2Code: "SG",
	},
	{
		name: "Slovakia",
		dialingCode: "421",
		iso3Code: "SVK",
		iso2Code: "SK",
	},
	{
		name: "Slovenia",
		dialingCode: "386",
		iso3Code: "SVN",
		iso2Code: "SI",
	},
	{
		name: "Solomon Islands",
		dialingCode: "677",
		iso3Code: "SLB",
		iso2Code: "SB",
	},
	{
		name: "Somalia",
		dialingCode: "252",
		iso3Code: "SOM",
		iso2Code: "SO",
	},
	{
		name: "South Georgia and the South Sandwich Islands",
		dialingCode: "0",
		iso3Code: "SGS",
		iso2Code: "GS",
	},
	{
		name: "South Korea",
		dialingCode: "82",
		iso3Code: "KOR",
		iso2Code: "KR",
	},
	{
		name: "South Africa",
		dialingCode: "27",
		iso3Code: "ZAF",
		iso2Code: "ZA",
	},
	{
		name: "Spain",
		dialingCode: "34",
		iso3Code: "ESP",
		iso2Code: "ES",
	},
	{
		name: "Sri Lanka",
		dialingCode: "94",
		iso3Code: "LKA",
		iso2Code: "LK",
	},
	{
		name: "Sudan ",
		dialingCode: "249",
		iso3Code: "SDN",
		iso2Code: "SD",
	},
	{
		name: "Suriname",
		dialingCode: "597",
		iso3Code: "SUR",
		iso2Code: "SR",
	},
	{
		name: "Svalbard and Jan Mayen",
		dialingCode: "0",
		iso3Code: "SJM",
		iso2Code: "SJ",
	},
	{
		name: "Sweden",
		dialingCode: "46",
		iso3Code: "SWE",
		iso2Code: "SE",
	},
	{
		name: "Switzerland",
		dialingCode: "41",
		iso3Code: "CHE",
		iso2Code: "CH",
	},
	{
		name: "Syrian Arab Republic",
		dialingCode: "963",
		iso3Code: "SYR",
		iso2Code: "SY",
	},
	{
		name: "Taiwan",
		dialingCode: "886",
		iso3Code: "TWN",
		iso2Code: "TW",
	},
	{
		name: "Tajikistan",
		dialingCode: "992",
		iso3Code: "TJK",
		iso2Code: "TJ",
	},
	{
		name: "Tanzania",
		dialingCode: "255",
		iso3Code: "TZA",
		iso2Code: "TZ",
	},
	{
		name: "Thailand",
		dialingCode: "66",
		iso3Code: "THA",
		iso2Code: "TH",
	},
	{
		name: "Timor-Leste",
		dialingCode: "670",
		iso3Code: "TLS",
		iso2Code: "TL",
	},
	{
		name: "Togo",
		dialingCode: "228",
		iso3Code: "TGO",
		iso2Code: "TG",
	},
	{
		name: "Tokelau",
		dialingCode: "690",
		iso3Code: "TKL",
		iso2Code: "TK",
	},
	{
		name: "Tonga",
		dialingCode: "676",
		iso3Code: "TON",
		iso2Code: "TO",
	},
	{
		name: "Trinidad and Tobago",
		dialingCode: "0",
		iso3Code: "TTO",
		iso2Code: "TT",
	},
	{
		name: "Tunisia",
		dialingCode: "216",
		iso3Code: "TUN",
		iso2Code: "TN",
	},
	{
		name: "Turkey",
		dialingCode: "90",
		iso3Code: "TUR",
		iso2Code: "TR",
	},
	{
		name: "Turkmenistan",
		dialingCode: "993",
		iso3Code: "TKM",
		iso2Code: "TM",
	},
	{
		name: "Turks and Caicos Islands",
		dialingCode: "0",
		iso3Code: "TCA",
		iso2Code: "TC",
	},
	{
		name: "Tuvalu",
		dialingCode: "688",
		iso3Code: "TUV",
		iso2Code: "TV",
	},
	{
		name: "Uganda",
		dialingCode: "256",
		iso3Code: "UGA",
		iso2Code: "UG",
	},
	{
		name: "Ukraine",
		dialingCode: "380",
		iso3Code: "UKR",
		iso2Code: "UA",
	},
	{
		name: "United Arab Emirates",
		dialingCode: "971",
		iso3Code: "ARE",
		iso2Code: "AE",
	},
	{
		name: "United Kingdom",
		dialingCode: "44",
		iso3Code: "GBR",
		iso2Code: "GB",
	},
	{
		name: "United States of America",
		dialingCode: "1",
		iso3Code: "USA",
		iso2Code: "US",
	},
	{
		name: "Uruguay",
		dialingCode: "598",
		iso3Code: "URY",
		iso2Code: "UY",
	},
	{
		name: "Uzbekistan",
		dialingCode: "998",
		iso3Code: "UZB",
		iso2Code: "UZ",
	},
	{
		name: "Vanuatu",
		dialingCode: "678",
		iso3Code: "VUT",
		iso2Code: "VU",
	},
	{
		name: "Venezuela",
		dialingCode: "58",
		iso3Code: "VEN",
		iso2Code: "VE",
	},
	{
		name: "Vietnam",
		dialingCode: "84",
		iso3Code: "VNM",
		iso2Code: "VN",
	},
	{
		name: "Virgin Islands (British)",
		dialingCode: "0",
		iso3Code: "VGB",
		iso2Code: "VG",
	},
	{
		name: "Virgin Islands (U.S.)",
		dialingCode: "0",
		iso3Code: "VIR",
		iso2Code: "VI",
	},
	{
		name: "Wallis and Futuna",
		dialingCode: "681",
		iso3Code: "WLF",
		iso2Code: "WF",
	},
	{
		name: "Western Sahara",
		dialingCode: "0",
		iso3Code: "ESH",
		iso2Code: "EH",
	},
	{
		name: "Yemen",
		dialingCode: "967",
		iso3Code: "YEM",
		iso2Code: "YE",
	},
	{
		name: "Zambia",
		dialingCode: "260",
		iso3Code: "ZMB",
		iso2Code: "ZM",
	},
	{
		name: "Zimbabwe",
		dialingCode: "263",
		iso3Code: "ZWE",
		iso2Code: "ZW",
	},
	{
		name: "Åland Islands",
		dialingCode: "0",
		iso3Code: "ALA",
		iso2Code: "AX",
	},
];
