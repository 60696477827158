import { useOutsideClick } from "../../utilities/hooks";
import { useUI } from "../../context/ui";
import { useEffect, useState } from "react";
import { CiBellOn } from "react-icons/ci";
import { useLocation } from "react-router-dom";

const Notifications = () => {
	const [activeTab, setActiveTab] = useState("INBOX");
	const { pathname } = useLocation();
	const {
		showNotification,
		setShowNotification,
		newNotification,
		setNewNotification,
		archiveNotification,
		archiveAllNotifications,
		notificationsList,
	} = useUI();

	function getTimeAgo(timestamp) {
		const currentDate = new Date();
		const targetDate = new Date(timestamp);

		const secondsAgo = Math.floor((currentDate - targetDate) / 1000);
		const minutesAgo = Math.floor(secondsAgo / 60);
		const hoursAgo = Math.floor(minutesAgo / 60);
		const daysAgo = Math.floor(hoursAgo / 24);

		if (secondsAgo < 60) {
			return `${secondsAgo} second${secondsAgo !== 1 ? "s" : ""} ago`;
		} else if (minutesAgo < 60) {
			return `${minutesAgo} minute${minutesAgo !== 1 ? "s" : ""} ago`;
		} else if (hoursAgo < 24) {
			return `${hoursAgo} hour${hoursAgo !== 1 ? "s" : ""} ago`;
		} else {
			return `${daysAgo} day${daysAgo !== 1 ? "s" : ""} ago`;
		}
	}

	useEffect(() => {
		if (pathname) {
			setShowNotification(false);
		}
	}, [pathname]);

	const [box] = useOutsideClick({
		state: true,
		callBack: () => {
			setShowNotification(false);
		},
	});

	return (
		<div className='pos-rel notification'>
			{newNotification && <div className='new'></div>}
			<div
				className='icon b-r-100 flex center-center'
				onClick={() => {
					setShowNotification(true);
					setNewNotification(false);
				}}>
				<CiBellOn />
			</div>
			{showNotification && (
				<div className='list' ref={box}>
					<div className='top'>
						<ul className='tab'>
							<li
								className={`${
									activeTab === "INBOX" ? "active" : ""
								}`}
								onClick={() => setActiveTab("INBOX")}>
								<p>Inbox</p>
							</li>
							<li
								className={`${
									activeTab === "ARCHIVE" ? "active" : ""
								}`}
								onClick={() => setActiveTab("ARCHIVE")}>
								<p>Archive</p>
							</li>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='19.15'
								height='19.15'
								viewBox='0 0 19.15 19.15'
								className='close'
								onClick={() => {
									setShowNotification(false);
								}}>
								<g transform='translate(-504.25 -245.25)'>
									<path
										d='M.75,8.825H-.75V0H.75Z'
										transform='translate(516.945 251.705) rotate(45)'
									/>
									<path
										d='M8.825.75H0V-.75H8.825Z'
										transform='translate(510.705 251.705) rotate(45)'
									/>
									<path
										d='M8.825-.75A9.575,9.575,0,1,1-.75,8.825,9.586,9.586,0,0,1,8.825-.75Zm0,17.65A8.075,8.075,0,1,0,.75,8.825,8.084,8.084,0,0,0,8.825,16.9Z'
										transform='translate(505 246)'
									/>
								</g>
							</svg>
						</ul>
					</div>
					{activeTab === "INBOX" && (
						<>
							<div className='lists-holder'>
								{notificationsList.filter((o) => !o.archive)
									.length > 0 ? (
									<ul className='lists'>
										{notificationsList
											.filter((o) => !o.archive)
											.sort((a, b) => b.id - a.id)
											.map((d, i) => (
												<li key={i}>
													{d.status ? (
														<div className='ico ico-success'>
															<svg
																xmlns='http://www.w3.org/2000/svg'
																width='19.995'
																height='19.995'
																viewBox='0 0 19.995 19.995'>
																<path
																	id='iconmonstr-check-mark-circle-lined'
																	d='M12,2.005A10,10,0,1,1,2,12a10,10,0,0,1,10-10Zm0,1.5a8.5,8.5,0,1,0,8.5,8.5,8.5,8.5,0,0,0-8.5-8.5ZM6.949,12.391l3.851,3.43a.75.75,0,0,0,1.051-.052L17.8,9.26a.745.745,0,0,0-.552-1.249.751.751,0,0,0-.554.243l-5.453,5.962-3.3-2.938a.747.747,0,1,0-1,1.113Z'
																	transform='translate(-2 -2.005)'
																	fill='green'
																/>
															</svg>
														</div>
													) : (
														<div className='ico ico-fail'>
															<svg
																xmlns='http://www.w3.org/2000/svg'
																width='19.995'
																height='19.995'
																viewBox='0 0 19.995 19.995'>
																<path
																	id='iconmonstr-error-lined'
																	d='M12,21.534a10,10,0,1,0-10-10A10,10,0,0,0,12,21.534Zm0-1.5a8.5,8.5,0,1,1,8.5-8.5A8.5,8.5,0,0,1,12,20.034Zm0-6.5a.75.75,0,0,1-.75-.75v-5.5a.75.75,0,0,1,1.5,0v5.5A.75.75,0,0,1,12,13.534Zm0,3a1,1,0,1,0-1-1A1,1,0,0,0,12,16.534Z'
																	transform='translate(-2.005 -1.539)'
																	fill='red'
																/>
															</svg>
														</div>
													)}
													<div className='t'>
														<p className='m'>
															{d.message}
														</p>
														<p className='d'>
															{getTimeAgo(d.id)}
														</p>
													</div>
													<div
														className='del'
														onClick={() =>
															archiveNotification(
																d.id
															)
														}>
														<svg
															xmlns='http://www.w3.org/2000/svg'
															width='18'
															height='20'
															viewBox='0 0 18 20'>
															<path
																id='iconmonstr-trash-can-lined'
																d='M4.015,5.494H3.762A.747.747,0,0,1,3.762,4H9.015V3a1.029,1.029,0,0,1,1-1h4a1.029,1.029,0,0,1,1,1V4h5.254a.747.747,0,0,1,0,1.494h-.254V20.929a1.038,1.038,0,0,1-1,1.071h-14a1.038,1.038,0,0,1-1-1.071Zm14.5,0h-13V20.5h13ZM14.265,8a.75.75,0,0,0-.75.75v8.5a.75.75,0,0,0,1.5,0V8.75A.75.75,0,0,0,14.265,8Zm-4.5,0a.75.75,0,0,0-.75.75v8.5a.75.75,0,0,0,1.5,0V8.75A.75.75,0,0,0,9.765,8Zm3.75-4V3.5h-3V4Z'
																transform='translate(-3.015 -2)'
															/>
														</svg>
													</div>
												</li>
											))}
									</ul>
								) : (
									<div className='no-data'>
										<div>
											<div className='i'>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width='22.001'
													height='24'
													viewBox='0 0 22.001 24'>
													<path
														id='iconmonstr-product-2'
														d='M23,6.066V18.131L12,24,1,18.131V6L12,0,23,6.066ZM2,17.531,11.5,22.6V12.03L2,7.084ZM22,7.143l-9.5,4.889V22.6L22,17.531ZM16.48,8.859,6.946,3.895,2.6,6.268l9.4,4.9L16.48,8.859ZM8,3.318,17.569,8.3,21.4,6.326,12,1.141,8,3.318Z'
														transform='translate(-0.999)'
														fillRule='evenodd'
													/>
												</svg>
											</div>
											<p
												className='color-primary'
												style={{ fontSize: "12px" }}>
												No new notifications
											</p>
										</div>
									</div>
								)}
							</div>
							{notificationsList.filter((o) => !o.archive)
								.length > 0 && (
								<div
									className='archive-all flex center-center'
									style={{
										borderTop: "solid 1px #f0f0f0",
									}}>
									<p
										className='transition cursor-pointer t-a-c color-primary hover-color-secondary'
										onClick={() =>
											archiveAllNotifications()
										}
										style={{
											fontSize: "11px",
											padding: "16px 12px",
										}}>
										Archive All
									</p>
								</div>
							)}
						</>
					)}
					{activeTab === "ARCHIVE" && (
						<>
							<div className='lists-holder'>
								<ul className='lists'>
									{notificationsList
										.filter((o) => o.archive)
										.sort((a, b) => b.id - a.id)
										.map((d, i) => (
											<li key={i}>
												{d.status ? (
													<div className='ico ico-success'>
														<svg
															xmlns='http://www.w3.org/2000/svg'
															width='19.995'
															height='19.995'
															viewBox='0 0 19.995 19.995'>
															<path
																id='iconmonstr-check-mark-circle-lined'
																d='M12,2.005A10,10,0,1,1,2,12a10,10,0,0,1,10-10Zm0,1.5a8.5,8.5,0,1,0,8.5,8.5,8.5,8.5,0,0,0-8.5-8.5ZM6.949,12.391l3.851,3.43a.75.75,0,0,0,1.051-.052L17.8,9.26a.745.745,0,0,0-.552-1.249.751.751,0,0,0-.554.243l-5.453,5.962-3.3-2.938a.747.747,0,1,0-1,1.113Z'
																transform='translate(-2 -2.005)'
																fill='green'
															/>
														</svg>
													</div>
												) : (
													<div className='ico ico-fail'>
														<svg
															xmlns='http://www.w3.org/2000/svg'
															width='19.995'
															height='19.995'
															viewBox='0 0 19.995 19.995'>
															<path
																id='iconmonstr-error-lined'
																d='M12,21.534a10,10,0,1,0-10-10A10,10,0,0,0,12,21.534Zm0-1.5a8.5,8.5,0,1,1,8.5-8.5A8.5,8.5,0,0,1,12,20.034Zm0-6.5a.75.75,0,0,1-.75-.75v-5.5a.75.75,0,0,1,1.5,0v5.5A.75.75,0,0,1,12,13.534Zm0,3a1,1,0,1,0-1-1A1,1,0,0,0,12,16.534Z'
																transform='translate(-2.005 -1.539)'
																fill='red'
															/>
														</svg>
													</div>
												)}
												<div className='t'>
													<p className='m'>
														{d.message}
													</p>
													<p className='d'>
														{getTimeAgo(d.id)}
													</p>
												</div>
											</li>
										))}
								</ul>
							</div>
						</>
					)}
				</div>
			)}
		</div>
	);
};

export default Notifications;
