import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import PageTitle from "../../components/common/PageTitle";
import LoaderIcon from "../../components/ui/LoaderIcon";
import { API_URL, RS_TAGLINE, SYSTEM_TOKEN } from "../../utilities/constants";
import RentSavvyLogo from "../../components/common/RentSavvyLogo";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import ButtonTextOnly from "../../components/ui/ButtonTextOnly";
import { countries } from "../../utilities/countries";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
const ThankYou = () => {
 

  return (
    <section id="auth-page">
      <PageTitle title="Register" />
      <section className="auth-header">
      <NavLink to='/login'>
					<div className='logo'>
						<RentSavvyLogo />
					</div>
				</NavLink>
        <div className="options flex center-center">
          <p>
            <NavLink to="/login">Already have an account?</NavLink>
          </p>
          {/* <p>
            <NavLink to="/contact">
              <span className="t-d-ul color-primary f-w-600">Contact us</span>
            </NavLink>
          </p> */}
        </div>
      </section>
      <section className="auth-content">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="90.396"
          height="82.351"
          viewBox="0 0 90.396 82.351"
          className="svg-top"
        >
          <g transform="translate(-288.14 -169.729)">
            <path
              d="M276.138,252.079V334.43h57.775s18.088-.5,24.874-10.241,12.908-27.337,0-41.4-31.156-30.71-31.156-30.71Z"
              transform="translate(654.483 504.159) rotate(180)"
              fill="#5dc6b3"
            />
            <path
              d="M327.624,252.079V190.464l25.5,21.287V252.08Z"
              fill="#1e4ccb"
            />
            <path
              d="M327.624,190.464l25.4-19.87,25.512,19.87L353.027,211.9Z"
              fill="#3b86b5"
            />
          </g>
        </svg>
        <div className="inner">
          <div className="col col-1-2 left">
            <div className="text">
              <h1 className="l-h-1 f-w-300 color-primary">
                Thank You 
                <br />
                Registration successful! 
              </h1>
              <br />
              <h5 className="f-w-300 color-primary">{RS_TAGLINE}</h5>
              <br />
              <h6 className="f-w-300 color-primary">Please wait a moment and check your email for the link to reset your password.</h6>
            </div>
          </div>
          <div className="col col-1-2 right pos-rel">
             
          </div>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="90.205"
          height="82.351"
          viewBox="0 0 90.205 82.351"
          className="svg-bottom"
        >
          <g transform="translate(-276.138 -252.079)">
            <path
              d="M276.138,252.079V334.43h57.775s18.088-.5,24.874-10.241,12.908-27.337,0-41.4-31.156-30.71-31.156-30.71Z"
              fill="#5dc6b3"
            />
            <path
              d="M290.953,334.43l43.512-.032a56.454,56.454,0,0,0,8.178-1.2,45.36,45.36,0,0,0,5.6-1.582v-32.64L320.021,269.53l-29.069,29.446Z"
              fill="#3b86b5"
            />
            <path
              d="M276.138,298.387V269.279l44.181.244-28.911,28.864Z"
              fill="#1e4ccb"
            />
          </g>
        </svg>
      </section>
    </section>
  );
};

export default ThankYou;
