import { useEffect, useState } from "react";
import FullPageLoader from "../../components/ui/FullPageLoader";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import {
	CiCircleCheck,
	CiCirclePlus,
	CiNoWaitingSign,
	CiTrash,
} from "react-icons/ci";
import { API_URL } from "../../utilities/constants";
import RSModal from "../../components/ui/RSModal";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import Pagination from "../../components/common/Pagination";
import PageTitle from "../../components/common/PageTitle";
import ResultsPerPage from "../_common/ResultsPerPage";
import LoaderIcon from "../../components/ui/LoaderIcon";
import { useUI } from "../../context/ui";
import { decodeRolesFromToken } from "../../utilities/helpers";

const ConfirmStatusChange = ({
	brokerId,
	callback,
	setShow,
	currentStatusID,
	text,
}) => {
	const [loading, setLoading] = useState(false);
	const handleStatusChange = async () => {
		setLoading(true);
		try {
			const resp = await fetch(
				API_URL +
				`/api/1.0.0/user/${brokerId}/status/${currentStatusID === 1 ? 14 : 1
				}`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
						"X-JWT-Assertion": localStorage.getItem("token"),
					},
				}
			);
			if (resp.ok) {
				const response = await resp.json();
				if (response) {
					setShow(false);
					callback();
				}
			}
		} catch (e) {
			console.log("Error", e);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div
			style={{
				height: "auto",
				width: "480px",
				padding: "40px",
			}}>
			<h4 className='color-primary'>
				Are you sure you want to {text} the broker?
			</h4>
			<br />
			<p>
				Changing this might have some unexpected consequences, please
				make sure that you understand.
			</p>
			<br />
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "flex-end",
					gap: "12px",
				}}>
				<ButtonLightTextIcon
					title='Go back'
					icon={
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='26.562'
							height='17.809'
							viewBox='0 0 26.562 17.809'>
							<path d='M0,8.88,10.686,0l1.937,2.293L6.453,7.4H26.562v3H6.454l6.166,5.108-1.934,2.3Z' />
						</svg>
					}
					onClick={() => setShow(false)}
				/>
				{loading ? (
					<ButtonTextIcon
						type='button'
						title='Updating...'
						disabled={loading}
						icon={<LoaderIcon />}
					/>
				) : (
					<ButtonTextIcon
						title='I understand'
						icon={<CiCircleCheck />}
						onClick={() => handleStatusChange()}
					/>
				)}
			</div>
		</div>
	);
};

const ConfirmDelete = ({ brokerId, callback, setShow }) => {
	const [loading, setLoading] = useState(false);
	const handleDelete = async () => {
		setLoading(true);
		try {
			const resp = await fetch(API_URL + `/api/1.0.0/user/${brokerId}`, {
				method: "DELETE",
				headers: {
					"Content-Type": "application/json",
					"X-JWT-Assertion": localStorage.getItem("token"),
				},
			});
			if (resp.ok) {
				const response = await resp.json();
				if (response) {
					setShow(false);
					callback();
				}
			}
		} catch (e) {
			console.log("Error", e);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div
			style={{
				height: "auto",
				width: "480px",
				padding: "40px",
			}}>
			<h4 className='color-primary'>
				Are you sure you want to delete the broker?
			</h4>
			<br />
			<p>
				Once you delete the broker, the broker can no longer log in to
				Rent-Savvy and all applications under the broker will be
				impacted.
			</p>
			<br />
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "flex-end",
					gap: "12px",
				}}>
				<ButtonLightTextIcon
					title='Go back'
					icon={
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='26.562'
							height='17.809'
							viewBox='0 0 26.562 17.809'>
							<path d='M0,8.88,10.686,0l1.937,2.293L6.453,7.4H26.562v3H6.454l6.166,5.108-1.934,2.3Z' />
						</svg>
					}
					onClick={() => setShow(false)}
				/>
				{loading ? (
					<ButtonTextIcon
						type='button'
						title='Deleting...'
						disabled={loading}
						icon={<LoaderIcon />}
					/>
				) : (
					<ButtonTextIcon
						title='Yes, Delete'
						icon={<CiCircleCheck />}
						onClick={() => handleDelete()}
					/>
				)}
			</div>
		</div>
	);
};

const BrokerList = () => {
	const [loading, setLoading] = useState(false);
	const location = useLocation();
	const [data, setData] = useState(null);
	const [listdata, setListData] = useState(null);
	const [selectedagencyId, setSelectedAgencyId] = useState("");
	const { setSegmentTitle } = useUI();
	const roleId = decodeRolesFromToken()[0];
	const navigate = useNavigate();
	const pageNo = new URLSearchParams(location.search).get("pageNo");
	const pageSize = new URLSearchParams(location.search).get("pageSize");

	const callAgencyListApi = async () => {
		setLoading(true);

		try {
			const resp = await fetch(
				API_URL +
				`/api/1.0.0/admin/brokerAgency?pageNo=0&pageSize=100&sortOrder=desc`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						"X-JWT-Assertion": localStorage.getItem("token"),
					},
				}
			);
			if (resp.ok) {
				const response = await resp.json();
				setListData(response.data);
			}
		} catch (e) {
			console.log("Error", e);
		} finally {
			setLoading(false);
		}
	};

	// const callListApi = async () => {
	// 	setLoading(true);
	// 	const pageNo = new URLSearchParams(location.search).get("pageNo");
	// 	const pageSize = new URLSearchParams(location.search).get("pageSize");

	// 	let endpoint = `/api/1.0.0/brokerAgency/brokers?pageNo=${pageNo}&pageSize=${pageSize}&sortOrder=desc`;

	// 	if (selectedagencyId) {
	// 		endpoint += `&agencyID=${selectedagencyId}`;
	// 	}
	// 	try {
	// 		const resp = await fetch(API_URL + endpoint,
	// 			{
	// 				method: "GET",
	// 				headers: {
	// 					"Content-Type": "application/json",
	// 					"X-JWT-Assertion": localStorage.getItem("token"),
	// 				},
	// 			}
	// 		);
	// 		if (resp.ok) {
	// 			const response = await resp.json();
	// 			setData(response.data);
	// 		}
	// 	} catch (e) {
	// 		console.log("Error", e);
	// 	} finally {
	// 		setLoading(false);
	// 	}
	// };

	// useEffect(() => {
	// 	if (location.search) {
	// 		callListApi();
	// 		callAgencyListApi(selectedagencyId);
	// 		setSegmentTitle("Brokers");
	// 	}
	// }, [location.search], selectedagencyId);


	const callListApi = async () => {
		setLoading(true);
		const pageNo = new URLSearchParams(location.search).get("pageNo");
		const pageSize = new URLSearchParams(location.search).get("pageSize");
		const agencyId = new URLSearchParams(location.search).get("agencyId"); // Get agencyId from query
	  
		let endpoint = `/api/1.0.0/brokerAgency/brokers?pageNo=${pageNo}&pageSize=${pageSize}&sortOrder=desc`;
	  
		if (agencyId) {
		  endpoint += `&agencyID=${agencyId}`; // Include agencyId in the API call if present
		}
	  
		try {
		  const resp = await fetch(API_URL + endpoint, {
			method: "GET",
			headers: {
			  "Content-Type": "application/json",
			  "X-JWT-Assertion": localStorage.getItem("token"),
			},
		  });
	  
		  if (resp.ok) {
			const response = await resp.json();
			setData(response.data);
		  }
		} catch (e) {
		  console.log("Error", e);
		} finally {
		  setLoading(false);
		}
	  };

	  useEffect(() => {
		const agencyId = new URLSearchParams(location.search).get("agencyId");
		if (agencyId) {
		  setSelectedAgencyId(agencyId); 
		}
		callListApi();
		callAgencyListApi(); 
		setSegmentTitle("Brokers");
	  }, [location.search, selectedagencyId]);

	// const handleAgencyChange = (event) => {
	// 	const agencyID = event.target.value;
	// 	setSelectedAgencyId(agencyID);
	// 	navigate(`/basa-brokers?pageNo=0&pageSize=${pageSize}&${agencyID}`);
	// };

	const handleAgencyChange = (event) => {
		const agencyID = event.target.value;
		setSelectedAgencyId(agencyID);
		navigate(`/basa-brokers?pageNo=0&pageSize=${pageSize}&agencyId=${agencyID}`);
	  };

	return (
		<section className='pos-rel'>
			<PageTitle title='Brokers' />
			<FullPageLoader isActive={loading} />
			<section>
				<div className='flex center-left' style={{ padding: "20px" }}>
					<ResultsPerPage
						targetURL={`/basa-brokers?pageNo=0&pageSize=`}
					/>
					{roleId === 1 && (
						<select
							onChange={handleAgencyChange}
							style={{ width: "350px", marginLeft:"20px" }}
							value={selectedagencyId}
						>
							<option value="">Select Broker By Agency</option>
							{listdata &&
								listdata.brokerAgencies &&
								listdata.brokerAgencies.map((c, i) => (
									<option value={c.agencyId} key={i}>
										{c.agencyName}
									</option>
								))}
						</select>
					)}
					{roleId === 3 && (
						<div style={{ marginLeft: "auto" }}>
							<NavLink to='/basa-brokers/add'>
								<ButtonTextIcon
									title='Add Brokers'
									icon={<CiCirclePlus />}
								/>
							</NavLink>
						</div>
					)}
				</div>
				<div className='results'>
					<div className='rs-table-holder'>
						<table className='rs-table'>
							<thead>
								<tr>
									<th>Broker Name</th>
									<th>Email</th>
									<th>Phone</th>
									<th>Status</th>
									<th></th>
								</tr>
							</thead>
							{data && (
								<tbody>
									{data.agencyBrokerDTOS.map((d, i) => (
										<tr key={i}>
											<td>
												{d.firstName} {d.lastName}
											</td>
											<td>{d.email}</td>
											<td>{`(+971) ${d.phoneNo}`}</td>
											<td>
												{d.statusID === 1 ||
													d.statusID === 14 ? (
													<RSModal
														selector={
															<div
																className={`cursor-pointer status ${d.statusID ===
																	1
																	? "active"
																	: "inactive"
																	}`}>
																{d.statusID ===
																	1
																	? "Active"
																	: "Inactive"}
															</div>
														}
														content={
															<ConfirmStatusChange
																text={
																	d.statusID ===
																		1
																		? "deactivate"
																		: "re-activate"
																}
																currentStatusID={
																	d.statusID
																}
																brokerId={d.id}
																callback={() =>
																	callListApi()
																}
															/>
														}
													/>
												) : (
													<div className='cursor-pointer status bg-color-tertiary'>
														Pending
													</div>
												)}
											</td>
											<td>
												<RSModal
													selector={<CiTrash />}
													content={
														<ConfirmDelete
															brokerId={d.id}
															callback={() =>
																callListApi()
															}
														/>
													}
												/>
											</td>
										</tr>
									))}
								</tbody>
							)}
						</table>
					</div>
				</div>
				<br />
				{data && (
					<Pagination
						totalRecords={data.totalRecords}
						pageNo={data.pageNo}
						pageSize={data.pageSize}
						baseUrl='/basa-brokers'
					/>
				)}
			</section>
		</section>
	);
};

export default BrokerList;
