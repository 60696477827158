import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { decodeRolesFromToken } from "../../utilities/helpers";
import { getDefaultURLForRole } from "../../services/roles";

const DefaultPage = () => {
	const navigate = useNavigate();

	useEffect(() => {
		const token = localStorage.getItem("token");
		if (token) {
			const roles = decodeRolesFromToken();
			navigate(getDefaultURLForRole(roles[0]));
		} else {
			navigate("/login");
		}
	}, []);

	return <></>;
};

export default DefaultPage;
