import { useEffect, useState } from "react";
import FullPageLoader from "../../components/ui/FullPageLoader";
import { NavLink, useLocation, useParams } from "react-router-dom";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import {
	CiCircleCheck,
	CiNoWaitingSign,
	CiTrash,
} from "react-icons/ci";
import { API_URL } from "../../utilities/constants";
import RSModal from "../../components/ui/RSModal";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import Pagination from "../../components/common/Pagination";
import PageTitle from "../../components/common/PageTitle";
import ResultsPerPage from "../_common/ResultsPerPage";
import LoaderIcon from "../../components/ui/LoaderIcon";
import { useUI } from "../../context/ui";

const ConfirmStatusChange = ({
	brokerId,
	callback,
	setShow,
	currentStatusID,
	text,
}) => {
	const [loading, setLoading] = useState(false);
	const handleStatusChange = async () => {
		setLoading(true);
		try {
			const resp = await fetch(
				API_URL +
					`/api/1.0.0/user/${brokerId}/status/${
						currentStatusID === 1 ? 14 : 1
					}`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
						"X-JWT-Assertion": localStorage.getItem("token"),
					},
				}
			);
			if (resp.ok) {
				const response = await resp.json();
				if (response) {
					setShow(false);
					callback();
				}
			}
		} catch (e) {
			console.log("Error", e);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div
			style={{
				height: "auto",
				width: "480px",
				padding: "40px",
			}}>
			<h4 className='color-primary'>
				Are you sure you want to {text} the broker?
			</h4>
			<br />
			<p>
				Changing this might have some unexpected consequences, please
				make sure that you understand.
			</p>
			<br />
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "flex-end",
					gap: "12px",
				}}>
				<ButtonLightTextIcon
					title='Go back'
					icon={
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='26.562'
							height='17.809'
							viewBox='0 0 26.562 17.809'>
							<path d='M0,8.88,10.686,0l1.937,2.293L6.453,7.4H26.562v3H6.454l6.166,5.108-1.934,2.3Z' />
						</svg>
					}
					onClick={() => setShow(false)}
				/>
				{loading ? (
					<ButtonTextIcon
						type='button'
						title='Updating...'
						disabled={loading}
						icon={<LoaderIcon />}
					/>
				) : (
					<ButtonTextIcon
						title='I understand'
						icon={<CiCircleCheck />}
						onClick={() => handleStatusChange()}
					/>
				)}
			</div>
		</div>
	);
};

const ConfirmDelete = ({ brokerId, callback, setShow }) => {
	const [loading, setLoading] = useState(false);
	const handleDelete = async () => {
		setLoading(true);
		try {
			const resp = await fetch(API_URL + `/api/1.0.0/user/${brokerId}`, {
				method: "DELETE",
				headers: {
					"Content-Type": "application/json",
					"X-JWT-Assertion": localStorage.getItem("token"),
				},
			});
			if (resp.ok) {
				const response = await resp.json();
				if (response) {
					setShow(false);
					callback();
				}
			}
		} catch (e) {
			console.log("Error", e);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div
			style={{
				height: "auto",
				width: "480px",
				padding: "40px",
			}}>
			<h4 className='color-primary'>
				Are you sure you want to delete the broker?
			</h4>
			<br />
			<p>
				Once you delete the broker, the broker can no longer log in to
				Rent-Savvy.
			</p>
			<br />
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "flex-end",
					gap: "12px",
				}}>
				<ButtonLightTextIcon
					title='Go back'
					icon={
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='26.562'
							height='17.809'
							viewBox='0 0 26.562 17.809'>
							<path d='M0,8.88,10.686,0l1.937,2.293L6.453,7.4H26.562v3H6.454l6.166,5.108-1.934,2.3Z' />
						</svg>
					}
					onClick={() => setShow(false)}
				/>
				{loading ? (
					<ButtonTextIcon
						type='button'
						title='Deleting...'
						disabled={loading}
						icon={<LoaderIcon />}
					/>
				) : (
					<ButtonTextIcon
						title='Yes, Delete'
						icon={<CiCircleCheck />}
						onClick={() => handleDelete()}
					/>
				)}
			</div>
		</div>
	);
};

const MyTask = () => {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState(null);
	const { setSegmentTitle } = useUI();

	const callListApi = async () => {
		setLoading(true);
		
		try {
			const resp = await fetch(
				API_URL +
					`/api/1.0.0/loan-application/cp/my-applications`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						"X-JWT-Assertion": localStorage.getItem("token"),
					},
				}
			);
			if (resp.ok) {
				const response = await resp.json();
				setData(response.data);
			}
		} catch (e) {
			console.log("Error", e);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
			callListApi();
	}, []);

	return (
		<section className='pos-rel'>
			<PageTitle title='My Task' />
			<FullPageLoader isActive={loading} />
			<section>
				<div className='flex center-left' style={{ padding: "20px" }}>
					<ResultsPerPage
						targetURL={`/cpur-my-task?pageNo=0&pageSize=`}
					/>
				</div>
				<div className='results'>
					<div className='rs-table-holder'>
						<table className='rs-table'>
							<thead>
								<tr>
									<th>Customer Name</th>
									<th>Email</th>
									<th>Phno</th>
									<th>Loan Amount</th>
									<th>Status</th>
								</tr>
							</thead>
							{data && (
								<tbody>
									{data?.loanApplications.map((d, i) => (
										<tr key={i}>
											<td>{d.tenant.firstName} {d.tenant.lastName}</td>
											<td>{d.tenant.email}</td>
											<td>{d.tenant.phoneNo}</td>
											<td>${d.rentalValue}</td>
											<td>
												<div
													className={`status ${
														d.statusId === 1
															? "active"
															: "inactive"
													}`}>
													{d.statusId === 1
														? "Active"
														: "Inactive"}
												</div>
											</td>
										</tr>
									))}
								</tbody>
							)}
						</table>
					</div>
				</div>
				<br />
				{data && (
					<Pagination
						totalRecords={data.totalRecords}
						pageNo={data.pageNo}
						pageSize={data.pageSize}
						baseUrl='/cpur-my-task'
					/>
				)}
			</section>
		</section>
	);
};

export default MyTask;
