import { useState } from "react";
import PopUpModal from "./PopUpModal";
import { useOutsideClick } from "../../utilities/hooks";
import { countries } from "../../utilities/countries";
import * as RSFlag from "react-flags-select";

const CountrySelector = ({ selected, selector, callback }) => {
	const [show, setShow] = useState();

	const [box] = useOutsideClick({
		state: true,
		callBack: () => {
			setShow(false);
		},
	});

	const trimName = (str) => {
		if (str.length < 32) {
			return str;
		} else {
			return str.substring(0, 32) + "...";
		}
	};

	function capitalizeFirstLetter(str) {
		if (!str) return "";
		return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
	}

	const FlagComponent = ({ countryCode, ...rest }) => {
		const sel = countries.find((c) => c.dialingCode === countryCode);
		const selCode = capitalizeFirstLetter(sel.iso2Code);
		const Flag = RSFlag[selCode];
		return Flag ? (
			<Flag {...rest} />
		) : (
			<div
				className='default-image'
				style={{
					width: "32px",
					height: "20px",
				}}></div>
		);
	};

	const Country = ({ title, code }) => {
		return (
			<li
				className='flex center-left col-1-1 transition cursor-pointer t-a-c color-primary hover-color-secondary'
				style={{
					borderBottom: "solid 1px #f0f0f0",
					padding: "6px 0",
					gap: "12px",
					padding:'12px',
					// background:"rgb(243, 244, 246)"
				}}
				title={title}
				onClick={() => {
					callback(`+${code}`);
					setShow(false);
				}}>
				<div className='of-hid' >
					<FlagComponent
						key={code}
						countryCode={code}
						style={{
							width: "32px",
							height: "auto",
							display: "block",
						background:"rgb(243, 244, 246)"

						}}
					/>
				</div>
				<p className=''>{trimName(title)}</p>
				<p className=''>(+{code})</p>
			</li>
		);
	};

	const SelectedCountry = ({ title, ...rest }) => {
		const getImage = (code) => {
			const s = code.split("+")[1];
			const obj = countries.find((c) => c.dialingCode === s);
			const selCode = capitalizeFirstLetter(obj.iso2Code);
			const Flag = RSFlag[selCode];
			return Flag ? (
				<Flag
					style={{
						width: "32px",
						height: "auto",
						display: "block",
					}}
				/>
			) : (
				<div
					className='default-image'
					style={{
						width: "32px",
						height: "44px",
					}}></div>
			);
		};

		return (
			<div
				className='flex center-left'
				style={{
					gap: "12px",
					cursor: "pointer",
				}}>
				<div>{getImage(title)}</div>
				<p
					className='color-light f-w-600'
					style={{
						fontSize: "13px",
						lineHeight: 1,
						background:"rgb(243, 244, 246)"
					}}>
					{findCountryNameByCode(title)} ({title})
				</p>
			</div>
		);
	};

	const findCountryNameByCode = (code) => {
		const s = code.split("+")[1];
		const obj = countries.find((c) => c.dialingCode === s);
		return obj.name;
	};

	return (
		<>
			<div onClick={() => setShow(true)}
			className="flex "
			style={{background:"rgb(243, 244, 246)", height:"44px", padding:'12px'}}
				>
				{selected ? (
					<SelectedCountry title={selected} />
				) : (
					<span>{selector}</span>
				)}
			</div>
			{show && (
				<PopUpModal>
					<section id='rs-modal'>
						<div className='inner'>
							<div
								className='box'
								ref={box}
								style={{
									padding: "30px 20px",
								}}>
								<h6 className='t-a-c color-primary'>
									Select Country
								</h6>
								<hr
									style={{
										outline: "none",
										border: "none",
										height: "3px",
										width: "24px",
										background: "#f0f0f0",
										margin: "10px auto",
									}}
								/>
								<ul
									style={{
										height: "280px",
										overflowY: "scroll",
									}}>
									{countries.map((c, i) => (
										<Country
											key={i}
											title={c.name}
											code={c.dialingCode}
										/>
									))}
								</ul>
							</div>
						</div>
					</section>
				</PopUpModal>
			)}
		</>
	);
};

export default CountrySelector;
