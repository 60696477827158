import { useEffect, useState } from "react";
import { useOutsideClick } from "../../utilities/hooks";
import { CiUser } from "react-icons/ci";
import { useUI } from "../../context/ui";
import { NavLink, useLocation } from "react-router-dom";

const ProfileInfo = () => {
	const [show, setShow] = useState(false);
	const { setShowLogout } = useUI();
	const { pathname } = useLocation();

	const [box] = useOutsideClick({
		state: true,
		callBack: () => {
			setShow(false);
		},
	});

	useEffect(() => {
		if (pathname) {
			setShow(false);
		}
	}, [pathname]);

	return (
		<div className='pos-rel'>
			<div
				className='icon b-r-100 flex center-center'
				onClick={() => setShow(!show)}>
				<CiUser />
			</div>
			{show && (
				<div className='profile-info' ref={box}>
					<ul>
						<li
							className={`cursor-pointer hover-color-secondary transition ${
								pathname === "/profile" ? "active" : ""
							}`}>
							<NavLink to='/profile'>My Profile</NavLink>
						</li>
						<li className='cursor-pointer hover-color-secondary transition'>
							<a onClick={() => setShowLogout(true)}>Logout</a>
						</li>
					</ul>
				</div>
			)}
		</div>
	);
};

export default ProfileInfo;
