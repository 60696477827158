import { Line } from "react-chartjs-2";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
} from "chart.js";

const SampleChart = () => {
	const generateRandomIntegers = (length, min, max) => {
		const randomIntegers = [];
		for (let i = 0; i < length; i++) {
			const randomInt = Math.floor(Math.random() * (max - min + 1)) + min;
			randomIntegers.push(randomInt);
		}
		return randomIntegers;
	};

	const data = generateRandomIntegers(12, 0, 20);
	const chartData = {
		labels: [
			"January",
			"February",
			"March",
			"April",
			"May",
			"June",
			"July",
		],
		datasets: [
			{
				label: " Total",
				data: data.map((value) => Number(value)),
				borderColor: "#3b86b5",
				backgroundColor: "#3b86b5",
				borderWidth: 1.5,
				pointRadius: 3,
				pointHoverRadius: 8,
				font: {
					size: 12,
					family: "Inter",
				},
			},
		],
	};

	const options = {
		responsive: true,
		plugins: {
			legend: {
				display: false,
				position: "top",
				labels: {
					font: {
						size: 12,
						family: "Inter",
					},
					color: "white",
				},
			},
			title: {
				// display: false,
				text: "",
				color: "#fff",
				font: {
					size: 12,
					family: "Inter",
				},
			},
			tooltip: {
				titleColor: "white",
				titleFont: {
					size: 14,
					family: "Inter",
				},
				bodyColor: "white",
				bodyFont: {
					size: 12,
					family: "Inter",
				},
				cornerRadius: 4,
				usePointStyle: false,
			},
		},
		interaction: {
			intersect: false,
		},
		scales: {
			x: {
				ticks: {
					color: "#3d3368",
					font: {
						size: 12,
						family: "Inter",
					},
				},
				// display: false,
				title: {
					// display: false,
					text: "...",
					color: "#646464",
					font: {
						size: 12,
						family: "Inter",
					},
				},
			},
			y: {
				// display: false,
				ticks: {
					color: "#4b5362",
					font: {
						size: 12,
						family: "Inter",
					},
				},
				title: {
					// display: false,
					text: "...",
					color: "#646464",
					font: {
						size: 12,
						family: "Inter",
					},
				},
			},
		},
	};

	ChartJS.register(
		CategoryScale,
		LinearScale,
		PointElement,
		LineElement,
		Title,
		Tooltip,
		Legend
	);

	return <>{data && <Line options={options} data={chartData} />}</>;
};

export default SampleChart;
