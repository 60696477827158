import { useState } from "react";
import { CiFileOn } from "react-icons/ci";
import { useUI } from "../../context/ui";

const FileInput = ({ callback, accept, defaultText, maxSizeAllowed }) => {
	const [f, setF] = useState("");
	const { addToastMessage } = useUI();

	return (
		<div className='file-input-holder col-1-1 pos-rel'>
			{f ? f : defaultText}
			<input
				type='file'
				accept={accept}
				onChange={(e) => {
					const file = e.target.files[0];
					if (file && maxSizeAllowed && file.size > maxSizeAllowed) {
						addToastMessage({
							status: false,
							message: "File size should not exceed 2 MB",
						});
						return;
					}
					setF(e.target.files[0]?.name);
					callback(e.target.files[0]);
				}}
			/>
			<CiFileOn />
		</div>
	);
};

export default FileInput;
