import PageTitle from "../../components/common/PageTitle";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import { CiLogin } from "react-icons/ci";
import { NavLink } from "react-router-dom";

const NotAuthorized = () => {
	return (
		<>
			<PageTitle title='Unauthorized Access' />
			<section id='not-found'>
				<div
					style={{
						height: "100vh",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						textAlign: "center",
					}}>
					<div>
						<h4 className='color-primary'>Error.</h4>
						<h1 className='color-primary'>Unauthorized Access</h1>
						<br />
						<p>
							You are either not authorized to view the page or
							the page does not exist.
						</p>
						<p>Login to your account instead.</p>
						<br />
						<br />
						<NavLink to='/login'>
							<ButtonTextIcon
								title='Go to Login'
								icon={<CiLogin />}
							/>
						</NavLink>
					</div>
				</div>
			</section>
		</>
	);
};

export default NotAuthorized;
