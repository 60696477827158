import { useEffect, useState } from "react";
import PageTitle from "../../components/common/PageTitle";
import FullPageLoader from "../../components/ui/FullPageLoader";
import { CiCreditCard1, CiCircleCheck } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import { API_URL, BASE_URL } from "../../utilities/constants";
import { useParams } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { useUI } from "../../context/ui";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import LoaderIcon from "../../components/ui/LoaderIcon";
import SemiCircleChart from "../../components/ui/SemiCircleChart";

const ViewCreditScore = () => { 
  const [creditScore, setCreditScore] = useState(null);
  const [showPaymentButton, setShowPaymentButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {
    setNewNotification,
    setShowNotification,
    addNotification,
    setSegmentTitle,
  } = useUI();

  const SectionForm = ({ children }) => {
    return (
      <div className="col-1-2">
        <table>
          <thead></thead>
          <tbody>{children}</tbody>
        </table>
      </div>
    );
  };

  const Row = ({ children }) => {
    return (
      <div className="row b-r-8">
        <div className="col-1-1 wrap flex">{children}</div>
      </div>
    );
  };

  const SectionTitle = ({ title, subtitle, icon }) => {
    return (
      <div className="col-1-2">
        <div
          className="flex center-left"
          style={{
            gap: "12px",
          }}
        >
          {icon}
          <div className="flex center-left">
            <div>
              <p className="f-w-700 l-h-1-2 color-primary">{title}</p>
              <p className="l-h-1-2 color-light small">{subtitle}</p>
            </div>
          </div>
        </div>
        <br />
        <br />
        <div className="credit-score-box b-r-10" style={{ margin: "10px" }}>
          <h6 className="title f-w-700 color-primary">Credit Score</h6>
          <br />
          <SemiCircleChart
            current={creditScore ? creditScore : 0}
            total={900}
          />
        </div>
      </div>
    );
  };

  const checkIfCreditScoreRequired = async () => {
    setLoading(true);
    try {
      const resp = await fetch(
        API_URL + `/api/1.0.0/tenant/isCreditCheckRequired`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "X-JWT-Assertion": localStorage.getItem("token"),
          },
        }
      );
      if (resp.ok) {
        const response = await resp.json();
        if (!response.data.isCreditScoreRequired && response.data.creditScore) {
          setCreditScore(response.data.creditScore);
          setShowPaymentButton(true); 
        } else {
          navigate(`/credit-score`);
        }
      }
    } catch (e) {
      console.log("Error", e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    checkIfCreditScoreRequired();
  }, []);

  useEffect(() => {
    setSegmentTitle("Credit Score");
  }, []);

  return (
    <>
      <section>
        <PageTitle title="Credit Check" />
        <FullPageLoader isActive={loading} />
        <section className="pad-20 create-loan-application">
          <Row>
            <SectionTitle
              title="Credit Score"
              subtitle="Fetch credit info"
              icon={
                <CiCreditCard1
                  className="color-primary"
                  style={{
                    height: "32px",
                    width: "32px",
                    strokeWidth: "1px",
                  }}
                />
              }
            />
            <SectionForm>
              <tr>
                <td colSpan="2">
                  <div
                    className="payment-info-box"
                    style={{ border: "solid 1px #cfcfc0" ,paddingTop:"40px",paddingBottom:"40px" }}
                  >
                    <div className="price bg-color-primary b-r-10 pad-20">
                      <h6 className="color-white">Credit Score</h6>
                      <h3 className="color-white">Congratulations!</h3>
                      <br />
                      <p className="color-white l-h-1-2 font-size-12 ">
                        You can view your pre-approved rental offers by clicking
                        on the "View Offers" button.
                      </p>
                    </div>
                    <br />
                    <br />
                    <br />
                    <div className="t-a-c">
                      <ButtonTextIcon
                        className="b-r-20"
                        style={{ width: "100%" }}
                        type="button"
                        title={showPaymentButton ? "View Offers" : "Loading.."}
                        onClick={() => {
                          navigate(`/te-offer`);
                        }}
                        disabled={!showPaymentButton}
                        icon={
                          <>
                            {!showPaymentButton && (
                              <LoaderIcon />
                            ) }
                          </>
                        }
                      />
                    </div>
                  </div>
                </td>
              </tr>
            </SectionForm>
          </Row>
        </section>
      </section>
    </>
  );
};

export default ViewCreditScore;
