import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import FullPageLoader from "../../components/ui/FullPageLoader";
import { useParams } from "react-router-dom";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import LoaderIcon from "../../components/ui/LoaderIcon";
import RSModal from "../../components/ui/RSModal";
import { useNavigate } from "react-router-dom";
import {
	CiBag1,
	CiBarcode,
	CiBookmark,
	CiCalculator1,
	CiCircleCheck,
	CiClock2,
	CiRuler,
	CiHeart,
	CiHome,
	CiHospital1,
	CiLink,
	CiLocationOn,
	CiMail,
	CiMoneyBill,
	CiPhone,
	CiStickyNote,
	CiUser,
	CiCircleRemove,
	CiBoxList,
	CiChat1,
	CiTimer,
} from "react-icons/ci";
import {
	API_URL,
	DEFAULT_PER_PAGE,
	FREQUENCY_TYPE_LIST,
	LOAN_APPLICATION_APPLIED_STATUS,
	LOAN_APPLICATION_REJECTED_STATUS,
	LOAN_APPLICATION_STATUSES,
} from "../../utilities/constants";
import PageTitle from "../../components/common/PageTitle";
import { useUI } from "../../context/ui";
import TransparentImage from "../../images/icons/4x2.5.png";
import {
	formatDateTime,
	formatServerDate,
} from "../../../src/utilities/helpers";
import ApplicationHistoryView from "../../components/loan/ApplicationHistoryView";
import { formatAmountValue } from "../../utilities/helpers"

const CreditProviderLoanApplicationDetail = () => {
	const { id } = useParams();
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState(null);
	const { setSegmentTitle } = useUI();

	const callApi = async (id) => {
		setLoading(true);
		try {
			const resp = await fetch(
				API_URL + `/api/1.0.0/loan-application/${id}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						"X-JWT-Assertion": localStorage.getItem("token"),
					},
				}
			);
			if (resp.ok) {
				const response = await resp.json();
				setData(response.data);
			}
		} catch (e) {
			console.log("Error", e);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (id) {
			callApi(id);
			setSegmentTitle("Application Detail");
		}
	}, [id]);

	const getStatusDetails = (id) => {
		const o = LOAN_APPLICATION_STATUSES.find((obj) => obj.id === id);
		return o
			? o
			: {
					title: "Not found",
					color: "#5dc6b3",
			  };
	};

	const getFrequencyDetails = (value) => {
		const status = FREQUENCY_TYPE_LIST.find((obj) => obj.value === value);
		return status
			? status
			: {
					name: "NA",
					value: "NA",
					shortName: "na",
			  };
	};

	return (
		<section className='pos-rel'>
			<PageTitle title='Application Detail' />
			<FullPageLoader isActive={loading} />
			{data && (
				<section>
					<section className='property-detail pad-20'>
						<div className='flex center-left'>
							<p
								className='color-primary f-w-700'
								style={{
									width: "180px",
									overflow: "hidden",
									whiteSpace: "nowrap",
									textOverflow: "ellipsis",
								}}>
								<span>#</span>&nbsp;{data.applicationNo}
							</p>
							<div
								className='mar-l-a b-r-20'
								style={{
									background: getStatusDetails(
										data.applicationStatus
									).color,
									padding: "12px 24px",
								}}>
								<p className='color-white l-h-1'>
									{
										getStatusDetails(data.applicationStatus)
											.title
									}
								</p>
							</div>
						</div>
						<br />
						<div className='wrap pad-20 pos-rel'>
							<div
								className='credit-score-application-detail b-r-100 flex center-center'
								title='Credit Score'>
								{data.creditScore ? data.creditScore : "-"}
							</div>
							<table className='col-1-1'>
								<thead></thead>
								<tbody>
									<tr>
										<td colSpan='2'>
											<p className='f-w-600 color-primary'>
												Tenant Info:
											</p>
										</td>
									</tr>
									<tr>
										<td colSpan='2'>
											<hr />
										</td>
									</tr>
									<tr>
										<td>
											<div className='flex center-left'>
												<CiUser className='color-faded' />
												&nbsp;
												<p className='font-size-14 color-primary'>
													{data.tenant.firstName}{" "}
													{data.tenant.lastName}
												</p>
											</div>
										</td>
										<td>
											<div className='flex center-left'>
												<CiPhone className='color-faded' />
												&nbsp;
												<p className='font-size-14 color-primary'>
													{`(${data.tenant.countryCode}) ${data.tenant.phoneNo}`}
												</p>
											</div>
										</td>
									</tr>
									<tr>
										<td colSpan='2'>
											<div className='flex center-left'>
												<CiMail className='color-faded' />
												&nbsp;
												<p className='font-size-14 color-primary'>
													{data.tenant.email}
												</p>
											</div>
										</td>
									</tr>
									<tr>
										<td colSpan='2'>
											<br />
										</td>
									</tr>
									<tr>
										<td colSpan='2'>
											<p className='f-w-600 color-primary'>
												Agreement:
											</p>
										</td>
									</tr>
									<tr>
										<td colSpan='2'>
											<hr />
										</td>
									</tr>
									<tr>
										<td>
											<div className='flex center-left'>
												<CiBookmark className='color-faded' />
												&nbsp;
												<p className='font-size-14 color-faded'>
													Type
												</p>
												&nbsp;
												<p className='font-size-14 color-primary'>
													{data.agreementType}
												</p>
											</div>
										</td>
										<td>
											<div className='flex center-left'>
												<CiMoneyBill className='color-faded' />
												&nbsp;
												<p className='font-size-14 color-faded'>
													Rent
												</p>
												&nbsp;
												<p className='font-size-14 color-primary'>
												{`${formatAmountValue(data.rentalValue)}/- AED`}
												</p>
											</div>
										</td>
									</tr>
									<tr>
										<td colSpan='2'>
											<div className='flex top-left'>
												<CiCircleCheck className='color-faded' />
												&nbsp;
												<p className='font-size-14 color-faded'>
													Terms
												</p>
												&nbsp;
												<p className='font-size-14 color-primary flex-1'>
													{data.rentalTerms}
												</p>
											</div>
										</td>
									</tr>
									<tr>
										<td colSpan='2'>
											<br />
										</td>
									</tr>
									<tr>
										<td colSpan='2'>
											<p className='f-w-600 color-primary'>
												Income Info:
											</p>
										</td>
									</tr>
									<tr>
										<td colSpan='2'>
											<hr />
										</td>
									</tr>
									<tr>
										<td>
											<div className='flex center-left'>
												<CiClock2 className='color-faded' />
												&nbsp;
												<p className='font-size-14 color-faded'>
													DoB
												</p>
												&nbsp;
												<p className='font-size-14 color-primary'>
													{data.dateOfBirth
														? formatServerDate(
																data.dateOfBirth
														  )
														: "-"}
												</p>
											</div>
										</td>
										<td>
											<div className='flex center-left'>
												<CiHeart className='color-faded' />
												&nbsp;
												<p className='font-size-14 color-faded'>
													Marital Status
												</p>
												&nbsp;
												<p className='font-size-14 color-primary'>
													{data.maritalStatus ===
													null ? (
														<>
															<span>-</span>
														</>
													) : (
														<>
															{data.maritalStatus
																? "Married"
																: "Unmarried"}
														</>
													)}
												</p>
											</div>
										</td>
									</tr>
									<tr>
										<td>
											<div className='flex center-left'>
												<CiBookmark className='color-faded' />
												&nbsp;
												<p className='font-size-14 color-faded'>
													Citizenship
												</p>
												&nbsp;
												<p className='font-size-14 color-primary'>
													{data.citizenshipStatus
														? data.citizenshipStatus
														: "-"}
												</p>
											</div>
										</td>
										<td>
											<div className='flex center-left'>
												<CiStickyNote className='color-faded' />
												&nbsp;
												<p className='font-size-14 color-faded'>
													Emirates ID
												</p>
												&nbsp;
												<p className='font-size-14 color-primary'>
													{data.uaePass
														? data.uaePass
														: "-"}
												</p>
											</div>
										</td>
									</tr>
									<tr>
										<td>
											<div className='flex center-left'>
												<CiMoneyBill className='color-faded' />
												&nbsp;
												<p className='font-size-14 color-faded'>
													Employment
												</p>
												&nbsp;
												<p className='font-size-14 color-primary'>
													{data.employmentType
														? data.employmentType
														: "-"}
												</p>
											</div>
										</td>
										<td>
											<div className='flex center-left'>
												<CiMoneyBill className='color-faded' />
												&nbsp;
												<p className='font-size-14 color-faded'>
												{data.employmentType === "Self Employed"
                            ? "Annual Revenue"
                            : "Annual Income"}
												</p>
												&nbsp;
												<p className='font-size-14 color-primary'>
													{data.annualIncome
														? `${formatAmountValue(data.annualIncome)}/- AED`
														: "-"}
												</p>
											</div>
										</td>
									</tr>
									<tr>
										<td>
											<div className='flex center-left'>
												<CiHospital1 className='color-faded' />
												&nbsp;
												<p className='font-size-14 color-faded'>
												{data.employmentType === "Self Employed"
                            ? "Business Type"
                            : "Company Type"}
												</p>
												&nbsp;
												<p className='font-size-14 color-primary'>
													{data.companyType
														? data.companyType
														: "-"}
												</p>
											</div>
										</td>
										<td>
											<div className='flex center-left'>
												<CiHospital1 className='color-faded' />
												&nbsp;
												<p className='font-size-14 color-faded'>
												{data.employmentType === "Self Employed"
                            ? "Business Name"
                            : "Company Name"}
												</p>
												&nbsp;
												<p className='font-size-14 color-primary'>
													{data.companyName
														? data.companyName
														: "-"}
												</p>
											</div>
										</td>
									</tr>
									<tr>
										<td colSpan='2'>
											<div className='flex center-left'>
												<CiLocationOn className='color-faded' />
												&nbsp;
												<p className='font-size-14 color-faded'>
												{data.employmentType === "Self Employed"
                            ? "Business Address"
                            : "Company Address"}
												</p>
												&nbsp;
												<p className='font-size-14 color-primary'>
													{data.companyAddress
														? data.companyAddress
														: "-"}
												</p>
											</div>
										</td>
									</tr>
									<tr>
										<td colSpan='2'>
											<div className='flex center-left'>
												<CiBag1 className='color-faded' />
												&nbsp;
												<p className='font-size-14 color-faded'>
												{data.employmentType === "Self Employed"
                            ? "Nature of Business"
                            : "Job Title"}
												</p>
												&nbsp;
												<p className='font-size-14 color-primary'>
													{data.title
														? data.title
														: "-"}
												</p>
											</div>
										</td>
									</tr>
									<tr>
										<td colSpan='2'>
											<div className='flex center-left'>
												<CiMail className='color-faded' />
												&nbsp;
												<p className='font-size-14 color-faded'>
													Work Email
												</p>
												&nbsp;
												<p className='font-size-14 color-primary'>
													{data.workEmail
														? data.workEmail
														: "-"}
												</p>
											</div>
										</td>
									</tr>
									<tr>
										<td colSpan='2'>
											<hr />
										</td>
									</tr>
									<tr>
										<td>
											<div className='flex top-left'>
												<CiHospital1 className='color-fade' />
												&nbsp;
												<p className='font-size-14 color-faded'>
												{data.employmentType === "Self Employed"
                            ? "Recent Tax Returns"
                            : "Payslip"}
												</p>
												&nbsp;
												<div className='flex-1'>
													{data.s3PayslipList.map(
														(p, i) => (
															<p
																className='font-size-12 color-primary'
																key={i}>
																<a
																	className='hover-color-secondary'
																	href={p}
																	target='_blank'
																	rel='noreferrer'>
																	Click to
																	View
																</a>
															</p>
														)
													)}
												</div>
											</div>
										</td>
										<td>
											<div className='flex center-left'>
												<CiHospital1 className='color-faded' />
												&nbsp;
												<p className='font-size-14 color-faded'>
												{data.employmentType === "Self Employed"
                            ? "Incorparation Certificate"
                            : "Salary Certificate"}
												</p>
												&nbsp;
												{data.salaryCertificate ? (
													<p className='font-size-12 color-primary flex-1'>
														<a
															className='hover-color-secondary'
															href={
																data.s3SalaryCertificate
															}
															target='_blank'
															rel='noreferrer'>
															Click to view
														</a>
													</p>
												) : (
													<p className='font-size-14 color-primary'>
														-
													</p>
												)}
											</div>
										</td>
									</tr>
									<tr>
										<td colSpan='2'>
											<div className='flex center-left'>
												<CiHospital1 className='color-faded' />
												&nbsp;
												<p className='font-size-14 color-faded'>
													Bank Statement
												</p>
												&nbsp;
												{data.s3BankStatementList ? (
													<p className='font-size-12 color-primary flex-1'>
														<a
															className='hover-color-secondary'
															href={
																data.s3BankStatementList
															}
															target='_blank'
															rel='noreferrer'>
															Click to view
														</a>
													</p>
												) : (
													<p className='font-size-14 color-primary'>
														-
													</p>
												)}
											</div>
										</td>
									</tr>
									<tr>
										<td colSpan='2'>
											<hr />
										</td>
									</tr>
									<tr>
										<td colSpan='2'>
											<p className='f-w-600 color-primary'>
												Bank Info:
											</p>
										</td>
									</tr>
									<tr>
										<td colSpan='2'>
											<hr />
										</td>
									</tr>
									<tr>
										<td colSpan='2'>
											<div className='flex center-left'>
												<CiHome className='color-faded' />
												&nbsp;
												<p className='font-size-14 color-faded'>
													Name
												</p>
												&nbsp;
												<p className='font-size-14 color-primary'>
													{data.bankName
														? data.bankName
														: "-"}
												</p>
											</div>
										</td>
									</tr>
									<tr>
										<td colSpan='2'>
											<div className='flex center-left'>
												<CiHome className='color-faded' />
												&nbsp;
												<p className='font-size-14 color-faded'>
													Account No
												</p>
												&nbsp;
												<p className='font-size-14 color-primary'>
													{data.bankAccountNumber
														? data.bankAccountNumber
														: "-"}
												</p>
											</div>
										</td>
									</tr>
									<tr>
										<td colSpan='2'>
											<div className='flex center-left'>
												<CiBarcode className='color-faded' />
												&nbsp;
												<p className='font-size-14 color-faded'>
													SWIFT Code
												</p>
												&nbsp;
												<p className='font-size-14 color-primary'>
													{data.swiftCode
														? data.swiftCode
														: "-"}
												</p>
											</div>
										</td>
									</tr>
									<tr>
										<td colSpan='2'>
											<br />
										</td>
									</tr>
									<tr>
										<td colSpan='2'>
											<p className='f-w-600 color-primary'>
												Broker Info:
											</p>
										</td>
									</tr>
									<tr>
										<td colSpan='2'>
											<hr />
										</td>
									</tr>
									<tr>
										<td colSpan='1'>
											<div className='flex center-left'>
												<div className='flex center-left'>
													<CiUser className='color-faded' />
													&nbsp;
													<p className='font-size-14 color-faded'>
														Name
													</p>
													&nbsp;
													<p className='font-size-14 color-primary'>
														{
															data.agencyBrokerDTO
																.firstName
														}{" "}
														{
															data.agencyBrokerDTO
																.lastName
														}
													</p>
												</div>
											</div>
										</td>
										<td colSpan='1'></td>
									</tr>
									<tr>
										<td colSpan='1'>
											<div className='flex center-left'>
												<div className='flex center-left'>
													<CiMail className='color-faded' />
													&nbsp;
													<p className='font-size-14 color-faded'>
														Email
													</p>
													&nbsp;
													<p className='font-size-14 color-primary'>
														{
															data.agencyBrokerDTO
																.email
														}
													</p>
												</div>
											</div>
										</td>
										<td colSpan='1'>
											<div className='flex center-left'>
												<div className='flex center-left'>
													<CiPhone className='color-faded' />
													&nbsp;
													<p className='font-size-14 color-faded'>
														Phone
													</p>
													&nbsp;
													<p className='font-size-14 color-primary'>
														{`(+971) ${data.agencyBrokerDTO.phoneNo}`}
													</p>
												</div>
											</div>
										</td>
									</tr>
									{data.config && (
										<>
											<tr>
												<td colSpan='2'>
													<br />
												</td>
											</tr>
											<tr>
												<td colSpan='2'>
													<p className='f-w-600 color-primary'>
														Offer Info:
													</p>
												</td>
											</tr>
											<tr>
												<td colSpan='2'>
													<hr />
												</td>
											</tr>
											<tr>
												<td colSpan='1'>
													<div className='flex center-left'>
														<CiHospital1 className='color-faded' />
														&nbsp;
														<p className='font-size-14 color-faded'>
															Credit Provider
														</p>
														&nbsp;
														<p className='font-size-14 color-primary'>
															{data?.config
																?.creditProviderFirm
																.name
																? data?.config
																		?.creditProviderFirm
																		.name
																: "-"}
														</p>
													</div>
												</td>
												<td colSpan='1'>
													<div className='flex center-left'>
														<CiMoneyBill className='color-faded' />
														&nbsp;
														<p className='font-size-14 color-faded'>
															Processing Fee
														</p>
														&nbsp;
														<p className='font-size-14 color-primary'>
															{data?.config
																?.processingFee
																? data?.config
																		?.processingFee !==
																  0
																	? `${formatAmountValue(data?.config?.processingFee)}/- AED`
																	: "Zero"
																: "Zero"}
														</p>
													</div>
												</td>
											</tr>
											<tr>
												<td colSpan='1'>
													<div className='flex center-left'>
														<CiMoneyBill className='color-faded' />
														&nbsp;
														<p className='font-size-14 color-faded'>
															Rental upto
														</p>
														&nbsp;
														<p className='font-size-14 color-primary'>
															{data?.totalPayable
																? `${formatAmountValue(data?.totalPayable)}/- AED`
																: "-"}
														</p>
													</div>
												</td>
												<td colSpan='1'>
													<div className='flex center-left'>
														<CiMoneyBill className='color-faded' />
														&nbsp;
														<p className='font-size-14 color-faded'>
															Installment
														</p>
														&nbsp;
														<p className='font-size-14 color-primary'>
															{data?.totalPayable
																? `${formatAmountValue(data?.totalPayable)}/- AED
                            ${getFrequencyDetails(data.frequency).name}`
																: "-"}
														</p>
													</div>
												</td>
											</tr>
											<tr>
												<td colSpan='1'>
													<div className='flex center-left'>
														<CiRuler className='color-faded' />
														&nbsp;
														<p className='font-size-14 color-faded'>
														  EI Ratio
														</p>
														&nbsp;
														<p className='font-size-14 color-primary'>
															{data?.eiRatio
																?  data?.eiRatio
																: "-"}
														</p>
													</div>
												</td>
												</tr>
										</>
									)}
								</tbody>
							</table>
						</div>
					</section>
					<section className='property-detail pad-20'>
						<div className='wrap b-r-5 of-hid'>
							<div
								className='default-image of-hid'
								style={
									data.property.s3PicturesList
										? {
												backgroundImage: `url(${data.property.s3PicturesList[0]})`,
										  }
										: {}
								}>
								<img
									className='col-1-1'
									style={{
										height: "auto",
										display: "block",
									}}
									src={TransparentImage}
								/>
							</div>
							<br />
							<div className='pad-20'>
								<table className='col-1-1'>
									<thead></thead>
									<tbody>
										<tr>
											<td colSpan='2'>
												<p className='f-w-600 color-primary'>
													{data.property.propertyName}
												</p>
											</td>
										</tr>
										<tr>
											<td colSpan='2'>
												<p className='font-size-14 color-primary'>
													{data.property.description}
												</p>
											</td>
										</tr>
										<tr>
											<td colSpan='2'>
												<hr />
											</td>
										</tr>
										<tr>
											<td colSpan='2'>
												<div className='flex center-left'>
													<CiCalculator1 className='color-faded' />
													&nbsp;
													<p className='font-size-14 color-faded'>
														#RERA
													</p>
													&nbsp;
													<p className='font-size-14 color-primary'>
														{
															data.property
																.reraNumber
														}
													</p>
												</div>
											</td>
										</tr>
										<tr>
											<td colSpan='2'>
												<div className='flex center-left'>
													<CiLocationOn className='color-faded' />
													&nbsp;
													<p className='font-size-14 color-faded'>
														Address
													</p>
													&nbsp;
													<p className='font-size-14 color-primary'>
														{
															data.property
																.propertyAddress
														}
													</p>
												</div>
											</td>
										</tr>
										<tr>
											<td>
												<div className='flex center-left'>
													<CiHome className='color-faded' />
													&nbsp;
													<p className='font-size-14 color-faded'>
														Size
													</p>
													&nbsp;
													<p className='font-size-14 color-primary'>
														{
															data.property
																.propertySize
														}{" "}
														sq.ft.
													</p>
												</div>
											</td>
											<td>
												<div className='flex center-left'>
													<CiHome className='color-faded' />
													&nbsp;
													<p className='font-size-14 color-faded'>
														Type
													</p>
													&nbsp;
													<p className='font-size-14 color-primary'>
														{
															data.property
																.propertyType
														}
													</p>
												</div>
											</td>
										</tr>
										<tr>
											<td colSpan='2'>
												<hr />
											</td>
										</tr>
										<tr>
											<td colSpan='2'>
												<div className='flex center-left'>
													<CiUser className='color-faded' />
													&nbsp;
													<p className='font-size-14 color-faded'>
														Owner
													</p>
													&nbsp;
													<p className='font-size-14 color-primary'>
														{
															data.property.owner
																.fullName
														}
													</p>
												</div>
											</td>
										</tr>
										<tr>
											<td colSpan='2'>
												<div className='flex center-left'>
													<CiLocationOn className='color-faded' />
													&nbsp;
													<p className='font-size-14 color-primary'>
														{
															data.property.owner
																.address
														}
													</p>
													<p className='font-size-14 color-faded'>
														,
													</p>
													&nbsp;
													<p className='font-size-14 color-primary'>
														{
															data.property.owner
																.pinCode
														}
													</p>
												</div>
											</td>
										</tr>
										<tr>
											<td colSpan='2'>
												<div className='flex center-left'>
													<CiMail className='color-faded' />
													&nbsp;
													<p className='font-size-14 color-primary'>
														{
															data.property.owner
																.email
														}
													</p>
												</div>
											</td>
										</tr>
										<tr>
											<td colSpan='2'>
												<div className='flex center-left'>
													<CiPhone className='color-faded' />
													&nbsp;
													<p className='font-size-14 color-primary'>
														{
															data.property.owner
																.phoneNumber
														}
													</p>
												</div>
											</td>
										</tr>
										<tr>
											<td colSpan='2'>
												<hr />
											</td>
										</tr>
										<tr>
											<td>
												<a
													href={
														data.property
															.deedCopySignUrl
													}
													target='_new'
													rel='noreferrer'>
													<div className='flex center-left'>
														<CiLink className='color-faded' />
														&nbsp;
														<p className='transition font-size-14 color-faded hover-color-secondary'>
															Deed Copy
														</p>
													</div>
												</a>
											</td>
											<td>
												<a
													href={
														data.property
															.propertyUrl
													}
													target='_blank'
													rel='noreferrer'>
													<div className='flex center-right'>
														<CiLink className='color-primary' />
														&nbsp;
														<p className='transition font-size-14 color-faded hover-color-secondary'>
															Open URL
														</p>
													</div>
												</a>
											</td>
										</tr>
										<tr>
											<td colSpan='2'>
												<br />
											</td>
										</tr>
										<tr>
											<td colSpan='2'>
												<div
													className='flex center-right'
													style={{ gap: "8px" }}>
													<RSModal
														selector={
															<ButtonTextIcon
																title='View History'
																icon={
																	<CiBoxList />
																}
															/>
														}
														content={
															<ApplicationHistoryView
																loanApplicationHistory={
																	data.loanApplicationHistory
																}
															/>
														}
													/>
												</div>
											</td>
										</tr>
										{parseInt(data.applicationStatus) ===
											LOAN_APPLICATION_APPLIED_STATUS && (
											<>
												<tr>
													<td colSpan='2'>
														<hr />
													</td>
												</tr>
												<tr>
													<td colSpan='2'>
														<div
															className='flex center-center'
															style={{
																gap: "12px",
															}}>
															<RSModal
																selector={
																	<ButtonLightTextIcon
																		disabled={
																			loading
																		}
																		type='button'
																		title='Reject'
																		icon={
																			<CiCircleRemove />
																		}
																	/>
																}
																content={
																	<RejectFormContent
																		id={
																			data.id
																		}
																		callback={() => {
																			callApi(
																				id
																			);
																		}}
																	/>
																}
															/>
															<RSModal
																selector={
																	<ButtonTextIcon
																		disabled={
																			loading
																		}
																		type='button'
																		title='Accept'
																		icon={
																			<CiCircleCheck />
																		}
																	/>
																}
																content={
																	<AcceptFormContent
																		loanAmount={
																			data.rentalValue
																		}
																		interestRate={
																			data.interestRate
																		}
																		frequency={
																			data.frequency
																		}
																		id={
																			data.id
																		}
																		processingFee={
																			data
																				?.config
																				?.processingFee
																				? data
																						?.config
																						?.processingFee
																				: 0
																		}
																		callback={() => {
																			callApi(
																				id
																			);
																		}}
																	/>
																}
															/>
														</div>
													</td>
												</tr>
											</>
										)}
									</tbody>
								</table>
							</div>
						</div>
					</section>
				</section>
			)}
		</section>
	);
};

const AcceptFormContent = ({
	loanAmount,
	interestRate,
	frequency,
	id,
	processingFee,
	setShow,
	callback,
}) => {
	const [loading, setLoading] = useState(false);
	const initialValues = {
		loanAmount: loanAmount,
		interestRate: interestRate,
		frequency: frequency,
		comment: "",
	};

	const validationSchema = Yup.object({
		comment: Yup.string().required("Comment is required"),
		interestRate: Yup.string()
			.matches(/^\d+(\.\d+)?$/)
			.required("Interest rate is required"),
		loanAmount: Yup.string()
			.matches(/^[0-9]*$/, "Only numbers allowed!")
			.required("Loan amount is required"),
		frequency: Yup.string().required("Frequency is required"),
	});

	const FieldTitle = ({ title }) => {
		return (
			<p
				className='title f-w-700 color-primary'
				style={{ marginBottom: "5px", fontSize: "14px" }}>
				{title}
			</p>
		);
	};

	const LikeInput = ({ title }) => {
		return (
			<div className='ui-like-input col-1-1 cursor-not-allowed'>
				{title ? title : "0"}
			</div>
		);
	};

	const handleSubmit = async (
		values,
		{ validateForm, setErrors, setSubmitting, resetForm, setStatus }
	) => {
		setLoading(true);
		setSubmitting(true);
		try {
			const request = {
				comment: values.comment,
				loanAmount: values.loanAmount,
				interestRate: values.interestRate,
				frequency: values.frequency,
			};

			const response = await fetch(
				`${API_URL}/api/1.0.0/loan-application/approve-loan/${id}`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
						"X-JWT-Assertion": localStorage.getItem("token"),
					},
					body: JSON.stringify(request),
				}
			);

			if (response.ok) {
				const data = await response.json();
				if (data) {
					callback();
					setShow(false);
				}
			} else {
				const errorData = await response.json();
				throw new Error(
					errorData.result.responseDescription || "API request failed"
				);
			}
		} catch (e) {
			console.error("Error:", e);
			setStatus({
				success: false,
				message: "Something went wrong, please try again.",
			});
			setLoading(false);
		} finally {
			setSubmitting(false);
		}
	};

	function capitalizeFirstLetter(str) {
		return str[0].toUpperCase() + str.slice(1);
	}

	return (
		<div className='pos-rel' style={{ width: "480px", padding: "40px" }}>
			<CiCircleRemove
				className='cursor-pointer transition hover-color-secondary'
				onClick={() => setShow(false)}
				style={{
					position: "absolute",
					top: "10px",
					right: "10px",
					fontSize: "32px",
				}}
			/>
			<h4 className='color-primary'>Accept application</h4>
			<br />
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				enableReinitialize={true}
				onSubmit={handleSubmit}>
				{({
					values,
					errors,
					touched,
					status,
					validateForm,
					setFieldValue,
					resetForm,
					handleSubmit,
					isSubmitting,
				}) => (
					<Form>
						<div style={{ marginBottom: "10px" }}>
							<FieldTitle title='Loan Amount (AED)' />
							<Field
								name='loanAmount'
								type='text'
								placeholder='AED *'
								className={
									"col-1-1 " +
									(errors.loanAmount && touched.loanAmount
										? " error"
										: "")
								}
							/>
							<ErrorMessage
								name='loanAmount'
								component='p'
								className='error-messages'
							/>
						</div>
						<div style={{ marginBottom: "10px" }}>
							<FieldTitle title='Interest %' />
							<Field
								name='interestRate'
								type='text'
								placeholder='Eg [5.2] *'
								className={
									"col-1-1 " +
									(errors.interestRate && touched.interestRate
										? " error"
										: "")
								}
							/>
							<ErrorMessage
								name='interestRate'
								component='p'
								className='error-messages'
							/>
						</div>
						<div style={{ marginBottom: "10px" }}>
							<FieldTitle title='Processing Fee' />
							<LikeInput title={processingFee} />
						</div>
						<div style={{ marginBottom: "10px" }}>
							<FieldTitle title='Payment Frequency' />
							<Field
								as='select'
								name='frequency'
								className={
									"col-1-1 " +
									(errors.frequency && touched.frequency
										? " error"
										: "")
								}>
								<option value=''>Select Frequency</option>
								{FREQUENCY_TYPE_LIST &&
									FREQUENCY_TYPE_LIST.map((a, i) => (
										<option value={a.value} key={i}>
											{capitalizeFirstLetter(a.name)}
										</option>
									))}
							</Field>
							<ErrorMessage
								name='frequency'
								component='p'
								className='error-messages'
							/>
						</div>
						<div style={{ marginBottom: "10px" }}>
							<FieldTitle title='Comments' />
							<Field
								name='comment'
								component='textarea'
								type='text'
								placeholder='Enter comments.. *'
								maxLength='200'
								style={{ height: "100px" }}
								className={
									"col-1-1 " +
									(errors.comment && touched.comment
										? " error"
										: "")
								}
							/>
							<ErrorMessage
								name='comment'
								component='p'
								className='error-messages'
							/>
						</div>
						<div style={{ marginBottom: "10px" }}>
							{status && (
								<p
									className={
										status.success
											? "success-messages"
											: "error-messages"
									}>
									{status.message}
								</p>
							)}
						</div>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "flex-end",
								gap: "12px",
								marginBottom: "10px",
							}}>
							<ButtonLightTextIcon
								title='Cancel'
								icon={null}
								onClick={() => setShow(false)}
							/>
							{loading ? (
								<ButtonTextIcon
									type='button'
									title='Accpeting...'
									disabled={loading}
									icon={<LoaderIcon />}
								/>
							) : (
								<ButtonTextIcon
									type='submit'
									title='Accept'
									icon={<CiCircleCheck />}
								/>
							)}
						</div>
					</Form>
				)}
			</Formik>
		</div>
	);
};

const RejectFormContent = ({ id, setShow, callback }) => {
	const [loading, setLoading] = useState(false);
	const initialValues = {
		comment: "",
	};
	const validationSchema = Yup.object({
		comment: Yup.string().required("Comment is required"),
	});

	const handleSubmit = async (
		values,
		{ validateForm, setErrors, setSubmitting, resetForm, setStatus }
	) => {
		setLoading(true);
		setSubmitting(true);
		try {
			const request = {
				applicationStatus: 12,
				comment: values.comment,
			};

			const response = await fetch(
				`${API_URL}/api/1.0.0/loan-application/${id}/status`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
						"X-JWT-Assertion": localStorage.getItem("token"),
					},
					body: JSON.stringify(request),
				}
			);

			if (response.ok) {
				const data = await response.json();
				if (data) {
					setShow(false);
					callback();
				}
			} else {
				const errorData = await response.json();
				throw new Error(
					errorData.result.responseDescription || "API request failed"
				);
			}
		} catch (e) {
			console.error("Error:", e);
			setStatus({
				success: false,
				message: "Something went wrong, please try again.",
			});
			setLoading(false);
		} finally {
			setSubmitting(false);
		}
	};

	const FieldTitle = ({ title }) => {
		return (
			<p
				className='title f-w-700 color-primary'
				style={{ marginBottom: "5px", fontSize: "14px" }}>
				{title}
			</p>
		);
	};

	return (
		<div className='pos-rel' style={{ width: "480px", padding: "40px" }}>
			<CiCircleRemove
				className='cursor-pointer transition hover-color-secondary'
				onClick={() => setShow(false)}
				style={{
					position: "absolute",
					top: "10px",
					right: "10px",
					fontSize: "32px",
				}}
			/>
			<h4 className='color-primary'>Reject application</h4>
			<br />
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				enableReinitialize={true}
				onSubmit={handleSubmit}>
				{({
					values,
					errors,
					touched,
					status,
					validateForm,
					setFieldValue,
					resetForm,
					handleSubmit,
					isSubmitting,
				}) => (
					<Form>
						<div>
							<FieldTitle title='Comment' />
							<Field
								name='comment'
								component='textarea'
								type='text'
								placeholder='Reason for rejection'
								maxLength='200'
								style={{ height: "100px" }}
								className={
									"col-1-1 " +
									(errors.comment && touched.comment
										? " error"
										: "")
								}
							/>
							<ErrorMessage
								name='comment'
								component='p'
								className='error-messages'
							/>
						</div>
						<br />
						<div>
							{status && (
								<p
									className={
										status.success
											? "success-messages"
											: "error-messages"
									}>
									{status.message}
								</p>
							)}
						</div>
						<br />
						<div
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "flex-end",
								gap: "12px",
							}}>
							<ButtonLightTextIcon
								title='Cancel'
								icon={null}
								onClick={() => setShow(false)}
							/>
							{loading ? (
								<ButtonTextIcon
									type='button'
									title='Updating...'
									disabled={loading}
									icon={<LoaderIcon />}
								/>
							) : (
								<ButtonTextIcon
									style={{ background: "red" }}
									type='submit'
									title='Reject'
									icon={<CiCircleRemove />}
								/>
							)}
						</div>
					</Form>
				)}
			</Formik>
		</div>
	);
};

export default CreditProviderLoanApplicationDetail;
