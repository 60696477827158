import { useEffect, useState } from "react";
import PageTitle from "../../components/common/PageTitle";
import FullPageLoader from "../../components/ui/FullPageLoader";
import { useLocation, useNavigate } from "react-router-dom";
import { API_URL } from "../../utilities/constants";
import { useParams } from "react-router-dom";
import { useUI } from "../../context/ui";

const AddPreInstallmentPaymentVerify = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const {
    setNewNotification,
    setShowNotification,
    addNotification,
    setSegmentTitle,
  } = useUI();

  const location = useLocation();

  const verifyTransaction = async () => {
    try {
      const resp = await fetch(
        API_URL +
          "/api/1.0.0/transaction/" +
          localStorage.getItem("transactionId") +
          "/check",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-JWT-Assertion": localStorage.getItem("token"),
          },
        }
      );
      if (resp.ok) {
        const response = await resp.json();
        if (response.result.responseCode == 0) {
          //chnage application
          const response = await fetch(
            `${API_URL}/api/1.0.1/loan/${id}/status/26`,
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                "X-JWT-Assertion": localStorage.getItem("token"),
              },
            }
          );
          if (response.ok) {
            const data = await response.json();
            if ((data.result.responseCode === 200)) {
              localStorage.removeItem("transactionId");
              navigate(
                `/te-credit-accounts/${id}/pre-installment/payment-success`
              );
            } else {
              localStorage.removeItem("transactionId");
              navigate(
                `/te-credit-accounts/${id}/pre-installment/payment-failure`
              );
            }
          } else {
            const errorData = await response.json();
            throw new Error(
              errorData.result.responseDescription || "API request failed"
            );
          }
        } else {
          setNewNotification(true);
          setShowNotification(true);
          addNotification({
            id: Date.now(),
            message: "You have to re-pay your pre-installment again.",
            status: false,
            archive: false,
          });
          localStorage.removeItem("transactionId");
          navigate(`/te-credit-accounts/${id}/pre-installment/payment-failure`);
        }
      }
    } catch (e) {
      console.log("Error", e);
      navigate(`/te-credit-accounts/${id}/pre-installment/payment-failure`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const queryObj = {};
    params.forEach((value, key) => {
      queryObj[key] = value;
    });
    if (
      queryObj.result &&
      queryObj.result === "Success" &&
      localStorage.getItem("transactionId")
    ) {
      verifyTransaction();
    } else {
      setNewNotification(true);
      setShowNotification(true);
      localStorage.removeItem("transactionId");
      addNotification({
        id: Date.now(),
        message: "Your payment failed while trying to check credit score.",
        status: false,
        archive: false,
      });
      navigate(`/te-credit-accounts/${id}/pre-installment/payment-failure`);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    setSegmentTitle("Verifying...");
  }, []);

  return (
    <section>
      <PageTitle title="Verifying..." />
      <FullPageLoader isActive={loading} />
    </section>
  );
};

export default AddPreInstallmentPaymentVerify;
