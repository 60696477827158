import { useEffect, useState } from "react";
import FullPageLoader from "../../components/ui/FullPageLoader";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import {
	CiCircleCheck,
	CiCirclePlus,
	CiNoWaitingSign,
	CiTrash,
} from "react-icons/ci";
import { API_URL } from "../../utilities/constants";
import RSModal from "../../components/ui/RSModal";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import Pagination from "../../components/common/Pagination";
import PageTitle from "../../components/common/PageTitle";
import ResultsPerPage from "../_common/ResultsPerPage";
import LoaderIcon from "../../components/ui/LoaderIcon";
import { useUI } from "../../context/ui";

const ConfirmStatusChange = ({
	brokerId,
	callback,
	setShow,
	currentStatusID,
	text,
}) => {
	const [loading, setLoading] = useState(false);
	const handleStatusChange = async () => {
		setLoading(true);
		try {
			const resp = await fetch(
				API_URL +
				`/api/1.0.0/user/${brokerId}/status/${currentStatusID === 1 ? 14 : 1
				}`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
						"X-JWT-Assertion": localStorage.getItem("token"),
					},
				}
			);
			if (resp.ok) {
				const response = await resp.json();
				if (response) {
					setShow(false);
					callback();
				}
			}
		} catch (e) {
			console.log("Error", e);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div
			style={{
				height: "auto",
				width: "480px",
				padding: "40px",
			}}>
			<h4 className='color-primary'>
				Are you sure you want to {text} the broker?
			</h4>
			<br />
			<p>
				Changing this might have some unexpected consequences, please
				make sure that you understand.
			</p>
			<br />
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "flex-end",
					gap: "12px",
				}}>
				<ButtonLightTextIcon
					title='Go back'
					icon={
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='26.562'
							height='17.809'
							viewBox='0 0 26.562 17.809'>
							<path d='M0,8.88,10.686,0l1.937,2.293L6.453,7.4H26.562v3H6.454l6.166,5.108-1.934,2.3Z' />
						</svg>
					}
					onClick={() => setShow(false)}
				/>
				{loading ? (
					<ButtonTextIcon
						type='button'
						title='Updating...'
						disabled={loading}
						icon={<LoaderIcon />}
					/>
				) : (
					<ButtonTextIcon
						title='I understand'
						icon={<CiCircleCheck />}
						onClick={() => handleStatusChange()}
					/>
				)}
			</div>
		</div>
	);
};

const ConfirmDelete = ({ brokerId, callback, setShow }) => {
	const [loading, setLoading] = useState(false);
	const handleDelete = async () => {
		setLoading(true);
		try {
			const resp = await fetch(API_URL + `/api/1.0.0/user/${brokerId}`, {
				method: "DELETE",
				headers: {
					"Content-Type": "application/json",
					"X-JWT-Assertion": localStorage.getItem("token"),
				},
			});
			if (resp.ok) {
				const response = await resp.json();
				if (response) {
					setShow(false);
					callback();
				}
			}
		} catch (e) {
			console.log("Error", e);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div
			style={{
				height: "auto",
				width: "480px",
				padding: "40px",
			}}>
			<h4 className='color-primary'>
				Are you sure you want to delete the broker?
			</h4>
			<br />
			<p>
				Once you delete the broker, the broker can no longer log in to
				Rent-Savvy.
			</p>
			<br />
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "flex-end",
					gap: "12px",
				}}>
				<ButtonLightTextIcon
					title='Go back'
					icon={
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='26.562'
							height='17.809'
							viewBox='0 0 26.562 17.809'>
							<path d='M0,8.88,10.686,0l1.937,2.293L6.453,7.4H26.562v3H6.454l6.166,5.108-1.934,2.3Z' />
						</svg>
					}
					onClick={() => setShow(false)}
				/>
				{loading ? (
					<ButtonTextIcon
						type='button'
						title='Deleting...'
						disabled={loading}
						icon={<LoaderIcon />}
					/>
				) : (
					<ButtonTextIcon
						title='Yes, Delete'
						icon={<CiCircleCheck />}
						onClick={() => handleDelete()}
					/>
				)}
			</div>
		</div>
	);
};

const CreditProviderUserLoanList = () => {
	const [loading, setLoading] = useState(false);
	const location = useLocation();
	const [data, setData] = useState(null);
	const navigate = useNavigate();
	const { id } = useParams();
	const { setSegmentTitle } = useUI();

	const callListApi = async () => {
		setLoading(true);
		const pageNo = new URLSearchParams(location.search).get("pageNo");
		const pageSize = new URLSearchParams(location.search).get("pageSize");
		try {
			const resp = await fetch(
				API_URL +
				`/api/1.0.0/loan-application/cp?pageNo=${pageNo}&pageSize=${pageSize}&sortOrder=desc`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						"X-JWT-Assertion": localStorage.getItem("token"),
					},
				}
			);
			if (resp.ok) {
				const response = await resp.json();
				setData(response.data);
			}
		} catch (e) {
			console.log("Error", e);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		callListApi();
	}, []);


	// const handleAssignApi
	const handleAssignApi = async (id) => {
        setLoading(true);
        try {
            const response = await fetch(API_URL + `/api/1.0.0/credit-provider/assign/applicationId/${id}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-JWT-Assertion": localStorage.getItem("token"),
                },
            });
            if (response.ok) {
                const data = await response.json();
				navigate(
					`/cpur-my-task`
				);
            } else {
                console.log('errror');
            }
        } catch (error) {
            console.log("An error occurred.");
        }finally{
            setLoading(false);
        }
    };

	return (
		<section className='pos-rel'>
			<PageTitle title='Loan Application' />
			<FullPageLoader isActive={loading} />
			<section>
				<div className='flex center-left' style={{ padding: "20px" }}>
					<ResultsPerPage
						targetURL={`/cpur-loan-list?pageNo=0&pageSize=`}
					/>
				</div>
				<div className='results'>
					<div className='rs-table-holder'>
						<table className='rs-table'>
							<thead>
								<tr>
									<th>Customer Name</th>
									<th>Email</th>
									<th>Phno</th>
									<th>Loan Amount</th>
									<th>Status</th>
									<th>Assign to Myself</th>
								</tr>
							</thead>
							{data && (
								<tbody>
									{data?.loanApplications.map((d, i) => (
										<tr key={i}>
											<td>{d.tenant.firstName} {d.tenant.lastName}</td>
											<td>{d.tenant.email}</td>
											<td>{d.tenant.phoneNo}</td>
											<td>${d.rentalValue}</td>
											<td>
												<div
													className={`status ${
														d.statusId === 1
															? "active"
															: "inactive"
													}`}>
													{d.statusId === 1
														? "Active"
														: "Inactive"}
												</div>
											</td>
											<td>{d.creditProviderUser? d.creditProviderUser:<button onClick={() => { handleAssignApi(d.id); }}>Assign to Myself</button>}</td>
											{/* <td>
												{d.statusId === 1 ||
													d.statusId === 1 ? (
													<RSModal
														selector={
															<div
																className={`cursor-pointer status ${d.statusID ===
																	1
																	? "active"
																	: "inactive"
																	}`}>
																{d.statusID ===
																	1
																	? "Deactivate"
																	: "Activate"}
															</div>
														}
														content={
															<ConfirmStatusChange
																text={
																	d.statusID ===
																		1
																		? "deactivate"
																		: "re-activate"
																}
																currentStatusID={
																	d.statusID
																}
																brokerId={d.id}
																callback={() =>
																	callListApi()
																}
															/>
														}
													/>
												) : (
													<CiNoWaitingSign />
												)}
											</td> */}
											
										</tr>
									))}
								</tbody>
							)}
						</table>
					</div>
				</div>
				<br />
				{data && (
					<Pagination
						totalRecords={data.totalRecords}
						pageNo={data.pageNo}
						pageSize={data.pageSize}
						baseUrl='/cpur-loan-list'
					/>
				)}
			</section>
		</section>
	);
};

export default CreditProviderUserLoanList;
