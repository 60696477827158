import "./css/globals.scss";
import "./css/base.scss";
import "./css/pages.scss";
import "./css/responsive.scss";

import { Route, Routes } from "react-router-dom";

// ====== Contexts & HOCs ====== //

import RoleBasedComponent from "./context/roles";
import { UIProvider } from "./context/ui";

// ====== Common Pages ====== //

import Login from "./pages/_common/Login";
import SetPassword from "./pages/_common/SetPassword";
import LinkCode from "./pages/_common/LinkCode";
import NotFound from "./pages/_common/NotFound";
import NotAuthorized from "./pages/_common/NotAuthorized";
import DefaultPage from "./pages/_common/DefaultPage";
import UIGuide from "./pages/_common/UIGuide";
import Profile from "./pages/_common/Profile";
import ForgotPassword from "./pages/_common/ForgotPassword";
import ResetPassword from "./pages/_common/ResetPassword";

// ====== Pending ====== //

import ContactUs from "./pages/_common/ContactUs";

// ====== Super Admin [RoleId: 1] ====== //

import SuperAdminDashboard from "./pages/rs-superadmin/SuperAdminDashboard";
import AgencyList from "./pages/rs-superadmin/AgencyList";
import CreateAgency from "./pages/rs-superadmin/CreateAgency";
import UpdateAgency from "./pages/rs-superadmin/UpdateAgency";
import SaCreateCreditProvider from "./pages/rs-superadmin/SuperAdminCreateCreditProvider";
import SaCreditProvidersList from "./pages/rs-superadmin/SuperAdminCreditProviders";

// ====== Sub Admin [RoleId: 2] ====== //

// ====== Agency Admin [RoleId: 3] ====== //

import TnCAgreement from "./pages/agency-superadmin/TnCAgreement";
import AgencyAdminPending from "./pages/agency-superadmin/AgencyAdminPending";
import AgencySuperadminDashboard from "./pages/agency-superadmin/AgencySuperadminDashboard";
import BrokerList from "./pages/agency-superadmin/BrokerList";
import AddBroker from "./pages/agency-superadmin/AddBroker";

// ====== Agency Broker [RoleId: 4] ====== //

import AgencyBrokerDashboard from "./pages/agency-broker/AgencyBrokerDashboard";
import AgencyBrokerPropertyListing from "./pages/agency-broker/AgencyBrokerPropertyListing";
import AgencyBrokerPropertyDetail from "./pages/agency-broker/AgencyBrokerPropertyDetail";
import CreateProperty from "./pages/agency-broker/CreateProperty";
import AgencyBrokerPending from "./pages/agency-broker/AgencyBrokerPending";
import AgencyBrokerLoanApplications from "./pages/agency-broker/AgencyBrokerLoanApplications";
import AgencyBrokerCreateApplication from "./pages/agency-broker/AgencyBrokerCreateApplication";
import AgencyBrokerLoanApplicationDetail from "./pages/agency-broker/AgencyBrokerLoanApplicationDetail";

// ====== Individual Broker [RoleId: 5] ====== //

// ===== CP Admin Admin [RoleId: 6] ====== //

import CPSADashboard from "./pages/creditprovider-admin/CPSADashboard";
import CreditProvidersList from "./pages/creditprovider-admin/CreditProvidersList";
import AddCreditProvider from "./pages/creditprovider-admin/AddCreditProviderUser";
import CreditProviderLoanApplicationList from "./pages/creditprovider-admin/CreditProviderLoanApplicationList";
import FirmPendingDetails from "./pages/creditprovider-admin/FirmPendingDetails";

// ===== CP Reviewer [RoleId: 7] ====== //

import MyTask from "./pages/creditprovider-reviewer/MyTask";
import CreditProviderUserDashboard from "./pages/creditprovider-reviewer/CpUserDashboard";
import CreditProviderUserLoanList from "./pages/creditprovider-reviewer/CreditProviderUserLoanList";

// ====== Tenant Pages [RoleId: 8] ====== //

import TenantDashboard from "./pages/tenant/TenantDashboard";
import TenantMyApplications from "./pages/tenant/TenantMyApplications";
import TenantMyApplicationDetail from "./pages/tenant/TenantMyApplicationDetail";
import TenantUpdateApplication from "./pages/tenant/TenantUpdateApplication";
import AddCreditScoreToApplication from "./pages/tenant/AddCreditScoreToApplication";
import ViewCreditScoreToApplication from "./pages/tenant/ViewCreditScoreToApplication";
import AddCreditScoreVerify from "./pages/tenant/AddCreditScoreVerify";
import AddCreditScorePaymentFailure from "./pages/tenant/AddCreditScorePaymentFailure";
import TenantMyApplicationDetailPreApproval from "./pages/tenant/TenantMyApplicationDetailPreApproval";
import TenantLoanList from "./pages/tenant/TenantLoanList";

// ===== CP Approver [RoleId: 9] ====== //

import CPApproverDashboard from "./pages/creditprovider-approver/CPApproverDashboard";
import CreditProviderLoanApplicationDetail from "./pages/creditprovider-admin/CreditProviderLoanApplicationDetail";
import TenantLoanDetail from "./pages/tenant/TenantLoanDetail";
import CreditProviderCreditAccountDetail from "./pages/creditprovider-admin/CreditProviderCreditAccountDetail";
import CreditProviderCreditAccountList from "./pages/creditprovider-admin/CreditProviderCreditAccountList";
import AddPreInstallmentPaymentVerify from "./pages/tenant/AddPreInstallmentPaymentVerify";
import AddPreInstallmentPaymentFailure from "./pages/tenant/AddPreInstallmentPaymentFailure";
import AddPreInstallmentPaymentSuccess from "./pages/tenant/AddPreInstallmentPaymentSuccess";
import MyCreditScoreVerify from "./pages/tenant/MyCreditScoreVerify";
import MyCreditScore from "./pages/tenant/MyCreditScore";
import ViewCreditScore from "./pages/tenant/ViewCreditScore";
import MyCreditScorePaymentFailure from "./pages/tenant/MyCreditScorePaymentFailure";
import ThankYou from "./pages/_common/ThankYou";
import TransactionHistory from "./pages/_common/TransactionHistory";
import SaCreditProvider from "./pages/rs-superadmin/SuperAdminCreditProvider";
import MyCreditScoreHistory from "./pages/tenant/MyCreditScoreHistory";
import MyOfferList from "./pages/tenant/MyOfferList";
import TenantPending from "./pages/tenant/TenantPending";
import { useEffect } from "react";
import { clarity } from "react-microsoft-clarity";
import {
	BASE_URL,
	MS_CLARITY_KEY_ADMIN,
	MS_CLARITY_KEY_TENANT,
} from "./utilities/constants";
import UpdateProperty from "./pages/agency-broker/UpdateProperty";

// ===== Tenant Specific ====== //

import TenantLogin from "./pages/_common/TenantLogin";
import TenantRegister from "./pages/_common/TenantRegister";

// ====== App Routes ====== //

const App = () => {
	useEffect(() => {
		let key = "";
		if (BASE_URL === "https://tenant.rent-savvy.com") {
			key = MS_CLARITY_KEY_TENANT;
		} else if (BASE_URL === "https://app.rent-savvy.com") {
			key = MS_CLARITY_KEY_ADMIN;
		}
		if (key !== "") {
			clarity.init(key);
		}
		// clarity.consent(); // Handle the consent part later.
	}, []);

	const getLoginPage = () => {
		if (BASE_URL === "https://tenant.rent-savvy.com") {
			return <TenantLogin />;
		} else {
			return <Login />;
		}
	};

	return (
		<UIProvider>
			<Routes>
				<Route exact path='/login' element={getLoginPage()} />
				<Route path='/set-password' element={<SetPassword />} />
				<Route path='/forgot-password' element={<ForgotPassword />} />
				<Route path='/reset-password' element={<ResetPassword />} />
				<Route path='/register' element={<TenantRegister />} />
				<Route path='/contact' element={<ContactUs />} />
				<Route path='/thank-you' element={<ThankYou />} />
				<Route path='/link' element={<LinkCode />} />
				<Route
					path='/profile'
					element={
						<RoleBasedComponent
							allowedRoles={[1, 2, 3, 4, 5, 6, 7, 8, 9]}
							component={Profile}
						/>
					}
				/>
				<Route
					path='/transaction-history'
					element={
						<RoleBasedComponent
							allowedRoles={[1, 8]}
							component={TransactionHistory}
						/>
					}
				/>

				{/* =================== RoleId: 1 ================== */}

				<Route
					path='/sa-dashboard'
					element={
						<RoleBasedComponent
							allowedRoles={[1]}
							component={SuperAdminDashboard}
						/>
					}
				/>
				<Route
					path='/sa-agency'
					element={
						<RoleBasedComponent
							allowedRoles={[1]}
							component={AgencyList}
						/>
					}
				/>
				<Route
					path='/sa-agency/create'
					element={
						<RoleBasedComponent
							allowedRoles={[1]}
							component={CreateAgency}
						/>
					}
				/>
				<Route
					path='/sa-agency/update/:id'
					element={
						<RoleBasedComponent
							allowedRoles={[1]}
							component={UpdateAgency}
						/>
					}
				/>
				<Route
					path='/sa-credit-provider/create'
					element={
						<RoleBasedComponent
							allowedRoles={[1]}
							component={SaCreateCreditProvider}
						/>
					}
				/>
				<Route
					path='/sa-credit-provider'
					element={
						<RoleBasedComponent
							allowedRoles={[1]}
							component={SaCreditProvidersList}
						/>
					}
				/>
				<Route
					path='/sa-credit-provider/:id'
					element={
						<RoleBasedComponent
							allowedRoles={[1]}
							component={SaCreditProvider}
						/>
					}
				/>

				{/* =================== RoleId: 2 ================== */}

				{/* =================== RoleId: 3 ================== */}

				<Route path='/basa-tnc-agreement' element={<TnCAgreement />} />
				<Route
					path='/basa-pending-details'
					element={<AgencyAdminPending />}
				/>
				<Route
					path='/basa-dashboard'
					element={
						<RoleBasedComponent
							allowedRoles={[3]}
							component={AgencySuperadminDashboard}
						/>
					}
				/>
				<Route
					path='/basa-brokers'
					element={
						<RoleBasedComponent
							allowedRoles={[1, 3]}
							component={BrokerList}
						/>
					}
				/>
				<Route
					path='/basa-brokers/add'
					element={
						<RoleBasedComponent
							allowedRoles={[3]}
							component={AddBroker}
						/>
					}
				/>

				{/* =================== RoleId: 4 ================== */}

				<Route
					path='/ab-dashboard'
					element={
						<RoleBasedComponent
							allowedRoles={[4]}
							component={AgencyBrokerDashboard}
						/>
					}
				/>
				<Route
					path='/ab-properties'
					element={
						<RoleBasedComponent
							allowedRoles={[3, 4]}
							component={AgencyBrokerPropertyListing}
						/>
					}
				/>
				<Route
					path='/ab-properties/add'
					element={
						<RoleBasedComponent
							allowedRoles={[4]}
							component={CreateProperty}
						/>
					}
				/>
				<Route
					path='/ab-properties/:id'
					element={
						<RoleBasedComponent
							allowedRoles={[3, 4]}
							component={AgencyBrokerPropertyDetail}
						/>
					}
				/>
				<Route
					path='/ab-properties/update/:id'
					element={
						<RoleBasedComponent
							allowedRoles={[4]}
							component={UpdateProperty}
						/>
					}
				/>
				<Route
					path='/ab-loan-applications'
					element={
						<RoleBasedComponent
							allowedRoles={[3, 4]}
							component={AgencyBrokerLoanApplications}
						/>
					}
				/>
				<Route
					path='/ab-loan-applications/create'
					element={
						<RoleBasedComponent
							allowedRoles={[4]}
							component={AgencyBrokerCreateApplication}
						/>
					}
				/>
				<Route
					path='/ab-loan-applications/:id'
					element={
						<RoleBasedComponent
							allowedRoles={[3, 4]}
							component={AgencyBrokerLoanApplicationDetail}
						/>
					}
				/>
				<Route
					path='/ab-pending-details'
					element={<AgencyBrokerPending />}
				/>

				{/* =================== RoleId: 6 ================== */}

				<Route
					path='/cpsa-dashboard'
					element={
						<RoleBasedComponent
							allowedRoles={[6]}
							component={CPSADashboard}
						/>
					}
				/>
				<Route
					path='/cp-admin-firm-pending-details/:id'
					element={<FirmPendingDetails />}
				/>
				<Route
					path='/cpsa-credit-provider/add'
					element={
						<RoleBasedComponent
							allowedRoles={[6]}
							component={AddCreditProvider}
						/>
					}
				/>
				<Route
					path='/cpsa-credit-provider'
					element={
						<RoleBasedComponent
							allowedRoles={[6]}
							component={CreditProvidersList}
						/>
					}
				/>
				<Route
					path='/cpsa-loan-application-list'
					element={
						<RoleBasedComponent
							allowedRoles={[6, 7]}
							component={CreditProviderLoanApplicationList}
						/>
					}
				/>
				<Route
					path='/cpsa-loan-applications/:id'
					element={
						<RoleBasedComponent
							allowedRoles={[6, 7]}
							component={CreditProviderLoanApplicationDetail}
						/>
					}
				/>
				<Route
					path='/cpsa-credit-accounts/:id'
					element={
						<RoleBasedComponent
							allowedRoles={[6]}
							component={CreditProviderCreditAccountDetail}
						/>
					}
				/>
				<Route
					path='/cpsa-credit-accounts'
					element={
						<RoleBasedComponent
							allowedRoles={[6]}
							component={CreditProviderCreditAccountList}
						/>
					}
				/>
				{/* =================== RoleId: 7 ================== */}

				<Route
					path='/cpua-dashboard'
					element={
						<RoleBasedComponent
							allowedRoles={[7]}
							component={CPApproverDashboard}
						/>
					}
				/>
				{/* =================== RoleId: 8 ================== */}

				<Route path='/te-pending-details' element={<TenantPending />} />
				<Route
					path='/te-dashboard'
					element={
						<RoleBasedComponent
							allowedRoles={[8]}
							component={TenantDashboard}
						/>
					}
				/>
				<Route
					path='/te-my-applications'
					element={
						<RoleBasedComponent
							allowedRoles={[8]}
							component={TenantMyApplications}
						/>
					}
				/>
				<Route
					path='/te-my-applications/:id/credit-score'
					element={
						<RoleBasedComponent
							allowedRoles={[8]}
							component={AddCreditScoreToApplication}
						/>
					}
				/>
				<Route
					path='/te-my-applications/:id/show-credit-score'
					element={
						<RoleBasedComponent
							allowedRoles={[8]}
							component={ViewCreditScoreToApplication}
						/>
					}
				/>
				<Route
					path='/te-my-applications/:id/credit-score/verify'
					element={
						<RoleBasedComponent
							allowedRoles={[8]}
							component={AddCreditScoreVerify}
						/>
					}
				/>
				<Route
					path='/te-credit-accounts/:id/pre-installment/verify'
					element={
						<RoleBasedComponent
							allowedRoles={[8]}
							component={AddPreInstallmentPaymentVerify}
						/>
					}
				/>
				<Route
					path='/te-credit-accounts/:id/pre-installment/payment-failure'
					element={
						<RoleBasedComponent
							allowedRoles={[8]}
							component={AddPreInstallmentPaymentFailure}
						/>
					}
				/>
				<Route
					path='/te-credit-accounts/:id/pre-installment/payment-success'
					element={
						<RoleBasedComponent
							allowedRoles={[8]}
							component={AddPreInstallmentPaymentSuccess}
						/>
					}
				/>
				<Route
					path='/te-my-applications/:id/credit-score/payment-failure'
					element={
						<RoleBasedComponent
							allowedRoles={[8]}
							component={AddCreditScorePaymentFailure}
						/>
					}
				/>
				<Route
					path='/te-my-applications/:id/update'
					element={
						<RoleBasedComponent
							allowedRoles={[8]}
							component={TenantUpdateApplication}
						/>
					}
				/>
				<Route
					path='/te-my-applications/:id'
					element={
						<RoleBasedComponent
							allowedRoles={[8]}
							component={TenantMyApplicationDetail}
						/>
					}
				/>
				<Route
					path='/te-my-applications/:id/pre-approval'
					element={
						<RoleBasedComponent
							allowedRoles={[8]}
							component={TenantMyApplicationDetailPreApproval}
						/>
					}
				/>
				<Route
					path='/te-credit-accounts/:id'
					element={
						<RoleBasedComponent
							allowedRoles={[8]}
							component={TenantLoanDetail}
						/>
					}
				/>
				<Route
					path='/te-credit-accounts'
					element={
						<RoleBasedComponent
							allowedRoles={[8]}
							component={TenantLoanList}
						/>
					}
				/>
				<Route
					path='/credit-score'
					element={
						<RoleBasedComponent
							allowedRoles={[8]}
							component={MyCreditScore}
						/>
					}
				/>
				<Route
					path='/te-credit-score-history'
					element={
						<RoleBasedComponent
							allowedRoles={[8]}
							component={MyCreditScoreHistory}
						/>
					}
				/>
				<Route
					path='/te-offer'
					element={
						<RoleBasedComponent
							allowedRoles={[8]}
							component={MyOfferList}
						/>
					}
				/>
				<Route
					path='/my-credit-score/payment-failure'
					element={
						<RoleBasedComponent
							allowedRoles={[8]}
							component={MyCreditScorePaymentFailure}
						/>
					}
				/>
				<Route
					path='/my-credit-score/verify'
					element={
						<RoleBasedComponent
							allowedRoles={[8]}
							component={MyCreditScoreVerify}
						/>
					}
				/>
				<Route
					path='/my-credit-score'
					element={
						<RoleBasedComponent
							allowedRoles={[8]}
							component={ViewCreditScore}
						/>
					}
				/>
				{/* =================== RoleId: 9 ================== */}
				<Route
					path='/cpur-dashboard'
					element={
						<RoleBasedComponent
							allowedRoles={[9]}
							component={CreditProviderUserDashboard}
						/>
					}
				/>
				<Route
					path='/cpur-loan-list'
					element={
						<RoleBasedComponent
							allowedRoles={[9]}
							component={CreditProviderUserLoanList}
						/>
					}
				/>
				<Route
					path='/cpur-my-task'
					element={
						<RoleBasedComponent
							allowedRoles={[9]}
							component={MyTask}
						/>
					}
				/>
				{/* ====================== Unauthenticated Pages ========================== */}

				<Route path='/not-authorized' element={<NotAuthorized />} />
				<Route path='/ui-guide' element={<UIGuide />} />
				<Route path='/' element={<DefaultPage />} />
				<Route path='*' element={<NotFound />} />
			</Routes>
		</UIProvider>
	);
};

export default App;
