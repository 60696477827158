import { CiCircleCheck, CiSearch, CiTrash } from "react-icons/ci";
import ButtonTextOnly from "../../components/ui/ButtonTextOnly";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import SearchBar from "../../components/ui/SearchBar";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import RSTabs from "../../components/ui/RSTabs";
import RSModal from "../../components/ui/RSModal";

const PopUpContent = ({ setShow }) => {
	return (
		<div
			style={{
				height: "auto",
				width: "480px",
				padding: "40px",
			}}>
			<h4 className='color-primary'>
				Are you sure you want to delete the user?
			</h4>
			<br />
			<p>
				Everyone has the right to an effective remedy by the competent
				national tribunals for acts violating the fundamental rights
				granted him by the constitution or by law.
			</p>
			<br />
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "flex-end",
					gap: "12px",
				}}>
				<ButtonLightTextIcon
					title='Go back'
					icon={
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='26.562'
							height='17.809'
							viewBox='0 0 26.562 17.809'>
							<path d='M0,8.88,10.686,0l1.937,2.293L6.453,7.4H26.562v3H6.454l6.166,5.108-1.934,2.3Z' />
						</svg>
					}
					onClick={() => setShow(false)}
				/>
				<ButtonTextIcon
					title='Yes, Delete'
					icon={<CiCircleCheck />}
					onClick={() => setShow(false)}
				/>
			</div>
		</div>
	);
};

const UIGuide = () => {
	return (
		<section>
			<div
				style={{
					maxWidth: "1120px",
					margin: "0 auto",
					padding: "120px 20px",
				}}>
				<div>
					<h1>
						Everyone has the right to an effective remedy by the
						competent national tribunals for acts violating the
						fundamental rights granted him by the constitution or by
						law.
					</h1>
					<br />
					<h2>
						Everyone has the right to an effective remedy by the
						competent national tribunals for acts violating the
						fundamental rights granted him by the constitution or by
						law.
					</h2>
					<br />
					<h3>
						Everyone has the right to an effective remedy by the
						competent national tribunals for acts violating the
						fundamental rights granted him by the constitution or by
						law.
					</h3>
					<br />
					<h4>
						Everyone has the right to an effective remedy by the
						competent national tribunals for acts violating the
						fundamental rights granted him by the constitution or by
						law.
					</h4>
					<br />
					<h5>
						Everyone has the right to an effective remedy by the
						competent national tribunals for acts violating the
						fundamental rights granted him by the constitution or by
						law.
					</h5>
					<br />
					<h6>
						Everyone has the right to an effective remedy by the
						competent national tribunals for acts violating the
						fundamental rights granted him by the constitution or by
						law.
					</h6>
					<br />
					<p>
						Everyone has the right to an effective remedy by the
						competent national tribunals for acts violating the
						fundamental rights granted him by the constitution or by
						law.
					</p>
					<br />
					<p>
						No one shall be subjected to arbitrary arrest, detention
						or exile. Everyone is entitled in full equality to a
						fair and public hearing by an independent and impartial
						tribunal, in the determination of his rights and
						obligations and of any criminal charge against him. No
						one shall be subjected to arbitrary interference with
						his privacy, family, home or correspondence, nor to
						attacks upon his honour and reputation. Everyone has the
						right to the protection of the law against such
						interference or attacks.
					</p>
					<br />
					<p>
						Everyone has the right to freedom of thought, conscience
						and religion; this right includes freedom to change his
						religion or belief, and freedom, either alone or in
						community with others and in public or private, to
						manifest his religion or belief in teaching, practice,
						worship and observance. Everyone has the right to
						freedom of opinion and expression; this right includes
						freedom to hold opinions without interference and to
						seek, receive and impart information and ideas through
						any media and regardless of frontiers. Everyone has the
						right to rest and leisure, including reasonable
						limitation of working hours and periodic holidays with
						pay.
					</p>
				</div>
				<br />
				<br />
				<h4>Form Elements:</h4>
				<br />
				<div style={{ width: "420px" }}>
					<input type='text' placeholder='Input type text' />
					<br />
					<select>
						<option value=''>Select Dropdown</option>
						<option value='One'>Value One</option>
						<option value='Two'>Value Two</option>
						<option value='Three'>Value Three</option>
						<option value='Four'>Value Four</option>
					</select>
					<br />
					<textarea placeholder='Placeholder for textarea'></textarea>
					<br />
					<input
						className='error'
						type='text'
						placeholder='Input type text'
					/>
					<br />
					<div>
						<label
							htmlFor='checkId'
							style={{
								display: "flex",
								alignItems: "center",
								gap: "12px",
								cursor: "pointer",
							}}>
							<input type='checkbox' id='checkId' />
							<p>Input type Checkbox</p>
						</label>
					</div>
					<br />
					<br />
					<h4>Radio Buttons</h4>
					<br />
					<div>
						<label
							htmlFor='radio1'
							style={{
								display: "flex",
								alignItems: "center",
								gap: "12px",
								cursor: "pointer",
							}}>
							<input name='radio' type='radio' id='radio1' />
							<p>Button Value One</p>
						</label>
					</div>
					<br />
					<div>
						<label
							htmlFor='radio2'
							style={{
								display: "flex",
								alignItems: "center",
								gap: "12px",
								cursor: "pointer",
							}}>
							<input name='radio' type='radio' id='radio2' />
							<p>Button Value Two</p>
						</label>
					</div>
					<br />
					<div>
						<label
							htmlFor='radio3'
							style={{
								display: "flex",
								alignItems: "center",
								gap: "12px",
								cursor: "pointer",
							}}>
							<input name='radio' type='radio' id='radio3' />
							<p>Button Value Three</p>
						</label>
					</div>
					<br />
					<br />
					<h4>Buttons</h4>
					<br />
					<ButtonTextOnly title='Simple Button' />
					<br />
					<br />
					<ButtonTextIcon title='Icon & Button' icon={<CiSearch />} />
					<br />
					<br />
					<ButtonLightTextIcon
						title='Go back'
						icon={
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='26.562'
								height='17.809'
								viewBox='0 0 26.562 17.809'>
								<path d='M0,8.88,10.686,0l1.937,2.293L6.453,7.4H26.562v3H6.454l6.166,5.108-1.934,2.3Z' />
							</svg>
						}
					/>
					<br />
					<br />
					<SearchBar
						placeholder='Search here'
						onSubmit={(values) => {
							console.log(values);
						}}
					/>
				</div>
				<br />
				<br />
				<h4>Tabs:</h4>
				<br />
				<RSTabs
					options={[
						{
							title: "Agency",
							id: "tab-one",
							content: (
								<div>
									<p>Content for agency</p>
								</div>
							),
						},
						{
							title: "Credit Provider",
							id: "tab-two",
							content: (
								<div>
									<p>Content for credit provider</p>
								</div>
							),
						},
						{
							title: "All",
							id: "tab-three",
							content: (
								<div>
									<p>Content for all</p>
								</div>
							),
						},
					]}
				/>
				<br />
				<br />
				<h4>Table:</h4>
				<br />
				<table className='rs-table'>
					<thead>
						<tr>
							<th>Broker Name</th>
							<th>Role</th>
							<th>Email</th>
							<th>Phone</th>
							<th>Status</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td className='bold'>Jack Barker</td>
							<td>Agency Admin</td>
							<td>john.doe@gmail.com</td>
							<td>(715) 748-2133</td>
							<td>
								<div className='status active'>Active</div>
							</td>
							<td>
								<RSModal
									selector={<CiTrash />}
									content={<PopUpContent />}
								/>
							</td>
						</tr>
						<tr>
							<td className='bold'>Jack Barker</td>
							<td>Agency Admin</td>
							<td>john.doe@gmail.com</td>
							<td>(715) 748-2133</td>
							<td>
								<div className='status inactive'>Inactive</div>
							</td>
							<td>
								<RSModal
									selector={<CiTrash />}
									content={<PopUpContent />}
								/>
							</td>
						</tr>
						<tr>
							<td className='bold'>Jack Barker</td>
							<td>Agency Admin</td>
							<td>john.doe@gmail.com</td>
							<td>(715) 748-2133</td>
							<td>
								<div className='status active'>Active</div>
							</td>
							<td>
								<RSModal
									selector={<CiTrash />}
									content={<PopUpContent />}
								/>
							</td>
						</tr>
						<tr>
							<td className='bold'>Jack Barker</td>
							<td>Agency Admin</td>
							<td>john.doe@gmail.com</td>
							<td>(715) 748-2133</td>
							<td>
								<div className='status active'>Active</div>
							</td>
							<td>
								<RSModal
									selector={<CiTrash />}
									content={<PopUpContent />}
								/>
							</td>
						</tr>
						<tr>
							<td className='bold'>Jack Barker</td>
							<td>Agency Admin</td>
							<td>john.doe@gmail.com</td>
							<td>(715) 748-2133</td>
							<td>
								<div className='status inactive'>Inactive</div>
							</td>
							<td>
								<RSModal
									selector={<CiTrash />}
									content={<PopUpContent />}
								/>
							</td>
						</tr>
						<tr>
							<td className='bold'>Jack Barker</td>
							<td>Agency Admin</td>
							<td>john.doe@gmail.com</td>
							<td>(715) 748-2133</td>
							<td>
								<div className='status active'>Active</div>
							</td>
							<td>
								<RSModal
									selector={<CiTrash />}
									content={<PopUpContent />}
								/>
							</td>
						</tr>
						<tr>
							<td className='bold'>Jack Barker</td>
							<td>Agency Admin</td>
							<td>john.doe@gmail.com</td>
							<td>(715) 748-2133</td>
							<td>
								<div className='status active'>Active</div>
							</td>
							<td>
								<RSModal
									selector={<CiTrash />}
									content={<PopUpContent />}
								/>
							</td>
						</tr>
						<tr>
							<td className='bold'>Jack Barker</td>
							<td>Agency Admin</td>
							<td>john.doe@gmail.com</td>
							<td>(715) 748-2133</td>
							<td>
								<div className='status active'>Active</div>
							</td>
							<td>
								<RSModal
									selector={<CiTrash />}
									content={<PopUpContent />}
								/>
							</td>
						</tr>
						<tr>
							<td className='bold'>Jack Barker</td>
							<td>Agency Admin</td>
							<td>john.doe@gmail.com</td>
							<td>(715) 748-2133</td>
							<td>
								<div className='status inactive'>Inactive</div>
							</td>
							<td>
								<RSModal
									selector={<CiTrash />}
									content={<PopUpContent />}
								/>
							</td>
						</tr>
					</tbody>
				</table>
				<br />
				<br />
				<ul className='rs-pagination'>
					<li>
						<a href='#'>1</a>
					</li>
					<li className='active'>
						<a href='#'>2</a>
					</li>
					<li>
						<a href='#'>3</a>
					</li>
					<li>
						<a href='#'>4</a>
					</li>
					<li>
						<a href='#'>5</a>
					</li>
					<li>
						<a href='#'>6</a>
					</li>
				</ul>
			</div>
		</section>
	);
};

export default UIGuide;
