import { useEffect, useState } from "react";
import { useUI } from "../../context/ui";
import PageTitle from "../../components/common/PageTitle";
import FullPageLoader from "../../components/ui/FullPageLoader";
import {
  API_URL,
  FREQUENCY_TYPE_LIST,
  LOAN_APPLICATION_STATUSES,
} from "../../utilities/constants";
import { NavLink, useParams } from "react-router-dom";
import RSModal from "../../components/ui/RSModal";
import TransparentImage from "../../images/icons/4x2.5.png";
import {
  CiBag1,
  CiBank,
  CiBarcode,
  CiBookmark,
  CiCalculator1,
  CiRuler,
  CiBookmarkCheck,
  CiCircleCheck,
  CiChat1,
  CiTimer,
  CiBoxList,
  CiClock2,
  CiLink,
  CiHeart,
  CiHome,
  CiHospital1,
  CiLocationOn,
  CiMail,
  CiMoneyBill,
  CiPhone,
  CiSquareCheck,
  CiSquarePlus,
  CiUser,
} from "react-icons/ci";
import { formatDateTime, formatServerDate } from "../../utilities/helpers";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import ApplicationHistoryView from "../../components/loan/ApplicationHistoryView";
import { formatAmountValue } from "../../utilities/helpers"

const TenantMyApplicationDetail = () => {
  const { id } = useParams();
  const { setSegmentTitle } = useUI();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const callApi = async (id) => {
    setLoading(true);
    try {
      const resp = await fetch(API_URL + `/api/1.0.0/loan-application/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-JWT-Assertion": localStorage.getItem("token"),
        },
      });
      if (resp.ok) {
        const response = await resp.json();
        setData(response.data);
      }
    } catch (e) {
      console.log("Error", e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      callApi(id);
      setSegmentTitle("Application Detail");
    }
  }, [id]);

  const getFrequencyDetails = (value) => {
    const status = FREQUENCY_TYPE_LIST.find((obj) => obj.value === value);
    return status
      ? status
      : {
          name: "NA",
          value: "NA",
          shortName: "na",
        };
  };

  return (
    <section>
      <PageTitle title="Application Detail" />
      <FullPageLoader isActive={loading} />
      {data && (
        <>
          <section className="property-detail pad-20">
            <div className="wrap pad-20 pos-rel">
              <table className="col-1-1">
                <thead></thead>
                <tbody>
                  <tr>
                    <td colSpan="2">
                      <p className="f-w-600 color-primary">My Info:</p>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <hr />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="flex center-left">
                        <CiUser className="color-faded" />
                        &nbsp;
                        <p className="font-size-14 color-primary">
                          {data.tenant.firstName} {data.tenant.lastName}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div className="flex center-left">
                        <CiPhone className="color-faded" />
                        &nbsp;
                        <p className="font-size-14 color-primary">
                          {`(+971) ${data.tenant.phoneNo}`}
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="flex center-left">
                        <CiMail className="color-faded" />
                        &nbsp;
                        <p className="font-size-14 color-primary">
                          {data.tenant.email}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div className="flex center-left">
                        <CiCircleCheck className="color-faded" />
                        &nbsp;
                        <p className="font-size-14 color-faded">Credit Score</p>
                        &nbsp;
                        {data.creditScore ? (
                          <p className="font-size-14 color-secondary f-w-600">
                            {data.creditScore}
                          </p>
                        ) : (
                          <p className="font-size-12 color-primary">
                            <NavLink
                              to={`/te-my-applications/${data.id}/credit-score`}
                            >
                              <span className="t-d-ul">Click to check</span>
                            </NavLink>
                          </p>
                        )}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <br />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <p className="f-w-600 color-primary">Agreement</p>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <hr />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="flex center-left">
                        <CiBookmark className="color-faded" />
                        &nbsp;
                        <p className="font-size-14 color-faded">Type</p>
                        &nbsp;
                        <p className="font-size-14 color-primary">
                          {data.agreementType}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div className="flex center-left">
                        <CiMoneyBill className="color-faded" />
                        &nbsp;
                        <p className="font-size-14 color-faded">Rent</p>
                        &nbsp;
                        <p className="font-size-14 color-primary">
                        {`${formatAmountValue(data.rentalValue)}/- AED`}
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <div className="flex top-left">
                        <CiCircleCheck className="color-faded" />
                        &nbsp;
                        <p className="font-size-14 color-faded">Terms</p>
                        &nbsp;
                        <p className="font-size-14 color-primary flex-1">
                          {data.rentalTerms}
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <br />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <p className="f-w-600 color-primary">Income Info:</p>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <hr />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="flex center-left">
                        <CiClock2 className="color-faded" />
                        &nbsp;
                        <p className="font-size-14 color-faded">DoB</p>
                        &nbsp;
                        <p className="font-size-14 color-primary">
                          {data.dateOfBirth
                            ? formatServerDate(data.dateOfBirth)
                            : "-"}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div className="flex center-left">
                        <CiHeart className="color-faded" />
                        &nbsp;
                        <p className="font-size-14 color-faded">
                          Marital Status
                        </p>
                        &nbsp;
                        <p className="font-size-14 color-primary">
                          {data.maritalStatus === null ? (
                            <>
                              <span>-</span>
                            </>
                          ) : (
                            <>{data.maritalStatus ? "Married" : "Unmarried"}</>
                          )}
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="flex center-left">
                        <CiBookmark className="color-faded" />
                        &nbsp;
                        <p className="font-size-14 color-faded">Citizenship</p>
                        &nbsp;
                        <p className="font-size-14 color-primary">
                          {data.citizenshipStatus
                            ? data.citizenshipStatus
                            : "-"}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div className="flex center-left">
                        <CiSquarePlus className="color-faded" />
                        &nbsp;
                        <p className="font-size-14 color-faded">Emirates ID</p>
                        &nbsp;
                        <p className="font-size-14 color-primary">
                          {data.uaePass ? data.uaePass : "-"}
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="flex center-left">
                        <CiMoneyBill className="color-faded" />
                        &nbsp;
                        <p className="font-size-14 color-faded">Employment</p>
                        &nbsp;
                        <p className="font-size-14 color-primary">
                          {data.employmentType ? data.employmentType : "-"}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div className="flex center-left">
                        <CiMoneyBill className="color-faded" />
                        &nbsp;
                        <p className="font-size-14 color-faded">
                          {data.employmentType === "Self Employed"
                            ? "Annual Revenue"
                            : "Annual Income"}
                        </p>
                        &nbsp;
                        <p className="font-size-14 color-primary">
                          {data.annualIncome
                            ? 
                            `${formatAmountValue(data.annualIncome)}/- AED`
                            : "-"}
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="flex center-left">
                        <CiHospital1 className="color-faded" />
                        &nbsp;
                        <p className="font-size-14 color-faded">
                          {data.employmentType === "Self Employed"
                            ? "Business Type"
                            : "Company Type"}
                        </p>
                        &nbsp;
                        <p className="font-size-14 color-primary">
                          {data.companyType ? data.companyType : "-"}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div className="flex center-left">
                        <CiHospital1 className="color-faded" />
                        &nbsp;
                        <p className="font-size-14 color-faded">
                          {data.employmentType === "Self Employed"
                            ? "Business Name"
                            : "Company Name"}
                        </p>
                        &nbsp;
                        <p className="font-size-14 color-primary">
                          {data.companyName ? data.companyName : "-"}
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <div className="flex center-left">
                        <CiLocationOn className="color-faded" />
                        &nbsp;
                        <p className="font-size-14 color-faded">
                          {data.employmentType === "Self Employed"
                            ? "Business Address"
                            : "Company Address"}
                        </p>
                        &nbsp;
                        <p className="font-size-14 color-primary">
                          {data.companyAddress ? data.companyAddress : "-"}
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <div className="flex center-left">
                        <CiBag1 className="color-faded" />
                        &nbsp;
                        <p className="font-size-14 color-faded">
                          {data.employmentType === "Self Employed"
                            ? "Nature of Business"
                            : "Job Title"}
                        </p>
                        &nbsp;
                        <p className="font-size-14 color-primary">
                          {data.title ? data.title : "-"}
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <div className="flex center-left">
                        <CiMail className="color-faded" />
                        &nbsp;
                        <p className="font-size-14 color-faded">Work Email</p>
                        &nbsp;
                        <p className="font-size-14 color-primary">
                          {data.workEmail ? data.workEmail : "-"}
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <hr />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <div className="flex top-left">
                        <CiHospital1 className="color-faded" />
                        &nbsp;
                        <p className="font-size-14 color-faded">
                          {data.employmentType === "Self Employed"
                            ? "Recent Tax Returns"
                            : "Payslip"}
                        </p>
                        &nbsp;
                        <div className="flex-1">
                          {data.s3PayslipList.map((p, i) => (
                            <p className="font-size-12 color-primary" key={i}>
                              <a
                                className="hover-color-secondary"
                                href={p}
                                target="_blank"
                                rel="noreferrer"
                              >
                                Click to View
                              </a>
                            </p>
                          ))}
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <div className="flex center-left">
                        <CiHospital1 className="color-faded" />
                        &nbsp;
                        <p className="font-size-14 color-faded">
                          {data.employmentType === "Self Employed"
                            ? "Incorparation Certificate"
                            : "Salary Certificate"}
                        </p>
                        &nbsp;
                        <p className="font-size-12 color-primary flex-1">
                          <a
                            className="hover-color-secondary"
                            href={data.s3SalaryCertificate}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Click to view
                          </a>
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <div className="flex center-left">
                        <CiBank className="color-faded" />
                        &nbsp;
                        <p className="font-size-14 color-faded">
                          Bank Statement
                        </p>
                        &nbsp;
                        <p className="font-size-12 color-primary flex-1">
                          <a
                            className="hover-color-secondary"
                            href={data.s3BankStatementList}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Click to view
                          </a>
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <br />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <p className="f-w-600 color-primary">Bank Info:</p>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <hr />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <div className="flex center-left">
                        <CiHome className="color-faded" />
                        &nbsp;
                        <p className="font-size-14 color-faded">Name</p>
                        &nbsp;
                        <p className="font-size-14 color-primary">
                          {data.bankName ? data.bankName : "-"}
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <div className="flex center-left">
                        <CiHome className="color-faded" />
                        &nbsp;
                        <p className="font-size-14 color-faded">Account No</p>
                        &nbsp;
                        <p className="font-size-14 color-primary">
                          {data.bankAccountNumber
                            ? data.bankAccountNumber
                            : "-"}
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <div className="flex center-left">
                        <CiBarcode className="color-faded" />
                        &nbsp;
                        <p className="font-size-14 color-faded">SWIFT Code</p>
                        &nbsp;
                        <p className="font-size-14 color-primary">
                          {data.swiftCode ? data.swiftCode : "-"}
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <br />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <p className="f-w-600 color-primary">Broker Info:</p>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <hr />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="1">
                      <div className="flex center-left">
                        <div className="flex center-left">
                          <CiUser className="color-faded" />
                          &nbsp;
                          <p className="font-size-14 color-faded">Name</p>
                          &nbsp;
                          <p className="font-size-14 color-primary">
                            {data.agencyBrokerDTO.firstName}{" "}
                            {data.agencyBrokerDTO.lastName}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td colSpan="1"></td>
                  </tr>
                  <tr>
                    <td colSpan="1">
                      <div className="flex center-left">
                        <div className="flex center-left">
                          <CiMail className="color-faded" />
                          &nbsp;
                          <p className="font-size-14 color-faded">Email</p>
                          &nbsp;
                          <p className="font-size-14 color-primary">
                            {data.agencyBrokerDTO.email}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td colSpan="1">
                      <div className="flex center-left">
                        <div className="flex center-left">
                          <CiPhone className="color-faded" />
                          &nbsp;
                          <p className="font-size-14 color-faded">Phone</p>
                          &nbsp;
                          <p className="font-size-14 color-primary">
                            {`(+971) ${data.agencyBrokerDTO.phoneNo}`}
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  {data.config && (
                    <>
                      <tr>
                        <td colSpan="2">
                          <br />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="2">
                          <p className="f-w-600 color-primary">Offer Info:</p>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="2">
                          <hr />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="1">
                          <div className="flex center-left">
                            <CiHospital1 className="color-faded" />
                            &nbsp;
                            <p className="font-size-14 color-faded">
                              Credit Provider
                            </p>
                            &nbsp;
                            <p className="font-size-14 color-primary">
                              {data?.config?.creditProviderFirm.name
                                ? data?.config?.creditProviderFirm.name
                                : "-"}
                            </p>
                          </div>
                        </td>
                        <td colSpan="1">
                          <div className="flex center-left">
                            <CiMoneyBill className="color-faded" />
                            &nbsp;
                            <p className="font-size-14 color-faded">
                              Processing Fee
                            </p>
                            &nbsp;
                            <p className="font-size-14 color-primary">
                              {data?.config?.processingFee
                                ? data?.config?.processingFee !== 0
                                  ? 
                                  `${formatAmountValue( data?.config?.processingFee)}/- AED`
                                  : "Zero"
                                : "Zero"}
                            </p>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="1">
                          <div className="flex center-left">
                            <CiMoneyBill className="color-faded" />
                            &nbsp;
                            <p className="font-size-14 color-faded">
                              Rental upto
                            </p>
                            &nbsp;
                            <p className="font-size-14 color-primary">
                              {data?.totalPayable
                                ? `${formatAmountValue( data?.totalPayable)}/- AED`
                                : "-"}
                            </p>
                          </div>
                        </td>
                        <td colSpan="1">
                          <div className="flex center-left">
                            <CiMoneyBill className="color-faded" />
                            &nbsp;
                            <p className="font-size-14 color-faded">
                              Installment
                            </p>
                            &nbsp;
                            <p className="font-size-14 color-primary">
                              {data?.totalPayable
                                ? `${formatAmountValue( data.installment)}/- AED
                            ${getFrequencyDetails(data.frequency).name}`
                                : "-"}
                            </p>
                          </div>
                        </td>
                      </tr>
                      {/* <tr>
                        <td colSpan="1">
                          <div className="flex center-left">
                            <CiRuler className="color-faded" />
                            &nbsp;
                            <p className="font-size-14 color-faded">EI Ratio</p>
                            &nbsp;
                            <p className="font-size-14 color-primary">
                              {data?.eiRatio ? data?.eiRatio : "-"}
                            </p>
                          </div>
                        </td>
                      </tr> */}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </section>
          <section className="property-detail pad-20">
            <div className="wrap b-r-5 of-hid">
              <div
                className="default-image of-hid"
                style={
                  data.property.s3PicturesList
                    ? {
                        backgroundImage: `url(${data.property.s3PicturesList[0]})`,
                      }
                    : {}
                }
              >
                <img
                  className="col-1-1"
                  style={{
                    height: "auto",
                    display: "block",
                  }}
                  src={TransparentImage}
                />
              </div>
              <br />
              <div className="pad-20">
                <table className="col-1-1">
                  <thead></thead>
                  <tbody>
                    <tr>
                      <td colSpan="2">
                        <p className="f-w-600 color-primary">
                          {data.property.propertyName}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <p className="font-size-14 color-primary">
                          {data.property.description}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <hr />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <div className="flex center-left">
                          <CiCalculator1 className="color-faded" />
                          &nbsp;
                          <p className="font-size-14 color-faded">#RERA</p>
                          &nbsp;
                          <p className="font-size-14 color-primary">
                            {data.property.reraNumber}
                          </p>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <div className="flex center-left">
                          <CiLocationOn className="color-faded" />
                          &nbsp;
                          <p className="font-size-14 color-faded">Address</p>
                          &nbsp;
                          <p className="font-size-14 color-primary">
                            {data.property.propertyAddress}
                          </p>
                        </div>
                      </td>
                      {/* <td>
                        <div className="flex center-left">
                          <CiLocationOn className="color-faded" />
                          &nbsp;
                          <p className="font-size-14 color-faded">PIN</p>
                          &nbsp;
                          <p className="font-size-14 color-primary">
                            {data.pinCode}
                          </p>
                        </div>
                      </td> */}
                    </tr>
                    <tr>
                      <td>
                        <div className="flex center-left">
                          <CiHome className="color-faded" />
                          &nbsp;
                          <p className="font-size-14 color-faded">Size</p>
                          &nbsp;
                          <p className="font-size-14 color-primary">
                            {data.property.propertySize} sq.ft.
                          </p>
                        </div>
                      </td>
                      <td>
                        <div className="flex center-left">
                          <CiHome className="color-faded" />
                          &nbsp;
                          <p className="font-size-14 color-faded">Type</p>
                          &nbsp;
                          <p className="font-size-14 color-primary">
                            {data.property.propertyType}
                          </p>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <hr />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <div className="flex center-left">
                          <CiUser className="color-faded" />
                          &nbsp;
                          <p className="font-size-14 color-faded">Owner</p>
                          &nbsp;
                          <p className="font-size-14 color-primary">
                            {data.property.owner.fullName}
                          </p>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <div className="flex center-left">
                          <CiLocationOn className="color-faded" />
                          &nbsp;
                          <p className="font-size-14 color-primary">
                            {data.property.owner.address}
                          </p>
                          <p className="font-size-14 color-faded">,</p>
                          &nbsp;
                          <p className="font-size-14 color-primary">
                            {data.property.owner.pinCode}
                          </p>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <div className="flex center-left">
                          <CiMail className="color-faded" />
                          &nbsp;
                          <p className="font-size-14 color-primary">
                            {data.property.owner.email}
                          </p>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <div className="flex center-left">
                          <CiPhone className="color-faded" />
                          &nbsp;
                          <p className="font-size-14 color-primary">
                            {data.property.owner.phoneNumber}
                          </p>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <hr />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <a
                          href={data.property.deedCopySignUrl}
                          target="_new"
                          rel="noreferrer"
                        >
                          <div className="flex center-left">
                            <CiLink className="color-faded" />
                            &nbsp;
                            <p className="transition font-size-14 color-faded hover-color-secondary">
                              Deed Copy
                            </p>
                          </div>
                        </a>
                      </td>
                      <td>
                        <a
                          href={data.property.propertyUrl}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <div className="flex center-right">
                            <CiLink className="color-primary" />
                            &nbsp;
                            <p className="transition font-size-14 color-faded hover-color-secondary">
                              Open URL
                            </p>
                          </div>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <br />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <div
                          className="flex center-right"
                          style={{ gap: "8px" }}
                        >
                          {!data.creditScore && data.annualIncome && (
                            <NavLink
                              to={`/te-my-applications/${data.id}/credit-score`}
                            >
                              <ButtonLightTextIcon
                                title="Check Credit"
                                icon={<CiSquareCheck />}
                              />
                            </NavLink>
                          )}
                          {!data.configId &&
                            data.annualIncome &&
                            data.creditScore && (
                              <NavLink
                                to={`/te-my-applications/${data.id}/pre-approval`}
                              >
                                <ButtonLightTextIcon
                                  title="View Offer"
                                  icon={<CiBookmarkCheck />}
                                />
                              </NavLink>
                            )}
                          <NavLink to={`/te-my-applications/${id}/update`}>
                            <ButtonTextIcon
                              title="Update Application"
                              icon={<CiCircleCheck />}
                            />
                          </NavLink>

                          <RSModal
                            selector={
                              <ButtonTextIcon
                                title="View History"
                                icon={<CiBoxList />}
                              />
                            }
                            content={
                              <ApplicationHistoryView
                                loanApplicationHistory={
                                  data.loanApplicationHistory
                                }
                              />
                            }
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </section>
        </>
      )}
    </section>
  );
};

export default TenantMyApplicationDetail;
