import { Fragment, useEffect, useState } from "react";
import { Formik, Field, Form, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import { NavLink, useNavigate } from "react-router-dom";
import {
	API_URL,
	DEFAULT_PER_PAGE,
	FREQUENCY_TYPE_LIST,
} from "../../utilities/constants";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import ButtonTextOnly from "../../components/ui/ButtonTextOnly";
import FullPageLoader from "../../components/ui/FullPageLoader";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import PageTitle from "../../components/common/PageTitle";
import { useUI } from "../../context/ui";
import {
	CiBitcoin,
	CiHome,
	CiUser,
	CiCircleCheck,
	CiLocationArrow1,
	CiCircleRemove,
} from "react-icons/ci";
import LoaderIcon from "../../components/ui/LoaderIcon";
import FileInput from "../../components/ui/FileInput";
import { CountryOptions, CustomSelect } from "../../utilities/helpers";


const SaCreateCreditProvider = () => {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [allLocations, setAllLocations] = useState(null);
	const [regex, setRegex] = useState("");
	const [stampFile, setStampFile] = useState(null);
	const [stampUploadURL, setStampUploadURL] = useState(null);
	const [logoFile, setLogoFile] = useState(null);
	const [logoUploadURL, setLogoUploadURL] = useState(null);
	const {
		setNewNotification,
		setShowNotification,
		addNotification,
		setSegmentTitle,
		addToastMessage,
	} = useUI();

	const handleLogoUpload = async () => {
		setLoading(true);
		try {
			const formdata = new FormData();
			formdata.append("file", logoFile, logoFile.name);
			formdata.append("type", "CREDIT_PROVIDER_LOGO");
			const resp = await fetch(API_URL + `/api/1.0.0/uploadFile`, {
				method: "POST",
				headers: {
					"X-JWT-Assertion": localStorage.getItem("token"),
				},
				body: formdata,
				redirect: "follow",
			});
			if (resp.ok) {
				const responseData = await resp.json();
				addToastMessage({
					status: true,
					message:
						"You have successfully uploaded credit provider logo, thank you.",
				});
				if (responseData.result.responseCode === 200) {
					setLogoUploadURL(responseData.data);
				}
			} else {
				const errorData = await resp.json();
				const errorMessage = errorData.responseDescription;
				addToastMessage({
					status: false,
					message:
						errorMessage ||
						"Something went wrong while uploading a credit provider logo. Please try again.",
				});
			}
		} catch (e) {
			console.log("Error", e);
			setNewNotification(true);
			setShowNotification(true);
			addNotification({
				id: Date.now(),
				message:
					"Could not upload the credit provider logo at the moment.",
				status: false,
				archive: false,
			});
		} finally {
			setLoading(false);
		}
	};
	const handleStampUpload = async () => {
		setLoading(true);
		try {
			const formdata = new FormData();
			formdata.append("file", stampFile, stampFile.name);
			formdata.append("type", "CREDIT_PROVIDER_STAMP");
			const resp = await fetch(API_URL + `/api/1.0.0/uploadFile`, {
				method: "POST",
				headers: {
					"X-JWT-Assertion": localStorage.getItem("token"),
				},
				body: formdata,
				redirect: "follow",
			});
			if (resp.ok) {
				const responseData = await resp.json();
				addToastMessage({
					status: true,
					message:
						"You have successfully uploaded credit provider stamp.",
				});
				if (responseData.result.responseCode === 200) {
					setStampUploadURL(responseData.data);
				}
			} else {
				const errorData = await resp.json();
				const errorMessage = errorData.responseDescription;
				addToastMessage({
					status: false,
					message:
						errorMessage ||
						"Something went wrong while uploading credit provider stamp. Please try again.",
				});
			}
		} catch (e) {
			console.log("Error", e);
			setNewNotification(true);
			setShowNotification(true);
			addNotification({
				id: Date.now(),
				message:
					"Could not upload the credit provider stamp at the moment.",
				status: false,
				archive: false,
			});
		} finally {
			setLoading(false);
		}
	};

	const getLocations = async () => {
		try {
			const resp = await fetch(API_URL + "/api/1.0.0/ref/locations", {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					"X-JWT-Assertion": localStorage.getItem("token"),
				},
			});
			if (resp.ok) {
				const response = await resp.json();
				setAllLocations(response.data);
			}
		} catch (e) {
			console.log("Error", e);
		}
	};

	useEffect(() => {
		getLocations();
		setSegmentTitle("Add Credit Provider");
	}, []);

	const ValidationSchema = Yup.object().shape({
		adminEmail: Yup.string()
			.strict()
			.email("Invalid email format")
			.required("Admin email is required!")
			.matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/, "The email must have at least 2 characters after the last dot"),
		adminCountryCode: Yup.string().required("Required"),
		adminPhoneNo: Yup.string()
			.matches(
				/^5\d{8}$/,
				"The number should start from 5 followed by 8 digits"
			)
			.required("Phone number is required!")
			.min(9, "The number should start from 5 followed by 8 digits")
			.max(9, "The number should start from 5 followed by 8 digits"),
		adminFirstName: Yup.string()
			.matches(
				/^[A-Za-z\s-]+$/,
				"Admin's first name should contain only letters!"
			)
			.required("Admin's first name is required"),
		adminLastName: Yup.string()
			.matches(
				/^[A-Za-z\s-]+$/,
				"Admin's last name should contain only letters!"
			)
			.required("Admin last name is required"),
		adminAddress: Yup.string(),
		firmName: Yup.string().required("Organization name is required"),
		firmEmail: Yup.string()
			.strict()
			.email("Invalid email format")
			.required("Organization email is required!")
			.matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/, "The email must have at least 2 characters after the last dot"),
		firmEmirates: Yup.string().required("Emirate is required"),
		firmCity: Yup.string().required("Area is required"),
		firmCountryCode: Yup.string().required("Required"),
		firmLandline: Yup.string()
			// .matches(new RegExp(regex), "Must be 9 digits long starting from 4")
			.matches(new RegExp('^04\\d{7}$'), "Must be 9 digits long starting from 04")
			.required("Landline number is required!")
			.min(9, "Landline must be 9 digits long!")
			.max(9, "Landline must be 9 digits long!"),
		firmWebsiteURL: Yup.string()
			.matches(
				/((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
				"Enter correct url!"
			)
			.required("Required"),
		configs: Yup.array()
			.of(
				Yup.object().shape({
					minCreditScore: Yup.number()
						.typeError("Must be a number")
						.min(300, "Minimum value is 300")
						.max(900, "Maximum value is 900")
						.required("Min. Credit Score is required"),
					maxCreditScore: Yup.number()
						.typeError("Max. Credit Score must be a number")
						.min(300, "Max. Credit Score must be at least 300")
						.max(900, "Max. Credit Score cannot be more than 900")
						.required("Max. Credit Score is required")
						.when("minCreditScore", (minCreditScore, schema) => {
							return schema.test({
								test: function (maxCreditScore) {
									return (
										parseInt(maxCreditScore) >=
										parseInt(minCreditScore)
									);
								},
								message:
									"Max. Credit Score must be greater than or equal to Min. Credit Score",
							});
						}),
					minLoanAmount: Yup.number()
						.typeError("Must be a number")
						.min(0, "Minimum value is 0")
						.required("Min. Loan Amount is required"),
					maxLoanAmount: Yup.number()
						.typeError("Must be a number")
						.min(0, "Minimum value is 0")
						.required("Max. Loan Amount is required")
						.when("minLoanAmount", (minLoanAmount, schema) => {
							return schema.test({
								test: function (maxLoanAmount) {
									return (
										parseFloat(maxLoanAmount) >=
										parseFloat(minLoanAmount)
									);
								},
								message:
									"Max. Loan Amount must be greater than or equal to Min. Loan Amount",
							});
						}),
					interestPercent: Yup.number()
						.typeError("Must be a number")
						.min(0, "Minimum value is 0")
						.max(100, "Maximum value is 100")
						.required("Interest % is required"),
					riskRatio: Yup.number()
						.typeError("Must be a number")
						.min(0, "Minimum value is 0")
						.max(1, "Maximum value is 1")
						.required("Risk Ratio is required"),
					processingFee: Yup.number()
						.typeError("Must be a number")
						.min(0, "Minimum value is 0")
						.required("Processing Fee is required"),
					frequency: Yup.string()
						.oneOf(
							FREQUENCY_TYPE_LIST.map((item) => item.value),
							"Invalid Frequency"
						)
						.required("Frequency is required"),
				})
			)
			.required("Must have at least one config"),
	});

	const initial = {
		adminEmail: "",
		adminCountryCode: "+971",
		adminPhoneNo: "",
		adminLandline: "",
		adminFirstName: "",
		adminLastName: "",
		adminAddress: "",
		firmName: "",
		firmEmail: "",
		firmEmirates: "",
		firmCity: "",
		firmCountryCode: "+971",
		firmLandline: "",
		firmWebsiteURL: "",
		configs: [
			{
				minCreditScore: "",
				maxCreditScore: "",
				minLoanAmount: "",
				maxLoanAmount: "",
				interestPercent: "",
				riskRatio: "",
				processingFee: "",
				frequency: "",
			},
		],
	};

	const handleCreateAgency = async (value) => {
		setLoading(true);
		try {
			const request = {
				firstName: value.adminFirstName,
				lastName: value.adminLastName,
				address: "N/A",
				email: value.adminEmail,
				countryCode: value.adminCountryCode,
				phoneNo: value.adminPhoneNo,
				landline: "04123456",
				logo: logoUploadURL,
				stamp: stampUploadURL,
				contactDetails: {
					name: value.firmName,
					email: value.firmEmail,
					location: value.firmCity,
					websiteURL: value.firmWebsiteURL,
					countryCode: value.firmCountryCode,
					landline: value.firmLandline,
				},
				config: value.configs,
			};

			const resp = await fetch(API_URL + "/api/1.0.0/credit-provider", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"X-JWT-Assertion": localStorage.getItem("token"),
				},
				body: JSON.stringify(request),
			});

			if (resp.ok) {
				addToastMessage({
					status: true,
					message: "You have successfully created a credit provider.",
				});
				setNewNotification(true);
				setShowNotification(true);
				addNotification({
					id: Date.now(),
					message: "You have successfully created a Credit provider!",
					status: true,
					archive: false,
				});
				navigate(
					`/sa-credit-provider?pageNo=0&pageSize=${DEFAULT_PER_PAGE}`
				);
			} else {
				const errorData = await resp.json();
				const errorMessage = errorData.responseDescription;
				addToastMessage({
					status: false,
					message:
						errorMessage ||
						"Something went wrong while creating a credit provider. Please try again.",
				});
			}
		} catch (e) {
			setNewNotification(true);
			setShowNotification(true);
			addNotification({
				id: Date.now(),
				message:
					"Something went wrong when creating a Credit Provider!",
				status: false,
				archive: false,
			});
		} finally {
			setLoading(false);
		}
	};

	const getCities = (cityId) => {
		const e = allLocations.find((obj) => obj.code === cityId);
		console.log(e);
		return e ? e.subLocations : null;
	};

	const getRegexForCity = (selectedEmirate, selectedCity) => {
		const emirate = allLocations.find(
			(obj) => obj.code === selectedEmirate
		);
		const city = emirate.subLocations.find((o) => o.name === selectedCity);
		return city.regularExp;
	};

	const SingleStep = ({ title, subtitle, completion }) => {
		const getClassName = () => {
			let hasEmptyElement = false;
			if (Array.isArray(completion)) {
				hasEmptyElement = completion.some((element) => element === "");
			} else {
				hasEmptyElement = completion().some(
					(element) => element === ""
				);
			}

			if (hasEmptyElement) {
				return "incomplete";
			} else {
				return "complete";
			}
		};

		return (
			<div className={`step t-a-c ${getClassName()}`}>
				<p
					className='color-primary'
					style={{ fontSize: "12px", marginBottom: "5px" }}>
					<span className='f-w-700'>0{title}</span> {subtitle}
				</p>
				<div className={`b-r-100 icon`}>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='24'
						height='24'
						viewBox='0 0 24 24'>
						<path d='M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.25 16.518l-4.5-4.319 1.396-1.435 3.078 2.937 6.105-6.218 1.421 1.409-7.5 7.626z' />
					</svg>
				</div>
			</div>
		);
	};

	const Row = ({ children }) => {
		return (
			<div className='row b-r-8'>
				<div className='col-1-1 wrap flex'>{children}</div>
			</div>
		);
	};

	const SectionTitle = ({ title, subtitle, icon }) => {
		return (
			<div className='col-1-2'>
				<div
					className='flex center-left'
					style={{
						gap: "12px",
					}}>
					{icon}
					<div className='flex center-left'>
						<div>
							<p className='f-w-700 l-h-1-2 color-primary'>
								{title}
							</p>
							<p className='l-h-1-2 color-light small'>
								{subtitle}
							</p>
						</div>
					</div>
				</div>
			</div>
		);
	};

	const SectionForm = ({ children }) => {
		return (
			<div className='col-1-2'>
				<table>
					<thead></thead>
					<tbody>{children}</tbody>
				</table>
			</div>
		);
	};

	const FieldTitle = ({ title }) => {
		return (
			<p
				className='title f-w-700 color-primary'
				style={{ marginBottom: "5px", fontSize: "14px" }}>
				{title}
			</p>
		);
	};

	useEffect(() => {
		const onScroll = () => {
			const elem = document.getElementById("stepper-should-fix");
			const impact = document.getElementById("stepper-should-impact");
			if (elem && impact) {
				if (window.scrollY > 92) {
					elem.classList.add("loan-application-stepper-fixed-position");
					impact.classList.add("stepper-should-impact-fixed-position");
				} else {
					elem.classList.remove(
						"loan-application-stepper-fixed-position"
					);
					impact.classList.remove("stepper-should-impact-fixed-position");
				}
			}
		};
		window.removeEventListener("scroll", onScroll);
		window.addEventListener("scroll", onScroll, { passive: true });
		return () => window.removeEventListener("scroll", onScroll);
	});

	function capitalizeFirstLetter(str) {
		return str[0].toUpperCase() + str.slice(1);
	}

	return (
		<section>
			<PageTitle title='Add Credit Provider' />
			<FullPageLoader isActive={loading} />
			<Formik
				initialValues={initial}
				validationSchema={ValidationSchema}
				onSubmit={async (values, { validateForm }) => {
					console.log("ok ritik");
					handleCreateAgency(values);
				}}>
				{({
					values,
					errors,
					touched,
					validateForm,
					setFieldValue,
					resetForm,
					handleSubmit,
				}) => (
					<Form>
						<section>
							<section
								id='stepper-should-fix'
								className='loan-application-stepper'>
								<div
									className='steps flex center-center'
									style={{ gap: "20px" }}>
									<SingleStep
										title='1'
										subtitle='Admin Info'
										completion={[
											values.adminEmail,
											values.adminCountryCode,
											values.adminPhoneNo,
											values.adminFirstName,
											values.adminLastName,
										]}
									/>
									<SingleStep
										title='2'
										subtitle='Org details'
										completion={[
											values.firmName,
											values.firmEmail,
											values.firmEmirates,
											values.firmCity,
											values.firmCountryCode,
											values.firmLandline,
											values.firmWebsiteURL,
											stampUploadURL,
										]}
									/>
									<SingleStep
										title='3'
										subtitle='Pre approvals'
										completion={() => {
											let inputArray = [];
											values.configs?.map((config) => {
												for (const property in config) {
													inputArray.push(
														config[property]
													);
												}
											});
											return inputArray;
										}}
									/>
								</div>
							</section>
							<section
								id='stepper-should-impact'
								className='pad-20 create-loan-application'>
								<Row>
									<SectionTitle
										title='Admin Information'
										subtitle='Enter basic customer information'
										icon={
											<CiUser
												className='color-primary'
												style={{
													height: "32px",
													width: "32px",
													strokeWidth: "1px",
												}}
											/>
										}
									/>
									<SectionForm>
										<tr>
											<td colSpan='2'>
												<FieldTitle title='Email' />
												<Field
													name='adminEmail'
													type='text'
													placeholder='Eg [john.doe@gmail.com] *'
													className={
														"col-1-1 " +
														(errors.adminEmail &&
															touched.adminEmail
															? " error"
															: "")
													}
												/>
												<ErrorMessage
													name='adminEmail'
													component='p'
													className='error-messages'
												/>
											</td>
										</tr>
										<tr>
											<td width='120'>
												<FieldTitle title='Phone' />
												<Field name='adminCountryCode'>
													{({ field }) => (
														<CustomSelect
															{...field}
															options={CountryOptions}
															error={errors.adminCountryCode && touched.adminCountryCode}
															onChange={(e) => setFieldValue('adminCountryCode', e.target.value)}
														/>
													)}
												</Field>
											</td>
											<td>
												<FieldTitle title='&nbsp;' />
												<Field
													name='adminPhoneNo'
													type='text'
													placeholder='Eg 500000000 *'
													maxLength={9}
													className={
														"col-1-1 " +
														(errors.adminPhoneNo &&
															touched.adminPhoneNo
															? " error"
															: "")
													}
												/>
												<ErrorMessage
													name='adminPhoneNo'
													component='p'
													className='error-messages'
												/>
											</td>
										</tr>
										<tr>
											<td colSpan='2'>
												<FieldTitle title='First Name' />
												<Field
													name='adminFirstName'
													type='text'
													placeholder='Eg: [Jake] *'
													className={
														"col-1-1 " +
														(errors.adminFirstName &&
															touched.adminFirstName
															? " error"
															: "")
													}
												/>
												<ErrorMessage
													name='adminFirstName'
													component='p'
													className='error-messages'
												/>
											</td>
										</tr>
										<tr>
											<td colSpan='2'>
												<FieldTitle title='Last Name' />
												<Field
													name='adminLastName'
													type='text'
													placeholder='Eg: [Johnson] *'
													className={
														"col-1-1 " +
														(errors.adminLastName &&
															touched.adminLastName
															? " error"
															: "")
													}
												/>
												<ErrorMessage
													name='adminLastName'
													component='p'
													className='error-messages'
												/>
											</td>
										</tr>
									</SectionForm>
								</Row>
								<br />
								<Row>
									<SectionTitle
										title='Org Details'
										subtitle='Enter basic details of the organization'
										icon={
											<CiHome
												className='color-primary'
												style={{
													height: "32px",
													width: "32px",
													strokeWidth: "1px",
												}}
											/>
										}
									/>
									<SectionForm>
										<tr>
											<td colSpan='2'>
												<FieldTitle title='Organization Name' />
												<Field
													name='firmName'
													type='text'
													placeholder='Enter Org. Name *'
													className={
														"col-1-1 " +
														(errors.firmName &&
															touched.firmName
															? " error"
															: "")
													}
												/>
												<ErrorMessage
													name='firmName'
													component='p'
													className='error-messages'
												/>
											</td>
										</tr>
										<tr>
											<td colSpan='2'>
												<FieldTitle title='Email' />
												<Field
													name='firmEmail'
													type='text'
													placeholder='Eg [contact@organization.com] *'
													className={
														"col-1-1 " +
														(errors.firmEmail &&
															touched.firmEmail
															? " error"
															: "")
													}
												/>
												<ErrorMessage
													name='firmEmail'
													component='p'
													className='error-messages'
												/>
											</td>
										</tr>
										<tr>
											<td colSpan='4'>
												<p className='title f-w-700 color-primary'>
													Emirates
												</p>
												<Field
													as='select'
													name='firmEmirates'
													className={
														"col-1-1 " +
														(errors.firmEmirates &&
															touched.firmEmirates
															? " error"
															: "")
													}>
													<option value=''>
														Select Emirates
													</option>
													{allLocations && (
														<>
															{allLocations.map(
																(a, i) => (
																	<option
																		value={
																			a.code
																		}
																		key={i}
																		disabled={
																			a.code !==
																				"L0002"
																				? "disabled"
																				: null
																		}>
																		{a.name}
																	</option>
																)
															)}
														</>
													)}
												</Field>
												<ErrorMessage
													name='firmEmirates'
													component='p'
													className='error-messages'
												/>
											</td>
											<td colSpan='2'></td>
										</tr>
										{values.firmEmirates && (
											<tr>
												<td colSpan='4'>
													<p className='title f-w-700 color-primary'>
														Area
													</p>
													<Field
														as='select'
														name='firmCity'
														className={
															"col-1-1 " +
															(errors.firmCity &&
																touched.firmCity
																? " error"
																: "")
														}
														onChange={(e) => {
															setFieldValue(
																"firmCity",
																e.target.value
															);
															const regex =
																getRegexForCity(
																	values.firmEmirates,
																	e.target
																		.value
																);
															setRegex(regex);
														}}>
														<option value=''>
															Select Area
														</option>
														{getCities(
															values.firmEmirates
														).map((c, j) => (
															<option
																key={j}
																value={c.name}>
																{c.name}
															</option>
														))}
													</Field>
													<ErrorMessage
														name='firmCity'
														component='p'
														className='error-messages'
													/>
												</td>
												<td colSpan='2'></td>
											</tr>
										)}
										<tr>
											{/* <td width='120'>
												<FieldTitle title='Landline' />
												<Field
													as='select'
													name='firmCountryCode'
													className={
														"col-1-1 " +
														(errors.firmCountryCode &&
														touched.firmCountryCode
															? " error"
															: "")
													}>
													<option value='+971'>
														+971
													</option>
												</Field>
											</td> */}
											<td colSpan='4'>

												<FieldTitle title='&nbsp;' />
												<FieldTitle title='Landline' />
												<Field
													name='firmLandline'
													type='text'
													placeholder='Eg 04*******'
													maxLength={9}
													className={
														"col-1-1 " +
														(errors.firmLandline &&
															touched.firmLandline
															? " error"
															: "")
													}
												/>
												<ErrorMessage
													name='firmLandline'
													component='p'
													className='error-messages'
												/>
											</td>
										</tr>
										<tr>
											<td colSpan='4'>
												<FieldTitle title='Website URL' />
												<Field
													name='firmWebsiteURL'
													type='text'
													placeholder='Eg: [https://organization.com] *'
													className={
														"col-1-1 " +
														(errors.firmWebsiteURL &&
															touched.firmWebsiteURL
															? " error"
															: "")
													}
												/>
												<ErrorMessage
													name='firmWebsiteURL'
													component='p'
													className='error-messages'
												/>
											</td>
										</tr>
										<tr>
											<td colSpan="2">
												<FieldTitle title="Stamp" />
												<FileInput
													defaultText="Select Stamp"
													accept="image/jpeg, image/jpg, image/png"
													maxSizeAllowed={2 * 1024 * 1024}
													callback={(f) => {
														setStampFile(f);
													}}
												/>
												{stampFile && (
													<p
														className="cursor-pointer"
														style={{
															fontSize: "12px",
															color: "red",
															marginTop: "10px", 
														}}
														onClick={() => {
															setStampFile(null);
															setStampUploadURL('');
														}}
													>
														Delete stamp file
													</p>
												)}
											</td>
											<td className="t-a-r">
												<p>&nbsp;</p>
												<ButtonTextIcon
													type="button"
													title={stampUploadURL ? "Uploaded" : "Click to upload"}
													disabled={loading || !stampFile}
													icon={
														<>
															{loading ? (
																<LoaderIcon />
															) : (
																<>
																	{stampUploadURL ? <CiCircleCheck /> : <CiLocationArrow1 />}
																</>
															)}
														</>
													}
													onClick={handleStampUpload}
												/>

												{stampFile && (
													<p
														style={{
															fontSize: "12px",
															color: "red",
															marginTop: "10px",
														}}
													>
														*Click to upload
													</p>
												)}
											</td>
										</tr>
										<tr>
											<td colSpan="6" >
												<p className="status f-w-200 l-h-1 font-size-12">
													NOTE: Please upload files in JPG, PNG, or JPEG format, up to 2MB. Files exceeding these limits won't be accepted.
												</p>
											</td>
										</tr>
										<tr>
											<td colSpan="2">
												<FieldTitle title="Logo (Optional)" />
												<FileInput
													defaultText="Select Logo"
													accept="image/jpeg, image/jpg, image/png"
													maxSizeAllowed={2 * 1024 * 1024}
													callback={(f) => {
														setLogoFile(f);
													}}
												/>
												{logoFile && (
													<p
														className="cursor-pointer"
														style={{
															fontSize: "12px",
															color: "red",
															marginTop: "10px", 
														}}
														onClick={() => {
															setLogoFile(null);
															setLogoUploadURL("");
														}}
													>
														Delete logo file
													</p>
												)}
											</td>
											<td className="t-a-r">
												<p>&nbsp;</p>
												<ButtonTextIcon
													type="button"
													title={logoUploadURL ? "Uploaded" : "Click to upload"}
													disabled={loading || !logoFile}
													icon={
														<>
															{loading ? (
																<LoaderIcon />
															) : (
																<>
																	{logoUploadURL ? <CiCircleCheck /> : <CiLocationArrow1 />}
																</>
															)}
														</>
													}
													onClick={handleLogoUpload}
												/>

												{logoFile && (
													<p
														style={{
															fontSize: "12px",
															color: "red",
															marginTop: "10px",
														}}
													>
														*Click to upload
													</p>
												)}
											</td>
										</tr>
										{/* <tr>
											<td colSpan='2'>
												<FieldTitle title='Logo (Optional)' />
												<FileInput
													defaultText='Select Logo'
													accept='image/jpeg, image/jpg, image/png'
													maxSizeAllowed={
														2 * 1024 * 1024
													}
													callback={(f) => {
														setLogoFile(f);
													}}
												/>
											</td>
											<td className='t-a-r'>
												<p>&nbsp;</p>
												<ButtonTextIcon
													type='button'
													title={
														logoUploadURL
															? "Uploaded"
															: "Upload"
													}
													disabled={
														loading || !logoFile
													}
													icon={
														<>
															{loading ? (
																<LoaderIcon />
															) : (
																<>
																	{logoUploadURL ? (
																		<CiCircleCheck />
																	) : (
																		<CiLocationArrow1 />
																	)}
																</>
															)}
														</>
													}
													onClick={handleLogoUpload}
												/>


											</td>
										</tr>
										{logoFile && (
											<tr>
												<td colSpan='2'>
													<p
														className='cursor-pointer'
														style={{
															fontSize: "12px",
															color: "red",
														}}
														onClick={() => {
															setLogoFile(null);
															setLogoUploadURL(
																""
															);
														}}>
														Delete logo file
													</p>
													{logoFile && (
														<p
															style={{
																fontSize: '12px',
																color: 'red',
																marginTop: '5px',
															}}
														>
															*Upload logo file
														</p>
													)}
												</td>
											</tr>
										)} */}
										<tr>
											<td colSpan="6" >
												<p className="status f-w-200 l-h-1 font-size-12">
													NOTE: Please upload files in JPG, PNG, or JPEG format, up to 2MB. Files exceeding these limits won't be accepted.
												</p>
											</td>
										</tr>
									</SectionForm>
								</Row>
								<br />
								<Row>
									<SectionTitle
										title='Pre Approval'
										subtitle='Enter default configurations for pre-approval'
										icon={
											<CiBitcoin
												className='color-primary'
												style={{
													height: "32px",
													width: "32px",
													strokeWidth: "1px",
												}}
											/>
										}
									/>
									<SectionForm>
										<FieldArray name='configs'>
											{({ push, remove }) => (
												<>
													{values.configs.map(
														(config, index) => (
															<Fragment
																key={index}>
																<>
																	<tr>
																		<td colSpan='1'>
																			<div className='flex center-left'>
																				<p className='f-w-700 l-h-1-2 color-primary'>
																					Config{" "}
																					{index +
																						1}

																					.
																				</p>
																			</div>
																		</td>
																		<td colSpan='1'>
																			<div className='flex center-right'>
																				{index >
																					0 && (
																						<ButtonLightTextIcon
																							type='button'
																							title={
																								" Remove"
																							}
																							icon={
																								<CiCircleRemove />
																							}
																							onClick={() =>
																								remove(
																									index
																								)
																							}
																						/>
																					)}
																			</div>
																		</td>
																	</tr>
																	<tr>
																		<td>
																			<FieldTitle title='Min. Credit Score' />
																			<Field
																				name={`configs.${index}.minCreditScore`}
																				type='text'
																				placeholder='Eg [632] *'
																				maxLength='3'
																				className={
																					"col-1-1 " +
																					(errors
																						.configs?.[
																						index
																					]
																						?.minCreditScore &&
																						touched
																							.configs?.[
																							index
																						]
																							?.minCreditScore
																						? " error"
																						: "")
																				}
																			/>
																			<ErrorMessage
																				name={`configs.${index}.minCreditScore`}
																				component='p'
																				className='error-messages'
																			/>
																		</td>
																		<td>
																			<FieldTitle title='Max. Credit Score' />
																			<Field
																				name={`configs.${index}.maxCreditScore`}
																				type='text'
																				placeholder='Eg [900] *'
																				maxLength='3'
																				className={
																					"col-1-1 " +
																					(errors
																						.configs?.[
																						index
																					]
																						?.maxCreditScore &&
																						touched
																							.configs?.[
																							index
																						]
																							?.maxCreditScore
																						? " error"
																						: "")
																				}
																			/>
																			<ErrorMessage
																				name={`configs.${index}.maxCreditScore`}
																				component='p'
																				className='error-messages'
																			/>
																		</td>
																	</tr>
																	<tr>
																		<td>
																			<FieldTitle title='Min. Loan Amount (AED)' />
																			<Field
																				name={`configs.${index}.minLoanAmount`}
																				type='text'
																				placeholder='Eg [20000] *'
																				className={
																					"col-1-1 " +
																					(errors
																						.configs?.[
																						index
																					]
																						?.minLoanAmount &&
																						touched
																							.configs?.[
																							index
																						]
																							?.minLoanAmount
																						? " error"
																						: "")
																				}
																			/>
																			<ErrorMessage
																				name={`configs.${index}.minLoanAmount`}
																				component='p'
																				className='error-messages'
																			/>
																		</td>
																		<td>
																			<FieldTitle title='Max. Loan Amount (AED)' />
																			<Field
																				name={`configs.${index}.maxLoanAmount`}
																				type='text'
																				placeholder='Eg [120000] *'
																				className={
																					"col-1-1 " +
																					(errors
																						.configs?.[
																						index
																					]
																						?.maxLoanAmount &&
																						touched
																							.configs?.[
																							index
																						]
																							?.maxLoanAmount
																						? " error"
																						: "")
																				}
																			/>
																			<ErrorMessage
																				name={`configs.${index}.maxLoanAmount`}
																				component='p'
																				className='error-messages'
																			/>
																		</td>
																	</tr>
																	<tr>
																		<td colSpan='1'>
																			<FieldTitle title='Interest %' />
																			<Field
																				name={`configs.${index}.interestPercent`}
																				type='text'
																				placeholder='Eg [5.2] *'
																				className={
																					"col-1-1 " +
																					(errors
																						.configs?.[
																						index
																					]
																						?.interestPercent &&
																						touched
																							.configs?.[
																							index
																						]
																							?.interestPercent
																						? " error"
																						: "")
																				}
																			/>
																			<ErrorMessage
																				name={`configs.${index}.interestPercent`}
																				component='p'
																				className='error-messages'
																			/>
																		</td>
																		<td colSpan='1'>
																			<FieldTitle title='Risk Ratio' />
																			<Field
																				name={`configs.${index}.riskRatio`}
																				type='text'
																				placeholder='Eg [O.2] *'
																				className={
																					"col-1-1 " +
																					(errors
																						.configs?.[
																						index
																					]
																						?.riskRatio &&
																						touched
																							.configs?.[
																							index
																						]
																							?.riskRatio
																						? " error"
																						: "")
																				}
																			/>
																			<ErrorMessage
																				name={`configs.${index}.riskRatio`}
																				component='p'
																				className='error-messages'
																			/>
																		</td>
																	</tr>
																	<tr>
																		<td colSpan='1'>
																			<FieldTitle title='Processing Fee' />
																			<Field
																				name={`configs.${index}.processingFee`}
																				type='text'
																				placeholder='Eg [200] *'
																				className={
																					"col-1-1 " +
																					(errors
																						.configs?.[
																						index
																					]
																						?.processingFee &&
																						touched
																							.configs?.[
																							index
																						]
																							?.processingFee
																						? " error"
																						: "")
																				}
																			/>
																			<ErrorMessage
																				name={`configs.${index}.processingFee`}
																				component='p'
																				className='error-messages'
																			/>
																		</td>
																		<td colSpan='1'>
																			<FieldTitle title='Frequency' />
																			<Field
																				as='select'
																				name={`configs.${index}.frequency`}
																				className={
																					"col-1-1 " +
																					(errors
																						.configs?.[
																						index
																					]
																						?.frequency &&
																						touched
																							.configs?.[
																							index
																						]
																							?.frequency
																						? " error"
																						: "")
																				}>
																				<option value=''>
																					Select
																					Frequency
																				</option>
																				{FREQUENCY_TYPE_LIST &&
																					FREQUENCY_TYPE_LIST.map(
																						(
																							a,
																							i
																						) => (
																							<option
																								value={
																									a.value
																								}
																								key={
																									i
																								}>
																								{capitalizeFirstLetter(
																									a.name
																								)}
																							</option>
																						)
																					)}
																			</Field>
																			<ErrorMessage
																				name={`configs.${index}.frequency`}
																				component='p'
																				className='error-messages'
																			/>
																		</td>
																	</tr>
																</>
															</Fragment>
														)
													)}
													<tr>
														<td colSpan='2'>
															<div className='flex center-center'>
																<button
																	type='button'
																	onClick={() =>
																		push({
																			minCreditScore:
																				"",
																			maxCreditScore:
																				"",
																			minLoanAmount:
																				"",
																			maxLoanAmount:
																				"",
																			interestPercent:
																				"",
																			riskRatio:
																				"",
																			processingFee:
																				"",
																			frequency:
																				"",
																		})
																	}>
																	Add More
																</button>
															</div>
														</td>
													</tr>
												</>
											)}
										</FieldArray>
									</SectionForm>
								</Row>
								<br />
								<div
									className='flex center-center'
									style={{ gap: "12px" }}>
									<NavLink
										to={`/sa-credit-provider?pageNo=0&pageSize=${DEFAULT_PER_PAGE}`}>
										<ButtonLightTextIcon
											type='button'
											title='Cancel'
										/>
									</NavLink>
									{loading ? (
										<ButtonTextIcon
											type='button'
											title='Creating...'
											disabled={loading}
											icon={<LoaderIcon />}
										/>
									) : (
										<ButtonTextOnly
											type='submit'
											disabled={
												loading || !stampUploadURL
											}
											title='Create Now'
										/>
									)}
								</div>
							</section>
						</section>
					</Form>
				)}
			</Formik>
		</section>
	);
};

export default SaCreateCreditProvider;
