import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import PageTitle from "../../components/common/PageTitle";
import LoaderIcon from "../../components/ui/LoaderIcon";
import { jwtDecode } from "jwt-decode";
import { getDefaultURLForRole } from "../../services/roles";
import { API_URL, RS_TAGLINE } from "../../utilities/constants";
import RentSavvyLogo from "../../components/common/RentSavvyLogo";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import ButtonTextOnly from "../../components/ui/ButtonTextOnly";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import { CiCircleCheck } from "react-icons/ci";
import { useUI } from "../../context/ui";

const AgencyBrokerPending = () => {
	const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState("");
	const [brnVerified, setBrnVerified] = useState(false);
	const [brnVerifying, setBrnVerifying] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();
	const {
		addToastMessage,
	} = useUI();

	const FormValidation = Yup.object().shape({
		brnNumber: Yup.string()
			.matches(/^\d+$/, "BRN number must contain only digits")
			.required("Required!"),
	});

	const initial = {
		brnNumber: "",
	};

	const checkIfNextURLExists = () => {
		const params = new URLSearchParams(location.search);
		const queryObj = {};
		params.forEach((value, key) => {
			queryObj[key] = value;
		});
		if (queryObj.next) {
			return queryObj.next;
		} else {
			return false;
		}
	};

	const handleSubmit = async (brnNumber) => {
		setStatus("");
		setLoading(true);
		try {
			const resp = await fetch(API_URL + "/api/1.0.0/user", {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					"X-JWT-Assertion": localStorage.getItem("token"),
				},
				body: JSON.stringify({
					brnNumber: brnNumber,
				}),
			});
			if (resp.ok) {
				const response = await resp.json();
				if (response.result.responseCode === 200) {
					addToastMessage({
						status: true,
						message:
							"You have successfully update the flow, Thank You!",
					});
					const nextURL = checkIfNextURLExists();
					const checkForFlow = localStorage.getItem("flow");
					if (checkForFlow) {
						const checkForFlowObj = JSON.parse(checkForFlow);
						const filteredFlows = checkForFlowObj.filter((el) => {
							return el !== "/ab-pending-details";
						});
						if (
							localStorage.setItem(
								"flow",
								JSON.stringify(filteredFlows)
							) === undefined
						) {
							if (filteredFlows.length) {
								if (nextURL) {
									navigate(
										filteredFlows[0] + "?next=" + nextURL
									);
								} else {
									navigate(filteredFlows[0]);
								}
							} else {
								localStorage.removeItem("flow");
								if (nextURL) {
									navigate(nextURL);
								} else {
									const decodedToken = jwtDecode(
										localStorage.getItem("token")
									);
									const roles = decodedToken.authorities;
									navigate(getDefaultURLForRole(roles[0]));
								}
							}
						} else {
							setStatus("Could not update the flow");
						}
					} else {
						if (nextURL) {
							navigate(nextURL);
						} else {
							const decodedToken = jwtDecode(
								localStorage.getItem("token")
							);
							const roles = decodedToken.authorities;
							navigate(getDefaultURLForRole(roles[0]));
						}
					}
				} else {
					setStatus("Could not update BRN");
				}
			} else {
				const errorData = await resp.json();
				const errorMessage = errorData.responseDescription;
				addToastMessage({
					status: false,
					message:
						errorMessage ||
						"Something went wrong while updating. Please try again.",
				});
				setStatus("API Failed");
			}
		} catch (error) {
			setStatus("An Error Occurred");
		} finally {
			setLoading(false);
		}
	};

	const handleBRNVerify = async (val) => {
		setBrnVerifying(true);
		try {
			const resp = await fetch(API_URL + `/api/1.0.0/verify/brn/${val}`, {
				headers: {
					"Content-Type": "application/json",
					"X-JWT-Assertion": localStorage.getItem("token"),
				},
			});
			if (resp.ok) {
				const response = await resp.json();
				if (response.result.responseCode === 200) {
					setBrnVerified(true);
					setStatus("");
				} else {
					setStatus("BRN is incorrect!");
				}
			} else {
				setStatus("Invalid BRN!");
			}
		} catch {
			setStatus("Verify BRN API failed!");
		} finally {
			setBrnVerifying(false);
		}
	};

	return (
		<section id='auth-page'>
			<PageTitle title='Enter Details' />
			<section className='auth-header'>
				<NavLink to='/login'>
				<div className='logo'>
					<RentSavvyLogo />
				</div>
				</NavLink>
			</section>
			<section className='auth-content'>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='90.396'
					height='82.351'
					viewBox='0 0 90.396 82.351'
					className='svg-top'>
					<g transform='translate(-288.14 -169.729)'>
						<path
							d='M276.138,252.079V334.43h57.775s18.088-.5,24.874-10.241,12.908-27.337,0-41.4-31.156-30.71-31.156-30.71Z'
							transform='translate(654.483 504.159) rotate(180)'
							fill='#5dc6b3'
						/>
						<path
							d='M327.624,252.079V190.464l25.5,21.287V252.08Z'
							fill='#1e4ccb'
						/>
						<path
							d='M327.624,190.464l25.4-19.87,25.512,19.87L353.027,211.9Z'
							fill='#3b86b5'
						/>
					</g>
				</svg>
				<div className='inner'>
					<div className='col col-1-2 left'>
						<div className='text'>
							<h1 className='l-h-1 f-w-300 color-primary'>
								Enter
								<br />
								Details!
							</h1>
							<br />
							<h5 className='f-w-300 color-primary'>
								{RS_TAGLINE}
							</h5>
						</div>
					</div>
					<div className='col col-1-2 right pos-rel'>
						<div className='box pending-details'>
							<Formik
								initialValues={initial}
								validationSchema={FormValidation}
								onSubmit={async (values, { resetForm }) => {
									handleSubmit(values.brnNumber);
								}}>
								{({
									values,
									errors,
									touched,
									setFieldValue,
									resetForm,
								}) => (
									<Form>
										<h4 className='color-primary t-a-c'>
											Enter Details
										</h4>
										<table>
											<thead></thead>
											<tbody>
												<tr>
													<td>
														<p className='title f-w-700 color-primary'>
															Registered Number
															(BRN):
														</p>
														<Field
															name='brnNumber'
															type='text'
															maxLength="15"
															placeholder='[Eg: 123*******]'
															className={
																" " +
																(errors.brnNumber &&
																touched.brnNumber
																	? " error"
																	: "")
															}
														/>
													</td>
													<td className='verify'>
														<p className='title f-w-700 color-primary'>
															&nbsp;
														</p>
														<>
															{brnVerified ? (
																<ButtonLightTextIcon
																	type='button'
																	data-name='verified'
																	title='Verified'
																	icon={
																		<CiCircleCheck />
																	}
																/>
															) : (
																<ButtonLightTextIcon
																	type='button'
																	disabled={
																		values
																			.brnNumber
																			.length < 15
																	}
																	title='Verify'
																	onClick={() =>
																		handleBRNVerify(
																			values.brnNumber
																		)
																	}
																	icon={
																		<>
																			{brnVerifying ? (
																				<LoaderIcon />
																			) : (
																				<svg
																					xmlns='http://www.w3.org/2000/svg'
																					width='18'
																					height='24'
																					viewBox='0 0 18 24'>
																					<path
																						d='M15.762,8.047l-4.381,4.475L9.166,10.4,7.93,11.638,11.381,15,17,9.285ZM12,2.544A11.888,11.888,0,0,0,19,4.96v9.575c0,3.042-1.686,3.827-7,7.107-5.309-3.278-7-4.065-7-7.107V4.96A11.884,11.884,0,0,0,12,2.544ZM12,0C8.629,2.866,6.516,3,3,3V14.535c0,4.6,3.2,5.8,9,9.465,5.8-3.661,9-4.862,9-9.465V3c-3.516,0-5.629-.134-9-3Z'
																						transform='translate(-3)'
																					/>
																				</svg>
																			)}
																		</>
																	}
																/>
															)}
														</>
													</td>
												</tr>
												<tr>
													<td colSpan='2'>
														{loading ? (
															<ButtonTextIcon
																type='button'
																title='Updating...'
																disabled={
																	loading
																}
																icon={
																	<LoaderIcon />
																}
															/>
														) : (
															<ButtonTextOnly
																disabled={
																	!brnVerified
																}
																type='submit'
																title='Update Details'
															/>
														)}
													</td>
												</tr>
											</tbody>
										</table>
										{status && (
											<p className='status color-secondary f-w-300 l-h-1'>
												{status}
											</p>
										)}
									</Form>
								)}
							</Formik>
						</div>
					</div>
				</div>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='90.205'
					height='82.351'
					viewBox='0 0 90.205 82.351'
					className='svg-bottom'>
					<g transform='translate(-276.138 -252.079)'>
						<path
							d='M276.138,252.079V334.43h57.775s18.088-.5,24.874-10.241,12.908-27.337,0-41.4-31.156-30.71-31.156-30.71Z'
							fill='#5dc6b3'
						/>
						<path
							d='M290.953,334.43l43.512-.032a56.454,56.454,0,0,0,8.178-1.2,45.36,45.36,0,0,0,5.6-1.582v-32.64L320.021,269.53l-29.069,29.446Z'
							fill='#3b86b5'
						/>
						<path
							d='M276.138,298.387V269.279l44.181.244-28.911,28.864Z'
							fill='#1e4ccb'
						/>
					</g>
				</svg>
			</section>
		</section>
	);
};

export default AgencyBrokerPending;
