import { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { CiCirclePlus, CiCircleCheck } from "react-icons/ci";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import RSModal from "../../components/ui/RSModal";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import { API_URL, DEFAULT_PER_PAGE } from "../../utilities/constants";
import FullPageLoader from "../../components/ui/FullPageLoader";
import Pagination from "../../components/common/Pagination";
import PageTitle from "../../components/common/PageTitle";
import ResultsPerPage from "../_common/ResultsPerPage";
import { useUI } from "../../context/ui";

const PopUpContent = ({ agencyAdminId, callback, setShow, statusID }) => {
  const [loading, setLoading] = useState(false);
  const { addToastMessage } = useUI();


  const handleProceed = async () => {
    setLoading(true);
    try {
      const resp = await fetch(
        API_URL + `/api/1.0.0/admin/brokerAgency/status`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "X-JWT-Assertion": localStorage.getItem("token"),
          },
          body: JSON.stringify({
            userId: agencyAdminId,
            statusID: statusID === 1 ? 2 : 1,
          }),
        }
      );
      if (resp.ok) {
        const response = await resp.json();
        addToastMessage({
          status: true,
          message:
            "You have successfully updated status, thank you.",
        });
        if (response.result.responseCode === 200) {
          setShow(false);
          callback();
        }
      }
      else {
        const errorData = await resp.json();
        const errorMessage = errorData.responseDescription;
        addToastMessage({
          status: false,
          message:
            errorMessage ||
            "Something went wrong while updating status. Please try again.",
        });
      }
    } catch (e) {
      console.log("Error", e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <FullPageLoader isActive={loading} />
      <div
        style={{
          height: "auto",
          width: "480px",
          padding: "40px",
        }}
      >
        <h4 className="color-primary">
          Are you sure you want to {statusID === 1 ? "deactivate" : "activate"}{" "}
          the agency?
        </h4>
        <br />
        <p>
          On activating or deactivating the agency, some of the features might
          not work as expected.
          <br />
          Are you sure you want to proceed?
        </p>
        <br />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "12px",
          }}
        >
          <ButtonLightTextIcon
            title="Cancel"
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26.562"
                height="17.809"
                viewBox="0 0 26.562 17.809"
              >
                <path d="M0,8.88,10.686,0l1.937,2.293L6.453,7.4H26.562v3H6.454l6.166,5.108-1.934,2.3Z" />
              </svg>
            }
            onClick={() => setShow(false)}
          />
          <ButtonTextIcon
            title="Yes, Proceed"
            icon={<CiCircleCheck />}
            onClick={() => handleProceed()}
          />
        </div>
      </div>
    </>
  );
};

const AgencyList = () => {
  const location = useLocation();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { setSegmentTitle } = useUI();

  const callListApi = async () => {
    setLoading(true);
    const pageNo = new URLSearchParams(location.search).get("pageNo");
    const pageSize = new URLSearchParams(location.search).get("pageSize");
    try {
      const resp = await fetch(
        API_URL +
        `/api/1.0.0/admin/brokerAgency?pageNo=${pageNo}&pageSize=${pageSize}&sortOrder=desc`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "X-JWT-Assertion": localStorage.getItem("token"),
          },
        }
      );
      if (resp.ok) {
        const response = await resp.json();
        setData(response.data);
      }
    } catch (e) {
      console.log("Error", e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (location.search) {
      callListApi();
      setSegmentTitle("Agencies");
    }
  }, [location.search]);

  return (
    <section className="pos-rel">
      <PageTitle title="Agencies" />
      <FullPageLoader isActive={loading} />
      <section>
        <div className="flex center-left" style={{ padding: "20px" }}>
          <ResultsPerPage targetURL={`/sa-agency?pageNo=0&pageSize=`} />
          <div style={{ marginLeft: "auto" }}>
            <NavLink to="/sa-agency/create">
              <ButtonTextIcon title="Add Agency" icon={<CiCirclePlus />} />
            </NavLink>
          </div>
        </div>
        <div className="results">
          <div className="rs-table-holder">
            <table className="rs-table">
              <thead>
                <tr>
                  <th>Agency</th>
                  <th>Admin</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Commercial</th>
                  <th>Status</th>
                </tr>
              </thead>
              {data && (
                <tbody>
                  {data.brokerAgencies.map((d, i) => (
                    <tr key={i}>
                      <td>
                        {" "}
                        <div
                          className="flex center-left"
                          style={{ gap: "8px" }}
                        >
                          <div
                            className="b-r-100 of-hid default-image "
                            style={{
                              height: "32px",
                              width: "32px",
                              backgroundImage: d.logo ? `url(${d.logo})` : null,
                            }}
                          ></div>
                          <p className="font-size-14 t-d-ul t-a-c color-secondary transition hover-color-primary">
                            <NavLink to={`/basa-brokers?pageNo=0&pageSize=${DEFAULT_PER_PAGE}&agencyId=${d.agencyId}`}>
                              <span>{d.agencyName}</span>
                            </NavLink>
                          </p>
                        </div>
                      </td>
                      <td>{d.agencyAdminName}</td>
                      <td>{d.agencyAdminEmail}</td>
                      <td>{`(+971) ${d.phoneNo}`}</td>
                      <td>
                        <p className="font-size-12 t-d-ul t-a-c">
                          <NavLink to={`/sa-agency/update/${d.agencyId}`}>
                            <span>Edit</span>
                          </NavLink>
                        </p>
                      </td>
                      <td>
                        {d.agencyActive ? (
                          <>
                            <RSModal
                              selector={
                                <>
                                  {d.statusID === 1 ? (
                                    <div className="cursor-pointer status active">
                                      Active
                                    </div>
                                  ) : (
                                    <div className="cursor-pointer status inactive">
                                      Inactive
                                    </div>
                                  )}
                                </>
                              }
                              content={
                                <PopUpContent
                                  agencyAdminId={d.agencyAdminId}
                                  statusID={d.statusID}
                                  data={d}
                                  callback={() => callListApi()}
                                />
                              }
                            />
                          </>
                        ) : (
                          <div className="cursor-pointer status bg-color-tertiary">
                            Pending
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
              {((data && data?.AgencyList?.length === 0 && !loading) || (!data && !loading)) && (
                <tbody><tr>
                  <td colSpan={6} style={{ textAlign: "center", padding: "10px" }}>
                    <p> No records found. </p>
                  </td>
                </tr>
                </tbody>
              )}
            </table>
          </div>
        </div>
        <br />
        {data && (
          <Pagination
            totalRecords={data.totalRecords}
            pageNo={data.pageNo}
            pageSize={data.pageSize}
            baseUrl="/sa-agency"
          />
        )}
      </section>
    </section>
  );
};

export default AgencyList;
