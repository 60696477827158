const RentSavvyLogo = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='477.457'
			height='186.493'
			viewBox='0 0 477.457 186.493'>
			<g transform='translate(-276.138 -169.729)'>
				<g transform='translate(377.9 461.729)'>
					<path
						d='M357.249-286.737c-2.469,2.859-5.913,6.5-7.6,8.188-2.794,2.729-3.379,2.989-7.083,3.379-5.2.52-5.913,1.884-1.1,2.144l3.444.195.325,26.317.325,26.317,1.754,3.054c2.339,4.289,4.744,6.108,9.422,7.408,9.812,2.664,19.884-1.495,23.653-9.682,1.04-2.209.325-2.989-1.365-1.43-1.56,1.43-6.5,2.014-8.382.975a8.175,8.175,0,0,1-2.729-3.444c-1.1-2.534-1.235-4.549-1.235-25.862,0-20.534.13-23.263,1.04-23.588a65.8,65.8,0,0,1,8.837-.39c5.913,0,7.733-.195,7.538-.845-.195-.52-2.794-.845-8.707-1.1L367-275.43l-.195-8.317L366.606-292h-2.4C361.992-291.935,361.407-291.48,357.249-286.737Z'
						transform='translate(-111.408)'
						fill='#3d3368'
					/>
					<path
						d='M63.912-267.276a19.62,19.62,0,0,0-3.314,1.235c-2.274,1.17-6.043,5.783-7.928,9.617l-1.754,3.639-.325-6.693-.325-6.758-14.1-.195c-7.8-.065-14.166.065-14.166.39a14,14,0,0,0,2.664,3.054c4.484,4.354,4.484,4.159,4.484,29.111,0,13.841-.26,23.068-.65,24.3a15.146,15.146,0,0,1-3.574,4.419A13.3,13.3,0,0,0,22-202.166c0,.325,9.552.585,21.183.585s20.989-.26,20.794-.585c-.195-.26-2.989-2.014-6.108-3.9a44.351,44.351,0,0,1-6.563-4.224c-.52-.585-.715-6.238-.585-19.3l.195-18.389,2.209-1.495c4.224-2.859,8.252-1.884,12.931,3.119l3.184,3.379,3.379-9.682c1.819-5.263,3.314-10.2,3.184-10.917C75.414-266.3,68.461-268.446,63.912-267.276Z'
						transform='translate(-0.665 -8.544)'
						fill='#3d3368'
					/>
					<path
						d='M137.617-267.216c-16.115,2.924-24.238,12.281-26.187,30.281-.65,5.783,1.235,15.79,3.769,20.729,4.744,8.9,14.3,14.685,25.992,15.66,5.393.455,13.256-.91,17.545-3.119,5.653-2.859,11.7-10.592,12.541-16.245l.455-2.6-3.054,3.249c-4.354,4.679-8.317,6.238-15.66,6.3-4.809,0-6.3-.26-8.447-1.43a19.271,19.271,0,0,1-9.357-11.7c-1.689-5.913-2.079-9.747-1.1-10.527.52-.455,8.122-.78,19.949-.845l19.1-.195-.13-4.809c-.325-11.761-6.108-19.754-16.96-23.393C151.523-267.411,142.3-268.126,137.617-267.216Zm10.4,2.989c3.444,2.4,5.588,10.592,5.2,19.819l-.195,4.809-9.162.195c-6.823.13-9.357-.065-9.877-.65-.845-1.04.13-11.761,1.56-16.765,1.43-4.874,5-8.447,8.512-8.447A7.929,7.929,0,0,1,148.014-264.227Z'
						transform='translate(-31.939 -8.539)'
						fill='#3d3368'
					/>
					<path
						d='M261.336-267.265a19.578,19.578,0,0,0-13.126,8.837c-.975,1.625-2.014,2.924-2.274,2.924-.325,0-.52-2.469-.52-5.523v-5.523H231.575c-7.668,0-14.1.195-14.3.455-.26.195,1.17,2.079,3.119,4.159l3.639,3.769-.195,24.757-.195,24.757-3.574,3.379L216.5-201.9l17.48.195c9.617.065,17.61,0,17.8-.195s-1.17-1.884-2.989-3.769l-3.379-3.444v-21.054c.065-19.429.13-21.248,1.235-22.548,5-5.978,13.971-5.263,16.635,1.3,1.56,3.9,2.4,15.14,2.144,30.021l-.195,12.736-2.6,2.469c-5.328,5-6.563,4.614,13.711,4.614h18.064l-3.639-3.769-3.7-3.7-.26-20.144c-.195-17.09-.455-20.664-1.43-23.718-2.079-6.368-5.783-10.982-10.527-13.126C271.733-267.4,265.17-267.98,261.336-267.265Z'
						transform='translate(-68.779 -8.555)'
						fill='#3d3368'
					/>
					<path
						d='M413-224.426v3.574l24.887-.13,24.822-.195v-6.5l-24.822-.195L413-228Z'
						transform='translate(-137.594 -22.413)'
						fill='#3d3368'
					/>
					<path
						d='M38.749-143.783c-11.047,3.574-16.375,10.2-16.375,20.534.065,10.982,3.7,15.14,20.534,23.328,6.173,2.989,12.021,6.238,13,7.148,5.458,5.2,2.339,13.906-5.718,15.92-4.224,1.1-9.032.91-10.072-.325-.52-.65-3.249-5.653-6.173-11.177-2.924-5.588-5.523-10.2-5.913-10.332-.325-.13-1.235,1.625-2.014,3.834-.715,2.274-2.339,6.953-3.639,10.4l-2.274,6.3,2.079.65c6.693,2.209,10.007,2.729,19.624,3.119,12.021.39,16.05-.13,22.548-3.184C69.55-79.908,74.813-85.106,75.918-89a28.7,28.7,0,0,0,.715-12.931c-1.754-6.953-5.653-10.2-19.559-16.44-14.815-6.693-16.895-8.577-16.895-15.14,0-2.664.39-3.964,1.43-5.328,3.119-4.029,11.372-5.653,13.971-2.794.715.845,3.574,5.523,6.368,10.4,2.729,4.938,5.263,8.967,5.523,8.967.39,0,6.173-13.971,7.408-17.934.52-1.689.13-1.884-7.993-3.574C58.633-145.472,44.078-145.472,38.749-143.783Z'
						transform='translate(0 -51.462)'
						fill='#3d3368'
					/>
					<path
						d='M141.07-143.666c-7.083,1.689-12.216,3.249-12.606,3.834a5.007,5.007,0,0,0,.585,2.4c.52,1.1,2.339,5.133,4.094,9.1,1.754,3.9,3.444,7.148,3.769,7.278.65.195,1.17-.65,6.433-10.592,4.744-9.032,6.433-11.307,8.447-11.307,4.354,0,8.382,3.314,9.747,8.058,1.3,4.224,1.43,23.133.195,23.133a32.673,32.673,0,0,1-4.419-1.3c-4.938-1.754-13.386-1.754-18.584,0a24.926,24.926,0,0,0-12.281,9.357c-1.884,3.054-1.949,3.509-1.949,10.4,0,8.252.975,10.852,5.263,14.685,4.354,3.769,7.408,4.744,14.166,4.419,3.314-.195,6.628-.715,7.538-1.235a22.534,22.534,0,0,0,7.863-6.758,13.332,13.332,0,0,1,2.534-2.924c.26,0,.65.845.845,1.884.52,2.6,4.094,6.5,7.278,7.928,3.769,1.754,11.372,1.689,15.075-.13,3.249-1.56,5.848-4.679,6.433-7.6.39-2.014-.455-2.924-1.365-1.43-.715,1.1-3.314.715-4.614-.715-1.17-1.3-1.235-2.664-1.235-22.288v-20.859l-1.884-4.094a20.58,20.58,0,0,0-11.372-10.657c-3.184-1.235-5-1.43-14.361-1.625C148.153-144.771,144.9-144.576,141.07-143.666Zm20.859,34.244c.845.455.13,20.079-.78,21.833-.975,1.754-4.809,3.769-7.408,3.769-6.823,0-10.527-11.891-6.238-20.144,2.469-4.744,5.848-6.693,10.657-6.238A14.261,14.261,0,0,1,161.928-109.422Z'
						transform='translate(-36.561 -51.578)'
						fill='#3d3368'
					/>
					<path
						d='M227-143.35a46.4,46.4,0,0,0,2.664,5.978c1.495,2.924,5,12.086,7.863,20.339,9.1,26.382,10.982,31.71,12.736,37.039l1.754,5.2H268.2l4.614-13.646c2.534-7.538,6.043-17.869,7.8-23.068,3.574-10.722,4.289-12.346,10.657-23.068,2.534-4.289,4.614-8.187,4.614-8.642,0-.585-2.729-.78-11.372-.78-7.148,0-11.371.26-11.371.65,0,.325,1.884,4.614,4.224,9.487s4.224,9.487,4.224,10.267c0,1.3-10.072,31.45-10.722,32.165-.195.13-.585,0-.91-.325-.78-.78-13.711-40.418-13.711-41.912,0-.65,1.04-2.859,2.274-4.938a20.819,20.819,0,0,0,2.274-4.614c0-.585-3.834-.78-16.895-.78C232.848-144,227-143.74,227-143.35Z'
						transform='translate(-72.456 -51.83)'
						fill='#3d3368'
					/>
					<path
						d='M343-143.616c0,.195,1.3,3.054,2.859,6.3,1.56,3.314,6.108,15.335,10.007,26.772,3.964,11.436,8.317,24.173,9.747,28.266l2.534,7.473h16.115l.78-2.274c.39-1.235,4.029-11.956,7.993-23.718,7.018-20.794,7.408-21.768,13.321-31.97,3.314-5.783,5.848-10.657,5.653-10.852-.585-.65-22.873-.455-22.873.195,0,.26,1.884,4.484,4.224,9.292,2.339,4.874,4.224,9.487,4.224,10.332,0,2.534-10.592,33.14-11.242,32.49-.78-.78-14.1-40.937-14.1-42.432a18.554,18.554,0,0,1,2.274-4.873,20.82,20.82,0,0,0,2.274-4.614c0-.585-3.834-.78-16.895-.78C350.6-144.006,343-143.811,343-143.616Z'
						transform='translate(-113.08 -51.824)'
						fill='#3d3368'
					/>
					<path
						d='M459-143.291a21.554,21.554,0,0,0,2.144,4.354c2.209,3.9,3.7,7.278,8.577,19.949,1.625,4.289,3.249,8.382,3.574,9.1s3.119,7.733,6.173,15.6,6.758,17.155,8.122,20.664c1.43,3.444,2.6,6.953,2.6,7.8,0,.78-1.3,3.834-2.924,6.758-2.534,4.809-2.729,5.393-1.56,5.393,2.4,0,8.447-11.177,16.5-30.606,2.729-6.563,5.588-13.581,6.433-15.53s3.184-7.668,5.263-12.671c4.874-11.956,5.328-12.931,8.252-17.74a37.554,37.554,0,0,0,2.469-4.354,46.689,46.689,0,0,1,2.534-4.484c1.43-2.274,2.4-4.354,2.209-4.549-.65-.65-22.938-.455-22.938.195,0,.325,2.079,4.874,4.614,10.072l4.679,9.422-1.754,4.419c-.975,2.4-2.209,5.263-2.664,6.368-2.469,5.653-6.823,16.635-6.823,17.155,0,.325-.39.715-.91.91-.65.26-2.144-3.184-5.848-13.386-2.794-7.6-5.848-16.05-6.888-18.844-2.664-7.018-2.6-7.8,1.365-12.866a9.87,9.87,0,0,0,1.884-3.119c0-.52-5.133-.715-17.545-.715C464.393-144.006,459-143.811,459-143.291Z'
						transform='translate(-153.703 -51.824)'
						fill='#3d3368'
					/>
					<path
						d='M468.924-24.032A114.478,114.478,0,0,0,466-11.88a78.643,78.643,0,0,0,8.188.195h8.187l-1.884-2.144C476.137-18.768,469.379-24.746,468.924-24.032Z'
						transform='translate(-156.154 -93.822)'
						fill='#3d3368'
					/>
				</g>
				<g>
					<path
						d='M276.138,252.079V334.43h57.775s18.088-.5,24.874-10.241,12.908-27.337,0-41.4-31.156-30.71-31.156-30.71Z'
						fill='#5dc6b3'
					/>
					<path
						d='M290.953,334.43l43.512-.032a56.453,56.453,0,0,0,8.178-1.2,45.362,45.362,0,0,0,5.6-1.582v-32.64l-28.222-29.446-29.069,29.446Z'
						fill='#3b86b5'
					/>
					<path
						d='M276.138,298.387V269.279l44.181.244-28.911,28.864Z'
						fill='#1e4ccb'
					/>
					<path
						d='M276.138,252.079V334.43h57.775s18.088-.5,24.874-10.241,12.908-27.337,0-41.4-31.156-30.71-31.156-30.71Z'
						transform='translate(654.483 504.159) rotate(180)'
						fill='#5dc6b3'
					/>
					<path
						d='M327.624,252.079V190.464l25.5,21.287v40.329Z'
						fill='#1e4ccb'
					/>
					<path
						d='M327.624,190.464l25.4-19.87,25.512,19.87L353.027,211.9Z'
						fill='#3b86b5'
					/>
				</g>
			</g>
		</svg>
	);
};

export default RentSavvyLogo;
