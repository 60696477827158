import Aside from "../components/layout/Aside";
import Header from "../components/layout/Header";

const WithLayout = ({ children }) => {
	return (
		<main>
			<Aside />
			<section className='container'>
				<Header />
				{children}
			</section>
		</main>
	);
};

export default WithLayout;
