import { useEffect, useState } from "react";
import PageTitle from "../../components/common/PageTitle";
import FullPageLoader from "../../components/ui/FullPageLoader";
import { CiCreditCard1, CiCircleCheck } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import { API_URL, BASE_URL } from "../../utilities/constants";
import { useParams } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { useUI } from "../../context/ui";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import LoaderIcon from "../../components/ui/LoaderIcon";
import { convertAEDToUSD } from "../../utilities/helpers";

const MyCreditScore = () => {
	const { id } = useParams();
	const [showPaymentButton, setShowPaymentButton] = useState(false);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const {
		setNewNotification,
		setShowNotification,
		addNotification,
		setSegmentTitle,
		addToastMessage,
	} = useUI();

	const SectionForm = ({ children }) => {
		return (
			<div className='col-1-2'>
				<table>
					<thead></thead>
					<tbody>{children}</tbody>
				</table>
			</div>
		);
	};

	const Row = ({ children }) => {
		return (
			<div className='row b-r-8'>
				<div className='col-1-1 wrap flex'>{children}</div>
			</div>
		);
	};

	const SectionTitle = ({ title, subtitle, icon }) => {
		return (
			<div className='col-1-2'>
				<div
					className='flex center-left'
					style={{
						gap: "12px",
					}}>
					{icon}
					<div className='flex center-left'>
						<div>
							<p className='f-w-700 l-h-1-2 color-primary'>
								{title}
							</p>
							<p className='l-h-1-2 color-light small'>
								{subtitle}
							</p>
						</div>
					</div>
				</div>
				<br />
				<br />
				<div className='credit-score-box b-r-10'>
					<h6 className='title f-w-700 color-primary'>
						Credit Score
					</h6>
					<br />
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='393'
						height='196.001'
						viewBox='0 0 393 196.001'>
						<g transform='translate(-1867 -928)'>
							<path
								d='M139,196h50c0,80.5,65.72,146,146.5,146S482,276.5,482,196h50c0,108.248-87.976,196-196.5,196S139,304.248,139,196Z'
								transform='translate(2399 1320) rotate(180)'
								fill='#c1bbdd'
							/>
							<path
								d='M50,208.691H0a196.961,196.961,0,0,1,3.992-39.5A194.664,194.664,0,0,1,33.559,99.1,196.742,196.742,0,0,1,300.277,42.223L276.7,86.562A146.073,146.073,0,0,0,196.5,62.69c-80.78,0-146.5,65.5-146.5,146Z'
								transform='translate(1867 915.31)'
								fill='#3c3368'
							/>
							<path
								d='M5.73-6.84H9.36l.36-1.92c3.33-.81,5.85-2.43,5.85-6.21v-.06c0-3.9-3.09-6.3-7.5-6.3A10.55,10.55,0,0,0,.3-18.18l3,3.96A6.882,6.882,0,0,1,7.68-16.2c1.44,0,2.25.63,2.25,1.65v.03c0,1.29-1.23,2.1-4.71,2.4l-.24.27ZM4.5,0h5.91V-5.1H4.5ZM15.03,3.84h4.62L32.94-23.94H28.32ZM40.5.42c6.3,0,9.72-4.68,9.72-11.55v-.06c0-3.87-.99-6.27-2.52-7.77a8.227,8.227,0,0,0-6.45-2.46c-4.8,0-8.37,3-8.37,7.65v.06c0,4.11,2.97,6.63,7.05,6.63a6.337,6.337,0,0,0,4.44-1.71c-.27,2.7-1.56,4.38-3.96,4.38a6.888,6.888,0,0,1-4.5-1.68L33.18-2.07A11.328,11.328,0,0,0,40.5.42Zm1.02-11.67a2.747,2.747,0,0,1-3-2.76v-.06a2.726,2.726,0,0,1,2.94-2.82,2.8,2.8,0,0,1,3,2.85v.06A2.706,2.706,0,0,1,41.52-11.25ZM62.34.42c6,0,9.75-4.98,9.75-10.92v-.06c0-5.94-3.72-10.86-9.69-10.86s-9.75,4.98-9.75,10.92v.06C52.65-4.5,56.34.42,62.34.42Zm.06-5.16c-2.34,0-3.9-2.4-3.9-5.76v-.06c0-3.36,1.53-5.7,3.84-5.7s3.9,2.43,3.9,5.76v.06C66.24-7.11,64.74-4.74,62.4-4.74ZM84.21.42c6,0,9.75-4.98,9.75-10.92v-.06c0-5.94-3.72-10.86-9.69-10.86s-9.75,4.98-9.75,10.92v.06C74.52-4.5,78.21.42,84.21.42Zm.06-5.16c-2.34,0-3.9-2.4-3.9-5.76v-.06c0-3.36,1.53-5.7,3.84-5.7s3.9,2.43,3.9,5.76v.06C88.11-7.11,86.61-4.74,84.27-4.74Z'
								transform='translate(2016 1096)'
								fill='#3c3368'
							/>
							<path
								d='M11.052-10.044V-12.6H.612v2.556H4.446V0H7.218V-10.044Zm9.9,5.238v-.036a5.015,5.015,0,0,0-5.2-4.986,5.064,5.064,0,0,0-5.238,5.022v.036a5.015,5.015,0,0,0,5.2,4.986A5.064,5.064,0,0,0,20.952-4.806Zm-2.7.036a2.472,2.472,0,0,1-2.5,2.628,2.563,2.563,0,0,1-2.538-2.664v-.036a2.472,2.472,0,0,1,2.5-2.628,2.563,2.563,0,0,1,2.538,2.664ZM28.332-.4v-2.2a2.783,2.783,0,0,1-1.35.342c-.612,0-.882-.306-.882-.936V-7.308h2.268v-2.34H26.1v-2.466H23.364v2.466H22.212v2.34h1.152v4.572c0,2.232,1.134,2.9,2.808,2.9A4.034,4.034,0,0,0,28.332-.4ZM38.664,0V-5.6c0-2.61-1.314-4.158-4.3-4.158a8.512,8.512,0,0,0-3.744.774L31.3-6.894a7.1,7.1,0,0,1,2.664-.522c1.368,0,2.07.63,2.07,1.764v.162a6.856,6.856,0,0,0-2.358-.4c-2.286,0-3.888.972-3.888,3.078v.036C29.79-.864,31.284.18,33.1.18a3.71,3.71,0,0,0,2.916-1.224V0ZM36.072-3.4c0,.99-.864,1.692-2.142,1.692-.882,0-1.494-.432-1.494-1.17v-.036c0-.864.72-1.332,1.89-1.332a4.242,4.242,0,0,1,1.746.36ZM43.866,0V-13.14H41.13V0Z'
								transform='translate(2041 1061)'
								fill='#9296a1'
							/>
						</g>
					</svg>
					<br />
					<div className='t-a-c'>
						<p className='color-primary font-size-12'>
							Credit Score will appear once the Payment is
							complete.
						</p>
					</div>
				</div>
			</div>
		);
	};

	const checkIfCreditScoreRequired = async () => {
		setLoading(true);
		try {
			const resp = await fetch(
				API_URL + `/api/1.0.0/tenant/isCreditCheckRequired`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						"X-JWT-Assertion": localStorage.getItem("token"),
					},
				}
			);
			if (resp.ok) {
				const response = await resp.json();
				if (
					!response.data.isCreditScoreRequired &&
					response.data.creditScore
				) {
					navigate(`/my-credit-score`);
				} else {
					setShowPaymentButton(true);
				}
			} else {
				setShowPaymentButton(true);
			}
		} catch (e) {
			console.log("Error", e);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		checkIfCreditScoreRequired();
	}, []);

	useEffect(() => {
		setSegmentTitle("My Credit Score");
	}, []);

	const getMyUName = () => {
		const decodedToken = jwtDecode(localStorage.getItem("token"));
		const uN = decodedToken.userName;
		return uN ? uN : "";
	};

	const initiatePaymentFlow = async () => {
		setLoading(true);
		try {
			const response = await fetch(API_URL + "/api/1.0.0/transaction", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"X-JWT-Assertion": localStorage.getItem("token"),
				},
				body: JSON.stringify({
					amount: convertAEDToUSD(35.00) * 100, //10 USD
					currencyCode: "USD",
					invoiceNumber: "CC_RS_APP_" + Math.random(),
					comment1: "Check Credit Score Payment.",
					address1: "Some address",
					city: "Bangalore",
					country: "India",
					name: getMyUName(),
					state: "KA",
					zipCode: "560100",
					returnURL: `${BASE_URL}/my-credit-score/verify`,
				}),
				redirect: "follow",
			});
			if (response.ok) {
				const data = await response.json();

				if (data) {
					localStorage.setItem("transactionId", data.transactionId);
					window.location.href = data.redirectURL;
				} else {
					addToastMessage({
						status: false,
						message:
							"Something went wrong while initiating your payment request.",
					});
				}
			} else {
				const errorData = await response.json();
				const errorMessage = errorData.responseDescription;
				addToastMessage({
					status: false,
					message:
						errorMessage ||
						"Something went wrong while initiating a payment request. Please try again.",
				});

				setNewNotification(true);
				setShowNotification(true);
				addNotification({
					id: Date.now(),
					message: "Could not initiate a payment for credit check.",
					status: false,
					archive: false,
				});
			}
		} catch (e) {
			console.log("Error", e);
			setLoading(false);
		}
	};

	return (
		<>
			<section>
				<PageTitle title='Credit Check' />
				<FullPageLoader isActive={loading} />
				<section className='pad-20 create-loan-application'>
					<Row>
						<SectionTitle
							title='Credit Score'
							subtitle='Fetch credit info'
							icon={
								<CiCreditCard1
									className='color-primary'
									style={{
										height: "32px",
										width: "32px",
										strokeWidth: "1px",
									}}
								/>
							}
						/>
						<SectionForm>
							<tr>
								<td colSpan='2'>
									<div
										className='payment-info-box'
										style={{ border: "solid 1px #cfcfc0" }}>
										<div className='price bg-color-primary b-r-10 pad-20'>
											<h6 className='color-white'>
												Credit Score
											</h6>
											<h3 className='color-white'>
												35 AED
											</h3>
											<p className='color-white l-h-1-2'>
												Pre-requisite for application:
											</p>
										</div>
										<br />
										<ul className='benefits'>
											<li style={{ listStyle: "none" }}>
												<p className='color-light f-w-300'>
													<span>&#10003;</span>&nbsp;
													30 days validity
												</p>
											</li>
											<li style={{ listStyle: "none" }}>
												<p className='color-light f-w-300'>
													<span>&#10003;</span>&nbsp;
													One-time only charge*
												</p>
											</li>
											<li style={{ listStyle: "none" }}>
												<p className='color-light f-w-300'>
													<span>&#10003;</span>&nbsp;
													Fetch to get instant pre
													approvals
												</p>
											</li>
											<li style={{ listStyle: "none" }}>
												<p className='color-light f-w-300'>
													<span>&#10003;</span>&nbsp;
													Use across multiple rental
													applications*
												</p>
											</li>
											<li style={{ listStyle: "none" }}>
												<p className='color-light f-w-300'>
													<span>&#10003;</span>&nbsp;
													Valid across brokers
												</p>
											</li>
										</ul>
										<br />
										<div className='t-a-c'>
											<ButtonTextIcon
												className='b-r-20'
												style={{ width: "100%" }}
												type='button'
												title={
													showPaymentButton
														? "Fetch now"
														: "Loading.."
												}
												onClick={() => {
													initiatePaymentFlow();
												}}
												disabled={!showPaymentButton}
												icon={
													<>
														{!showPaymentButton && (
															<LoaderIcon />
														)}
													</>
												}
											/>
										</div>
										<p
											className='font-size-12 color-light'
											style={{ marginTop: "5px" }}>
											* Within validity
										</p>
									</div>
								</td>
							</tr>
						</SectionForm>
					</Row>
				</section>
			</section>
		</>
	);
};

export default MyCreditScore;
