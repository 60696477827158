import { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { CiCirclePlus } from "react-icons/ci";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import { API_URL } from "../../utilities/constants";
import FullPageLoader from "../../components/ui/FullPageLoader";
import Pagination from "../../components/common/Pagination";
import PageTitle from "../../components/common/PageTitle";
import ResultsPerPage from "../_common/ResultsPerPage";
import { useUI } from "../../context/ui";

const SaCreditProvidersList = () => {
  const location = useLocation();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { setSegmentTitle } = useUI();

  const callListApi = async () => {
    setLoading(true);
    const pageNo = new URLSearchParams(location.search).get("pageNo");
    const pageSize = new URLSearchParams(location.search).get("pageSize");
    try {
      const resp = await fetch(
        API_URL +
          `/api/1.0.0/credit-provider/firm?pageNo=${pageNo}&pageSize=${pageSize}&sortOrder=desc`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "X-JWT-Assertion": localStorage.getItem("token"),
          },
        }
      );
      if (resp.ok) {
        const response = await resp.json();
        setData(response.data);
      }
    } catch (e) {
      console.log("Error", e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (location.search) {
      callListApi();
      setSegmentTitle("Credit Providers");
    }
  }, [location.search]);

  return (
    <section className="pos-rel">
      <PageTitle title="Credit Providers" />
      <FullPageLoader isActive={loading} />
      <section>
        <div className="flex center-left" style={{ padding: "20px" }}>
          <ResultsPerPage
            targetURL={`/sa-credit-provider?pageNo=0&pageSize=`}
          />
          <div style={{ marginLeft: "auto" }}>
            <NavLink to="/sa-credit-provider/create">
              <ButtonTextIcon title="Add Credit Provider" icon={<CiCirclePlus />} />
            </NavLink>
          </div>
        </div>
        <div className="results">
          <div className="rs-table-holder">
            <table className="rs-table">
              <thead>
                <tr>
                  <th>Organization Name</th>
                  <th>Admin</th>
                  <th>Email</th>
                  <th>Landline</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              {data && (
                <tbody>
                  {data.firm.map((d, i) => (
                    <tr key={i}>
                      <td>
                        <div
                          className="flex center-left"
                          style={{ gap: "8px" }}
                        >
                          <div
                            className="b-r-100 of-hid default-image "
                            style={{
                              height: "32px",
                              width: "32px",
                              backgroundImage:
                              d.logo ? `url(${d.logo})` : null,
                            }}
                          ></div>
                          {d.name}
                        </div>
                      </td>
                      <td>{d.adminName}</td>
                      <td>{d.email}</td>
                      <td>{d.landline}</td>
                      <td>
                        <div
                          className={`status ${
                            d.statusId === 1 ? "active" : "inactive"
                          }`}
                        >
                          {d.statusId === 1 ? "Active" : "Inactive"}
                        </div>
                      </td>
                      <td>
                      <div
                          className="flex center-center"
                          style={{
                            gap: "12px",
                          }}
                        >
                          <NavLink
                            className="t-d-ul transition hover-color-secondary"
                            to={`/sa-credit-provider/${d.id}`}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="14"
                              viewBox="0 0 20 14"
                              style={{
                                height: "16px",
                                width: "16px",
                              }}
                            >
                              <path
                                d="M12,5C7.92,5,4.256,8.093,2.145,11.483a1,1,0,0,0,0,1.034C4.256,15.907,7.92,19,12,19c4.143,0,7.8-3.09,9.864-6.493a1,1,0,0,0,0-1.014C19.794,8.09,16.141,5,12,5Zm8.413,7c-1.837,2.878-4.9,5.5-8.413,5.5-3.465,0-6.532-2.632-8.4-5.5C5.465,9.132,8.533,6.5,12,6.5,15.516,6.5,18.577,9.124,20.411,12ZM12,8a4,4,0,1,1-4,4A4,4,0,0,1,12,8Zm0,1.5A2.5,2.5,0,1,0,14.5,12,2.5,2.5,0,0,0,12,9.5Z"
                                transform="translate(-2 -5)"
                              />
                            </svg>
                          </NavLink>
                          </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
              {((data && data?.firm?.lenght === 0 && !loading) || (!data && !loading)) && (
                <tbody><tr>
                <td colSpan={6} style={{textAlign:"center", padding:"10px"}}>
                   <p> No records found. </p>
                </td>
              </tr>
            </tbody>)}
            </table>
          </div>
        </div>
        <br />
        {data && (
          <Pagination
            totalRecords={data.totalRecords}
            pageNo={data.pageNo}
            pageSize={data.pageSize}
            baseUrl="/sa-credit-provider"
          />
        )}
      </section>
    </section>
  );
};

export default SaCreditProvidersList;
