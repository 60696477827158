import { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { NavLink, useNavigate } from "react-router-dom";
import { API_URL, DEFAULT_PER_PAGE } from "../../utilities/constants";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import ButtonTextOnly from "../../components/ui/ButtonTextOnly";
import FullPageLoader from "../../components/ui/FullPageLoader";
import PageTitle from "../../components/common/PageTitle";
import { useUI } from "../../context/ui";
import { CountryOptions, CustomSelect } from "../../utilities/helpers";

const CreateAgency = () => {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [step, setStep] = useState(1);
	const token = localStorage.getItem("token");
	const [allLocations, setAllLocations] = useState(null);
	const [allAgencySubscriptionTypes, setAllAgencySubscriptionTypes] =
		useState(null);
	const [regex, setRegex] = useState("");
	const {
		setNewNotification,
		setShowNotification,
		addNotification,
		setSegmentTitle,
		addToastMessage,
	} = useUI();

	const getInitialData = async () => {
		setLoading(true);
		try {
			const [resp1, resp2] = await Promise.all([
				fetch(API_URL + "/api/1.0.0/ref/locations", {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						"X-JWT-Assertion": token,
					},
				}),
				fetch(API_URL + "/api/1.0.0/ref/agencySubscriptionTypes", {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						"X-JWT-Assertion": token,
					},
				}),
			]);
			const response1 = await resp1.json();
			setAllLocations(response1.data);

			const response2 = await resp2.json();
			setAllAgencySubscriptionTypes(response2.data);
		} catch (e) {
			console.log("Error", e);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getInitialData();
		setSegmentTitle("Create Agency");
	}, []);

	const StepOneSchema = Yup.object().shape({
		agencyname: Yup.string().required("Agency name is required!"),
		firstname: Yup.string()
			.matches(
				/^[A-Za-z\s-]+$/,
				"Admin's first name should contain only letters!"
			)
			.required("Admin's first name is required!"),
		lastname: Yup.string()
			.matches(
				/^[A-Za-z\s-]+$/,
				"Admin's last name should contain only letters!"
			)
			.required("Admin's last name is required!"),
		email: Yup.string()
			.strict()
			.email("Invalid email format")
			.required("Email is required!")
			.matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/, "The email must have at least 2 characters after the last dot"),
		phoneCode: Yup.string().required("Phone Code is required"),
		phone: Yup.string()
			.matches(
				/^5\d{8}$/,
				"The number should start from 5 followed by 8 digits"
			)
			.required("Phone number is required!")
			.min(9, "The number should start from 5 followed by 8 digits")
			.max(9, "The number should start from 5 followed by 8 digits"),
		landline: Yup.string()
			// .matches(new RegExp(regex), "Must be 9 digits long starting from 4")
			.matches(new RegExp('^04\\d{7}$'), "Must be 9 digits long starting from 04")
			.required("Landline number is required!")
			.min(9, "Landline must be 9 digits long!")
			.max(9, "Landline must be 9 digits long!"),
		emirates: Yup.string().required("Emirates is required"),
		city: Yup.string().required("Area is required"),
		websiteUrl: Yup.string().matches(
			/((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
			"Enter correct url!"
		),
	});

	const today = new Date();
	today.setHours(0, 0, 0, 0);

	const StepTwoSchema = Yup.object().shape({
		startDate: Yup.date()
			.required("Start Date is required")
			.min(today, "Start Date cannot be in the past"),
		endDate: Yup.date()
			.required("End date is required")
			.min(
				Yup.ref("startDate"),
				"End Date must be later than Start Date"
			),
		incentive: Yup.string()
			.matches(/^\d+(\.\d+)?$/, "Only digits or decimal values allowed.")
			.required("Incentive is required"),
		subscriptionFrequency: Yup.string().required(
			"Subscription Frequency is required"
		),
		subscriptionAmount: Yup.string()
			.matches(/^[0-9]*$/, "Only numbers allowed!")
			.required("Subscription Amount is required"),
	});

	const handleNext = async (validateForm) => {
		const errors = await validateForm();
		if (Object.keys(errors).length === 0) {
			setStep((prevStep) => prevStep + 1);
		}
	};

	const handlePrevious = () => {
		setStep((prevStep) => prevStep - 1);
	};

	const initial = {
		agencyname: "",
		firstname: "",
		lastname: "",
		email: "",
		phoneCode: "+971",
		phone: "",
		landline: "",
		websiteUrl: "",
		emirates: "",
		city: "",
		startDate: "",
		endDate: "",
		incentive: "",
		subscriptionFrequency: "",
		subscriptionAmount: "",
	};

	const handleCreateAgency = async (value) => {
		setLoading(true);
		try {
			const request = {
				agencyName: value.agencyname,
				location: value.city,
				websiteURL: value.websiteUrl,
				firstName: value.firstname,
				lastName: value.lastname,
				email: value.email,
				countryCode: value.phoneCode,
				phoneNo: value.phone,
				landline: value.landline,
				commercialConfigDTO: {
					startDate: value.startDate,
					endDate: value.endDate,
					incentive: value.incentive,
					subscriptionFrequency: value.subscriptionFrequency,
					subscriptionAmount: value.subscriptionAmount,
				},
			};

			const resp = await fetch(API_URL + "/api/1.0.0/brokerAgency", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"X-JWT-Assertion": token,
				},
				body: JSON.stringify(request),
			});

			if (resp.ok) {
				addToastMessage({
					status: true,
					message: "Agency has been created successfully.",
				});
				setNewNotification(true);
				addNotification({
					id: Date.now(),
					message: "You have successfully created an agency!",
					status: true,
					archive: false,
				});
				navigate(`/sa-agency?pageNo=0&pageSize=${DEFAULT_PER_PAGE}`);
			} else {
				const errorData = await resp.json();
				const errorMessage = errorData.responseDescription;
				addToastMessage({
					status: false,
					message:
						errorMessage ||
						"Something went wrong while creating an agency. Please try again.",
				});
				setNewNotification(true);
				addNotification({
					id: Date.now(),
					message:
						"Something went wrong while creating a agency. Please try again",
					status: false,
					archive: false,
				});
			}
		} catch (errorCode) {
			setNewNotification(true);
			setShowNotification(true);
			addNotification({
				id: Date.now(),
				message: "Something went wrong when creating an agency!",
				status: false,
				archive: false,
			});
		} finally {
			setLoading(false);
		}
	};

	const getCities = (cityId) => {
		const e = allLocations.find((obj) => obj.code === cityId);
		return e ? e.subLocations : null;
	};

	const getRegexForCity = (selectedEmirate, selectedCity) => {
		const emirate = allLocations.find(
			(obj) => obj.code === selectedEmirate
		);
		const city = emirate.subLocations.find((o) => o.name === selectedCity);
		return city.regularExp;
	};

	return (
		<section>
			<PageTitle title='Create Agency' />
			<FullPageLoader isActive={loading} />
			<section className='create-agency'>
				{step === 1 && (
					<>
						<h5 className='color-primary'>Agency Details</h5>
						<p>
							Please note that details cannot be edited after
							saving
						</p>
						<br />
					</>
				)}
				{step === 2 && (
					<>
						<h5 className='color-primary'>Commercial Details</h5>
						<p>Enter the commercial terms</p>
						<br />
					</>
				)}
				<section className='table-wrapper'>
					<Formik
						initialValues={initial}
						validationSchema={
							step === 1 ? StepOneSchema : StepTwoSchema
						}
						onSubmit={async (values, { validateForm }) => {
							if (step === 1) {
								await handleNext(validateForm);
							} else {
								handleCreateAgency(values);
							}
						}}>
						{({
							values,
							errors,
							touched,
							validateForm,
							setFieldValue,
							resetForm,
							handleSubmit,
						}) => (
							<Form>
								<table>
									<thead></thead>
									<tbody>
										{step === 1 && (
											<>
												<tr>
													<td colSpan='3' width='50%'>
														<p className='title f-w-700 color-primary'>
															Agency Name
														</p>
														<Field
															name='agencyname'
															type='text'
															placeholder='Agency Name *'
															className={
																"col-1-1 " +
																(errors.agencyname &&
																	touched.agencyname
																	? " error"
																	: "")
															}
														/>
														<ErrorMessage
															name='agencyname'
															component='p'
															className='error-messages'
														/>
													</td>
													<td colSpan='3'></td>
												</tr>
												<tr>
													<td colSpan='3'>
														<p className='title f-w-700 color-primary'>
															First Name
														</p>
														<Field
															name='firstname'
															type='text'
															placeholder='Eg: John *'
															className={
																"col-1-1 " +
																(errors.firstname &&
																	touched.firstname
																	? " error"
																	: "")
															}
														/>
														<ErrorMessage
															name='firstname'
															component='p'
															className='error-messages'
														/>
													</td>
													<td colSpan='3'>
														<p className='title f-w-700 color-primary'>
															Last Name
														</p>
														<Field
															name='lastname'
															type='text'
															placeholder='Eg: Smith *'
															className={
																"col-1-1 " +
																(errors.lastname &&
																	touched.lastname
																	? " error"
																	: "")
															}
														/>
														<ErrorMessage
															name='lastname'
															component='p'
															className='error-messages'
														/>
													</td>
												</tr>
												<tr>
													<td colSpan='2'>
														<p className='title f-w-700 color-primary'>
															Phone
														</p>
														<Field name='phoneCode'>
															{({ field }) => (
																<CustomSelect
																	{...field}
																	options={CountryOptions}
																	error={errors.phoneCode && touched.phoneCode}
																	onChange={(e) => setFieldValue('phoneCode', e.target.value)}
																/>
															)}
														</Field>
													</td>
													<td colSpan='4'>
														<p className='title f-w-700 color-primary'>
															&nbsp;
														</p>
														<Field
															name='phone'
															type='text'
															maxLength='9'
															placeholder='Eg 500000000 *'
															className={
																"col-1-1 " +
																(errors.phone &&
																	touched.phone
																	? " error"
																	: "")
															}
														/>
														<ErrorMessage
															name='phone'
															component='p'
															className='error-messages'
														/>
													</td>
													<td colSpan='2'></td>
												</tr>
												<tr>
													<td colSpan='3'>
														<p className='title f-w-700 color-primary'>
															Email
														</p>
														<Field
															name='email'
															type='text'
															placeholder='Email *'
															className={
																"col-1-1 " +
																(errors.email &&
																	touched.email
																	? " error"
																	: "")
															}
														/>
														<ErrorMessage
															name='email'
															component='p'
															className='error-messages'
														/>
													</td>
													<td colSpan='2'></td>
												</tr>

												<tr>
													<td colSpan='4'>
														<p className='title f-w-700 color-primary'>
															Emirates
														</p>
														<Field
															as='select'
															name='emirates'
															className={
																"col-1-1 " +
																(errors.emirates &&
																	touched.emirates
																	? " error"
																	: "")
															}>
															<option value=''>
																Select Emirates
															</option>
															{allLocations && (
																<>
																	{allLocations.map(
																		(
																			a,
																			i
																		) => (
																			<option
																				value={
																					a.code
																				}
																				key={
																					i
																				}
																				disabled={
																					a.code !==
																						"L0002"
																						? "disabled"
																						: null
																				}>
																				{
																					a.name
																				}
																			</option>
																		)
																	)}
																</>
															)}
														</Field>
														<ErrorMessage
															name='emirates'
															component='p'
															className='error-messages'
														/>
													</td>
													<td colSpan='2'></td>
												</tr>
												{values.emirates && (
													<tr>
														<td colSpan='4'>
															<p className='title f-w-700 color-primary'>
																Area
															</p>
															<Field
																as='select'
																name='city'
																className={
																	"col-1-1 " +
																	(errors.city &&
																		touched.city
																		? " error"
																		: "")
																}
																onChange={(
																	e
																) => {
																	setFieldValue(
																		"city",
																		e.target
																			.value
																	);
																	const regex =
																		getRegexForCity(
																			values.emirates,
																			e
																				.target
																				.value
																		);
																	setRegex(
																		regex
																	);
																}}>
																<option value=''>
																	Select Area
																</option>
																{getCities(
																	values.emirates
																).map(
																	(c, j) => (
																		<option
																			key={
																				j
																			}
																			value={
																				c.name
																			}>
																			{
																				c.name
																			}
																		</option>
																	)
																)}
															</Field>
															<ErrorMessage
																name='city'
																component='p'
																className='error-messages'
															/>
														</td>
														<td colSpan='2'></td>
													</tr>
												)}
												<tr>
													<td colSpan='4'>
														<p className='title f-w-700 color-primary'>
															Landline
														</p>
														<Field
															name='landline'
															type='text'
															placeholder='Eg: 04********'
															maxLength='9'
															className={
																"col-1-1 " +
																(errors.landline &&
																	touched.landline
																	? " error"
																	: "")
															}
														/>
														<ErrorMessage
															name='landline'
															component='p'
															className='error-messages'
														/>
													</td>
													<td colSpan='2'></td>
												</tr>
												<tr>
													<td colSpan='4'>
														<p className='title f-w-700 color-primary'>
															Website Url
															(Optional)
														</p>
														<Field
															name='websiteUrl'
															type='text'
															placeholder='Eg: https://website.com'
															className={
																"col-1-1 " +
																(errors.websiteUrl &&
																	touched.websiteUrl
																	? " error"
																	: "")
															}
														/>
													</td>
													<td colSpan='2'></td>
												</tr>
												<tr>
													<td
														colSpan='6'
														className='btns t-a-r'>
														<NavLink
															to={`/sa-agency?pageNo=0&pageSize=${DEFAULT_PER_PAGE}`}>
															<ButtonLightTextIcon
																type='button'
																title='Cancel'
															/>
														</NavLink>
														<ButtonTextOnly
															type='submit'
															onClick={() =>
																handleNext(
																	validateForm
																)
															}
															title='Next'
														/>
													</td>
												</tr>
											</>
										)}
										{step === 2 && (
											<>
												<tr>
													<td colSpan='3'>
														<p className='title f-w-700 color-primary'>
															Start Date
														</p>
														<Field
															name='startDate'
															type='date'
															placeholder='Select Date'
															className={
																" " +
																(errors.startDate &&
																	touched.startDate
																	? " error"
																	: "")
															}
														/>
														<ErrorMessage
															name='startDate'
															component='p'
															className='error-messages'
														/>
													</td>
													<td colSpan='3'></td>
												</tr>
												<tr>
													<td colSpan='3'>
														<p className='title f-w-700 color-primary'>
															Valid Till Date
														</p>
														<Field
															name='endDate'
															type='date'
															placeholder='Select Date'
															className={
																" " +
																(errors.endDate &&
																	touched.endDate
																	? " error"
																	: "")
															}
														/>
														<ErrorMessage
															name='endDate'
															component='p'
															className='error-messages'
														/>
													</td>
													<td colSpan='3'></td>
												</tr>
												<tr>
													<td colSpan='3'>
														<p className='title f-w-700 color-primary'>
															Incentive %{" "}
														</p>
														<Field
															name='incentive'
															type='text'
															placeholder='Incentive % [Eg: 5] *'
															className={
																" " +
																(errors.incentive &&
																	touched.incentive
																	? " error"
																	: "")
															}
														/>
														<ErrorMessage
															name='incentive'
															component='p'
															className='error-messages'
														/>
													</td>
													<td colSpan='3'></td>
												</tr>
												<tr>
													<td colSpan='3'>
														<p className='title f-w-700 color-primary'>
															Subscription
															Frequency
														</p>
														<Field
															as='select'
															name='subscriptionFrequency'
															className={
																"col-1-1 " +
																(errors.subscriptionFrequency &&
																	touched.subscriptionFrequency
																	? " error"
																	: "")
															}>
															<option value=''>
																Select
															</option>
															{allAgencySubscriptionTypes.map(
																(t, i) => (
																	<option
																		key={i}
																		value={
																			t.name
																		}>
																		{t.name}
																	</option>
																)
															)}
														</Field>
														<ErrorMessage
															name='subscriptionFrequency'
															component='p'
															className='error-messages'
														/>
													</td>
													<td colSpan='3'></td>
												</tr>
												<tr>
													<td colSpan='3'>
														<p className='title f-w-700 color-primary'>
															Subscription Amount
															in AED
														</p>
														<Field
															name='subscriptionAmount'
															type='text'
															placeholder='Amount in AED [Eg: 20000] *'
															className={
																" " +
																(errors.subscriptionAmount &&
																	touched.subscriptionAmount
																	? " error"
																	: "")
															}
														// onChange={(e) => {
														// 	const rawValue = e.target.value.replace(/,/g, "");
														// 	const formattedValue = new Intl.NumberFormat().format(rawValue);
														// 	setFieldValue("subscriptionAmount", formattedValue);
														// }}
														/>
														<ErrorMessage
															name='subscriptionAmount'
															component='p'
															className='error-messages'
														/>
													</td>
													<td colSpan='3'></td>
												</tr>
												<tr>
													<td
														colSpan='6'
														className='btns t-a-r'>
														<ButtonLightTextIcon
															onClick={
																handlePrevious
															}
															type='button'
															title='Go Back'
														/>
														<ButtonTextOnly
															type='submit'
															title='Create'
														/>
													</td>
												</tr>
											</>
										)}
									</tbody>
								</table>
							</Form>
						)}
					</Formik>
				</section>
			</section>
		</section>
	);
};

export default CreateAgency;
