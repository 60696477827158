import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { NavLink, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import PageTitle from "../../components/common/PageTitle";
import LoaderIcon from "../../components/ui/LoaderIcon";
import {
	API_URL,
	APP_VERSION,
	RS_TAGLINE,
	SYSTEM_TOKEN,
} from "../../utilities/constants";
import RentSavvyLogo from "../../components/common/RentSavvyLogo";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import ButtonTextOnly from "../../components/ui/ButtonTextOnly";
import { countries } from "../../utilities/countries";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import { useUI } from "../../context/ui";
import { CustomSelect, CountryOptions } from "../../utilities/helpers";

const TenantRegister = () => {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState("");
	const { addToastMessage } = useUI();

	const FormValidation = Yup.object().shape({
		email: Yup.string()
			.strict()
			.email("Invalid email format")
			.required("Email is required!")
			.matches(
				/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/,
				"The email must have at least 2 characters after the last dot"
			),
		firstName: Yup.string()
			.matches(
				/^[A-Za-z\s-]+$/,
				"First name should contain only letters!"
			)
			.required("First name is required!"),
		lastName: Yup.string()
			.matches(/^[A-Za-z\s-]+$/, "Last name should contain only letters!")
			.required("Last name is required"),
		countryCode: Yup.string().required("Required"),
		phoneNo: Yup.string()
			.matches(
				/^5\d{8}$/,
				"The number should start from 5 followed by 8 digits"
			)
			.required("Phone number is required!")
			.min(9, "The number should start from 5 followed by 8 digits")
			.max(9, "The number should start from 5 followed by 8 digits"),
	});

	const initial = {
		firstName: "",
		lastName: "",
		email: "",
		countryCode: "+971",
		phoneNo: "",
	};

	const handleSubmit = async (values) => {
		setStatus("");
		setLoading(true);
		try {
			const request = {
				firstName: values.firstName,
				lastName: values.lastName,
				email: values.email,
				countryCode: values.countryCode,
				phoneNo: values.phoneNo,
			};
			const resp = await fetch(API_URL + `/api/1.0.0/tenant`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"X-JWT-Assertion": SYSTEM_TOKEN,
				},
				body: JSON.stringify(request),
			});
			if (resp.ok) {
				const response = await resp.json();
				if (response) {
					if (response.result.responseCode === 200) {
						setStatus(
							"Registration successful! Please wait a moment and check your email for the link to reset your password."
						);
						navigate("/thank-you");
					} else {
						setStatus("something went wrong please try again.");
					}
				}
			} else {
				const errorData = await resp.json();
				const errorMessage = errorData.responseDescription;
				addToastMessage({
					status: false,
					message:
						errorMessage ||
						"Something went wrong while registration. Please try again",
				});
			}
		} catch (e) {
			setStatus("something went wrong please try again");
			console.log("Error", e);
		} finally {
			setLoading(false);
		}
	};

	const FieldTitle = ({ title }) => {
		return (
			<p
				className='title f-w-700 color-primary'
				style={{ marginBottom: "5px", fontSize: "14px" }}>
				{title}
			</p>
		);
	};

	return (
		<section id='tenant-auth-page'>
			<PageTitle title='Register' />
			<section className='flex row col-1-1'>
				<section className='col flex center-left left'>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='90.396'
						height='82.351'
						viewBox='0 0 90.396 82.351'
						className='svg-top'>
						<g transform='translate(-288.14 -169.729)'>
							<path
								d='M276.138,252.079V334.43h57.775s18.088-.5,24.874-10.241,12.908-27.337,0-41.4-31.156-30.71-31.156-30.71Z'
								transform='translate(654.483 504.159) rotate(180)'
								fill='#5dc6b3'
							/>
							<path
								d='M327.624,252.079V190.464l25.5,21.287V252.08Z'
								fill='#1e4ccb'
							/>
							<path
								d='M327.624,190.464l25.4-19.87,25.512,19.87L353.027,211.9Z'
								fill='#3b86b5'
							/>
						</g>
					</svg>
					<div className='box'>
						<NavLink to='/login'>
							<div className='logo'>
								<RentSavvyLogo />
							</div>
						</NavLink>
						<div className='wc'>
							<h4 className='color-primary'>Welcome User!</h4>
							<p className='color-light f-w-300'>
								Already have an account!{" "}
								<span className='color-primary f-w-700'>
									<NavLink to='/login'>
										Account login.
									</NavLink>
								</span>
							</p>
						</div>
						<div className='form'>
							<Formik
								initialValues={initial}
								validationSchema={FormValidation}
								onSubmit={async (values, { resetForm }) => {
									handleSubmit(values);
								}}>
								{({
									values,
									errors,
									touched,
									setFieldValue,
									resetForm,
								}) => (
									<Form>
										<table>
											<thead></thead>
											<tbody>
												<tr>
													<td colSpan='2'>
														<p
															className='title f-w-600 color-primary'
															style={{
																fontSize:
																	"14px",
															}}>
															First Name
														</p>
														<Field
															name='firstName'
															type='text'
															placeholder='Eg: John *'
															className={
																"col-1-1 " +
																(errors.firstName &&
																touched.firstName
																	? " error"
																	: "")
															}
														/>
														<ErrorMessage
															name='firstName'
															component='p'
															className='error-messages'
														/>
													</td>
												</tr>
												<tr>
													<td colSpan='2'>
														<p
															className='title f-w-600 color-primary'
															style={{
																paddingTop:
																	"10px",
																fontSize:
																	"14px",
															}}>
															Last Name
														</p>
														<Field
															name='lastName'
															type='text'
															placeholder='Eg: Smith *'
															className={
																"col-1-1 " +
																(errors.lastName &&
																touched.lastName
																	? " error"
																	: "")
															}
														/>
														<ErrorMessage
															name='lastName'
															component='p'
															className='error-messages'
														/>
													</td>
												</tr>
												<tr>
													<td colSpan='2'>
														<p
															className='title f-w-600 color-primary'
															style={{
																paddingTop:
																	"10px",
																fontSize:
																	"14px",
															}}>
															Email
														</p>
														<Field
															name='email'
															type='email'
															placeholder='Eg: john.smith@gmail.com'
															className={
																" " +
																(errors.email &&
																touched.email
																	? " error"
																	: "")
															}
														/>
														<ErrorMessage
															name='email'
															component='p'
															className='error-messages'
														/>
													</td>
												</tr>
												<tr>
													<td>
														<p
															className='title f-w-600 color-primary'
															style={{
																paddingTop:
																	"10px",
																fontSize:
																	"14px",
															}}>
															Phone
														</p>
														<Field name='countryCode'>
															{({ field }) => (
																<CustomSelect
																	{...field}
																	options={
																		CountryOptions
																	}
																	error={
																		errors.countryCode &&
																		touched.countryCode
																	}
																	onChange={(
																		e
																	) =>
																		setFieldValue(
																			"countryCode",
																			e
																				.target
																				.value
																		)
																	}
																/>
															)}
														</Field>
													</td>
													<td>
														<FieldTitle title='&nbsp;' />
														<Field
															name='phoneNo'
															type='text'
															placeholder='Eg 500000000 *'
															maxLength={9}
															className={
																"col-1-1 " +
																(errors.phoneNo &&
																touched.phoneNo
																	? " error"
																	: "")
															}
														/>
														<ErrorMessage
															name='phoneNo'
															component='p'
															className='error-messages'
														/>
													</td>
												</tr>
												<tr>
													<td colSpan='2'>
														{loading ? (
															<ButtonTextIcon
																type='button'
																title='Registering...'
																disabled={
																	loading
																}
																icon={
																	<LoaderIcon />
																}
															/>
														) : (
															<ButtonTextOnly
																type='submit'
																title='Register now'
															/>
														)}
													</td>
												</tr>
											</tbody>
										</table>
										{status && (
											<p className='status color-secondary f-w-300 l-h-1'>
												{status}
											</p>
										)}
									</Form>
								)}
							</Formik>
						</div>
					</div>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='90.205'
						height='82.351'
						viewBox='0 0 90.205 82.351'
						className='svg-bottom'>
						<g transform='translate(-276.138 -252.079)'>
							<path
								d='M276.138,252.079V334.43h57.775s18.088-.5,24.874-10.241,12.908-27.337,0-41.4-31.156-30.71-31.156-30.71Z'
								fill='#5dc6b3'
							/>
							<path
								d='M290.953,334.43l43.512-.032a56.454,56.454,0,0,0,8.178-1.2,45.36,45.36,0,0,0,5.6-1.582v-32.64L320.021,269.53l-29.069,29.446Z'
								fill='#3b86b5'
							/>
							<path
								d='M276.138,298.387V269.279l44.181.244-28.911,28.864Z'
								fill='#1e4ccb'
							/>
						</g>
					</svg>
				</section>
				<section
					className='col flex center-center default-image right'
					style={{
						backgroundImage: `url(/images/login/bg.jpg)`,
					}}>
					<div className='box'>
						<div className='img'>
							<img
								className='top-left'
								src='/images/login/img-1.jpg'
							/>
							<img className='main' src='/images/login/img.jpg' />
							<img
								className='bottom-right'
								src='/images/login/img-2.jpg'
							/>
						</div>
						<div className='texts'>
							<h2 className='color-white'>
								Introducing Rent-Savvy
							</h2>
							<p className='color-white'>{RS_TAGLINE}</p>
							<p
								className='color-white f-w-300'
								style={{
									fontSize: "9px",
									marginTop: "10px",
								}}>
								Version: v{APP_VERSION}
							</p>
						</div>
					</div>
				</section>
			</section>
		</section>
	);
};

export default TenantRegister;
