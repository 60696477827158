import { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { NavLink, useNavigate } from "react-router-dom";
import SearchBar from "../../components/ui/SearchBar";
import { API_URL, DEFAULT_PER_PAGE } from "../../utilities/constants";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import ButtonTextOnly from "../../components/ui/ButtonTextOnly";
import FullPageLoader from "../../components/ui/FullPageLoader";
import PageTitle from "../../components/common/PageTitle";
import { useUI } from "../../context/ui";
import { CustomSelect, CountryOptions } from "../../utilities/helpers";


const AddCreditProvider = () => {
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const token = localStorage.getItem("token");
	const {
		setNewNotification,
		setShowNotification,
		addNotification,
		setSegmentTitle,
		addToastMessage,
	} = useUI();

	const ValidationSchema = Yup.object().shape({
		firstName: Yup.string()
			.matches(/^[A-Za-z\s-]+$/, "First name should contain only letters!")
			.required("First name is required!"),
		lastName: Yup.string()
			.matches(/^[A-Za-z\s-]+$/, "Last name should contain only letters!")
			.required("Last name is required!"),
		address: Yup.string().required("Address is required!"),
		email: Yup.string()
			.strict()
			.email("Invalid email format")
			.required("Email is required!")
			.matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/, "The email must have at least 2 characters after the last dot"),
		countryCode: Yup.string().required("Phone code is required"),
		phoneNo: Yup.string()
			.matches(
				/^5\d{8}$/,
				"The number should start from 5 followed by 8 digits"
			)
			.required("Phone number is required!")
			.min(9, "The number should start from 5 followed by 8 digits")
			.max(9, "The number should start from 5 followed by 8 digits"),
	});

	const initial = {
		firstName: "",
		lastName: "",
		address: "",
		email: "",
		countryCode: "+971",
		phoneNo: "",
	};

	const handleAddBroker = async (value) => {
		setLoading(true);
		try {
			const request = {
				// creditProviderId: 4,
				firstName: value.firstName,
				lastName: value.lastName,
				address: value.address,
				email: value.email,
				countryCode: value.countryCode,
				phoneNo: value.phoneNo,
			};

			const resp = await fetch(
				API_URL + "/api/1.0.0/credit-provider/user",
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						"X-JWT-Assertion": token,
					},
					body: JSON.stringify(request),
				}
			);

			if (resp.ok) {
				addToastMessage({
					status: true,
					message:
						"You have successfully created a broker, thank you.",
				});
				setNewNotification(true);
				setShowNotification(true);
				addNotification({
					id: Date.now(),
					message: "You have successfully created a broker!",
					status: true,
					archive: false,
				});
				navigate(
					`/cpsa-credit-provider?pageNo=0&pageSize=${DEFAULT_PER_PAGE}`
				);
			} else {
				const errorData = await resp.json();
				const errorMessage = errorData.responseDescription;
				addToastMessage({
					status: false,
					message:
						errorMessage ||
						"Something went wrong while creating a broker. Please try again.",
				});
			}
		} catch (e) {
			setNewNotification(true);
			setShowNotification(true);
			addNotification({
				id: Date.now(),
				message: "Something went wrong while creating a broker.",
				status: false,
				archive: false,
			});
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		setSegmentTitle("Add User");
	}, []);

	return (
		<section>
			<PageTitle title='Add User' />
			<FullPageLoader isActive={loading} />
			<section className='create-agency'>
				<h5 className='color-primary'>User Details</h5>
				<p>Enter the User details below:</p>
				<br />
				<section className='table-wrapper'>
					<Formik
						initialValues={initial}
						validationSchema={ValidationSchema}
						onSubmit={async (values, { validateForm }) => {
							await handleAddBroker(values);
						}}>
						{({
							values,
							errors,
							touched,
							validateForm,
							setFieldValue,
							resetForm,
							handleSubmit,
						}) => (
							<Form>
								<table>
									<thead></thead>
									<tbody>
										<tr>
											<td colSpan='3'>
												<p className='title f-w-700 color-primary'>
													First Name
												</p>
												<Field
													name='firstName'
													type='text'
													placeholder='Eg: John'
													className={
														"col-1-1 " +
														(errors.firstName &&
															touched.firstName
															? " error"
															: "")
													}
												/>
												<ErrorMessage
													name='firstName'
													component='p'
													className='error-messages'
												/>
											</td>
											<td colSpan='3'>
												<p className='title f-w-700 color-primary'>
													Last Name
												</p>
												<Field
													name='lastName'
													type='text'
													placeholder='Eg: Smith'
													className={
														"col-1-1 " +
														(errors.lastName &&
															touched.lastName
															? " error"
															: "")
													}
												/>
												<ErrorMessage
													name='lastName'
													component='p'
													className='error-messages'
												/>
											</td>
										</tr>
										<tr>
											<td colSpan='6'>
												<p className='title f-w-700 color-primary'>
													Address
												</p>
												<Field
													name='address'
													type='text'
													placeholder='Eg: 12 Street, City, Country'
													className={
														"col-1-1 " +
														(errors.address &&
															touched.address
															? " error"
															: "")
													}
												/>
												<ErrorMessage
													name='address'
													component='p'
													className='error-messages'
												/>
											</td>
										</tr>
										<tr>
											<td colSpan='4'>
												<p className='title f-w-700 color-primary'>
													Email
												</p>
												<Field
													name='email'
													type='email'
													placeholder='Eg: john.smith@gmail.com'
													className={
														"col-1-1 " +
														(errors.email &&
															touched.email
															? " error"
															: "")
													}
												/>
												<ErrorMessage
													name='email'
													component='p'
													className='error-messages'
												/>
											</td>
											<td colSpan='2'></td>
										</tr>
										<tr>
											<td colSpan='1'>
												<p className='title f-w-700 color-primary'>
													Phone
												</p>
												<Field name='countryCode'>
															{({ field }) => (
																<CustomSelect
																	{...field}
																	options={CountryOptions}
																	error={errors.countryCode && touched.countryCode}
																	onChange={(e) => setFieldValue('countryCode', e.target.value)}
																/>
															)}
														</Field>
											</td>
											<td colSpan='3'>
												<p className='title f-w-700 color-primary'>
													&nbsp;
												</p>
												<Field
													name='phoneNo'
													type='text'
													placeholder='Eg: 581234567 *'
													maxLength='9'
													className={
														"col-1-1 " +
														(errors.phoneNo &&
															touched.phoneNo
															? " error"
															: "")
													}
												/>
												<ErrorMessage
													name='phoneNo'
													component='p'
													className='error-messages'
												/>
											</td>
											<td colSpan='2'></td>
										</tr>
										{/* <tr>
											<td colSpan='4'>
												<p className='title f-w-700 color-primary'>
													Role Id
												</p>
												<Field
													as='select'
													name='roleId'
													className={
														"col-1-1 " +
														(errors.roleId &&
															touched.roleId
															? " error"
															: "")
													}>
													<option value='9'>
														Reviewer
													</option>
													<option value='7'>
														Approver
													</option>
												</Field>
												<ErrorMessage
													name='roleId'
													component='p'
													className='error-messages'
												/>
											</td>
											<td colSpan='2'></td>
										</tr> */}
										<tr>
											<td
												colSpan='6'
												className='btns t-a-r'>
												<NavLink
													to={`/cpsa-credit-provider?pageNo=0&pageSize=${DEFAULT_PER_PAGE}`}>
													<ButtonLightTextIcon
														type='button'
														title='Cancel'
													/>
												</NavLink>
												<ButtonTextOnly
													type='submit'
													title='Create Now'
												/>
											</td>
										</tr>
									</tbody>
								</table>
							</Form>
						)}
					</Formik>
				</section>
			</section>
		</section>
	);
};
export default AddCreditProvider;
