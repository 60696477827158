import { useEffect, useState } from "react";
import { useUI } from "../../context/ui";
import PageTitle from "../../components/common/PageTitle";
import FullPageLoader from "../../components/ui/FullPageLoader";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import {
  API_URL,
  LOAN_APPLICATION_STATUSES,
} from "../../utilities/constants";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import {  CiSearch } from "react-icons/ci";
import ButtonTextOnly from "../../components/ui/ButtonTextOnly";
import {
  CiBatteryCharging,
  CiBookmark,
  CiCalendar,
  CiCircleCheck,
  CiCircleQuestion,
  CiHome,
  CiLocationOn,
  CiMail,
  CiMoneyCheck1,
  CiPhone,
  CiRollingSuitcase,
  CiSquareCheck,
  CiTablets1,
  CiViewTimeline,
} from "react-icons/ci";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import { formatServerDate } from "../../utilities/helpers";
import Pagination from "../../components/common/Pagination";
import ResultsPerPage from "../_common/ResultsPerPage";
import ResultsStatusFilter from "../_common/ResultsStatusFilter";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { formatAmountValue } from "../../utilities/helpers"

const TenantMyApplications = () => {
  const { setSegmentTitle } = useUI();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  const callListApi = async () => {
    setLoading(true);
    const pageNo = new URLSearchParams(location.search).get("pageNo");
    const pageSize = new URLSearchParams(location.search).get("pageSize");
    const fromDate = new URLSearchParams(location.search).get("fromDate");
    const toDate = new URLSearchParams(location.search).get("toDate");
    const statusID = new URLSearchParams(location.search).get("statusID");

    try {
      let url =
        API_URL +
        `/api/1.0.0/loan-application/my-applications?${
          statusID ? `statusID=${statusID}&` : ""
        }pageNo=${pageNo}&pageSize=${pageSize}&sortOrder=desc`;

      if (fromDate && toDate) {
        url += "&fromDate=" + fromDate + "&toDate=" + toDate;
      }

      const resp = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-JWT-Assertion": localStorage.getItem("token"),
        },
      });
      if (resp.ok) {
        const response = await resp.json();
        setData(response.data);
      }
    } catch (e) {
      console.log("Error", e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (location.search) {
      callListApi();
      setSegmentTitle("My Rental Applications");
    }
  }, [location.search]);

  const getStatusDetails = (id) => {
    const o = LOAN_APPLICATION_STATUSES.find((obj) => obj.id === id);
    return o
      ? o
      : {
          title: "Not found",
          color: "#5dc6b3",
        };
  };

  const getPerPageValue = () => {
    const pageSize = new URLSearchParams(location.search).get("pageSize");
    return pageSize;
  };

  const getStatusIDValue = () => {
    const statusID = new URLSearchParams(location.search).get("statusID");
    return statusID;
  };

  const initialValues = {
    fromDate: "",
    toDate: "",
  };

  const FormValidation = Yup.object().shape({
    fromDate: Yup.date().required("End date is required"),
    toDate: Yup.date()
      .required("End date is required")
      .min(Yup.ref("fromDate"), "End Date must be later than Start Date"),
  });

  return (
    <section>
      <PageTitle title="My Rental Applications" />
      <FullPageLoader isActive={loading} />
      <section>
        <div
          className="flex center-left"
          style={{ padding: "20px", gap: "12px" }}
          //   style={{ padding: "20px 20px 0 20px" }}
        >
          <ResultsPerPage
            targetURL={`/te-my-applications?pageNo=0&${
              getStatusIDValue() ? `statusID=${getStatusIDValue()}` : ""
            }&pageSize=`}
          />
          <ResultsStatusFilter
            targetURL={`/te-my-applications?pageNo=0&pageSize=${getPerPageValue()}&statusID=`}
            statusFilterArr={[6,8,11,12,13,25]}
          />
          <div>
            <Formik
              initialValues={initialValues}
              validationSchema={FormValidation}
              onSubmit={async (values, { resetForm }) => {
                const pageNo = new URLSearchParams(location.search).get(
                  "pageNo"
                );
                const pageSize = new URLSearchParams(location.search).get(
                  "pageSize"
                );
                const statusID = new URLSearchParams(location.search).get(
                  "statusID"
                );
                navigate(
                  `/te-my-applications?${statusID ? `statusID=${statusID}&` : ""}pageNo=${pageNo}&pageSize=${pageSize}&fromDate=${values.fromDate}&toDate=${values.toDate}`
                );
              }}
            >
              {({ values, errors, touched, setFieldValue, resetForm }) => (
                <Form>
                  <div className="flex center-center" style={{ gap: "12px" }}>
                    <Field
                      name="fromDate"
                      type="date"
                      className={
                        " " +
                        (errors.fromDate && touched.fromDate ? " error" : "")
                      }
                    />
                    <Field
                      name="toDate"
                      type="date"
                      className={
                        " " + (errors.toDate && touched.toDate ? " error" : "")
                      }
                    />
                    <ButtonTextIcon type="submit" icon={<CiSearch />} />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        {data && (
          <section className="tenant-applications">
            {data.loanApplications.map((d, i) => (
              <div className="single" key={i}>
                <table className="col-1-1">
                  <thead></thead>
                  <tbody>
                    <tr>
                      <td colSpan="2">
                        <p
                          className="color-primary f-w-700 of-hid"
                          style={{
                            width: "180px",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <span>#</span>
                          &nbsp;
                          {d.applicationNo}
                        </p>
                        <h5 className="color-secondary">
                        {`${formatAmountValue(d.rentalValue)}/- AED`}
                          {/* {`${parseFloat(d.rentalValue).toFixed(2)}/- AED`} */}
                        </h5>
                      </td>
                      <td colSpan="2" className="t-a-r">
                        <div className="flex center-right">
                          <div
                            className="b-r-20"
                            style={{
                              background: getStatusDetails(d.applicationStatus)
                                .color,
                              padding: "12px 24px",
                            }}
                          >
                            <p className="color-white l-h-1">
                              {getStatusDetails(d.applicationStatus).title}
                            </p>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="4">
                        <hr />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2" width="50%">
                        <div className="flex center-left">
                          <div className="flex center-left">
                            <CiBookmark className="color-faded" />
                            &nbsp;
                            <p className="font-size-14 color-faded">
                              Agreement Type
                            </p>
                            &nbsp;
                            <p className="font-size-14 color-primary">
                              {d.agreementType}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td colSpan="2">
                        <div className="flex center-left">
                          <div className="flex center-left">
                            <CiBookmark className="color-faded" />
                            &nbsp;
                            <p className="font-size-14 color-faded">
                              Rental value
                            </p>
                            &nbsp;
                            <p className="font-size-14 color-primary">
                            {`${formatAmountValue(d.rentalValue)}/- AED`}
                               {/* {d.rentalValue}/- AED */}
                            </p>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="4">
                        <br />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="4">
                        <p className="f-w-600 color-primary">Property:</p>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="4">
                        <hr />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="flex center-left">
                          <div className="flex center-left">
                            <CiBookmark className="color-faded" />
                            &nbsp;
                            <p className="font-size-14 color-faded">Name</p>
                            &nbsp;
                            <p className="font-size-14 color-primary">
                              {d.property.propertyName}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="flex center-left">
                          <div className="flex center-left">
                            <CiTablets1 className="color-faded" />
                            &nbsp;
                            <p className="font-size-14 color-faded">RERA</p>
                            &nbsp;
                            <p className="font-size-14 color-primary">
                              {d.property.reraNumber}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="flex center-left">
                          <div className="flex center-left">
                            <CiHome className="color-faded" />
                            &nbsp;
                            <p className="font-size-14 color-faded">Type</p>
                            &nbsp;
                            <p className="font-size-14 color-primary">
                              {d.property.propertyType}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="flex center-left">
                          <div className="flex center-left">
                            <CiViewTimeline className="color-faded" />
                            &nbsp;
                            <p className="font-size-14 color-faded">Size</p>
                            &nbsp;
                            <p className="font-size-14 color-primary">
                              {d.property.propertySize}
                              sq.ft.
                            </p>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <div className="flex center-left">
                          <div className="flex center-left">
                            <CiLocationOn className="color-faded" />
                            &nbsp;
                            <p className="font-size-14 color-faded">Address</p>
                            &nbsp;
                            <p className="font-size-14 color-primary">
                              {d.property.propertyAddress}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td colSpan="2">
                        <div className="flex center-left">
                          <div className="flex center-left">
                            <CiCircleQuestion className="color-faded" />
                            &nbsp;
                            <p className="font-size-14 color-faded">
                              Description
                            </p>
                            &nbsp;
                            <p className="font-size-14 color-primary">
                              {d.property.description}
                            </p>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="4">
                        <br />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="4">
                        <p className="f-w-600 color-primary">Owner:</p>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="4">
                        <hr />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="flex center-left">
                          <div className="flex center-left">
                            <CiCircleCheck className="color-faded" />
                            &nbsp;
                            <p className="font-size-14 color-faded">Name</p>
                            &nbsp;
                            <p className="font-size-14 color-primary">
                              {d.property.owner.fullName}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="flex center-left">
                          <div className="flex center-left">
                            <CiMail className="color-faded" />
                            &nbsp;
                            <p className="font-size-14 color-faded">Email</p>
                            &nbsp;
                            <p className="font-size-14 color-primary">
                              {d.property.owner.email}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="flex center-left">
                          <div className="flex center-left">
                            <CiPhone className="color-faded" />
                            &nbsp;
                            <p className="font-size-14 color-faded">Phone</p>
                            &nbsp;
                            <p className="font-size-14 color-primary">
                              {`(+971) ${d.property.owner.phoneNumber}`}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="flex center-left">
                          <div className="flex center-left">
                            <CiLocationOn className="color-faded" />
                            &nbsp;
                            <p className="font-size-14 color-faded">Address</p>
                            &nbsp;
                            <p className="font-size-14 color-primary">
                              {d.property.owner.address}
                            </p>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="4">
                        <br />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="4">
                        <p className="f-w-600 color-primary">My Info:</p>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="4">
                        <hr />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="flex center-left">
                          <div className="flex center-left">
                            <CiCalendar className="color-faded" />
                            &nbsp;
                            <p className="font-size-14 color-faded">D.o.B</p>
                            &nbsp;
                            <p className="font-size-14 color-primary">
                              {d.dateOfBirth
                                ? formatServerDate(d.dateOfBirth)
                                : "-"}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="flex center-left">
                          <div className="flex center-left">
                            <CiBatteryCharging className="color-faded" />
                            &nbsp;
                            <p className="font-size-14 color-faded">
                              Marital Status
                            </p>
                            &nbsp;
                            <p className="font-size-14 color-primary">
                              {d.maritalStatus === null ? (
                                <>
                                  <span>-</span>
                                </>
                              ) : (
                                <>{d.maritalStatus ? "Married" : "Unmarried"}</>
                              )}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="flex center-left">
                          <div className="flex center-left">
                            <CiCircleCheck className="color-faded" />
                            &nbsp;
                            <p className="font-size-14 color-faded">
                              Citizenship Status
                            </p>
                            &nbsp;
                            <p className="font-size-14 color-primary">
                              {d.citizenshipStatus ? d.citizenshipStatus : "-"}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="flex center-left">
                          <div className="flex center-left">
                            <CiSquareCheck className="color-faded" />
                            &nbsp;
                            <p className="font-size-14 color-faded">
                              Emirates ID
                            </p>
                            &nbsp;
                            <p className="font-size-14 color-primary">
                              {d.uaePass ? d.uaePass : "-"}
                            </p>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                    <td>
                        <div className="flex center-left">
                          <div className="flex center-left">
                            <CiRollingSuitcase className="color-faded" />
                            &nbsp;
                            <p className="font-size-14 color-faded">
                              Employment Type
                            </p>
                            &nbsp;
                            <p className="font-size-14 color-primary">
                              {d.employmentType ? d.employmentType : "-"}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="flex center-left">
                          <div className="flex center-left">
                            <CiMoneyCheck1 className="color-faded" />
                            &nbsp;
                            <p className="font-size-14 color-faded">
                             {d.employmentType === "Self Employed" ? "Annual Revenue" : "Annual Income"} 
                            </p>
                            &nbsp;
                            <p className="font-size-14 color-primary">
                              {d.annualIncome ?`${formatAmountValue(d.annualIncome)}/- AED`
                             : "-"}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="flex center-left">
                          <div className="flex center-left">
                            <CiHome className="color-faded" />
                            &nbsp;
                            <p className="font-size-14 color-faded">
                            {d.employmentType === "Self Employed" ? 'Business Type':'Company Type'}
                            </p>
                            &nbsp;
                            <p className="font-size-14 color-primary">
                              {d.companyType ? d.companyType : "-"}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="flex center-left">
                          <div className="flex center-left">
                            <CiSquareCheck className="color-faded" />
                            &nbsp;
                            <p className="font-size-14 color-faded">
                            {d.employmentType === "Self Employed" ? 'Business Name':'Company Name'} 
                            </p>
                            &nbsp;
                            <p className="font-size-14 color-primary">
                              {d.companyName ? d.companyName : "-"}
                            </p>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="4">
                        <br />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="4">
                        <p className="f-w-600 color-primary">Broker:</p>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="4">
                        <hr />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <div className="flex center-left">
                          <div className="flex center-left">
                            <CiCircleCheck className="color-faded" />
                            &nbsp;
                            <p className="font-size-14 color-faded">Name</p>
                            &nbsp;
                            <p className="font-size-14 color-primary">
                              {d.agencyBrokerDTO.firstName}{" "}
                              {d.agencyBrokerDTO.lastName}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td colSpan="2"></td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <div className="flex center-left">
                          <div className="flex center-left">
                            <CiMail className="color-faded" />
                            &nbsp;
                            <p className="font-size-14 color-faded">Email</p>
                            &nbsp;
                            <p className="font-size-14 color-primary">
                              {d.agencyBrokerDTO.email}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td colSpan="2">
                        <div className="flex center-left">
                          <div className="flex center-left">
                            <CiPhone className="color-faded" />
                            &nbsp;
                            <p className="font-size-14 color-faded">Phone</p>
                            &nbsp;
                            <p className="font-size-14 color-primary">
                              {`(+971) ${d.agencyBrokerDTO.phoneNo}`}
                            </p>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="4">
                        <br />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="8" className="t-a-r">
                        <NavLink to={`/te-my-applications/${d.id}/update`}>
                          <ButtonLightTextIcon title="Update Application" />
                        </NavLink>
                        &nbsp;&nbsp;
                        <NavLink to={`/te-my-applications/${d.id}`}>
                          <ButtonTextOnly title="View Application" />
                        </NavLink>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ))}
            <br />
            {data && (
              <Pagination
                totalRecords={data.totalRecords}
                pageNo={data.pageNo}
                pageSize={data.pageSize}
                baseUrl="/te-my-applications"
              />
            )}
          </section>
        )}
        {((data &&  data?.loanApplications?.length === 0 && !loading) || (!data && !loading)) && (
                <section className="tenant-applications">
                  <div className="single" >
                        <p className="f-w-600 color-primary t-a-c">No application found.</p>
                  </div>
                </section>
        )}
      </section>
    </section>
  );
};

export default TenantMyApplications;
