import { useEffect, useState } from "react";
import FullPageLoader from "../../components/ui/FullPageLoader";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import {  CiSearch } from "react-icons/ci";
import { API_URL, LOAN_APPLICATION_STATUSES } from "../../utilities/constants";
import Pagination from "../../components/common/Pagination";
import PageTitle from "../../components/common/PageTitle";
import {
	formatServerDate,
} from "../../utilities/helpers";
import ResultsPerPage from "../_common/ResultsPerPage";
import { useUI } from "../../context/ui";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import ResultsStatusFilter from "../_common/ResultsStatusFilter";
import { formatAmountValue } from "../../utilities/helpers"

const CreditProviderLoanApplicationList = () => {
	const [loading, setLoading] = useState(false);
	const location = useLocation();
	const [data, setData] = useState(null);
	const { setSegmentTitle } = useUI();
	const navigate = useNavigate();

	const callListApi = async () => {
		setLoading(true);
		const pageNo = new URLSearchParams(location.search).get("pageNo");
		const pageSize = new URLSearchParams(location.search).get("pageSize");
		const fromDate = new URLSearchParams(location.search).get("fromDate");
		const toDate = new URLSearchParams(location.search).get("toDate");
		const statusID = new URLSearchParams(location.search).get("statusID");
		try {
			let url =
				API_URL +
				`/api/1.0.0/loan-application?${statusID?`statusID=${statusID}`:""}&pageNo=${pageNo}&pageSize=${pageSize}&sortOrder=desc`;
			if (fromDate && toDate) {
				url += "&fromDate=" + fromDate + "&toDate=" + toDate;
			}
			const resp = await fetch(url, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					"X-JWT-Assertion": localStorage.getItem("token"),
				},
			});
			if (resp.ok) {
				const response = await resp.json();
				setData(response.data);
			}
		} catch (e) {
			console.log("Error", e);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (location.search) {
			callListApi();
			setSegmentTitle("Rental Applications");
		}
	}, [location.search]);

	const getStatusDetails = (id) => {
		const o = LOAN_APPLICATION_STATUSES.find((obj) => obj.id === id);
		return o
			? o
			: {
					title: "Not found",
					color: "#5dc6b3",
			  };
	};

	const initialValues = {
		fromDate: "",
		toDate: "",
	};

	const FormValidation = Yup.object().shape({
		fromDate: Yup.date().required("End date is required"),
		toDate: Yup.date()
			.required("End date is required")
			.min(Yup.ref("fromDate"), "End Date must be later than Start Date"),
	});

	const getPerPageValue = () => {
		const pageSize = new URLSearchParams(location.search).get("pageSize");
		return pageSize;
	};

	const getStatusIDValue = () => {
		const statusID = new URLSearchParams(location.search).get("statusID");
		return statusID;
	};


	return (
		<section className='pos-rel'>
			<PageTitle title='Rental Applications' />
			<FullPageLoader isActive={loading} />
			<section>
				<div
					className='flex center-left'
					style={{ padding: "20px", gap: "12px" }}>
					<ResultsPerPage
						targetURL={`/cpsa-loan-application-list?pageNo=0&${getStatusIDValue()?`statusID=${getStatusIDValue()}`:""}&pageSize=`}
					/>
					<ResultsStatusFilter targetURL={`/cpsa-loan-application-list?pageNo=0&pageSize=${getPerPageValue()}&statusID=`} statusFilterArr={[6,11,12,13,25]} />
					<div>
						<Formik
							initialValues={initialValues}
							validationSchema={FormValidation}
							onSubmit={async (values, { resetForm }) => {
								const pageNo = new URLSearchParams(
									location.search
								).get("pageNo");
								const pageSize = new URLSearchParams(
									location.search
								).get("pageSize");
								const statusID = new URLSearchParams(
									location.search
								).get("statusID");
								navigate(
									`/cpsa-loan-application-list?${statusID ? `statusID=${statusID}&` : ""}pageNo=${pageNo}&pageSize=${pageSize}&fromDate=${values.fromDate}&toDate=${values.toDate}`
								);
							}}>
							{({
								values,
								errors,
								touched,
								setFieldValue,
								resetForm,
							}) => (
								<Form>
									<div
										className='flex center-center'
										style={{ gap: "12px" }}>
										<Field
											name='fromDate'
											type='date'
											className={
												" " +
												(errors.fromDate &&
												touched.fromDate
													? " error"
													: "")
											}
										/>
										<Field
											name='toDate'
											type='date'
											className={
												" " +
												(errors.toDate && touched.toDate
													? " error"
													: "")
											}
										/>
										<ButtonTextIcon
											type='submit'
											icon={<CiSearch />}
										/>
									</div>
								</Form>
							)}
						</Formik>
					</div>
				</div>
				<div className='results'>
					<div className='rs-table-holder'>
						<table className='rs-table'>
							<thead>
								<tr>
									<th>Customer name</th>
									<th>Email</th>
									<th>Phone</th>
									<th>Loan Amount</th>
									<th>Status</th>
									<th>Date</th>
									<th>Action</th>
								</tr>
							</thead>
							{data && (
								<tbody>
									{data.loanApplications.map((d, i) => (
										<tr key={i}>
											<td>
												{d.tenant.firstName}{" "}
												{d.tenant.lastName}
											</td>
											<td>{d.tenant.email}</td>
											<td>{`(${d.tenant.countryCode}) ${d.tenant.phoneNo}`}</td>
											<td>
												{`${formatAmountValue(d.rentalValue)}/- AED`}
											</td>
											<td>
												<div
													className='status active'
													style={{
														background:
															getStatusDetails(
																d.applicationStatus
															).color,
													}}>
													{
														getStatusDetails(
															d.applicationStatus
														).title
													}
												</div>
											</td>
											<td>
												{formatServerDate(d.createdAt)}
											</td>
											<td>
												<div
													className='flex center-center'
													style={{
														gap: "12px",
													}}>
													<NavLink
														className='t-d-ul transition hover-color-secondary'
														to={`/cpsa-loan-applications/${d.id}`}>
														<svg
															xmlns='http://www.w3.org/2000/svg'
															width='20'
															height='14'
															viewBox='0 0 20 14'
															style={{
																height: "16px",
																width: "16px",
															}}>
															<path
																d='M12,5C7.92,5,4.256,8.093,2.145,11.483a1,1,0,0,0,0,1.034C4.256,15.907,7.92,19,12,19c4.143,0,7.8-3.09,9.864-6.493a1,1,0,0,0,0-1.014C19.794,8.09,16.141,5,12,5Zm8.413,7c-1.837,2.878-4.9,5.5-8.413,5.5-3.465,0-6.532-2.632-8.4-5.5C5.465,9.132,8.533,6.5,12,6.5,15.516,6.5,18.577,9.124,20.411,12ZM12,8a4,4,0,1,1-4,4A4,4,0,0,1,12,8Zm0,1.5A2.5,2.5,0,1,0,14.5,12,2.5,2.5,0,0,0,12,9.5Z'
																transform='translate(-2 -5)'
															/>
														</svg>
													</NavLink>
												</div>
											</td>
										</tr>
									))}
								</tbody>
							)}
							{((data && data?.loanApplications?.length === 0 && !loading) || (!data && !loading)) && (
								<tbody>
									<tr>
										<td colSpan={7} style={{textAlign:"center", padding:"10px"}}>
											<p> No records found. </p>
										</td>
                                    </tr>
			                    </tbody>
							)}
						</table>
					</div>
				</div>
				<br />
				{data && (
					<Pagination
						totalRecords={data.totalRecords}
						pageNo={data.pageNo}
						pageSize={data.pageSize}
						baseUrl='/cpsa-loan-application-list'
					/>
				)}
			</section>
		</section>
	);
};

export default CreditProviderLoanApplicationList;
