import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { BASE_URL } from "./utilities/constants";

if (
	BASE_URL === "https://tenant.rent-savvy.com" ||
	BASE_URL === "https://app.rent-savvy.com"
) {
	Sentry.init({
		dsn: "https://fd0ae6d5cca40d6b062f135add2134d2@o4507768827084800.ingest.us.sentry.io/4507768828854272",
		integrations: [
			Sentry.browserTracingIntegration(),
			Sentry.replayIntegration({
				maskAllText: false,
				blockAllMedia: false,
			}),
		],
		tracesSampleRate: 1.0,
		tracePropagationTargets: [
			/^https:\/\/app.rent-savvy\.com/,
			/^https:\/\/tenant.rent-savvy\.com/,
		],
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
	});
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
