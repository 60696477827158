import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import { CiLogin } from "react-icons/ci";
import { NavLink } from "react-router-dom";
import PageTitle from "../../components/common/PageTitle";

const NotFound = () => {
	return (
		<>
			<PageTitle title='Error 404' />
			<section id='not-found'>
				<div
					style={{
						height: "100vh",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						textAlign: "center",
					}}>
					<div>
						<h4 className='color-primary'>Error 404.</h4>
						<h1 className='color-primary'>Page not found</h1>
						<br />
						<p>
							The page you are looking for has vanished or does
							not exist.
						</p>
						<p>Login to your account instead.</p>
						<br />
						<br />
						<NavLink to='/login'>
							<ButtonTextIcon
								title='Go to Login'
								icon={<CiLogin />}
							/>
						</NavLink>
					</div>
				</div>
			</section>
		</>
	);
};

export default NotFound;
