import { useEffect, useState } from "react";
import PageTitle from "../../components/common/PageTitle";
import FullPageLoader from "../../components/ui/FullPageLoader";
import { useLocation, useNavigate } from "react-router-dom";
import { API_URL } from "../../utilities/constants";
import { useParams } from "react-router-dom";
import { useUI } from "../../context/ui";

const MyCreditScoreVerify = () => { 
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();
	const {
		setNewNotification,
		setShowNotification,
		addNotification,
		setSegmentTitle,
	} = useUI();

	const location = useLocation();

	const verifyTransaction = async () => {
		try {
			const resp = await fetch(
				API_URL +
					"/api/1.0.0/transaction/" +
					localStorage.getItem("transactionId") +
					"/check",
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						"X-JWT-Assertion": localStorage.getItem("token"),
					},
				}
			);
			if (resp.ok) {
				const response = await resp.json();
				if (response.result.responseCode == 0) {
					try {
						const resp2 = await fetch(
							API_URL + "/api/1.0.0/tenant/credit-score",
							{
								method: "PUT",
								headers: {
									"Content-Type": "application/json",
									"X-JWT-Assertion":
										localStorage.getItem("token"),
								},
							}
						);
						if (resp2.ok) {
							const response2 = await resp2.json();
							if (response2.result.responseCode == 200) {
								localStorage.removeItem("transactionId");
								navigate(`/my-credit-score`); 
							} else {
								setNewNotification(true);
								setShowNotification(true);
								addNotification({
									id: Date.now(),
									message:
										"You have to re-check your latest credit score again.",
									status: false,
									archive: false,
								});
								navigate(`/credit-score`);
							}
						}
					} catch (e) {
						console.log("Error", e);
						navigate(`/credit-score`);
					}
				} else {
					setNewNotification(true);
					setShowNotification(true);
					addNotification({
						id: Date.now(),
						message:
							"You have to re check your latest credit score again.",
						status: false,
						archive: false,
					});
					navigate(`/credit-score`);
				}
			}
		} catch (e) {
			console.log("Error", e);
			navigate(`/credit-score`);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		const params = new URLSearchParams(location.search);
		const queryObj = {};
		params.forEach((value, key) => {
			queryObj[key] = value;
		});
		if (
			queryObj.result &&
			queryObj.result === "Success" &&
			localStorage.getItem("transactionId")
		) {
			verifyTransaction();
		} else {
			setNewNotification(true);
			setShowNotification(true);
			localStorage.removeItem("transactionId");
			addNotification({
				id: Date.now(),
				message:
					"Your payment failed while trying to check credit score.",
				status: false,
				archive: false,
			});
			navigate(`/my-credit-score/payment-failure`);
			setLoading(false);
		}
	}, []);

	useEffect(() => {
		setSegmentTitle("Verifying...");
	}, []);

	return (
		<section>
			<PageTitle title='Verifying...' />
			<FullPageLoader isActive={loading} />
		</section>
	);
};

export default MyCreditScoreVerify;
