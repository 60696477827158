import Carousel from "react-multi-carousel";
import { Suspense } from "react";

const ImageSliders = ({ images, showArrows }) => {
	const responsive = {
		superLargeDesktop: {
			breakpoint: { max: 4000, min: 3000 },
			items: 1
		},
		desktop: {
			breakpoint: { max: 3000, min: 1497 },
			items: 1,
			slidesToSlide: 1,
		},
		semitablet: {
			breakpoint: { max: 1497, min: 1081 },
			items: 1,
			slidesToSlide: 1,
		},
		tablet: {
			breakpoint: { max: 1081, min: 721 },
			items: 1,
			slidesToSlide: 1,
		},
		mobile: {
			breakpoint: { max: 760, min: 0 },
			items: 1,
			slidesToSlide: 1,
		},
		mobileSmall: {
			breakpoint: { max: 480, min: 0 },
			items: 1,
			slidesToSlide: 1,
		},
	};

	const ButtonGroup = ({ next, previous, ...rest }) => {
		let rightClassName = "right-nav";
		let leftClassName = "left-nav";
		// if (!carouselConfig.showArrow) {
		rightClassName = "disable";
		leftClassName = "disable";
		// }
		const {
			carouselState: { currentSlide, slidesToShow, totalItems },
		} = rest;
		return (
			<div className='carousel-button-group'>
				<div
					onClick={() => previous()}
					className={currentSlide === 0 ? "disable" : leftClassName}
				/>
				<div
					onClick={() => next()}
					className={
						currentSlide + slidesToShow >= totalItems
							? "disable"
							: rightClassName
					}
				/>
			</div>
		);
	};

	return (
		<Suspense fallback={<></>}>
			<div className='sliders'>
				{images.length > 0 && (
					<Carousel
						swipeable
						draggable={false}
						arrows={showArrows}
						renderButtonGroupOutside={true}
						customButtonGroup={<ButtonGroup />}
						showDots={true}
						responsive={responsive}
						renderDotsOutside
						ssr={true}
						infinite={true}
						removeArrowOnDeviceType={[]}
						autoPlay={false}
						autoPlaySpeed={4000}
						partialVisible={false}>
						{images.map((d) => (
							<div
								key={d}
								className='_single default-image'
								style={{
									backgroundImage: `url(${d})`,
								}}>
								<img src={d} />
							</div>
						))}
					</Carousel>
				)}
			</div>
		</Suspense>
	);
};

export default ImageSliders;
