import {
	CiBarcode,
	CiCirclePlus,
	CiCoinInsert,
	CiCoins1,
	CiMoneyCheck1,
} from "react-icons/ci";
import PageTitle from "../../components/common/PageTitle";
import { useEffect, useState } from "react";
import { useUI } from "../../context/ui";
import FullPageLoader from "../../components/ui/FullPageLoader";
import { API_URL } from "../../utilities/constants";
import { useOutsideClick } from "../../utilities/hooks";
import { formatAmountValue,formatDateTime } from "../../../src/utilities/helpers";

const TenantDashboard = () => {
	const { setSegmentTitle } = useUI();
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [selectedLoanDates, setSelectedLoanDates] = useState([]);

	const callApi = async () => {
		setLoading(true);
		try {
			const resp = await fetch(API_URL + `/api/1.0.1/loan/loan-cards`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'X-JWT-Assertion': localStorage.getItem("token"),
				},
			});
			if (resp.ok) {
				const response = await resp.json();
				setData(response.data);
			}
		} catch (e) {
			console.log("Error", e);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		callApi();
		setSegmentTitle("Dashboard");
	}, []);

	const handleDateClick = (dates) => {
		setSelectedLoanDates(dates);
		setShowModal(true);
	};

	const closeModal = () => {
		setShowModal(false);
		setSelectedLoanDates([]);
	};

	return (
		<>
			<PageTitle title='Dashboard' />
			<FullPageLoader isActive={loading} />
			<section>
				<section className='dashboard'>


					{data?.loanCards.map((loan) => (
						<div className="quick-links" key={loan.id} style={{ gridTemplateColumns: "repeat(2, 1fr)" }}>
							<div className="one flex center-left">
								<div className="flex center-left" style={{ gap: "10px" }}>
									<CiCirclePlus className="color-secondary" style={{ height: "60px", width: "auto" }} />
									<div>
										<h5 className="color-primary f-w-700 l-h-1-2">
											{loan.loanAccountNo ? loan.loanAccountNo : "-"}
										</h5>
										<p className="color-primary f-w-400 l-h-1-2">Loan Account Number</p>
									</div>
								</div>
							</div>

							<div className="one flex center-left">
								<div className="flex center-left" style={{ gap: "10px" }}>
									<CiMoneyCheck1 className="color-secondary" style={{ height: "60px", width: "auto" }} />
									<div>
										<div className="flex center-right" style={{ columnGap: "55px" }}>
											<h5 className="color-primary f-w-700 l-h-1-2">
												{loan.installmentAmount ? 
												`${formatAmountValue(loan.installmentAmount)}/- AED`
												: 0}
											</h5>
											<h6
												className="color-primary f-w-400 l-h-1-2"
												onClick={() => handleDateClick(loan.installmentDatesList)}
												style={{ cursor: 'pointer' }}
											>
												{loan.installmentDatesList && loan.installmentDatesList.length > 0 ? (
													<div className=' t-d-ul t-a-c color-secondary transition hover-color-primary'>
														Date: {
														loan.installmentDatesList[0].installmentDate
														// formatDateTime(loan.installmentDatesList[0].installmentDate)
														}
													</div>
												) : (
													"-"
												)}
											</h6>
										</div>
										<p className="color-primary f-w-400 l-h-1-2">Next Installment</p>
									</div>
								</div>
							</div>
						</div>
					))}

					<div className='quick-links' style={{ gridTemplateColumns: "repeat(2, 1fr)" }}>
						<div className='one flex center-left'>
							<div className='flex center-left' style={{ gap: "10px" }}>
								<CiCoinInsert className='color-secondary' style={{ height: "60px", width: "auto" }} />
								<div>
									<h5 className='color-primary f-w-700 l-h-1-2'>
										{data?.loyaltyPoints ? data?.loyaltyPoints : "-"}
									</h5>
									<p className='color-primary f-w-400 l-h-1-2'>Loyalty Points</p>
								</div>
							</div>
						</div>
					</div>

					{showModal && (
						<Modal onClose={closeModal} isOpen={showModal}>
							<h4 className="color-primary">Installment Dates</h4>
							{selectedLoanDates && selectedLoanDates.length > 0 ? (
								selectedLoanDates.map((date, index) => (
									<div key={index} style={{ marginTop: "5px" }}
									>
										Date: {date.installmentDate ? date.installmentDate : "-"}
									</div>
								))
							) : (
								<p>No Dates Available</p>
							)}
						</Modal>
					)}
				</section>
			</section>
		</>
	);
};

const Modal = ({ children, onClose, isOpen }) => {
	const [node] = useOutsideClick({ state: isOpen, callBack: onClose });

	return (
		<div className="modal-overlay">
			<div className="modal-content" ref={node}>
				{children}
			</div>
		</div>
	);
};

export default TenantDashboard;

