import { useOutsideClick } from "../../utilities/hooks";

const { useState, useEffect, cloneElement } = require("react");
const { default: PopUpModal } = require("../common/PopUpModal");

const RSModal = ({ selector, content }) => {
	const [show, setShow] = useState(false);
	const contentWithProps = cloneElement(content, { setShow });

	const [box] = useOutsideClick({
		state: true,
		callBack: () => {
			setShow(false);
		},
	});

	useEffect(() => {
		const body = document.getElementsByTagName("body")[0];
		if (show) {
			body.style.overflow = "hidden";
		} else {
			body.style.overflow = "auto";
		}
	}, [show]);

	return (
		<>
			<div onClick={() => setShow(true)}>{selector}</div>
			{show && (
				<PopUpModal>
					<section id='rs-modal'>
						<div className='inner'>
							<div className='box' ref={box}>
								{contentWithProps}
							</div>
						</div>
					</section>
				</PopUpModal>
			)}
		</>
	);
};

export default RSModal;
