import React, { useContext, createContext } from "react";
import { createPortal } from "react-dom";

const LayerContext = createContext(0);

const LayerWrapper = ({ children }) => (
	<LayerContext.Consumer>
		{(zIndex) => (
			<LayerContext.Provider value={zIndex + 999999}>
				{children}
			</LayerContext.Provider>
		)}
	</LayerContext.Consumer>
);

const Popover = ({ children, style }) => {
	const zIndex = useContext(LayerContext);
	return (
		<LayerWrapper
			style={{
				zIndex,
				...style,
			}}>
			{children}
		</LayerWrapper>
	);
};

const Portal = ({ children }) => {
	const zIndex = useContext(LayerContext);
	return createPortal(
		<div
			style={{
				height: "100vh",
				width: "100vw",
				position: "fixed",
				inset: 0,
				zIndex,
			}}>
			{children}
		</div>,
		document.body
	);
};

const PopUpModal = (props) => {
	return (
		<LayerWrapper>
			<Popover>
				<Portal>{props.children}</Portal>
			</Popover>
		</LayerWrapper>
	);
};

export default PopUpModal;
