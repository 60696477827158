import PageTitle from "../../components/common/PageTitle";
import FullPageLoader from "../../components/ui/FullPageLoader";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import RentSavvyLogo from "../../components/common/RentSavvyLogo";
import { ErrorMessage, Field, Form, Formik } from "formik";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import ButtonTextOnly from "../../components/ui/ButtonTextOnly";
import LoaderIcon from "../../components/ui/LoaderIcon";
import { countries } from "../../utilities/countries";
import { CiCircleCheck } from "react-icons/ci";
import { API_URL } from "../../utilities/constants";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { getDefaultURLForRole } from "../../services/roles";
import { useFetch } from "../../hooks/useFetch";
import { useUI } from "../../context/ui";
import { CountryOptions, CustomSelect } from "../../utilities/helpers";


const TenantPending = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(null);
  const [step, setStep] = useState(1);
  const [workemailVerified, setworkemailVerified] = useState(false);
  const [workemailVerifing, setworkemailVerifing] = useState(false);
  const [status, setStatus] = useState("");
  const { addToastMessage } = useUI();
  const {
    data,
    error,
    loading: profileLoading,
  } = useFetch(API_URL + "/api/1.0.0/rs/profile", {
    method: "GET",
  });

  const handleWorkEmailVerify = async (val) => {
    setworkemailVerifing(true);
    try {
      const resp = await fetch(API_URL + `/api/1.0.0/verify/brn/${val}`, {
        headers: {
          "Content-Type": "application/json",
          "X-JWT-Assertion": localStorage.getItem("token"),
        },
      });
      if (resp.ok) {
        const response = await resp.json();
        if (response.result.responseCode === 200) {
          setworkemailVerified(true);
          setStatus("");
        } else {
          setStatus("Work Email is incorrect!");
        }
      } else {
        setStatus("Invalid WorkEmail!");
      }
    } catch {
      setStatus("Verify WorkEmail API failed!");
    } finally {
      setworkemailVerifing(false);
    }
  };

  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    countryCode: "+971",
    phoneNo: "",
    dateOfBirth: "",
    maritalStatus: "",
    citizenshipStatus: "",
    uaePass: "",
    annualIncome: "",
    employmentType: "",
    companyType: "",
    companyName: "",
    title: "",
    workEmail: "",
    companyAddress: "",
  });

  useEffect(() => {
    if (data?.data) {
      setInitialValues({
        countryCode: "+971",
        firstName: data.data.firstName ? data.data.firstName : "",
        lastName: data.data.lastName ? data.data.lastName : "",
        phoneNo: data.data.phoneNo ? data.data.phoneNo : "",
        dateOfBirth: data.data?.additionalDetails?.dateOfBirth
          ? data.data?.additionalDetails?.dateOfBirth
          : "",
        maritalStatus: data.data?.additionalDetails?.maritalStatus
          ? data.data?.additionalDetails?.maritalStatus
          : false,
        citizenshipStatus: data.data?.additionalDetails?.citizenshipStatus
          ? data.data?.additionalDetails?.citizenshipStatus
          : "",
        uaePass: data.data?.additionalDetails?.uaePass
          ? data.data?.additionalDetails?.uaePass
          : "",
        annualIncome: data.data?.additionalDetails?.annualIncome
          ? data.data?.additionalDetails?.annualIncome
          : "",
        employmentType: data.data?.additionalDetails?.employmentType
          ? data.data?.additionalDetails?.employmentType
          : "",
        companyType: data.data?.additionalDetails?.companyType
          ? data.data?.additionalDetails?.companyType
          : "",
        companyName: data.data?.additionalDetails?.companyName
          ? data.data?.additionalDetails?.companyName
          : "",
        title: data.data?.additionalDetails?.title
          ? data.data?.additionalDetails?.title
          : "",
        workEmail: data.data?.additionalDetails?.workEmail
          ? data.data?.additionalDetails?.workEmail
          : "",
        companyAddress: data.data?.additionalDetails?.companyAddress
          ? data.data?.additionalDetails?.companyAddress
          : "",
      });
    }
  }, [data]);

  const StepOneSchema = Yup.object().shape({
    firstName: Yup.string()
      .matches(/^[A-Za-z\s-]+$/, "First name should contain only letters!")
      .required("First name is required"),
    lastName: Yup.string()
      .matches(/^[A-Za-z\s-]+$/, "Last name should contain only letters!")
      .required("Last name is required"),
    countryCode: Yup.string().required("Required"),
    phoneNo: Yup.string()
      .matches(
        /^5\d{8}$/,
        "The number should start from 5 followed by 8 digits"
      )
      .required("Phone number is required!")
      .min(9, "The number should start from 5 followed by 8 digits")
      .max(9, "The number should start from 5 followed by 8 digits"),
  });

  const StepThreeSchema = Yup.object().shape({
    citizenshipStatus: Yup.string().required("Nationality is required"),
    uaePass: Yup.string().required("Emirates ID is required")
      .matches(/^784\d{0,12}$/, "Emirates ID must start with '784' and be 15 digits long"),
  });

  const StepFourSchema = Yup.object().shape({
    employmentType: Yup.string().required("Employment Type is required"),
    companyType: Yup.string().required("Type is required"),
    companyName: Yup.string()
			.required("Name is required")
			.min(2, "Name must be at least 2 characters long")
			.max(50, "Name must be at most 50 characters long"),
    companyAddress: Yup.string().required("Address is required"),
    title: Yup.string().required("Is required")
			.min(2, "Title must be at least 2 characters long")
			.max(50, "Title must be at most 50 characters long"),
    workEmail: Yup.string()
      .strict()
      .email("Invalid email format")
      .required("Work email is required!")
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/, "The email must have at least 2 characters after the last dot"),
    annualIncome: Yup.string()
      .matches(/^[1-9]\d{4,}$/, "Annual income must be a minimum of 5 digits and cannot start with zero")
      .required("Annual income is required"),
  });

  const StepTwoSchema = Yup.object().shape({
    dateOfBirth: Yup.string()
			.required("Date of Birth is required")
      // .matches(/^(1[89]|[2-9]\d|1\d{2,})$/,"")
      .max(new Date(), "Date of birth cannot be in the future.")
      .test(
        "age",
        "You must be at least 18 years old",
        function (value) {
          if (!value) return false;
      
          const today = new Date();
          const birthDate = new Date(value); 
      
          const age = today.getFullYear() - birthDate.getFullYear();
          const monthDiff = today.getMonth() - birthDate.getMonth();
          const dayDiff = today.getDate() - birthDate.getDate();
      
          // Check if the user is 18 years or older
          if (
            age > 18 || 
            (age === 18 && (monthDiff > 0 || (monthDiff === 0 && dayDiff >= 0)))
          ) {
            return true;
          }
      
          return false;
        }
      ),
    maritalStatus: Yup.string().required("Marital Status is required"),
  });

  const SingleStep = ({ title, subtitle, completion }) => {
    const getClassName = () => {
      const hasEmptyElement = completion.some((element) => element === "");
      if (hasEmptyElement) {
        return "incomplete";
      } else {
        return "complete";
      }
    };

    return (
      <div className={`step t-a-c ${getClassName()}`}>
        <p
          className="color-primary"
          style={{ fontSize: "12px", marginBottom: "5px" }}
        >
          {/* <span className="f-w-700">0{title}</span> {subtitle} */}
        </p>
        <div className={`b-r-100 icon`}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.25 16.518l-4.5-4.319 1.396-1.435 3.078 2.937 6.105-6.218 1.421 1.409-7.5 7.626z" />
          </svg>
        </div>
      </div>
    );
  };

  const handleNext = () => {
    setStep(step + 1);
  };
  const handlePrevious = () => {
    if (step !== 1) {
      setStep(step - 1);
    }
  };

  const FieldTitle = ({ title }) => {
    return (
      <p
        className="title f-w-700 color-primary"
        style={{ marginBottom: "5px", fontSize: "14px" }}
      >
        {title}
      </p>
    );
  };

  const checkIfNextURLExists = () => {
    const params = new URLSearchParams(location.search);
    const queryObj = {};
    params.forEach((value, key) => {
      queryObj[key] = value;
    });
    if (queryObj.next) {
      return queryObj.next;
    } else {
      return false;
    }
  };

  const handleSubmit = async (values) => {
    setStatus("");
    setLoading(true);
    try {
      const request = {
        firstName: values.firstName,
        lastName: values.lastName,
        countryCode: values.countryCode,
        phoneNo: values.phoneNo,
        bio: "-",
        tenantDetails: {
          dateOfBirth: values.dateOfBirth,
          maritalStatus: values.maritalStatus,
          citizenshipStatus: values.citizenshipStatus,
          uaePass: values.uaePass,
          annualIncome: values.annualIncome,
          employmentType: values.employmentType,
          companyType: values.companyType,
          companyName: values.companyName,
          title: values.title,
          workEmail: values.workEmail,
          companyAddress: values.companyAddress,
        },
      };
      const resp = await fetch(API_URL + `/api/1.0.1/user/update`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "X-JWT-Assertion": localStorage.getItem("token"),
        },
        body: JSON.stringify(request),
      });
      if (resp.ok) {
        addToastMessage({
          status: true,
          message:
            "You have successfully updated, thank you.",
        });
        const response = await resp.json();
        if (response) {
          if (response.result.responseCode === 200) {
            const nextURL = checkIfNextURLExists();
            const checkForFlow = localStorage.getItem("flow");
            if (checkForFlow) {
              const checkForFlowObj = JSON.parse(checkForFlow);
              const filteredFlows = checkForFlowObj.filter((el) => {
                return el !== "/te-pending-details";
              });
              if (
                localStorage.setItem("flow", JSON.stringify(filteredFlows)) ===
                undefined
              ) {
                if (filteredFlows.length) {
                  if (nextURL) {
                    navigate(filteredFlows[0] + "?next=" + nextURL);
                  } else {
                    navigate(filteredFlows[0]);
                  }
                } else {
                  localStorage.removeItem("flow");
                  if (nextURL) {
                    navigate(nextURL);
                  } else {
                    const decodedToken = jwtDecode(
                      localStorage.getItem("token")
                    );
                    const roles = decodedToken.authorities;
                    navigate(getDefaultURLForRole(roles[0]));
                  }
                }
              } else {
                setStatus("Could not update the flow");
              }
            } else {
              if (nextURL) {
                navigate(nextURL);
              } else {
                const decodedToken = jwtDecode(localStorage.getItem("token"));
                const roles = decodedToken.authorities;
                navigate(getDefaultURLForRole(roles[0]));
              }
            }
          } else {
            setStatus("something went wrong please try again.");
          }
        }
      }
      else {
        const errorData = await resp.json();
        const errorMessage = errorData.responseDescription;
        addToastMessage({
          status: false,
          message:
            errorMessage ||
            "Something went wrong while updating agency commercial details. Please try again.",
        });
      }

      // else {
      //   const response = await resp.json();
      //   if (response) {
      //     console.log(response);
      //     if (response.responseCode === 100016) {
      //       setStatus("Email or Phone already taken.");
      //     } else {
      //       setStatus("something went wrong please try again.");
      //     }
      //   }
      // }
    } catch (e) {
      setStatus("something went wrong please try again");
      console.log("Error", e);
    } finally {
      setLoading(false);
    }
  };

  const LikeInput = ({ title }) => {
    return (
      <div className="ui-like-input col-1-1 cursor-not-allowed">
        {title ? title : " "}
      </div>
    );
  };

  return (
    <section>
      <PageTitle title="Pending Details" />
      <FullPageLoader isActive={loading || profileLoading} />
      <section id="tenant-register">
        <div className="flex center-center col-1-1">
          <div className="col col-1-2" style={{ padding: "30px" }}>
            <NavLink to='/login'>
            <div className="logo">
              <RentSavvyLogo />
            </div>
            </NavLink>
            <br />
            <br />
            <div style={{ paddingLeft: "40px" }}>
              <Formik
                initialValues={initialValues}
                validationSchema={
                  step === 1
                    ? StepOneSchema
                    : step === 2
                      ? StepTwoSchema
                      : step === 3
                        ? StepThreeSchema
                        : StepFourSchema
                }
                enableReinitialize={true}
                onSubmit={async (values, { resetForm }) => {
                  if (step === 4) {
                    handleSubmit(values);
                  } else {
                    handleNext();
                  }
                }}
              >
                {({ values, errors, touched, setFieldValue, resetForm }) => (
                  <Form>
                    <section
                      id="stepper-should-fix"
                      className="loan-application-stepper"
                    >
                      <div
                        className="steps flex center-left"
                        style={{ gap: "20px" }}
                      >
                        <SingleStep
                          title="1"
                          subtitle=""
                          completion={[
                            values.firstName,
                            values.lastName,
                            values.countryCode,
                            values.phoneNo,
                          ]}
                        />
                        <SingleStep
                          title="2"
                          subtitle=""
                          completion={[
                            values.dateOfBirth,
                            values.maritalStatus,
                          ]}
                        />
                        <SingleStep
                          title="3"
                          subtitle=""
                          completion={[
                            values.uaePass,
                            values.citizenshipStatus,
                          ]}
                        />
                        <SingleStep
                          title="4"
                          subtitle=""
                          completion={[
                            values.annualIncome,
                            values.employmentType,
                            values.companyType,
                            values.companyName,
                            values.title,
                            values.workEmail,
                            values.companyAddress,
                          ]}
                        />
                      </div>
                    </section>
                    <br />
                    <table style={{ width: "75%" }}>
                      <thead></thead>
                      <tbody>
                        {step === 1 && (
                          <>
                            <tr>
                              <td colSpan={2}>
                                <h4 className="color-primary t-a-l">
                                  Tell us more about you
                                </h4>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={2}>
                                <br />
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={2}>
                                <br />
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={2}>
                                <FieldTitle title="Whats your first name?" />
                                <Field
                                  name="firstName"
                                  type="text"
                                  placeholder="Eg: [Jake] *"
                                  className={
                                    "col-1-1 " +
                                    (errors.firstName && touched.firstName
                                      ? " error"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="firstName"
                                  component="p"
                                  className="error-messages"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={2}>
                                <FieldTitle title="Whats your last name?" />
                                <Field
                                  name="lastName"
                                  type="text"
                                  placeholder="Eg: [Jake] *"
                                  className={
                                    "col-1-1 " +
                                    (errors.lastName && touched.lastName
                                      ? " error"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="lastName"
                                  component="p"
                                  className="error-messages"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <FieldTitle title="Phone" />
                                <Field name='countryCode'>
													{({ field }) => (
														<CustomSelect
															{...field}
															options={CountryOptions}
															error={errors.countryCode && touched.countryCode}
															onChange={(e) => setFieldValue('countryCode', e.target.value)}
														/>
													)}
												</Field>
                               
                              </td>
                              <td>
                                <FieldTitle title="&nbsp;" />
                                <LikeInput title={values.phoneNo} />
                              </td>
                            </tr>
                            <tr><td colSpan={2}><FieldTitle title="&nbsp;" /><p>&nbsp;</p></td></tr>
                          </>
                        )}
                        {step === 2 && (
                          <>
                            <tr>
                              <td colSpan={2}>
                                <h4 className="color-primary t-a-l">
                                  Little more personal details!
                                </h4>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={2}>
                                <br />
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={2}>
                                <br />
                              </td>
                            </tr>
                            <tr>
                              <td colSpan="2" style={{ width: "50%" }}>
                                <FieldTitle title="Date of birth" />
                                <Field
                                  name="dateOfBirth"
                                  type="date"
                                  placeholder="DOB *"
                                  className={
                                    "col-1-1 " +
                                    (errors.dateOfBirth && touched.dateOfBirth
                                      ? " error"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="dateOfBirth"
                                  component="p"
                                  className="error-messages"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td colSpan="2" style={{ width: "50%" }}>
                                <FieldTitle title="Marital Status" />
                                <Field
                                  as="select"
                                  name="maritalStatus"
                                  className={
                                    "col-1-1 " +
                                    (errors.maritalStatus &&
                                      touched.maritalStatus
                                      ? " error"
                                      : "")
                                  }
                                >
                                  <option value="">Select</option>
                                  <option value="true">Married</option>
                                  <option value="false">Unmarried</option>
                                </Field>
                                <ErrorMessage
                                  name="maritalStatus"
                                  component="p"
                                  className="error-messages"
                                />
                              </td>
                            </tr>
                            <tr><td colSpan={2}><FieldTitle title="&nbsp;" /><p>&nbsp;</p></td></tr>
                            <tr><td colSpan={2}><FieldTitle title="&nbsp;" /><p>&nbsp;</p></td></tr>
                          </>
                        )}
                        {step === 3 && (
                          <>
                            <tr>
                              <td colSpan={2}>
                                <h4 className="color-primary t-a-l">
                                  What's your nationality?
                                </h4>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={2}>
                                <br />
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={2}>
                                <br />
                              </td>
                            </tr>
                            <tr>
                              <td colSpan="2">
                                <FieldTitle title="Nationality" />
                                <Field
                                  as="select"
                                  name="citizenshipStatus"
                                  className={
                                    "col-1-1 " +
                                    (errors.citizenshipStatus &&
                                      touched.citizenshipStatus
                                      ? " error"
                                      : "")
                                  }
                                >
                                  <option value="">Select Nationality</option>
                                  {countries.map((c, i) => (
                                    <option key={i} value={c.name}>
                                      {c.name}
                                    </option>
                                  ))}
                                </Field>
                                <ErrorMessage
                                  name="citizenshipStatus"
                                  component="p"
                                  className="error-messages"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td colSpan="2">
                                <FieldTitle title="Emirates ID" />
                                <Field
                                  name="uaePass"
                                  type="text"
                                  maxLength='15'
                                  placeholder="Eg: 784************ "
                                  className={
                                    "col-1-1 " +
                                    (errors.uaePass && touched.uaePass
                                      ? " error"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="uaePass"
                                  component="p"
                                  className="error-messages"
                                />
                              </td>
                            </tr>
                            <tr><td colSpan={2}><FieldTitle title="&nbsp;" /><p>&nbsp;</p></td></tr>
                            <tr><td colSpan={2}><FieldTitle title="&nbsp;" /><p>&nbsp;</p></td></tr>
                          </>
                        )}
                        {step === 4 && (
                          <>
                            <tr>
                              <td colSpan={2}>
                                <h4 className="color-primary t-a-l">
                                  Your employment type
                                </h4>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={2}>
                                <br />
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={2}>
                                <br />
                              </td>
                            </tr>
                            <tr>
                              <td colSpan="2">
                                <FieldTitle title={"Employment Type"} />
                                <Field
                                  as="select"
                                  name="employmentType"
                                  className={
                                    "col-1-1 " +
                                    (errors.employmentType &&
                                      touched.employmentType
                                      ? " error"
                                      : "")
                                  }
                                >
                                  <option value="">Select</option>
                                  <option value="Self Employed">
                                    Self Employed
                                  </option>
                                  <option value="Salaried">Salaried</option>
                                </Field>
                                <ErrorMessage
                                  name="employmentType"
                                  component="p"
                                  className="error-messages"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td colSpan="1">
                                <FieldTitle
                                  title={`${values.employmentType === "Self Employed"
                                    ? "Annual Revenue"
                                    : "Annual Income"
                                    }`}
                                />
                                <Field
                                  name="annualIncome"
                                  placeholder="AED [120000]*"
                                  className={
                                    "col-1-1 " +
                                    (errors.annualIncome && touched.annualIncome
                                      ? " error"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="annualIncome"
                                  component="p"
                                  className="error-messages"
                                />
                              </td>
                              <td colSpan="1">
                                <FieldTitle
                                  title={`${values.employmentType === "Self Employed"
                                    ? "Business Type"
                                    : "Company Type"
                                    }`}
                                />
                                <Field
                                  as="select"
                                  name="companyType"
                                  className={
                                    "col-1-1 " +
                                    (errors.companyType && touched.companyType
                                      ? " error"
                                      : "")
                                  }
                                >
                                  <option value="">Select</option>
                                  {values.employmentType === "Self Employed" ? (
                                    <>
                                      <option value="Sole Proprietorship">
                                        Sole Proprietorship
                                      </option>
                                      <option value="Partnership">
                                        Partnership
                                      </option>
                                      <option value="Corporation">
                                        Corporation
                                      </option>
                                    </>
                                  ) : (
                                    <>
                                      <option value="Private">Private</option>
                                      <option value="Government">
                                        Government
                                      </option>
                                    </>
                                  )}
                                </Field>
                                <ErrorMessage
                                  name="companyType"
                                  component="p"
                                  className="error-messages"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td colSpan="1">
                                <FieldTitle
                                  title={`${values.employmentType === "Self Employed"
                                    ? "Business"
                                    : "Company"
                                    } Name `}
                                />
                                <Field
                                  name="companyName"
                                  type="text"
                                  maxLength='50'
                                  placeholder={`Enter ${values.employmentType === "Self Employed"
                                    ? "business"
                                    : "company"
                                    } name *`}
                                  className={
                                    "col-1-1 " +
                                    (errors.companyName && touched.companyName
                                      ? " error"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="companyName"
                                  component="p"
                                  className="error-messages"
                                />
                              </td>
                              <td colSpan="1">
                                <FieldTitle
                                  title={`${values.employmentType === "Self Employed"
                                    ? "Nature of Business"
                                    : "Job Title"
                                    }`}
                                />
                                <Field
                                  name="title"
                                  type="text"
                                  maxLength='50'
                                  placeholder={`Eg [${values.employmentType === "Self Employed"
                                    ? "Software"
                                    : "Software Engineer"
                                    }] *`}
                                  className={
                                    "col-1-1 " +
                                    (errors.title && touched.title
                                      ? " error"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="title"
                                  component="p"
                                  className="error-messages"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <FieldTitle title="Work Email" />
                                <Field
                                  name="workEmail"
                                  type="email"
                                  placeholder="Eg: octopi@company.com *"
                                  className={
                                    "col-1-1 " +
                                    (errors.workEmail && touched.workEmail
                                      ? " error"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="workEmail"
                                  component="p"
                                  className="error-messages"
                                />
                              </td>
                              <td className="verify t-a-r">
                                <p className="title f-w-700 color-primary">
                                  &nbsp;
                                </p>
                                <>
                                  {workemailVerified ? (
                                    <ButtonLightTextIcon
                                      type="button"
                                      data-name="verified"
                                      title="Verified"
                                      icon={<CiCircleCheck />}
                                    />
                                  ) : (
                                    <ButtonLightTextIcon
                                      type="button"
                                      disabled={values.workemailVerifing}
                                      title="Verify"
                                      onClick={() =>
                                        handleWorkEmailVerify(values.workEmail)
                                      }
                                      icon={
                                        <>
                                          {workemailVerifing ? (
                                            <LoaderIcon />
                                          ) : (
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="18"
                                              height="24"
                                              viewBox="0 0 18 24"
                                            >
                                              <path
                                                d="M15.762,8.047l-4.381,4.475L9.166,10.4,7.93,11.638,11.381,15,17,9.285ZM12,2.544A11.888,11.888,0,0,0,19,4.96v9.575c0,3.042-1.686,3.827-7,7.107-5.309-3.278-7-4.065-7-7.107V4.96A11.884,11.884,0,0,0,12,2.544ZM12,0C8.629,2.866,6.516,3,3,3V14.535c0,4.6,3.2,5.8,9,9.465,5.8-3.661,9-4.862,9-9.465V3c-3.516,0-5.629-.134-9-3Z"
                                                transform="translate(-3)"
                                              />
                                            </svg>
                                          )}
                                        </>
                                      }
                                    />
                                  )}
                                </>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan='3'>
                                <FieldTitle
                                  title={`${values.employmentType === "Self Employed"
                                    ? "Business"
                                    : "Company"
                                    } Address `} />
                                <Field
                                  name='companyAddress'
                                  type='text'
                                  placeholder={`Enter ${values.employmentType === "Self Employed"
                                    ? "business"
                                    : "company"
                                    } address *`}
                                  className={
                                    "col-1-1 " +
                                    (errors.companyAddress &&
                                      touched.companyAddress
                                      ? " error"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name='companyAddress'
                                  component='p'
                                  className='error-messages'
                                />
                              </td>
                            </tr>
                          </>
                        )}
                        <tr>
                          <td>
                            <br />
                          </td>
                        </tr>
                        <tr style={{ alignSelf: "flex-end" }}>
                          <td colSpan={1}>
                            <div className="t-a-l">
                              {step !== 1 && (
                                <ButtonLightTextIcon
                                  disabled={loading}
                                  type="button"
                                  title="Back"
                                  onClick={handlePrevious}
                                  icon={
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="26.562"
                                      height="17.809"
                                      viewBox="0 0 26.562 17.809"
                                    >
                                      <path d="M0,8.88,10.686,0l1.937,2.293L6.453,7.4H26.562v3H6.454l6.166,5.108-1.934,2.3Z" />
                                    </svg>
                                  }
                                />
                              )}
                            </div>
                          </td>
                          <td colSpan={1}>
                            <div className="t-a-r">
                              {loading ? (
                                <ButtonTextIcon
                                  type="button"
                                  title="Registering..."
                                  disabled={loading}
                                  icon={<LoaderIcon />}
                                />
                              ) : (
                                <ButtonTextIcon
                                  type="submit"
                                  title={step !== 4 ? "Continue" : "Submit"}
                                  disabled={loading}
                                />
                              )}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {status && (
                      <p className="status color-secondary f-w-300 l-h-1">
                        {status}
                      </p>
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          </div>
          <div className=" col col-1-2 of-hid default-image"></div>
        </div>
      </section>
    </section>
  );
};

export default TenantPending;
