import { useEffect, useState } from "react";

const RSTabs = ({ options }) => {
	const [active, setActive] = useState(null);

	useEffect(() => {
		const first = options[0].id;
		setActive(first);
	}, []);

	const getActiveTabContent = () => {
		const c = options.find((o) => o.id === active);
		return c ? c.content : "";
	};

	return (
		<div className='rs-tabs'>
			<ul className='tab-selector'>
				{options.map((o, i) => (
					<li
						className={active === o.id ? "active" : ""}
						key={i}
						onClick={() => setActive(o.id)}>
						{o.title}
					</li>
				))}
			</ul>
			<div className='tab-content'>{getActiveTabContent()}</div>
		</div>
	);
};

export default RSTabs;
