import { useEffect, useState } from "react";
import PageTitle from "../../components/common/PageTitle";
import FullPageLoader from "../../components/ui/FullPageLoader";
import { Link, useNavigate } from "react-router-dom";
import {
  API_URL,
  DEFAULT_PER_PAGE,
  FREQUENCY_TYPE_LIST,
} from "../../utilities/constants";
import { useParams } from "react-router-dom";
import { useUI } from "../../context/ui";
import { CiCircleCheck, CiViewList } from "react-icons/ci";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import PopUpModal from "../../components/common/PopUpModal";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import { formatAmountValue } from "../../utilities/helpers"

const MyOfferList = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [error , setError] = useState(null);
  const navigate = useNavigate();
  const [preApprovalData, setPreApprovalData] = useState(null);
  const {
    setNewNotification,
    setShowNotification,
    addNotification,
    setSegmentTitle,
  } = useUI();
  const [showPopup, setShowPopup] = useState(false);

  const groupedByCreditProviderFirmId = (data) => {
    const grouped = data.reduce((acc, item) => {
      const { id, name, logo } = item.creditProviderFirm;
      if (!acc[id]) {
        acc[id] = {
          firmId: id,
          logo: logo,
          firmName: name,
          firmProcessingFee: item.processingFee,
          configs: [],
        };
      }
      acc[id].configs.push(item);
      return acc;
    }, {});

    return Object.values(grouped);
  };

  const getPreApprovals = async () => {
    setLoading(true);
    try {
      const resp = await fetch(`${API_URL}/api/1.0.0/tenant/loans`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-JWT-Assertion": localStorage.getItem("token"),
        },
        redirect: "follow",
      });

      if (resp.ok) {
        const response = await resp.json();
        if (response.result.responseCode == 200) {
          if (response.data === null || response.data.length === 0) {
            setPreApprovalData([]);//setShowPopup(true);
          } else {
            setPreApprovalData(groupedByCreditProviderFirmId(response.data));
          }
        }
      } else {
        const response = await resp.json();
        if(response["responseCode"]){
            setError(parseInt(response.responseCode));
        }
        setPreApprovalData([]);
      }
    } catch (e) {
      console.log("Error", e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setSegmentTitle("My Rental Offers");
    getPreApprovals();
  }, []);

  const getFrequencyDetails = (value) => {
    const status = FREQUENCY_TYPE_LIST.find((obj) => obj.value === value);
    return status
      ? status
      : {
          name: "NA",
          value: "NA",
          shortName: "na",
        };
  };
 

  return (
    <>
      <section>
        <PageTitle title="My Rental Offers" />
        <FullPageLoader isActive={loading} />
        {showPopup && (
          <PopUpModal>
            <section id="rs-modal">
              <div className="inner">
                <div className="box">
                  <div
                    style={{
                      height: "auto",
                      width: "480px",
                      padding: "40px",
                    }}
                  >
                    <h4 className="color-primary">Offer not available</h4>
                    <br />
                    <p>
                      We could not find any loan offers suitable for you at the
                      moment, please check again later.
                    </p>
                    <br />
                    <div
                      className="flex center-center"
                      style={{
                        justifyContent: "flex-end",
                        gap: "12px",
                      }}
                    >
                      <ButtonTextIcon
                        title="Return Back"
                        icon={<CiCircleCheck />}
                        onClick={() => {
                          navigate(`/te-credit-score-history`);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </PopUpModal>
        )}
        {preApprovalData && preApprovalData.length > 0 && (
          <section className="pre-approvals">
            <div className="flex top-left" style={{ gap: "20px" }}>
              <div className="col-1-3 bg-color-primary b-r-10 of-hid">
                <div className="flex center-left congratulations">
                  <div className="t-a-c">
                    <div className="flex center-center icon b-r-100 bg-color-secondary">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path
                          d="M13.5,20a2,2,0,1,0,2,2A2,2,0,0,0,13.5,20ZM2.949,24C2.351,24,.52,22.246.2,19.7a5.17,5.17,0,0,1,4.413-5.87,6.71,6.71,0,0,1,6.434-4.77,5.342,5.342,0,0,1,6.84-3.186,6.654,6.654,0,0,1,3.7,3.737.5.5,0,0,1-.937.347A5.623,5.623,0,0,0,17.53,6.8a4.312,4.312,0,0,0-5.422,2.3,6.456,6.456,0,0,1,2.755.889,3.248,3.248,0,0,1,1.508,2.577,2.536,2.536,0,0,1-3.289,2.591,3.361,3.361,0,0,1-2.143-2.169,5.68,5.68,0,0,1-.178-2.921A5.654,5.654,0,0,0,5.686,13.81a6,6,0,0,1,2.831,1.012,3.261,3.261,0,0,1,1.513,2.6,2.638,2.638,0,0,1-1.011,2.155,2.721,2.721,0,0,1-2.363.348,3.212,3.212,0,0,1-2.079-2.118,6.307,6.307,0,0,1-.2-2.919,4.16,4.16,0,0,0-3.178,4.685,5,5,0,0,0,2.015,3.5A.5.5,0,0,1,2.949,24ZM13.5,23a1,1,0,1,1,1-1A1,1,0,0,1,13.5,23Zm8.011-6.8L24,16.658l-1.744,1.833L22.589,21l-2.283-1.092L18.023,21l.333-2.509-1.744-1.833L19.1,16.2l1.2-2.225Zm-1.759.9-1.143.21.8.843L19.257,19.3l1.049-.5,1.049.5L21.2,18.149l.8-.843L20.86,17.1l-.554-1.022ZM5.407,14.8a5.286,5.286,0,0,0,.113,2.678,2.185,2.185,0,0,0,1.424,1.492,1.721,1.721,0,0,0,1.472-.187,1.657,1.657,0,0,0,.614-1.34,2.3,2.3,0,0,0-1.09-1.8A4.9,4.9,0,0,0,5.407,14.8ZM11.78,10.08a4.892,4.892,0,0,0,.1,2.569,2.379,2.379,0,0,0,1.486,1.55,1.568,1.568,0,0,0,1.411-.242,1.592,1.592,0,0,0,.6-1.355,2.219,2.219,0,0,0-1.056-1.775,5.834,5.834,0,0,0-2.536-.747ZM5.968,2.711,9,3.269,6.876,5.5,7.281,8.56,4.5,7.229,1.719,8.56l.4-3.057L0,3.269l3.032-.558L4.5,0,5.968,2.711Zm-2.285.9L2,3.918,3.179,5.161l-.226,1.7L4.5,6.121l1.547.74-.226-1.7L7,3.918l-1.686-.31L4.5,2.1,3.683,3.608ZM21.5,0A2.5,2.5,0,1,0,24,2.5,2.5,2.5,0,0,0,21.5,0Zm0,4A1.5,1.5,0,1,1,23,2.5,1.5,1.5,0,0,1,21.5,4Z"
                          fillRule="evenodd"
                        />
                      </svg>
                    </div>
                    <h4 className="color-white">Congratulations!</h4>
                    <p className="color-white">
                      You can view your pre approved rental offers and choose
                      one to apply.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-2-3">
                <div className="configs b-r-10 of-hid">
                  <h5 className="color-primary">Top Offers</h5>
                  <div className="all-configs">
                    {preApprovalData.map((a, i) => (
                      <div className="one col-1-1 flex top-left b-r-10" key={i}>
                        <div className="cp flex center-left">
                          <div
                            className="img default-image b-r-5 of-hid"
                            style={{
                              backgroundImage: a.logo ? `url(${a.logo})` : null,
                            }}
                          ></div>
                        </div>
                        <div className="options flex-1">
                          <h6 className="f-w-700 color-primary flex-1">
                            {a.firmName}
                          </h6>
                          <p
                            className="color-primary f-w-300 font-size-12"
                            style={{
                              marginBottom: "5px",
                            }}
                          >
                            Options:
                          </p>
                          {a.configs.map((c, j) => (
                            <div className="option-one flex" key={j}>
                              <div>
                                <p className="color-primary">
                                  Rental upto{" "}
                                  <span className="color-tertiary">
                                  {`${formatAmountValue(c.loanAmount)}/- AED`}
                                     {/* {c.loanAmount} AED */}
                                  </span>
                                </p>
                                <p className="font-size-12 color-faded">
                                {`${formatAmountValue(c.installmentAmount)}/- AED`}{" "}
                                  {getFrequencyDetails(c.frequency).name}
                                </p>
                                <div className="flex center-left">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="24"
                                    viewBox="0 0 18 24"
                                    style={{
                                      height: "12px",
                                      width: "auto",
                                    }}
                                  >
                                    <path
                                      d="M6,24,21,7H13.965l2.606-7h-8.2L3,14H8.888L6,24ZM6,12,9.713,1.961h3.845L10.951,9H16.5l-6.031,7.062L11.835,12Z"
                                      transform="translate(-3)"
                                      fill="#5dc6b3"
                                    />
                                  </svg>
                                  &nbsp;
                                  <p className="color-faded f-w-300 font-size-12">
                                    {c.processingFee
                                      ? c.processingFee !== 0
                                        ? `${formatAmountValue(c.processingFee)}/- AED`
                                        : "Zero"
                                      : "Zero"}{" "}
                                    processing fee
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
        {preApprovalData && preApprovalData.length === 0 && !loading && (
        <section className="pre-approvals">
          <div
            className="flex top-left"
            style={{ gap: "20px", marginLeft: "100px", marginRight: "100px" }}
          >
            <div className="col-1-1 bg-color-primary b-r-10 of-hid">
              <div className="flex center-center congratulations">
                <div className="t-a-c">
                  <div className="flex center-center icon b-r-100 bg-color-secondary">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M13.5,20a2,2,0,1,0,2,2A2,2,0,0,0,13.5,20ZM2.949,24C2.351,24,.52,22.246.2,19.7a5.17,5.17,0,0,1,4.413-5.87,6.71,6.71,0,0,1,6.434-4.77,5.342,5.342,0,0,1,6.84-3.186,6.654,6.654,0,0,1,3.7,3.737.5.5,0,0,1-.937.347A5.623,5.623,0,0,0,17.53,6.8a4.312,4.312,0,0,0-5.422,2.3,6.456,6.456,0,0,1,2.755.889,3.248,3.248,0,0,1,1.508,2.577,2.536,2.536,0,0,1-3.289,2.591,3.361,3.361,0,0,1-2.143-2.169,5.68,5.68,0,0,1-.178-2.921A5.654,5.654,0,0,0,5.686,13.81a6,6,0,0,1,2.831,1.012,3.261,3.261,0,0,1,1.513,2.6,2.638,2.638,0,0,1-1.011,2.155,2.721,2.721,0,0,1-2.363.348,3.212,3.212,0,0,1-2.079-2.118,6.307,6.307,0,0,1-.2-2.919,4.16,4.16,0,0,0-3.178,4.685,5,5,0,0,0,2.015,3.5A.5.5,0,0,1,2.949,24ZM13.5,23a1,1,0,1,1,1-1A1,1,0,0,1,13.5,23Zm8.011-6.8L24,16.658l-1.744,1.833L22.589,21l-2.283-1.092L18.023,21l.333-2.509-1.744-1.833L19.1,16.2l1.2-2.225Zm-1.759.9-1.143.21.8.843L19.257,19.3l1.049-.5,1.049.5L21.2,18.149l.8-.843L20.86,17.1l-.554-1.022ZM5.407,14.8a5.286,5.286,0,0,0,.113,2.678,2.185,2.185,0,0,0,1.424,1.492,1.721,1.721,0,0,0,1.472-.187,1.657,1.657,0,0,0,.614-1.34,2.3,2.3,0,0,0-1.09-1.8A4.9,4.9,0,0,0,5.407,14.8ZM11.78,10.08a4.892,4.892,0,0,0,.1,2.569,2.379,2.379,0,0,0,1.486,1.55,1.568,1.568,0,0,0,1.411-.242,1.592,1.592,0,0,0,.6-1.355,2.219,2.219,0,0,0-1.056-1.775,5.834,5.834,0,0,0-2.536-.747ZM5.968,2.711,9,3.269,6.876,5.5,7.281,8.56,4.5,7.229,1.719,8.56l.4-3.057L0,3.269l3.032-.558L4.5,0,5.968,2.711Zm-2.285.9L2,3.918,3.179,5.161l-.226,1.7L4.5,6.121l1.547.74-.226-1.7L7,3.918l-1.686-.31L4.5,2.1,3.683,3.608ZM21.5,0A2.5,2.5,0,1,0,24,2.5,2.5,2.5,0,0,0,21.5,0Zm0,4A1.5,1.5,0,1,1,23,2.5,1.5,1.5,0,0,1,21.5,4Z"
                        fillRule="evenodd"
                      />
                    </svg>
                  </div>
                  <h4 className="color-white">Oops!</h4>
                  {error === 1000866 ? (
                    <><p className="color-white">
                      We are unable to get an offers for you at the moment,
                    </p>
                    <p className="color-white">
                      Check your credit score to receive offer. 
                    </p>
                    <br/>
                    <Link to="/credit-score">
                      <ButtonLightTextIcon title="Check Credit Score" icon={<CiViewList />} />
                    </Link>
                    </>
                  ) : (
                    <p className="color-white">
                      We are unable to get an offers for you at the moment,
                      please try again later.
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        )} 
      </section>
    </>
  );
};
export default MyOfferList;
