import { useEffect, useState } from "react";
import FullPageLoader from "../../components/ui/FullPageLoader";
import { useParams } from "react-router-dom";
import FileInput from "../../components/ui/FileInput";
import RSModal from "../../components/ui/RSModal";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import LoaderIcon from "../../components/ui/LoaderIcon";
import { useNavigate, NavLink } from "react-router-dom";
import {
	CiBookmark,
	CiCalculator1,
	CiCircleCheck,
	CiHome,
	CiLink,
	CiMoneyBill,
	CiLocationOn,
	CiMail,
	CiPhone,
	CiUser,
	CiCircleRemove,
	CiLocationArrow1,
} from "react-icons/ci";
import TransparentImage from "../../images/icons/4x2.5.png";
import {
	API_URL,
	BASE_URL,
	DEFAULT_PER_PAGE,
	LOAN_APPLICATION_LOAN_INITIATED_STATUS,
	LOAN_APPLICATION_INITIAL_PAYMENT_STATUS,
	LOAN_APPLICATION_STATUSES,
	FREQUENCY_TYPE_LIST,
} from "../../utilities/constants";
import PageTitle from "../../components/common/PageTitle";
import { useUI } from "../../context/ui";
import { jwtDecode } from "jwt-decode";
import { check } from "prettier";
import { convertAEDToUSD } from "../../../src/utilities/helpers";
import { formatAmountValue } from "../../utilities/helpers"

const PayPreInstallmentModal = ({
	loanAmount,
	frequency,
	installmentAmount,
	callback,
	setShow,
}) => {
	const [loading, setLoading] = useState(false);

	const getFrequencyDetails = (value) => {
		const status = FREQUENCY_TYPE_LIST.find((obj) => obj.value === value);
		return status
			? status
			: {
					name: "NA",
					value: "NA",
					shortName: "na",
			  };
	};

	const handlePayPreInstallment = () => {
		setLoading(true);
		try {
			callback();
			setShow(false);
		} catch (e) {
			setLoading(false);
		}
	};
	function capitalizeFirstLetter(str) {
		return str[0].toUpperCase() + str.slice(1);
	}
	return (
		<div
			style={{
				height: "auto",
				width: "486px",
				padding: "40px",
				position: "relative", // Make the container relative for positioning the cross icon
				border: "1px solid #ddd", // Optional: for better visibility of the div
				borderRadius: "8px", // Optional: rounded corners for a polished look
				backgroundColor: "#fff", // Optional: white background for better contrast
			}}>
			<CiCircleRemove
				onClick={() => setShow(false)}
				style={{
					position: "absolute",
					top: "10px",
					right: "10px", // Positioning it to the top right within the div
					cursor: "pointer",
					fontSize: "24px",
					color: "grey",
				}}
			/>
			<h4 className='color-primary'>Pre-Installment Details</h4>
			<br />
			<p>
				Your installment amount is:{" "}
				{`${formatAmountValue(installmentAmount)}/- AED`}
				{/* {parseFloat(installmentAmount).toFixed(2)}/- AED */}
			</p>
			<p>
				{" "}
				For you loan amount : {`${formatAmountValue(loanAmount)}/- AED`} 
				 {/* //{parseFloat(loanAmount).toFixed(2)}/- AED{" "} */}
			</p>
			<p>
				{" "}
				Installment frequency :{" "}
				{capitalizeFirstLetter(
					getFrequencyDetails(frequency).name
				)}{" "}
			</p>
			<br />
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "flex-end",
					gap: "12px",
				}}>
				<ButtonLightTextIcon
					title='Go back'
					icon={
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='26.562'
							height='17.809'
							viewBox='0 0 26.562 17.809'>
							<path d='M0,8.88,10.686,0l1.937,2.293L6.453,7.4H26.562v3H6.454l6.166,5.108-1.934,2.3Z' />
						</svg>
					}
					onClick={() => setShow(false)}
				/>
				{loading ? (
					<ButtonTextIcon
						type='button'
						title='Updating...'
						disabled={loading}
						icon={<LoaderIcon />}
					/>
				) : (
					<ButtonLightTextIcon
						type='button'
						title={"Pay Now"}
						icon={<CiMoneyBill />}
						disabled={loading}
						onClick={() => {
							handlePayPreInstallment();
						}}
					/>
				)}
			</div>
		</div>
	);
};
const ConfirmReject = ({ loanApplicationID, callback, setShow }) => {
	const { setNewNotification, setShowNotification, addNotification } =
		useUI();
	const [loading, setLoading] = useState(false);

	const handleReject = async (id) => {
		setLoading(true);
		try {
			const response = await fetch(
				`${API_URL}/api/1.0.0/loan-application/reject-loan/${id}`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
						"X-JWT-Assertion": localStorage.getItem("token"),
					},
				}
			);
			if (response.ok) {
				const data = await response.json();
				if (data) {
					setShow(false);
					callback();
				}
			} else {
				const errorData = await response.json();
				throw new Error(
					errorData.result.responseDescription || "API request failed"
				);
			}
		} catch (e) {
			console.error("Error:", e);
			setNewNotification(true);
			setShowNotification(true);
			addNotification({
				id: Date.now(),
				message:
					"An error occurred while processing your acceptance. Please try again.",
				status: false,
				archive: false,
			});
			setLoading(false);
		}
	};

	return (
		<div
			style={{
				height: "auto",
				width: "486px",
				padding: "40px",
				position: "relative", // Make the container relative for positioning the cross icon
				border: "1px solid #ddd", // Optional: for better visibility of the div
				borderRadius: "8px", // Optional: rounded corners for a polished look
				backgroundColor: "#fff", // Optional: white background for better contrast
			}}>
			<CiCircleRemove
				onClick={() => setShow(false)}
				style={{
					position: "absolute",
					top: "10px",
					right: "10px", // Positioning it to the top right within the div
					cursor: "pointer",
					fontSize: "24px",
					color: "grey",
				}}
			/>
			<h4 className='color-primary'>Confirm reject</h4>
			<br />
			<p>
				Are you sure you want to reject? Please make sure you understand
				the consequences of this action.
			</p>
			<br />
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "flex-end",
					gap: "12px",
				}}>
				<ButtonLightTextIcon
					title='Go back'
					icon={
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='26.562'
							height='17.809'
							viewBox='0 0 26.562 17.809'>
							<path d='M0,8.88,10.686,0l1.937,2.293L6.453,7.4H26.562v3H6.454l6.166,5.108-1.934,2.3Z' />
						</svg>
					}
					onClick={() => setShow(false)}
				/>
				{loading ? (
					<ButtonTextIcon
						type='button'
						title='Updating...'
						disabled={loading}
						icon={<LoaderIcon />}
					/>
				) : (
					<ButtonTextIcon
						type='button'
						title='Confirm'
						icon={<CiCircleCheck />}
						onClick={() => {
							handleReject(loanApplicationID);
						}}
					/>
				)}
			</div>
		</div>
	);
};

const ConfirmAcceptChange = ({
	tenancyAgreementUploadURL,
	creditDocumentUploadURL,
	loanApplicationID,
	callback,
	setShow,
}) => {
	const { setNewNotification, setShowNotification, addNotification } =
		useUI();
	const [loading, setLoading] = useState(false);
	const handleReject = async (id) => {
		setLoading(true);
		try {
			const response = await fetch(
				`${API_URL}/api/1.0.0/loan-application/reject-loan/${id}`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
						"X-JWT-Assertion": localStorage.getItem("token"),
					},
				}
			);
			if (response.ok) {
				const data = await response.json();
				if (data) {
					setShow(false);
					callback();
				}
			} else {
				const errorData = await response.json();
				throw new Error(
					errorData.result.responseDescription || "API request failed"
				);
			}
		} catch (e) {
			console.error("Error:", e);
			setNewNotification(true);
			setShowNotification(true);
			addNotification({
				id: Date.now(),
				message:
					"An error occurred while processing your acceptance. Please try again.",
				status: false,
				archive: false,
			});
			setLoading(false);
		}
	};
	const handleAccept = async (id) => {
		setLoading(true);
		try {
			const request = {
				tenancyDocumentURL: tenancyAgreementUploadURL,
				creditDocumentURL: creditDocumentUploadURL,
			};
			const response = await fetch(
				// `${API_URL}/api/1.0.0/loan-application/tenancy-document/${id}`,
				`${API_URL}/api/1.0.0/loan-application/agreement-documents/${id}`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
						"X-JWT-Assertion": localStorage.getItem("token"),
					},
					body: JSON.stringify(request),
				}
			);
			if (response.ok) {
				const data = await response.json();
				if (data) {
					setShow(false);
					callback();
				}
			} else {
				const errorData = await response.json();
				throw new Error(
					errorData.result.responseDescription || "API request failed"
				);
			}
		} catch (e) {
			console.error("Error:", e);
			setNewNotification(true);
			setShowNotification(true);
			addNotification({
				id: Date.now(),
				message:
					"An error occurred while processing your acceptance. Please try again.",
				status: false,
				archive: false,
			});
			setLoading(false);
		}
	};

	return (
		<div
			style={{
				height: "auto",
				width: "486px",
				padding: "40px",
				position: "relative", // Make the container relative for positioning the cross icon
				border: "1px solid #ddd", // Optional: for better visibility of the div
				borderRadius: "8px", // Optional: rounded corners for a polished look
				backgroundColor: "#fff", // Optional: white background for better contrast
			}}>
			<CiCircleRemove
				onClick={() => setShow(false)}
				style={{
					position: "absolute",
					top: "10px",
					right: "10px", // Positioning it to the top right within the div
					cursor: "pointer",
					fontSize: "24px",
					color: "grey",
				}}
			/>
			<h4 className='color-primary'>Are you sure?</h4>
			<br />
			<p>
				Are you sure you want to upload the documents? Please make sure
				you understand that by uploading these documents you are
				accepting the rental and credit terms.
			</p>
			<br />
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "flex-end",
					gap: "12px",
				}}>
				<ButtonLightTextIcon
					title='Go back'
					icon={
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='26.562'
							height='17.809'
							viewBox='0 0 26.562 17.809'>
							<path d='M0,8.88,10.686,0l1.937,2.293L6.453,7.4H26.562v3H6.454l6.166,5.108-1.934,2.3Z' />
						</svg>
					}
					onClick={() => setShow(false)}
				/>
				{/* {loading ? (
          <ButtonTextIcon
            type="button"
            title="Updating..."
            disabled={loading}
            icon={<LoaderIcon />}
          />
        ) : (
          <ButtonTextIcon
            title="Reject"
            style={{ background: "red" }}
            disabled={loading}
            icon={<CiCircleRemove />}
            onClick={() => handleReject(loanApplicationID)}
          />
        )} */}
				{loading ? (
					<ButtonTextIcon
						type='button'
						title='Updating...'
						disabled={loading}
						icon={<LoaderIcon />}
					/>
				) : (
					<ButtonTextIcon
						title='Confirm'
						disabled={
							loading ||
							!tenancyAgreementUploadURL ||
							!creditDocumentUploadURL
						}
						icon={<CiCircleCheck />}
						onClick={() => handleAccept(loanApplicationID)}
					/>
				)}
			</div>
		</div>
	);
};

const TenantLoanDetail = () => {
	const navigate = useNavigate();
	const { id } = useParams();
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState(null);
	const {
		setNewNotification,
		setShowNotification,
		addNotification,
		setSegmentTitle,
		addToastMessage,
	} = useUI();
	const [tenancyAgreementUploadURL, setTenancyAgreementUploadURL] =
		useState("");
	const [tenancyAgreementFile, setTenancyAgreementFile] = useState(null);
	const [creditDocumentUploadURL, setCreditDocumentUploadURL] = useState("");
	const [creditDocumentFile, setCreditDocumentFile] = useState(null);
	const FieldTitle = ({ title }) => {
		return (
			<p
				className='title f-w-700 color-primary'
				style={{ marginBottom: "5px", fontSize: "14px" }}>
				{title}
			</p>
		);
	};

	const getMyUName = () => {
		const decodedToken = jwtDecode(localStorage.getItem("token"));
		const uN = decodedToken.userName;
		return uN ? uN : "";
	};

	const handlePayPreInstallment = async (amount) => {
		setLoading(true);
		try {
			const response = await fetch(API_URL + "/api/1.0.0/transaction", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"X-JWT-Assertion": localStorage.getItem("token"),
				},
				body: JSON.stringify({
					amount: amount ? convertAEDToUSD(amount) * 100 : 1000, //10 USD
					currencyCode: "USD",
					invoiceNumber: "PI_RS_APP_" + id,
					comment1: "Pre Installment Payment",
					address1: "Some address",
					city: "Bangalore",
					country: "India",
					name: getMyUName(),
					state: "KA",
					zipCode: "560100",
					returnURL: `${BASE_URL}/te-credit-accounts/${id}/pre-installment/verify`,
				}),
				redirect: "follow",
			});
			if (response.ok) {
				const data = await response.json();
				if (data) {
					localStorage.setItem("transactionId", data.transactionId);
					window.location.href = data.redirectURL;
				} else {
					addToastMessage({
						status: false,
						message:
							"Something went wrong while initiating a payment request. Please try again.",
					});
				}
			} else {
				const errorData = await response.json();
				const errorMessage = errorData.responseDescription;
				addToastMessage({
					status: false,
					message:
						errorMessage ||
						"Something went wrong while initiating a payment request. Please try again.",
				});
				setNewNotification(true);
				setShowNotification(true);
				addNotification({
					id: Date.now(),
					message:
						"Could not initiate a payment for pre installment.",
					status: false,
					archive: false,
				});
			}
		} catch (e) {
			console.log("Error", e);
			setLoading(false);
		}
	};

	const callApi = async (id) => {
		setLoading(true);
		try {
			const resp = await fetch(
				API_URL + `/api/1.0.1/tenant/loans/${id}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						"X-JWT-Assertion": localStorage.getItem("token"),
					},
				}
			);
			if (resp.ok) {
				const response = await resp.json();
				setData(response.data);
			}
		} catch (e) {
			console.log("Error", e);
			setNewNotification(true);
			setShowNotification(true);
			addNotification({
				id: Date.now(),
				message:
					"Could not fetch the credit account details at the moment.",
				status: false,
				archive: false,
			});
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (id) {
			callApi(id);
			setSegmentTitle("Credit Account Detail");
		}
	}, [id]);

	const handleCreditDocumentUpload = async () => {
		setLoading(true);
		try {
			const formdata = new FormData();
			formdata.append(
				"file",
				creditDocumentFile,
				creditDocumentFile.name
			);
			formdata.append("type", "CREDIT_DOCUMENT");
			const resp = await fetch(API_URL + `/api/1.0.0/uploadFile`, {
				method: "POST",
				headers: {
					"X-JWT-Assertion": localStorage.getItem("token"),
				},
				body: formdata,
				redirect: "follow",
			});
			if (resp.ok) {
				const responseData = await resp.json();
				if (responseData.result.responseCode === 200) {
					setCreditDocumentUploadURL(responseData.data);
					addToastMessage({
						status: true,
						message:
							"You have successfully uploaded the credit document.",
					});
				}
			} else {
				const errorData = await resp.json();
				const errorMessage = errorData.responseDescription;
				addToastMessage({
					status: false,
					message:
						errorMessage ||
						"Something went wrong while uploading a credit document. Please try again.",
				});
			}
		} catch (e) {
			console.log("Error", e);
			setNewNotification(true);
			setShowNotification(true);
			addNotification({
				id: Date.now(),
				message: "Could not upload the credit document at the moment.",
				status: false,
				archive: false,
			});
		} finally {
			setLoading(false);
		}
	};

	const downloadCreditDocumentPDF = async (e, id) => {
		e.preventDefault();
		const apiUrl = `${API_URL}/api/1.0.0/loan-application/credit-document/${id}`;

		try {
			const response = await fetch(apiUrl, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					"X-JWT-Assertion": localStorage.getItem("token"),
				},
			});
			const data = await response.json();
			if (data.result.responseCode === 200) {
				window.location.href = data.data;
			} else {
				const errorData = await response.json();
				throw new Error(
					errorData.result.responseDescription || "API request failed"
				);
			}
		} catch (e) {
			console.log("Error", e);
			setNewNotification(true);
			setShowNotification(true);
			addNotification({
				id: Date.now(),
				message:
					"We are currently unable to download the credit document. Please try again later.",
				status: false,
				archive: false,
			});
		}
	};

	const handleTenancyAgreementUpload = async () => {
		setLoading(true);
		try {
			const formdata = new FormData();
			formdata.append(
				"file",
				tenancyAgreementFile,
				tenancyAgreementFile.name
			);
			formdata.append("type", "TENANCY_AGREEMENT");
			const resp = await fetch(API_URL + `/api/1.0.0/uploadFile`, {
				method: "POST",
				headers: {
					"X-JWT-Assertion": localStorage.getItem("token"),
				},
				body: formdata,
				redirect: "follow",
			});
			if (resp.ok) {
				const responseData = await resp.json();
				if (responseData.result.responseCode === 200) {
					setTenancyAgreementUploadURL(responseData.data);
					addToastMessage({
						status: true,
						message:
							"Tenancy agreement has been uploaded successfully.",
					});
				}
			} else {
				const errorData = await resp.json();
				const errorMessage = errorData.responseDescription;
				addToastMessage({
					status: false,
					message:
						errorMessage ||
						"Something went wrong while uploading the tenancy agreement. Please try again.",
				});
			}
		} catch (e) {
			console.log("Error", e);
			setNewNotification(true);
			setShowNotification(true);
			addNotification({
				id: Date.now(),
				message:
					"Could not upload the tenancy agreement at the moment.",
				status: false,
				archive: false,
			});
		} finally {
			setLoading(false);
		}
	};

	const downloadTenancyAgreementPDF = async (e, id) => {
		e.preventDefault();
		const apiUrl = `${API_URL}/api/1.0.0/loan-application/tenancy-document/${id}`;

		try {
			const response = await fetch(apiUrl, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					"X-JWT-Assertion": localStorage.getItem("token"),
				},
			});
			const data = await response.json();
			if (data.result.responseCode === 200) {
				window.location.href = data.data;
			} else {
				const errorData = await response.json();
				throw new Error(
					errorData.result.responseDescription || "API request failed"
				);
			}
		} catch (e) {
			console.log("Error", e);
			setNewNotification(true);
			setShowNotification(true);
			addNotification({
				id: Date.now(),
				message:
					"We are currently unable to download the tenancy agreement. Please try again later.",
				status: false,
				archive: false,
			});
		}
	};

	const getStatusDetails = (id) => {
		const o = LOAN_APPLICATION_STATUSES.find((obj) => obj.id === id);
		return o
			? o
			: {
					title: "Not found",
					color: "#5dc6b3",
			  };
	};

	const getFrequencyDetails = (value) => {
		const status = FREQUENCY_TYPE_LIST.find((obj) => obj.value === value);
		return status
			? status
			: {
					name: "NA",
					value: "NA",
					shortName: "na",
			  };
	};

	return (
		<section className='pos-rel'>
			<PageTitle title='Credit Account Detail' />
			<FullPageLoader isActive={loading} />
			{data && (
				<section>
					<section className='property-detail pad-20'>
						<div className='flex center-left'>
							<p
								className='color-primary f-w-800'
								style={{
									width: "350px",
									overflow: "hidden",
									whiteSpace: "nowrap",
									textOverflow: "ellipsis",
								}}>
								<span>#</span>&nbsp;{data.loanAccountNo}
							</p>
							<div
								className='mar-l-a b-r-20'
								style={{
									background: getStatusDetails(
										data.loanStatusID
									).color,
									padding: "12px 24px",
								}}>
								<p className='color-white l-h-1'>
									{getStatusDetails(data.loanStatusID).title}
								</p>
							</div>
						</div>
						<br />
						<div className='wrap pad-20 pos-rel'>
							<table className='col-1-1'>
								<thead></thead>
								<tbody>
									<tr>
										<td colSpan='2'>
											<p className='f-w-600 color-primary'>
												Application:
											</p>
										</td>
									</tr>
									<tr>
										<td colSpan='2'>
											<hr />
										</td>
									</tr>
									<tr>
										<td>
											<div className='flex center-left'>
												<CiBookmark className='color-faded' />
												&nbsp;
												<p className='font-size-14 color-faded'>
													Application No
												</p>
												&nbsp;
												<NavLink
													to={`/te-my-applications/${data.loanApplicationID}`}>
													<p className='font-size-14 color-primary'>
														{data.loanApplicationNo}
													</p>
												</NavLink>
											</div>
										</td>
									</tr>
									<tr>
										<td colSpan='2'>
											<br />
										</td>
									</tr>

									<tr>
										<td colSpan='2'>
											<p className='f-w-600 color-primary'>
												Tenant Info:
											</p>
										</td>
									</tr>
									<tr>
										<td colSpan='2'>
											<hr />
										</td>
									</tr>
									<tr>
										<td>
											<div className='flex center-left'>
												<CiUser className='color-faded' />
												&nbsp;
												<p className='font-size-14 color-primary'>
													{
														data.tenantDetails
															.firstName
													}{" "}
													{
														data.tenantDetails
															.lastName
													}
												</p>
											</div>
										</td>
										<td>
											<div className='flex center-left'>
												<CiPhone className='color-faded' />
												&nbsp;
												<p className='font-size-14 color-primary'>
													{`(${data.tenantDetails.countryCode}) ${data.tenantDetails.phoneNo}`}
												</p>
											</div>
										</td>
									</tr>
									<tr>
										<td colSpan='2'>
											<div className='flex center-left'>
												<CiMail className='color-faded' />
												&nbsp;
												<p className='font-size-14 color-primary'>
													{data.tenantDetails.email}
												</p>
											</div>
										</td>
									</tr>
									<tr>
										<td colSpan='2'>
											<br />
										</td>
									</tr>
									<tr>
										<td colSpan='2'>
											<p className='f-w-600 color-primary'>
												Broker Info:
											</p>
										</td>
									</tr>
									<tr>
										<td colSpan='2'>
											<hr />
										</td>
									</tr>
									<tr>
										<td>
											<div className='flex center-left'>
												<CiUser className='color-faded' />
												&nbsp;
												<p className='font-size-14 color-primary'>
													{
														data.brokerDetails
															.firstName
													}{" "}
													{
														data.brokerDetails
															.lastName
													}
												</p>
											</div>
										</td>
										<td>
											<div className='flex center-left'>
												<CiPhone className='color-faded' />
												&nbsp;
												<p className='font-size-14 color-primary'>
													{`(${data.brokerDetails.countryCode}) ${data.brokerDetails.phoneNo}`}
												</p>
											</div>
										</td>
									</tr>
									<tr>
										<td colSpan='2'>
											<div className='flex center-left'>
												<CiMail className='color-faded' />
												&nbsp;
												<p className='font-size-14 color-primary'>
													{data.brokerDetails.email}
												</p>
											</div>
										</td>
									</tr>
									<tr>
										<td colSpan='2'>
											<br />
										</td>
									</tr>
									<tr>
										<td colSpan='2'>
											<p className='f-w-600 color-primary'>
												Offer Info:
											</p>
										</td>
									</tr>
									<tr>
										<td colSpan='2'>
											<hr />
										</td>
									</tr>
									{/* <tr>
												<td colSpan='1'>
													<div className='flex center-left'>
														<CiHospital1 className='color-faded' />
														&nbsp;
														<p className='font-size-14 color-faded'>
															Credit Provider
														</p>
														&nbsp;
														<p className='font-size-14 color-primary'>
															{data?.config
																?.creditProviderFirm
																.name
																? data?.config
																		?.creditProviderFirm
																		.name
																: "-"}
														</p>
													</div>
												</td>
												<td colSpan='1'>
													<div className='flex center-left'>
														<CiMoneyBill className='color-faded' />
														&nbsp;
														<p className='font-size-14 color-faded'>
															Processing Fee
														</p>
														&nbsp;
														<p className='font-size-14 color-primary'>
															{data?.config
																?.processingFee
																? data?.config
																		?.processingFee !==
																  0
																	? `AED ${parseFloat(
																		data?.config?.processingFee
														).toFixed(2)}/-`
																	: "Zero"
																: "Zero"}
														</p>
													</div>
												</td>
											</tr> */}
									<tr>
										<td colSpan='1'>
											<div className='flex center-left'>
												<CiMoneyBill className='color-faded' />
												&nbsp;
												<p className='font-size-14 color-faded'>
													Rental Amount
												</p>
												&nbsp;
												<p className='font-size-14 color-primary'>
													{data?.loanAmount
														? `${formatAmountValue(data?.loanAmount)}/- AED`
														: "-"}
												</p>
											</div>
										</td>
										<td colSpan='1'>
											<div className='flex center-left'>
												<CiMoneyBill className='color-faded' />
												&nbsp;
												<p className='font-size-14 color-faded'>
													Installment
												</p>
												&nbsp;
												<p className='font-size-14 color-primary'>
													{data?.installmentAmount
														? `${formatAmountValue(data?.loanAmount)}/- AED
														
                            ${getFrequencyDetails(data.frequency).name}`
														: "-"}
												</p>
											</div>
										</td>
									</tr>
									{/* <tr>
												<td colSpan='1'>
													<div className='flex center-left'>
														<CiRuler className='color-faded' />
														&nbsp;
														<p className='font-size-14 color-faded'>
														  EI Ratio
														</p>
														&nbsp;
														<p className='font-size-14 color-primary'>
															{data?.eiRatio
																?  data?.eiRatio
																: "-"}
														</p>
													</div>
												</td>
												</tr> */}
								</tbody>
							</table>
						</div>
					</section>
					<section className='property-detail pad-20'>
						<div className='wrap b-r-5 of-hid'>
							<div
								className='default-image of-hid'
								style={
									data.propertyDetails.s3PicturesList
										? {
												backgroundImage: `url(${data.propertyDetails.s3PicturesList[0]})`,
										  }
										: {}
								}>
								<img
									className='col-1-1'
									style={{
										height: "auto",
										display: "block",
									}}
									src={TransparentImage}
								/>
							</div>
							<br />
							<div className='pad-20'>
								<table className='col-1-1'>
									<thead></thead>
									<tbody>
										<tr>
											<td colSpan='2'>
												<p className='f-w-600 color-primary'>
													{
														data.propertyDetails
															.propertyName
													}
												</p>
											</td>
										</tr>
										<tr>
											<td colSpan='2'>
												<p className='font-size-14 color-primary'>
													{
														data.propertyDetails
															.description
													}
												</p>
											</td>
										</tr>
										<tr>
											<td colSpan='2'>
												<hr />
											</td>
										</tr>
										<tr>
											<td colSpan='2'>
												<div className='flex center-left'>
													<CiCalculator1 className='color-faded' />
													&nbsp;
													<p className='font-size-14 color-faded'>
														#RERA
													</p>
													&nbsp;
													<p className='font-size-14 color-primary'>
														{
															data.propertyDetails
																.reraNumber
														}
													</p>
												</div>
											</td>
										</tr>
										<tr>
											<td>
												<div className='flex center-left'>
													<CiLocationOn className='color-faded' />
													&nbsp;
													<p className='font-size-14 color-faded'>
														Address
													</p>
													&nbsp;
													<p className='font-size-14 color-primary'>
														{
															data.propertyDetails
																.propertyAddress
														}
													</p>
												</div>
											</td>
										</tr>
										<tr>
											<td>
												<div className='flex center-left'>
													<CiHome className='color-faded' />
													&nbsp;
													<p className='font-size-14 color-faded'>
														Size
													</p>
													&nbsp;
													<p className='font-size-14 color-primary'>
														{
															data.propertyDetails
																.propertySize
														}{" "}
														sq.ft.
													</p>
												</div>
											</td>
											<td>
												<div className='flex center-left'>
													<CiHome className='color-faded' />
													&nbsp;
													<p className='font-size-14 color-faded'>
														Type
													</p>
													&nbsp;
													<p className='font-size-14 color-primary'>
														{
															data.propertyDetails
																.propertyType
														}
													</p>
												</div>
											</td>
										</tr>
										<tr>
											<td colSpan='2'>
												<hr />
											</td>
										</tr>
										<tr>
											<td colSpan='2'>
												<div className='flex center-left'>
													<CiUser className='color-faded' />
													&nbsp;
													<p className='font-size-14 color-faded'>
														Owner
													</p>
													&nbsp;
													<p className='font-size-14 color-primary'>
														{
															data.propertyDetails
																.owner.fullName
														}
													</p>
												</div>
											</td>
										</tr>
										<tr>
											<td colSpan='2'>
												<div className='flex center-left'>
													<CiLocationOn className='color-faded' />
													&nbsp;
													<p className='font-size-14 color-primary'>
														{
															data.propertyDetails
																.owner.address
														}
													</p>
													<p className='font-size-14 color-faded'>
														,
													</p>
													&nbsp;
													<p className='font-size-14 color-primary'>
														{
															data.propertyDetails
																.owner.pinCode
														}
													</p>
												</div>
											</td>
										</tr>
										<tr>
											<td colSpan='2'>
												<div className='flex center-left'>
													<CiMail className='color-faded' />
													&nbsp;
													<p className='font-size-14 color-primary'>
														{
															data.propertyDetails
																.owner.email
														}
													</p>
												</div>
											</td>
										</tr>
										<tr>
											<td colSpan='2'>
												<div className='flex center-left'>
													<CiPhone className='color-faded' />
													&nbsp;
													<p className='font-size-14 color-primary'>
														{
															data.propertyDetails
																.owner
																.phoneNumber
														}
													</p>
												</div>
											</td>
										</tr>
										<tr>
											<td colSpan='2'>
												<hr />
											</td>
										</tr>
										<tr>
											<td>
												<a
													href={
														data.propertyDetails
															.deedCopySignUrl
													}
													target='_new'
													rel='noreferrer'>
													<div className='flex center-left'>
														<CiLink className='color-faded' />
														&nbsp;
														<p className='transition font-size-14 color-faded hover-color-secondary'>
															Deed Copy
														</p>
													</div>
												</a>
											</td>
											<td>
												<a
													href={
														data.propertyDetails
															.propertyUrl
													}
													target='_blank'
													rel='noreferrer'>
													<div className='flex center-right'>
														<CiLink className='color-primary' />
														&nbsp;
														<p className='transition font-size-14 color-faded hover-color-secondary'>
															Open URL
														</p>
													</div>
												</a>
											</td>
										</tr>
										{parseInt(data.loanStatusID) !==
											LOAN_APPLICATION_LOAN_INITIATED_STATUS && (
											<>
												<tr>
													<td colSpan='1'>
														<a
															href='#'
															onClick={(e) =>
																downloadTenancyAgreementPDF(
																	e,
																	data.loanApplicationID
																)
															}>
															<div className='flex center-left'>
																<CiLink className='color-primary' />
																&nbsp;
																<p className='transition font-size-14 color-faded hover-color-secondary'>
																	Tenancy
																	Agreement
																</p>
															</div>
															<br />
														</a>
													</td>
													<td colSpan='1'>
														<a
															href='#'
															onClick={(e) =>
																downloadCreditDocumentPDF(
																	e,
																	data.loanApplicationID
																)
															}>
															<div className='flex center-right'>
																<CiLink className='color-primary' />
																&nbsp;
																<p className='transition font-size-14 color-faded hover-color-secondary'>
																	Credit
																	Document
																</p>
															</div>
															<br />
														</a>
													</td>
												</tr>
											</>
										)}
										{data.disbursementProof && (
											<tr>
												<td colSpan='2'>
													<a
														href={
															data.disbursementProof
														}
														target='_blank'
														rel='noreferrer'>
														<div className='flex center-right'>
															<CiLink className='color-primary' />
															&nbsp;
															<p className='transition font-size-14 color-faded hover-color-secondary'>
																Disbursement
																Proof URL
															</p>
														</div>
													</a>
												</td>
											</tr>
										)}

										{parseInt(data.loanStatusID) ===
											LOAN_APPLICATION_LOAN_INITIATED_STATUS && (
											<>
												{" "}
												<tr>
													<td colSpan='2'>
														<br />
													</td>
												</tr>
												<tr>
													<td colSpan='2'>
														<div
															className='flex center-center'
															style={{
																gap: "12px",
															}}>
															<RSModal
																selector={
																	<ButtonLightTextIcon
																		type='button'
																		title={
																			"Pay Pre-Installment"
																		}
																		icon={
																			<CiMoneyBill />
																		}
																		disabled={
																			loading
																		}
																	/>
																}
																content={
																	<PayPreInstallmentModal
																		loanAmount={
																			data.loanAmount
																		}
																		frequency={
																			data.frequency
																		}
																		installmentAmount={
																			data.installmentAmount
																		}
																		callback={() => {
																			handlePayPreInstallment(
																				data.installmentAmount
																			);
																		}}
																	/>
																}
															/>
															<RSModal
																selector={
																	<ButtonTextIcon
																		type='button'
																		disabled={
																			loading
																		}
																		title='Reject'
																		icon={
																			<CiCircleRemove />
																		}
																	/>
																}
																content={
																	<ConfirmReject
																		loanApplicationID={
																			data.loanApplicationID
																		}
																		callback={() =>
																			callApi(
																				data.loanAccountID
																			)
																		}
																	/>
																}
															/>
														</div>
													</td>
												</tr>
											</>
										)}
										{parseInt(data.loanStatusID) ===
											LOAN_APPLICATION_INITIAL_PAYMENT_STATUS && (
											<>
												<tr>
													<td colSpan='2'>
														<br />
													</td>
												</tr>
												<tr>
													<td colSpan='2'>
														<p
															className=' font-size-14 color-primary '
															style={{
																maxWidth:
																	"320px",
															}}>
															NOTE: Please
															download the tenancy
															agreement & credit
															document, sign it,
															and upload it to
															complete the
															process.
														</p>
													</td>
												</tr>
												<tr>
													<td colSpan='2'>
														<hr />
													</td>
												</tr>
												<tr>
													<td colSpan='2'>
														<a
															href='#'
															onClick={(e) =>
																downloadTenancyAgreementPDF(
																	e,
																	data.loanApplicationID
																)
															}>
															<div className='flex center-left'>
																<CiLink className='color-primary' />
																&nbsp;
																<p className='transition font-size-14 color-faded hover-color-secondary'>
																	Click here
																	to download
																	tenancy
																	agreement
																</p>
															</div>
															<br />
														</a>
													</td>
												</tr>
												<tr>
													<td colSpan='2'>
														<a
															href='#'
															onClick={(e) =>
																downloadCreditDocumentPDF(
																	e,
																	data.loanApplicationID
																)
															}>
															<div className='flex center-left'>
																<CiLink className='color-primary' />
																&nbsp;
																<p className='transition font-size-14 color-faded hover-color-secondary'>
																	Click here
																	to download
																	credit
																	document
																</p>
															</div>
															<br />
														</a>
													</td>
												</tr>
												<tr>
													<td colSpan="2">
														<FieldTitle title="Tenancy Agreement" />
														<FileInput
															defaultText="Select Tenancy Agreement"
															accept="application/pdf"
															maxSizeAllowed={2 * 1024 * 1024}
															callback={(f) => {
																setTenancyAgreementFile(
																	f
																);
															}}
														/>
														{tenancyAgreementFile && (
															<p
																className="cursor-pointer"
																style={{
																	fontSize: "12px",
																	color: "red",
																	marginTop: "10px",
																}}
																onClick={() => {
																	setTenancyAgreementFile(
																		null
																	);
																	setTenancyAgreementUploadURL(
																		""
																	);
																}}
															>
																Delete tenancy agreement file
															</p>
														)}
													</td>
													<td className="t-a-r">
														<p>&nbsp;</p>
														<ButtonTextIcon
															type="button"
															title={tenancyAgreementUploadURL ? "Uploaded" : "Click to upload"}
															disabled={loading || !tenancyAgreementFile}
															icon={
																<>
																	{loading ? (
																		<LoaderIcon />
																	) : (
																		<>
																			{tenancyAgreementUploadURL ? <CiCircleCheck /> : <CiLocationArrow1 />}
																		</>
																	)}
																</>
															}
															onClick={handleTenancyAgreementUpload}
														/>

														{tenancyAgreementFile && (
															<p
																style={{
																	fontSize: "12px",
																	color: "red",
																	marginTop: "10px",
																}}
															>
																*Click to upload
															</p>
														)}
													</td>
												</tr>
												{/* <tr>
													<td colSpan='1'>
														<FieldTitle title='Tenancy Agreement' />
														<FileInput
															defaultText='Select Tenancy Agreement'
															accept='application/pdf'
															maxSizeAllowed={
																2 * 1024 * 1024
															}
															callback={(f) => {
																setTenancyAgreementFile(
																	f
																);
															}}
														/>
													</td>
													<td
														colSpan='1'
														style={{
															paddingLeft: "10px",
														}}>
														<p>&nbsp;</p>
														<ButtonTextIcon
															type='button'
															title={
																tenancyAgreementUploadURL
																	? "Uploaded"
																	: "Upload"
															}
															disabled={
																loading ||
																!tenancyAgreementFile
															}
															icon={
																<>
																	{loading ? (
																		<LoaderIcon />
																	) : (
																		<>
																			{tenancyAgreementUploadURL ? (
																				<CiCircleCheck />
																			) : (
																				<CiLocationArrow1 />
																			)}
																		</>
																	)}
																</>
															}
															onClick={
																handleTenancyAgreementUpload
															}
														/>
													</td>
												</tr>
												{tenancyAgreementFile && (
													<tr>
														<td colSpan='2'>
															<p
																className='cursor-pointer'
																style={{
																	fontSize:
																		"12px",
																	color: "red",
																}}
																onClick={() => {
																	setTenancyAgreementFile(
																		null
																	);
																	setTenancyAgreementUploadURL(
																		""
																	);
																}}>
																Delete tenancy
																agreement file
															</p>
														</td>
													</tr>
												)} */}
												<tr>
											<td colSpan="6" >
												<p className="status f-w-200 l-h-1 font-size-12">
													NOTE: Please upload files in PDF format, up to 2MB. Files exceeding these limits won't be accepted.
												</p>
											</td>
										</tr>
												<tr>
													<td colSpan='2'>
														<br />
													</td>
												</tr>
												<tr>
													<td colSpan='2'>
														<FieldTitle title='Credit Document' />
														<FileInput
															defaultText='Select Credit Document'
															accept='application/pdf,'
															maxSizeAllowed={
																2 * 1024 * 1024
															}
															callback={(f) => {
																setCreditDocumentFile(
																	f
																);
															}}
														/>
													</td>
													<td
														className="t-a-r"
														style={{
															paddingLeft: "12px",
														}}
														>
														<p>&nbsp;</p>
														<ButtonTextIcon
															type='button'
															title={
																creditDocumentUploadURL
																	? "Uploaded"
																	: "Click to upload"
															}
															disabled={
																loading ||
																!creditDocumentFile
															}
															icon={
																<>
																	{loading ? (
																		<LoaderIcon />
																	) : (
																		<>
																			{creditDocumentUploadURL ? (
																				<CiCircleCheck />
																			) : (
																				<CiLocationArrow1 />
																			)}
																		</>
																	)}
																</>
															}
															onClick={
																handleCreditDocumentUpload
															}
														/>
														{creditDocumentFile && (
															<p
																style={{
																	fontSize: "12px",
																	color: "red",
																	marginTop: "10px",
																}}
															>
																*Click to upload
															</p>
														)}
													</td>
												</tr>
												{creditDocumentFile && (
													<tr>
														<td colSpan='2'>
															<p
																className='cursor-pointer'
																style={{
																	fontSize:
																		"10px",
																	color: "red",
																}}
																onClick={() => {
																	setCreditDocumentFile(
																		null
																	);
																	setCreditDocumentUploadURL(
																		""
																	);
																}}>
																Delete credit
																document file
															</p>
														</td>
													</tr>
												)}
												<tr>
											<td colSpan="6" >
												<p className="status f-w-200 l-h-1 font-size-12">
													NOTE: Please upload files in PDF format, up to 2MB. Files exceeding these limits won't be accepted.
												</p>
											</td>
										</tr>
												<tr>
													<td colSpan='2'>
														<br />
													</td>
												</tr>
												<tr>
													<td colSpan='3'>
														<div
															className='flex center-center'
															style={{
																gap: "12px",
															}}>
															<RSModal
																selector={
																	<ButtonTextIcon
																		type='button'
																		disabled={
																			loading
																		}
																		title='Accept'
																		icon={
																			<CiCircleCheck />
																		}
																	/>
																}
																content={
																	<ConfirmAcceptChange
																		tenancyAgreementUploadURL={
																			tenancyAgreementUploadURL
																		}
																		creditDocumentUploadURL={
																			creditDocumentUploadURL
																		}
																		loanApplicationID={
																			data.loanApplicationID
																		}
																		callback={() =>
																			callApi(
																				data.loanAccountID
																			)
																		}
																	/>
																}
															/>
														</div>
													</td>
												</tr>
											</>
										)}
									</tbody>
								</table>
							</div>
						</div>
					</section>
				</section>
			)}
		</section>
	);
};

export default TenantLoanDetail;
