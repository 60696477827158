import { useEffect, useState } from "react";
import FullPageLoader from "../../components/ui/FullPageLoader";
import { NavLink, useLocation } from "react-router-dom";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import {
	CiCircleCheck,
	CiCirclePlus,
	CiNoWaitingSign,
	CiTrash,
} from "react-icons/ci";
import { API_URL } from "../../utilities/constants";
import RSModal from "../../components/ui/RSModal";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import Pagination from "../../components/common/Pagination";
import PageTitle from "../../components/common/PageTitle";
import ResultsPerPage from "../_common/ResultsPerPage";
import LoaderIcon from "../../components/ui/LoaderIcon";
import { useUI } from "../../context/ui";

const ConfirmStatusChange = ({
	brokerId,
	callback,
	setShow,
	currentStatusID,
	text,
}) => {
	const [loading, setLoading] = useState(false);
	const handleStatusChange = async () => {
		setLoading(true);
		try {
			const resp = await fetch(
				API_URL +
				`/api/1.0.0/user/${brokerId}/status/${currentStatusID === 1 ? 14 : 1
				}`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
						"X-JWT-Assertion": localStorage.getItem("token"),
					},
				}
			);
			if (resp.ok) {
				const response = await resp.json();
				if (response) {
					setShow(false);
					callback();
				}
			}
		} catch (e) {
			console.log("Error", e);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div
			style={{
				height: "auto",
				width: "480px",
				padding: "40px",
			}}>
			<h4 className='color-primary'>
				Are you sure you want to {text} the broker?
			</h4>
			<br />
			<p>
				Changing this might have some unexpected consequences, please
				make sure that you understand.
			</p>
			<br />
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "flex-end",
					gap: "12px",
				}}>
				<ButtonLightTextIcon
					title='Go back'
					icon={
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='26.562'
							height='17.809'
							viewBox='0 0 26.562 17.809'>
							<path d='M0,8.88,10.686,0l1.937,2.293L6.453,7.4H26.562v3H6.454l6.166,5.108-1.934,2.3Z' />
						</svg>
					}
					onClick={() => setShow(false)}
				/>
				{loading ? (
					<ButtonTextIcon
						type='button'
						title='Updating...'
						disabled={loading}
						icon={<LoaderIcon />}
					/>
				) : (
					<ButtonTextIcon
						title='I understand'
						icon={<CiCircleCheck />}
						onClick={() => handleStatusChange()}
					/>
				)}
			</div>
		</div>
	);
};

const ConfirmDelete = ({ brokerId, callback, setShow }) => {
	const [loading, setLoading] = useState(false);
	const handleDelete = async () => {
		setLoading(true);
		try {
			const resp = await fetch(API_URL + `/api/1.0.0/user/${brokerId}`, {
				method: "DELETE",
				headers: {
					"Content-Type": "application/json",
					"X-JWT-Assertion": localStorage.getItem("token"),
				},
			});
			if (resp.ok) {
				const response = await resp.json();
				if (response) {
					setShow(false);
					callback();
				}
			}
		} catch (e) {
			console.log("Error", e);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div
			style={{
				height: "auto",
				width: "480px",
				padding: "40px",
			}}>
			<h4 className='color-primary'>
				Are you sure you want to delete the broker?
			</h4>
			<br />
			<p>
				Once you delete the broker, the broker can no longer log in to
				Rent-Savvy.
			</p>
			<br />
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "flex-end",
					gap: "12px",
				}}>
				<ButtonLightTextIcon
					title='Go back'
					icon={
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='26.562'
							height='17.809'
							viewBox='0 0 26.562 17.809'>
							<path d='M0,8.88,10.686,0l1.937,2.293L6.453,7.4H26.562v3H6.454l6.166,5.108-1.934,2.3Z' />
						</svg>
					}
					onClick={() => setShow(false)}
				/>
				{loading ? (
					<ButtonTextIcon
						type='button'
						title='Deleting...'
						disabled={loading}
						icon={<LoaderIcon />}
					/>
				) : (
					<ButtonTextIcon
						title='Yes, Delete'
						icon={<CiCircleCheck />}
						onClick={() => handleDelete()}
					/>
				)}
			</div>
		</div>
	);
};

const CreditProvidersList = () => {
	const [loading, setLoading] = useState(false);
	const location = useLocation();
	const [data, setData] = useState(null);
	const { setSegmentTitle } = useUI();

	const callListApi = async () => {
		setLoading(true);
		const pageNo = new URLSearchParams(location.search).get("pageNo");
		const pageSize = new URLSearchParams(location.search).get("pageSize");
		try {
			const resp = await fetch(
				API_URL +
				`/api/1.0.0/credit-provider/user?pageNo=${pageNo}&pageSize=${pageSize}&sortOrder=desc`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						"X-JWT-Assertion": localStorage.getItem("token"),
					},
				}
			);
			if (resp.ok) {
				const response = await resp.json();
				setData(response.data);
			}
		} catch (e) {
			console.log("Error", e);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		callListApi();
		setSegmentTitle("Users");
	}, []);

	return (
		<section className='pos-rel'>
			<PageTitle title='Users' />
			<FullPageLoader isActive={loading} />
			<section>
				<div className='flex center-left' style={{ padding: "20px" }}>
					<ResultsPerPage
						targetURL={`/cpsa-credit-provider?pageNo=0&pageSize=`}
					/>
					<div style={{ marginLeft: "auto" }}>
						<NavLink to='/cpsa-credit-provider/add'>
							<ButtonTextIcon
								title='Add User'
								icon={<CiCirclePlus />}
							/>
						</NavLink>
					</div>
				</div>
				<div className='results'>
					<div className='rs-table-holder'>
						<table className='rs-table'>
							<thead>
								<tr>
									<th>Name</th>
									<th>Email</th>
									<th>Phone</th>
									<th>Status</th>
								</tr>
							</thead>
							{data && (
								<tbody>
									{data?.user.map((d, i) => (
										<tr key={i}>
											<td>{d.user.firstName} {d.user.lastName}</td>
											<td>{d.user.email}</td>
											<td>{`(${d.user.countryCode}) ${d.user.phoneNo}`}</td>
											<td>
												{(d.user.statusID === 1 ||
												d.user.statusID === 14) ? (
													<RSModal
														selector={
															<div
																className={`cursor-pointer status ${
																	d.user.statusID ===
																	1
																		? "active"
																		: "inactive"
																}`}>
																{d.user.statusID ===
																1
																	? "Active"
																	: "Inactive"}
															</div>
														}
														content={
															<ConfirmStatusChange
																text={
																	d.user.statusID ===
																	1
																		? "deactivate"
																		: "re-activate"
																}
																currentStatusID={
																	d.user.statusID
																}
																brokerId={d.id}
																callback={() =>
																	callListApi()
																}
															/>
														}
													/>
												) : (
													<div className='cursor-pointer status bg-color-tertiary'>
														Pending
													</div>
												)}
											</td>
											{/* <td>
												<div
													className={`status ${d.user.statusID === 1
															? "active"
															: "inactive"
														}`}>
													{d.user.statusID === 1
														? "Active"
														: "Inactive"}
												</div>
											</td> */}
										</tr>
									))}
								</tbody>
							)}
						</table>
					</div>
				</div>
				<br />
				{data && (
					<Pagination
						totalRecords={data.totalRecords}
						pageNo={data.pageNo}
						pageSize={data.pageSize}
						baseUrl='/cpsa-credit-provider'
					/>
				)}
			</section>
		</section>
	);
};

export default CreditProvidersList;
