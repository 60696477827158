import { useEffect } from "react";
import PageTitle from "../../components/common/PageTitle";
import { NavLink } from "react-router-dom";
import { DEFAULT_PER_PAGE } from "../../utilities/constants";
import { useUI } from "../../context/ui";
import ButtonTextOnly from "../../components/ui/ButtonTextOnly";
import { CiWarning } from "react-icons/ci";

const AddCreditScorePaymentFailure = () => {
	const { setSegmentTitle } = useUI();

	useEffect(() => {
		setSegmentTitle("Payment Failed");
	}, []);

	return (
		<>
			<section>
				<PageTitle title='Payment Failed' />
				<section
					className='flex center-center'
					style={{
						height: "100vh",
						width: "100%",
					}}>
					<div>
						<CiWarning
							className='color-secondary'
							style={{
								height: "80px",
								width: "auto",
								margin: "0 auto",
								display: "block",
							}}
						/>
						<br />
						<div className='t-a-c'>
							<h4
								className='color-primary'
								style={{ marginBottom: "10px" }}>
								Payment Failed!
							</h4>
							<p>
								We could not check your credit score at the
								moment.
								<br />
								Please try again later.
							</p>
							<br />
							<div>
								<NavLink
									to={`/te-my-applications?pageNo=0&pageSize=${DEFAULT_PER_PAGE}`}>
									<ButtonTextOnly title='Return Back' />
								</NavLink>
							</div>
						</div>
					</div>
				</section>
			</section>
		</>
	);
};

export default AddCreditScorePaymentFailure;
