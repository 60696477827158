import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import WithLayout from "../hoc/withLayout";
import { decodeRolesFromToken } from "../utilities/helpers";
import LoaderIcon from "../components/ui/LoaderIcon";

const hasRole = (sourceArray, targetArray) => {
	return targetArray.some((value) => sourceArray.includes(value));
};

const RoleBasedComponent = ({ component: Component, allowedRoles }) => {
	const [checking, setChecking] = useState(true);
	const navigate = useNavigate();
	const { pathname } = useLocation();

	const userRoleValidation = () => {
		const token = localStorage.getItem("token");
		if (token) {
			const roles = decodeRolesFromToken();
			const isAllowed = hasRole(roles, allowedRoles);
			if (!isAllowed) {
				navigate("/not-authorized");
			} else {
				setChecking(false);
			}
		} else {
			navigate("/login");
		}
	};

	useEffect(() => {
		userRoleValidation();
	}, [pathname]);

	if (checking) {
		return (
			<section
				style={{
					height: "100vh",
					width: "100vw",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}>
				<LoaderIcon
					style={{
						height: "24px",
						width: "24px",
					}}
				/>
			</section>
		);
	} else {
		return (
			<WithLayout>
				<Component />
			</WithLayout>
		);
	}
};

export default RoleBasedComponent;
