import React from "react";
import { CiChat1, CiUser, CiTimer } from "react-icons/ci"; // Assuming you're using react-icons for these icons
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon"; // Import your Button component
import { LOAN_APPLICATION_STATUSES } from "../../utilities/constants"; // Import your loan statuses
import { formatDateTime } from "../../utilities/helpers"; // Import your date formatting utility

const ApplicationHistoryView = ({ loanApplicationHistory, setShow }) => {
  const getStatusDetails = (id) => {
    const status = LOAN_APPLICATION_STATUSES.find((obj) => obj.id === id);
    return status
      ? status
      : {
          title: "Not found",
          color: "#5dc6b3",
        };
  };

  return (
    <div
      style={{
        height: "auto",
        width: "550px",
        padding: "40px",
      }}
    >
      <h4 className="color-primary">Application History</h4>
      <br />
      <div
        style={{
          maxHeight: "400px", // Set the max height for the scroll area
          overflowY: "auto", // Enable vertical scrolling
        }}
      >
        <section>
          {loanApplicationHistory &&
            loanApplicationHistory.map((h, i) => (
              <div
                key={i}
                style={{
                  position: "relative",
                  padding: "20px 0",
                  borderLeft: "2px solid #ddd",
                  marginLeft: "20px",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: "17px",
                    left: "-12px",
                    width: "22px",
                    height: "22px",
                    backgroundColor: "#fff",
                    border: "2px solid #ddd",
                    borderRadius: "50%",
                  }}
                ></div>
                <div
                  style={{
                    marginLeft: "40px",
                  }}
                >
                  <div className="flex center-left">
                    <div
                      className="b-r-20"
                      style={{
                        background: getStatusDetails(h.applicationStatus).color,
                        padding: "6px 12px",
                      }}
                    >
                      <p className="color-white l-h-1">
                        {getStatusDetails(h.applicationStatus).title}
                      </p>
                    </div>
                  </div>
                  <br />
                  <div className="flex center-left">
                    <CiChat1 className="color-faded" />
                    &nbsp;
                    <p className="font-size-14 color-faded">Comment:</p>
                    &nbsp;
                    <p className="font-size-14 color-primary">
                      {h.comment || "NA"}
                    </p>
                  </div>
                  <div className="flex center-left">
                    <CiUser className="color-faded" />
                    &nbsp;
                    <p className="font-size-14 color-faded">By:</p>
                    &nbsp;
                    <p className="font-size-14 color-primary">{h.createdBy}</p>
                  </div>
                  <div className="flex center-left">
                    <CiTimer className="color-faded" />
                    &nbsp;
                    <p className="font-size-14 color-faded">At:</p>
                    &nbsp;
                    <p className="font-size-14 color-primary">
                      {formatDateTime(h.createdAt)}
                    </p>
                  </div>
                </div>
              </div>
            ))}
        </section>
      </div>
      <br />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          gap: "12px",
        }}
      >
        <ButtonLightTextIcon
          title="Go back"
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26.562"
              height="17.809"
              viewBox="0 0 26.562 17.809"
            >
              <path d="M0,8.88,10.686,0l1.937,2.293L6.453,7.4H26.562v3H6.454l6.166,5.108-1.934,2.3Z" />
            </svg>
          }
          onClick={() => setShow(false)}
        />
      </div>
    </div>
  );
};

export default ApplicationHistoryView;
