import { createContext, useContext, useState, useCallback } from "react";
import ButtonTextIcon from "../components/ui/ButtonTextIcon";
import { CiCircleCheck } from "react-icons/ci";
import ButtonLightTextIcon from "../components/ui/ButtonLightTextIcon";
import { useNavigate } from "react-router-dom";
import PopUpModal from "../components/common/PopUpModal";
import { useOutsideClick } from "../utilities/hooks";
import SingleToast from "../components/common/SingleToast";

const UIContext = createContext();

const UIProvider = ({ children }) => {
	const [showNotification, setShowNotification] = useState(false);
	const [notificationsList, setNotificationsList] = useState([]);
	const [newNotification, setNewNotification] = useState(false);
	const [toastMessage, setToastMessage] = useState([]);
	const [segmentTitle, setSegmentTitle] = useState("");
	const [showLogout, setShowLogout] = useState(false);
	const navigate = useNavigate();

	const handleLogout = () => {
		localStorage.removeItem("token");
		setShowLogout(false);
		navigate("/login");
	};

	const archiveNotification = (id) => {
		const arr = notificationsList.map((single, i) => {
			if (single.id === id) {
				return {
					...single,
					archive: true,
				};
			} else {
				return single;
			}
		});
		setNotificationsList(arr);
	};

	const archiveAllNotifications = () => {
		const arr = notificationsList.map((item) => {
			return {
				...item,
				archive: true,
			};
		});
		setNotificationsList(arr);
	};

	const addNotification = (d) => {
		setNotificationsList([...notificationsList, d]);
	};

	const [box] = useOutsideClick({
		state: true,
		callBack: () => {
			setShowLogout(false);
		},
	});

	const addToastMessage = (d) => {
		setToastMessage((toastMessage) => [
			{
				...d,
				id: Date.now(),
			},
			...toastMessage,
		]);
	};

	const removeToast = useCallback((id) => {
		setToastMessage((prevToasts) =>
			prevToasts.filter((toast) => toast.id !== id)
		);
	}, []);

	return (
		<UIContext.Provider
			value={{
				showNotification,
				setShowNotification,
				notificationsList,
				setNotificationsList,
				newNotification,
				setNewNotification,
				segmentTitle,
				setSegmentTitle,
				addNotification,
				archiveNotification,
				archiveAllNotifications,
				setShowLogout,
				handleLogout,
				addToastMessage,
			}}>
			{showLogout && (
				<PopUpModal>
					<section id='rs-modal'>
						<div className='inner'>
							<div className='box' ref={box}>
								<div
									style={{
										height: "auto",
										width: "480px",
										padding: "40px",
									}}>
									<h4 className='color-primary'>
										Are you sure you want to log out of
										rent-savvy?
									</h4>
									<br />
									<p>
										Once you log out you will need to login
										with your username and password to
										resume using rent-savvy.com.
									</p>
									<br />
									<div
										className='flex center-center'
										style={{
											justifyContent: "flex-end",
											gap: "12px",
										}}>
										<ButtonLightTextIcon
											title='Cancel'
											icon={
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width='26.562'
													height='17.809'
													viewBox='0 0 26.562 17.809'>
													<path d='M0,8.88,10.686,0l1.937,2.293L6.453,7.4H26.562v3H6.454l6.166,5.108-1.934,2.3Z' />
												</svg>
											}
											onClick={() => setShowLogout(false)}
										/>
										<ButtonTextIcon
											title='Yes, Logout'
											icon={<CiCircleCheck />}
											onClick={() => {
												handleLogout();
											}}
										/>
									</div>
								</div>
							</div>
						</div>
					</section>
				</PopUpModal>
			)}
			{toastMessage.length > 0 && (
				<>
					<div id='rs-toast-messages'>
						{toastMessage.map((t, i) => (
							<SingleToast
								data={t}
								key={t.id}
								removeToast={removeToast}
							/>
						))}
					</div>
				</>
			)}
			{children}
		</UIContext.Provider>
	);
};

const useUI = () => {
	const context = useContext(UIContext);
	if (!context) {
		throw new Error("Curate Provider error.");
	}
	return context;
};

export { UIProvider, useUI };
