import { useState, useEffect } from "react";
import { NavLink, useLocation, useParams } from "react-router-dom";
import {
  CiCirclePlus,
  CiHospital1,
  CiMail,
  CiPhone,
  CiUser,
} from "react-icons/ci";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import { API_URL } from "../../utilities/constants";
import FullPageLoader from "../../components/ui/FullPageLoader";
import Pagination from "../../components/common/Pagination";
import PageTitle from "../../components/common/PageTitle";
import ResultsPerPage from "../_common/ResultsPerPage";
import { useUI } from "../../context/ui";
import { formatAmountValue } from "../../utilities/helpers";

const SaCreditProvider = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const { setSegmentTitle } = useUI();
  const { id } = useParams();

  const callApi = async (id) => {
    setLoading(true);
    try {
      const resp = await fetch(
        API_URL + `/api/1.0.0/credit-provider/firm/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "X-JWT-Assertion": localStorage.getItem("token"),
          },
        }
      );
      if (resp.ok) {
        const response = await resp.json();
        setData(response.data);
      }
    } catch (e) {
      console.log("Error", e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    callApi(id);
    setSegmentTitle("Credit Provider");
  }, [id]);

  return (
    <section>
      <PageTitle title="Credit Provider" />
      <FullPageLoader isActive={loading} />
      {data && (
        <section className="property-detail pad-20">
          {/* <h5 className="color-primary">{data.name}</h5> */}
          {/* <br /> */}
          <div className="wrap pad-20 pos-rel">
            <table className="col-1-1">
              <thead></thead>
              <tbody>
                <tr>
                  <td colSpan="2">
                    {" "}
                    <div className="flex center-left" style={{ gap: "8px" }}>
                      <div
                        className="b-r-100 of-hid default-image "
                        style={{
                          height: "32px",
                          width: "32px",
                          backgroundImage: data.logo
                            ? `url(${data.logo})`
                            : null,
                        }}
                      ></div>
                      &nbsp;
                      <p className="f-w-600 color-primary">{data.name}</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <hr />
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <br />
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <div className="flex center-left">
                      <CiMail />
                      &nbsp;
                      <p className="font-size-14 color-faded">Email :</p>
                      &nbsp;
                      <p className="font-size-14 color-primary">{data.email}</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <div className="flex center-left">
                      <CiPhone className="color-faded" />
                      &nbsp;
                      <p className="font-size-14 color-faded">Landline Number :</p>
                      &nbsp;
                      <p className="font-size-14 color-primary">
                        {`(${data.landline}`}
                      </p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <br />
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <p className="f-w-600 color-primary">Config:</p>
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <hr />
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <br />
                  </td>
                </tr>
              </tbody>
            </table>

            {
              data.configList &&
                data.configList.map((c, i) => (
                  <section className="config-cards">
                    <div className="config-card">
                      <div style={{ width: "573px", padding: "20px" }}>
                        <h6 className="color-primary hover-color-secondary transition">
                          Config {i + 1} :
                        </h6>
                        <br />
                        <table  className="col-1-1">
                          <thead></thead>
                          <tbody>
                            <tr>
                              <td>
                                <div className="flex center-left">
                                  <p className="font-size-14 color-faded">
                                    Min Rental Amount :
                                  </p>
                                  &nbsp;
                                  <p className="font-size-14 color-primary">
                                    {c.minLoanAmount
                                      ? `${formatAmountValue(c.minLoanAmount)}/- AED`
                                      : "-"}
                                  </p>
                                </div>
                              </td>
                              <td>
                                <div className="flex center-left">
                                  <p className="font-size-14 color-faded">
                                    Max Rental Amount :
                                  </p>
                                  &nbsp;
                                  <p className="font-size-14 color-primary">
                                    {c.maxLoanAmount
                                      ? `${formatAmountValue(c.maxLoanAmount)}/- AED`
                                      : "-"}
                                  </p>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div className="flex center-left">
                                  <p className="font-size-14 color-faded">
                                    Min Credit Score :
                                  </p>
                                  &nbsp;
                                  <p className="font-size-14 color-primary">
                                    {c.minCreditScore ? c.minCreditScore : "-"}
                                  </p>
                                </div>
                              </td>
                              <td>
                                <div className="flex center-left">
                                  <p className="font-size-14 color-faded">
                                    Max Credit Score :
                                  </p>
                                  &nbsp;
                                  <p className="font-size-14 color-primary">
                                    {c.maxCreditScore ? c.maxCreditScore : "-"}
                                  </p>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div className="flex center-left">
                                  <p className="font-size-14 color-faded">
                                    Frequency :
                                  </p>
                                  &nbsp;
                                  <p className="font-size-14 color-primary">
                                    {c.frequency
                                      ? c.frequency
                                          .toLowerCase()
                                          .charAt(0)
                                          .toUpperCase() +
                                        c.frequency.toLowerCase().slice(1)
                                      : "-"}
                                  </p>
                                </div>
                              </td>
                              <td>
                                <div className="flex center-left">
                                  <p className="font-size-14 color-faded">
                                    Risk Ratio :
                                  </p>
                                  &nbsp;
                                  <p className="font-size-14 color-primary">
                                    {c.riskRatio ? c.riskRatio : "-"}
                                  </p>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div className="flex center-left">
                                  <p className="font-size-14 color-faded">
                                    Processing fee :
                                  </p>
                                  &nbsp;
                                  <p className="font-size-14 color-primary">
                                    {c.processingFee
                                      ? `${formatAmountValue(c.processingFee)}/- AED`
                                      : "Free"}
                                  </p>
                                </div>
                              </td>
                              <td>
                                <div className="flex center-left">
                                  <p className="font-size-14 color-faded">
                                  Interest  :
                                  </p>
                                  &nbsp;
                                  <p className="font-size-14 color-primary">
                                    {c.interestPercent
                                      ? ` ${c.interestPercent}%`
                                      : "-"}
                                  </p>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        
                      </div>
                    </div>
                  </section>
                ))
            }
          </div>
        </section>
      )}
    </section>
  );
};

export default SaCreditProvider;
