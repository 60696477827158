import { useEffect, useState } from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { API_URL, DEFAULT_PER_PAGE } from "../../utilities/constants";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import ButtonTextOnly from "../../components/ui/ButtonTextOnly";
import FullPageLoader from "../../components/ui/FullPageLoader";
import PageTitle from "../../components/common/PageTitle";
import { useUI } from "../../context/ui";

const UpdateAgency = () => {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const token = localStorage.getItem("token");
	const [allAgencySubscriptionTypes, setAllAgencySubscriptionTypes] =
		useState(null);
	const {
		setNewNotification,
		setShowNotification,
		addNotification,
		setSegmentTitle,
		addToastMessage,
	} = useUI();
	const { id } = useParams();

	const [initialValues, setInitialValues] = useState({
		startDate: "",
		endDate: "",
		incentive: "",
		subscriptionFrequency: "",
		subscriptionAmount: "",
	});

	const transformInitialValues = (data) => {
		const transformedData = {};
		for (const key in data) {
			transformedData[key] = data[key] === null ? "" : data[key];
		}
		return transformedData;
	};

	const getInitialData = async () => {
		setLoading(true);
		try {
			const [resp1, resp2] = await Promise.all([
				fetch(API_URL + "/api/1.0.0/commercialConfig/" + id, {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						"X-JWT-Assertion": token,
					},
				}),
				fetch(API_URL + "/api/1.0.0/ref/agencySubscriptionTypes", {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						"X-JWT-Assertion": token,
					},
				}),
			]);
			const response1 = await resp1.json();
			const transformedData = transformInitialValues(response1.data);
			setInitialValues(transformedData);
			const response2 = await resp2.json();
			setAllAgencySubscriptionTypes(response2.data);
		} catch (e) {
			console.log("Error", e);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getInitialData();
		setSegmentTitle("Update Details");
	}, []);

	const ValidationSchema = Yup.object({
		startDate: Yup.date().required("Required"),
		endDate: Yup.date()
			.required("Required")
			.min(
				Yup.ref("startDate"),
				"End Date must be later than Start Date"
			),
		incentive: Yup.string()
			.matches(/^\d+(\.\d+)?$/)
			.required("Incentive is required"),
		subscriptionFrequency: Yup.string().required(
			"Subscription Frequency is required"
		),
		subscriptionAmount: Yup.string()
			.matches(/^[0-9]*$/, "Only numbers allowed!")
			.required("Subscription Amount is required"),
	});

	const handleUpdate = async (values) => {
		setLoading(true);
		try {
			const resp = await fetch(API_URL + "/api/1.0.0/commercialConfig", {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					"X-JWT-Assertion": token,
				},
				body: JSON.stringify({
					id: id,
					startDate: values.startDate,
					endDate: values.endDate,
					incentive: values.incentive,
					subscriptionFrequency: values.subscriptionFrequency,
					subscriptionAmount: values.subscriptionAmount,
				}),
			});

			if (resp.ok) {
				addToastMessage({
					status: true,
					message:
						"You have successfully updated agency commercial details, thank you.",
				});
				setNewNotification(true);
				addNotification({
					id: Date.now(),
					message:
						"You have successfully updated agency commercial details!",
					status: true,
					archive: false,
				});
				navigate(`/sa-agency?pageNo=0&pageSize=${DEFAULT_PER_PAGE}`);
			}
			else {
				const errorData = await resp.json();
				const errorMessage = errorData.responseDescription;
				addToastMessage({
					status: false,
					message:
						errorMessage ||
						"Something went wrong while updating agency commercial details. Please try again.",
				});
			}
		} catch (e) {
			setNewNotification(true);
			addNotification({
				id: Date.now(),
				message: "Something went wrong when updating an agency!",
				status: false,
				archive: false,
			});
		} finally {
			setLoading(false);
		}
	};

	return (
		<section>
			<PageTitle title='Update Agency' />
			<FullPageLoader isActive={loading} />
			<section className='create-agency'>
				<h5 className='color-primary'>Commercial Details</h5>
				<p>Update the commercial terms</p>
				<br />
				<section className='table-wrapper'>
					<Formik
						initialValues={initialValues}
						validationSchema={ValidationSchema}
						enableReinitialize={true}
						onSubmit={async (values, { validateForm }) => {
							handleUpdate(values);
						}}>
						{({
							values,
							errors,
							touched,
							validateForm,
							setFieldValue,
							resetForm,
							handleSubmit,
						}) => (
							<Form>
								<table>
									<thead></thead>
									<tbody>
										<tr>
											<td colSpan='3'>
												<p className='title f-w-700 color-primary'>
													Start Date
												</p>
												<Field
													name='startDate'
													type='date'
													placeholder='Select Date'
													className={
														" " +
														(errors.startDate &&
														touched.startDate
															? " error"
															: "")
													}
												/>
											</td>
											<td colSpan='3'></td>
										</tr>
										<tr>
											<td colSpan='3'>
												<p className='title f-w-700 color-primary'>
													Valid Till Date
												</p>
												<Field
													name='endDate'
													type='date'
													placeholder='Select Date'
													className={
														" " +
														(errors.endDate &&
														touched.endDate
															? " error"
															: "")
													}
												/>
											</td>
											<td colSpan='3'></td>
										</tr>
										<tr>
											<td colSpan='3'>
												<p className='title f-w-700 color-primary'>
													Incentive %{" "}
												</p>
												<Field
													name='incentive'
													type='text'
													placeholder='Incentive % [Eg: 5] *'
													className={
														" " +
														(errors.incentive &&
														touched.incentive
															? " error"
															: "")
													}
												/>
											</td>
											<td colSpan='3'></td>
										</tr>
										<tr>
											<td colSpan='3'>
												<p className='title f-w-700 color-primary'>
													Subscription Frequency
												</p>
												<Field
													as='select'
													name='subscriptionFrequency'
													className={
														"col-1-1 " +
														(errors.subscriptionFrequency &&
														touched.subscriptionFrequency
															? " error"
															: "")
													}>
													<option value=''>
														Select
													</option>
													{allAgencySubscriptionTypes && (
														<>
															{allAgencySubscriptionTypes.map(
																(t, i) => (
																	<option
																		key={i}
																		value={
																			t.name
																		}>
																		{t.name}
																	</option>
																)
															)}
														</>
													)}
												</Field>
											</td>
											<td colSpan='3'></td>
										</tr>
										<tr>
											<td colSpan='3'>
												<p className='title f-w-700 color-primary'>
													Subscription Amount in AED
												</p>
												<Field
													name='subscriptionAmount'
													type='text'
													placeholder='Amount in AED [Eg: 20000] *'
													className={
														" " +
														(errors.subscriptionAmount &&
														touched.subscriptionAmount
															? " error"
															: "")
													}
												/>
											</td>
											<td colSpan='3'></td>
										</tr>
										<tr>
											<td
												colSpan='6'
												className='btns t-a-r'>
												<NavLink
													to={`/sa-agency?pageNo=0&pageSize=${DEFAULT_PER_PAGE}`}>
													<ButtonLightTextIcon
														type='button'
														title='Go Back'
													/>
												</NavLink>
												<ButtonTextOnly
													type='submit'
													title='Update'
												/>
											</td>
										</tr>
									</tbody>
								</table>
							</Form>
						)}
					</Formik>
				</section>
			</section>
		</section>
	);
};

export default UpdateAgency;
