import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import PageTitle from "../../components/common/PageTitle";
import FullPageLoader from "../../components/ui/FullPageLoader";
import { CiBookmarkPlus, CiCircleCheck, CiHome, CiUser } from "react-icons/ci";
import SearchCustomer from "../../components/loan/SearchCustomer";
import SearchProperty from "../../components/properties/SearchProperty";
import ButtonTextOnly from "../../components/ui/ButtonTextOnly";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import LoaderIcon from "../../components/ui/LoaderIcon";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { API_URL, DEFAULT_PER_PAGE } from "../../utilities/constants";
import { useUI } from "../../context/ui";
import { CustomSelect, CountryOptions } from "../../utilities/helpers";


const AgencyBrokerCreateApplication = () => {
	const [loading, setLoading] = useState(false);
	const [propertyDetails, setPropertyDetails] = useState("");
	const [showReraSearch, setShowReraSearch] = useState(true);
	const navigate = useNavigate();
	const {
		setNewNotification,
		setShowNotification,
		addNotification,
		setSegmentTitle,
		addToastMessage,
	} = useUI();

	const location = useLocation();
	useEffect(() => {
		if (location.state) {
			const { propertyDetails } = location.state;
			if (propertyDetails) {
				setPropertyDetails(propertyDetails);
				setShowReraSearch(false);
			}
		}
	}, [location.state]);

	const Row = ({ children }) => {
		return (
			<div className='row b-r-8'>
				<div className='col-1-1 wrap flex'>{children}</div>
			</div>
		);
	};

	const SectionTitle = ({ title, subtitle, icon }) => {
		return (
			<div className='col-1-2'>
				<div
					className='flex center-left'
					style={{
						gap: "12px",
					}}>
					{icon}
					<div className='flex center-left'>
						<div>
							<p className='f-w-700 l-h-1-2 color-primary'>
								{title}
							</p>
							<p className='l-h-1-2 color-light small'>
								{subtitle}
							</p>
						</div>
					</div>
				</div>
			</div>
		);
	};

	const SectionForm = ({ children }) => {
		return (
			<div className='col-1-2'>
				<table>
					<thead></thead>
					<tbody>{children}</tbody>
				</table>
			</div>
		);
	};

	const FieldTitle = ({ title }) => {
		return (
			<p
				className='title f-w-700 color-primary'
				style={{ marginBottom: "5px", fontSize: "14px" }}>
				{title}
			</p>
		);
	};

	const LikeInput = ({ title }) => {
		return (
			<div className='ui-like-input col-1-1 cursor-not-allowed'>
				{title ? title : " "}
			</div>
		);
	};

	const FormValidation = Yup.object().shape({
		tenantEmail: Yup.string()
			.strict()
			.email("Invalid email format")
			.required("Customer email is required!")
			.matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/, "The email must have at least 2 characters after the last dot"),
		tenantCountryCode: Yup.string().required("Required"),
		tenantPhoneNo: Yup.string()
			.matches(
				/^5\d{8}$/,
				"The number should start from 5 followed by 8 digits"
			)
			.required("Phone number is required!")
			.min(9, "The number should start from 5 followed by 8 digits")
			.max(9, "The number should start from 5 followed by 8 digits"),
			tenantFirstName: Yup.string()
			.matches(/^[A-Za-z\s-]+$/, "First name should contain only letters!")
			.required("Customer name is required!"),
		tenantLastName: Yup.string()
			.required("Customer last name is required")
			.matches(
				/^[A-Za-z\s-]+$/,
				"Customer last name should contain only letters!"
			),
		agreementType: Yup.string().required("Required"),
		rentalValue: Yup.string()
			.matches(/^[0-9]*$/, "Only numbers allowed!")
			.required("Rental value is required"),
		rentalTerms: Yup.string(),
	});

	const initial = {
		tenantEmail: "",
		tenantCountryCode: "+971",
		tenantPhoneNo: "",
		tenantFirstName: "",
		tenantLastName: "",
		agreementType: "Biparty",
		rentalValue: "",
		rentalTerms: "",
	};

	const SingleStep = ({ title, subtitle, completion }) => {
		const getClassName = () => {
			const hasEmptyElement = completion.some(
				(element) => element === ""
			);
			if (hasEmptyElement) {
				return "incomplete";
			} else {
				return "complete";
			}
		};

		return (
			<div className={`step t-a-c ${getClassName()}`}>
				<p
					className='color-primary'
					style={{ fontSize: "12px", marginBottom: "5px" }}>
					<span className='f-w-700'>0{title}</span> {subtitle}
				</p>
				<div className={`b-r-100 icon`}>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='24'
						height='24'
						viewBox='0 0 24 24'>
						<path d='M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.25 16.518l-4.5-4.319 1.396-1.435 3.078 2.937 6.105-6.218 1.421 1.409-7.5 7.626z' />
					</svg>
				</div>
			</div>
		);
	};

	useEffect(() => {
		setSegmentTitle("New Application");
	}, []);

	useEffect(() => {
		const onScroll = () => {
			const elem = document.getElementById("stepper-should-fix");
			const impact = document.getElementById("stepper-should-impact");
			if (elem && impact) {
				if (window.scrollY > 92) {
					elem.classList.add("loan-application-stepper-fixed-position");
					impact.classList.add("stepper-should-impact-fixed-position");
				} else {
					elem.classList.remove(
						"loan-application-stepper-fixed-position"
					);
					impact.classList.remove("stepper-should-impact-fixed-position");
				}
			}
		};
		window.removeEventListener("scroll", onScroll);
		window.addEventListener("scroll", onScroll, { passive: true });
		return () => window.removeEventListener("scroll", onScroll);
	});

	return (
		<section>
			<PageTitle title='Create Loan application' />
			<FullPageLoader isActive={loading} />
			<Formik
				initialValues={initial}
				validationSchema={FormValidation}
				onSubmit={async (values, { validateForm }) => {
					setLoading(true);
					try {
						const request = {
							loanApplicationDetails: {
								agreementType: values.agreementType,
								rentalValue: values.rentalValue,
								rentalTerms: values.rentalTerms,
							},
							tenantDetails: {
								email: values.tenantEmail,
								countryCode: values.tenantCountryCode,
								phoneNo: values.tenantPhoneNo,
								firstName: values.tenantFirstName,
								lastName: values.tenantLastName,
							},
							propertyDetails: {
								...propertyDetails,
							},
						};

						const resp = await fetch(
							API_URL + "/api/1.0.0/loan-application",
							{
								method: "POST",
								headers: {
									"Content-Type": "application/json",
									"X-JWT-Assertion":
										localStorage.getItem("token"),
								},
								body: JSON.stringify(request),
							}
						);

						if (resp.ok) {
							addToastMessage({
								status: true,
								message:
									"You have successfully created the application, thank you!",
							});
							const response = await resp.json();
							if (response.data) {
								setNewNotification(true);
								addNotification({
									id: Date.now(),
									message:
										"Application created successfully!",
									status: true,
									archive: false,
								});

								navigate(
									`/ab-loan-applications/${response.data.applicationId}`
								);
							}
						} else {
							const errorData = await resp.json();
							const errorMessage = errorData.responseDescription;
							addToastMessage({
								status: false,
								message:
									errorMessage ||
									"Something went wrong while creating a loan application. Please try again",
							});
							setNewNotification(true);
							addNotification({
								id: Date.now(),
								message:
									"Something went wrong while creating a loan application",
								status: false,
								archive: false,
							});
						}
					} catch (e) {
						addToastMessage({
							status: false,
							message:
								e ||
								"Something went wrong while creating a loan application. Please try again",
						});
						setNewNotification(true);
						addNotification({
							id: Date.now(),
							message:
								"Something went wrong while creating a loan application",
							status: false,
							archive: false,
						});
					} finally {
						setLoading(false);
					}
				}}>
				{({
					values,
					errors,
					touched,
					validateForm,
					setFieldValue,
					resetForm,
					handleSubmit,
				}) => (
					<Form>
						<section>
							<section
								id='stepper-should-fix'
								className='loan-application-stepper'>
								<div
									className='steps flex center-center'
									style={{ gap: "20px" }}>
									<SingleStep
										title='1'
										subtitle='Customer Info'
										completion={[
											values.tenantEmail,
											values.tenantCountryCode,
											values.tenantPhoneNo,
											values.tenantFirstName,
											values.tenantLastName,
										]}
									/>
									<SingleStep
										title='2'
										subtitle='Property details'
										completion={[propertyDetails + ""]}
									/>
									<SingleStep
										title='3'
										subtitle='Owner Info'
										completion={[propertyDetails + ""]}
									/>
									<SingleStep
										title='4'
										subtitle='Rental agreement'
										completion={[
											values.agreementType,
											values.rentalValue,
										]}
									/>
								</div>
							</section>
							<section
								id='stepper-should-impact'
								className='pad-20 create-loan-application'>
								<Row>
									<SectionTitle
										title='Customer Information'
										subtitle='Enter basic customer information'
										icon={
											<CiUser
												className='color-primary'
												style={{
													height: "32px",
													width: "32px",
													strokeWidth: "1px",
												}}
											/>
										}
									/>
									<SectionForm>
										<tr>
											<td colSpan='2'>
												<FieldTitle title='Search Customer' />
												<SearchCustomer
													placeholder='Search customer by email'
													callback={(d) => {
														setFieldValue(
															"tenantEmail",
															d.email
														);
														setFieldValue(
															"tenantCountryCode",
															d.countryCode
														);
														setFieldValue(
															"tenantPhoneNo",
															d.phoneNo
														);
														setFieldValue(
															"tenantFirstName",
															d.firstName
														);
														setFieldValue(
															"tenantLastName",
															d.lastName
														);
													}}
												/>
												<p
													className='color-light'
													style={{
														fontSize: "12px",
														marginTop: "10px",
													}}>
													Did not find the customer?
													Enter details below.
												</p>
											</td>
										</tr>
										<tr>
											<td colSpan='2'>
												<FieldTitle title='Email' />
												<Field
													name='tenantEmail'
													type='email'
													placeholder='Enter Email *'
													className={
														"col-1-1 " +
														(errors.tenantEmail &&
															touched.tenantEmail
															? " error"
															: "")
													}
												/>
												<ErrorMessage
													name='tenantEmail'
													component='p'
													className='error-messages'
												/>
											</td>
										</tr>
										<tr>
											<td width='120'>
												<FieldTitle title='Phone' />
												<Field name='tenantCountryCode'>
															{({ field }) => (
																<CustomSelect
																	{...field}
																	options={CountryOptions}
																	error={errors.tenantCountryCode && touched.tenantCountryCode}
																	onChange={(e) => setFieldValue('tenantCountryCode', e.target.value)}
																/>
															)}
														</Field>
												
											</td>
											<td>
												<FieldTitle title='&nbsp;' />
												<Field
													name='tenantPhoneNo'
													type='text'
													placeholder='Eg 500000000 *'
													maxLength={9}
													className={
														"col-1-1 " +
														(errors.tenantPhoneNo &&
															touched.tenantPhoneNo
															? " error"
															: "")
													}
												/>
												<ErrorMessage
													name='tenantPhoneNo'
													component='p'
													className='error-messages'
												/>
											</td>
										</tr>
										<tr>
											<td colSpan='2'>
												<FieldTitle title='First name' />
												<Field
													name='tenantFirstName'
													type='text'
													placeholder='Eg: John *'
													className={
														"col-1-1 " +
														(errors.tenantFirstName &&
															touched.tenantFirstName
															? " error"
															: "")
													}
												/>
												<ErrorMessage
													name='tenantFirstName'
													component='p'
													className='error-messages'
												/>
											</td>
										</tr>
										<tr>
											<td colSpan='2'>
												<FieldTitle title='Last name' />
												<Field
													name='tenantLastName'
													type='text'
													placeholder='Eg: Smith *'
													className={
														"col-1-1 " +
														(errors.tenantLastName &&
															touched.tenantLastName
															? " error"
															: "")
													}
												/>
												<ErrorMessage
													name='tenantLastName'
													component='p'
													className='error-messages'
												/>
											</td>
										</tr>
									</SectionForm>
								</Row>
								<br />
								<Row>
									<SectionTitle
										title='Property Details'
										subtitle='Search for property using RERA Number'
										icon={
											<CiHome
												className='color-primary'
												style={{
													height: "32px",
													width: "32px",
													strokeWidth: "1px",
												}}
											/>
										}
									/>
									<SectionForm>
										{showReraSearch && (
											<tr>
												<td colSpan='2'>
													<SearchProperty
														placeholder='Search with RERA Number'
														callback={(d) => {
															console.log(
																"Callback obj",
																d
															);
															setFieldValue(
																"propertyId",
																d.id
															);
															setPropertyDetails(
																d
															);
														}}
													/>
													<p
														className='color-light'
														style={{
															fontSize: "12px",
															marginTop: "10px",
														}}>
														Did not find the
														property?{" "}
														<span className='color-secondary t-d-ul cursor-pointer hover-color-primary'>
															<NavLink to='/ab-properties/add'>
																Click here
															</NavLink>
														</span>{" "}
														to add new.
													</p>
												</td>
											</tr>
										)}
										<tr>
											<td colSpan='2'>
												<FieldTitle title='Property Name' />
												<LikeInput
													title={
														propertyDetails?.propertyName
													}
												/>
											</td>
										</tr>
										<tr>
											<td colSpan='2'>
												<FieldTitle title='Property Address' />
												<LikeInput
													title={
														propertyDetails?.propertyAddress
													}
												/>
											</td>
										</tr>
										<tr>
											<td colSpan='2'>
												<FieldTitle title='Property Type' />
												<LikeInput
													title={
														propertyDetails?.propertyType
													}
												/>
											</td>
										</tr>
										<tr>
											<td colSpan='2'>
												<FieldTitle title='Property Size' />
												<LikeInput
													title={
														propertyDetails?.propertySize
													}
												/>
											</td>
										</tr>
									</SectionForm>
								</Row>
								<br />
								<Row>
									<SectionTitle
										title='Owner Information'
										subtitle='Enter owner information'
										icon={
											<CiUser
												className='color-primary'
												style={{
													height: "32px",
													width: "32px",
													strokeWidth: "1px",
												}}
											/>
										}
									/>
									<SectionForm>
										<tr>
											<td colSpan='2'>
												<FieldTitle title='Owner Email' />
												<LikeInput
													title={
														propertyDetails?.owner
															?.email
													}
												/>
											</td>
										</tr>
										<tr>
											<td colSpan='2'>
												<FieldTitle title='Owner Phone' />
												<LikeInput
													title={
														propertyDetails?.owner
															?.phoneNumber
													}
												/>
											</td>
										</tr>
										<tr>
											<td colSpan='2'>
												<FieldTitle title='Full name' />
												<LikeInput
													title={
														propertyDetails?.owner
															?.fullName
													}
												/>
											</td>
										</tr>
										<tr>
											<td colSpan='2'>
												<FieldTitle title='Owner UAE ID' />
												<LikeInput
													title={
														propertyDetails?.owner
															?.uaeNumber
													}
												/>
											</td>
										</tr>
									</SectionForm>
								</Row>
								<br />
								<Row>
									<SectionTitle
										title='Rental Agreement'
										subtitle='Enter agreement details to generate rental agreement'
										icon={
											<CiBookmarkPlus
												className='color-primary'
												style={{
													height: "32px",
													width: "32px",
													strokeWidth: "1px",
												}}
											/>
										}
									/>
									<SectionForm>
										<tr>
											<td colSpan='2'>
												<FieldTitle title='Rental Value (yearly)' />
												<Field
													name='rentalValue'
													type='text'
													placeholder=' [12000] AED *'
													className={
														"col-1-1 " +
														(errors.rentalValue &&
															touched.rentalValue
															? " error"
															: "")
													}
												/>
												<ErrorMessage
													name='rentalValue'
													component='p'
													className='error-messages'
												/>
											</td>
										</tr>
										<tr>
											<td colSpan='2'>
												<FieldTitle title='Rental terms' />
												<Field
													component='textarea'
													maxLength='300'
													name='rentalTerms'
													type='text'
													placeholder='- Pointer 1 - Pointer 2'
													className={
														"col-1-1 " +
														(errors.rentalTerms &&
															touched.rentalTerms
															? " error"
															: "")
													}
												/>
											</td>
										</tr>
									</SectionForm>
								</Row>
								<br />
								<div
									className='flex center-center'
									style={{ gap: "12px" }}>
									<NavLink
										to={`/ab-loan-applications?pageNo=0&pageSize=${DEFAULT_PER_PAGE}`}>
										<ButtonLightTextIcon
											type='button'
											title='Cancel'
										/>
									</NavLink>
									{loading ? (
										<ButtonTextIcon
											type='button'
											title='Creating...'
											disabled={loading}
											icon={<LoaderIcon />}
										/>
									) : (
										<ButtonTextOnly
											type='submit'
											title='Create Now'
										/>
									)}
								</div>
							</section>
						</section>
					</Form>
				)}
			</Formik>
		</section>
	);
};

export default AgencyBrokerCreateApplication;
