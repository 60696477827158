import { useUI } from "../../context/ui";
import Notifications from "./Notifications";
import ProfileInfo from "./ProfileInfo";

const Header = () => {
	const { segmentTitle } = useUI();

	return (
		<header>
			<h4 className='title color-primary'>{segmentTitle}</h4>
			<div className='menus flex center-center'>
				<Notifications />
				<ProfileInfo />
			</div>
		</header>
	);
};

export default Header;
