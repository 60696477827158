import { useState } from "react";
import { CiFileOn } from "react-icons/ci";
import { useUI } from "../../context/ui";

const FileInputMultiple = ({ callback, accept, defaultText, maxSizeAllowed }) => {
	// const [f, setF] = useState("");
	// const [files, setFiles] = useState(null);
	const { addToastMessage } = useUI();

	return (
		<div className='file-input-holder col-1-1 pos-rel'>
			{defaultText}
			<input
				type='file'
				accept={accept}
				multiple='multiple'
				onChange={(e) => {

					const files = e.target.files;
                    
					if(files.length === 0){
						addToastMessage({
							status: false,
							message:  "Select files.",
						});
						return;
					}

					if (files.length > 3) {
						addToastMessage({
							status: false,
							message:  "Maximum 3 files each under 2MB allowed.",
						});
						return;
				    }
					

					for (let i = 0 ; i < files.length ; i++){
						if(files[i]?.size && maxSizeAllowed && files[i].size > maxSizeAllowed){
							addToastMessage({
								status: false,
								message: "File size should not exceed 2 MB",
							});
							return;
						}
					}

					callback(Array.from(e.target.files));

					// if (e.target.files.length > 3) {
					// 	addToastMessage("Maximum 3 files each under 2MB allowed.");
					// } else {
					// 	callback(Array.from(e.target.files));
					// }
				}}
			/>
			<CiFileOn />
		</div>
	);
};

export default FileInputMultiple;
