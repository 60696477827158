import React from "react";
import { CiSearch } from "react-icons/ci";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";

const SearchBar = ({ icon, title, placeholder, ...rest }) => {
	const initial = {
		val: "",
	};

	const FormValidation = Yup.object().shape({
		val: Yup.string().strict().required("Value is required!"),
	});

	return (
		<div className='search-bar'>
			<Formik
				initialValues={initial}
				validationSchema={FormValidation}
				onSubmit={async (values, { resetForm }) => {
					console.log(values.val);
				}}>
				{({ values, errors, touched, setFieldValue, resetForm }) => (
					<Form>
						<div className='row'>
							<Field
								name='val'
								type='text'
								placeholder={placeholder}
								className={
									" " +
									(errors.val && touched.val ? " error" : "")
								}
							/>
							<button type='submit'>
								<span>
									<CiSearch />
								</span>
							</button>
						</div>
					</Form>
				)}
			</Formik>
		</div>
	);
};

export default SearchBar;
