import {
	CiBarcode,
	CiChat2,
	CiCirclePlus,
	CiCreditCard1,
	CiDiscount1,
	CiFlag1,
	CiFloppyDisk,
	CiGrid41,
	CiLock,
	CiMemoPad,
	CiMoneyBill,
	CiMusicNote1,
	CiPercent,
	CiTimer,
} from "react-icons/ci";
import PageTitle from "../../components/common/PageTitle";
import { lazy, Suspense, useEffect } from "react";
import { useUI } from "../../context/ui";
import SampleChart from "../rs-superadmin/SampleChart";
import LoaderIcon from "../../components/ui/LoaderIcon";
import { useFetch } from "../../../src/hooks/useFetch";
import { API_URL } from "../../../src/utilities/constants";
import { formatAmountValue } from "../../utilities/helpers"
const CreditScoreChart = lazy(() => import("../../components/dashboard/cpsa-chart/CreditScoreChart"));
const EIRatioChart = lazy(() => import( "../../components/dashboard/cpsa-chart/EIRatioChart"));
const RentalValueChart = lazy(() => import( "../../components/dashboard/cpsa-chart/RentalValueChart"));

const SectionLoader = () => {
	return(<section className="flex center-center" style={{height:"480px"}}>
	  <LoaderIcon style={{ height: '32px' ,width:"32px", opacity:"0.2" }} />
	</section>)
  }

const CPSADashboard = () => {
	const { setSegmentTitle } = useUI();
	const { data : loanData , error :loanError , loading :loanLoading } = useFetch(API_URL + "/api/1.0.1/dashboard/loans", {
		method: "GET",
	  });
	const { data:appData , error: appError, loading : appLoading } = useFetch(API_URL + "/api/1.0.1/dashboard/loan-applications", {
		method: "GET",
	  });
	useEffect(() => {
		setSegmentTitle("Dashboard");
	}, []);

	return (
		<>
			<PageTitle title='Dashboard' />
			<section>
				<section className='dashboard'>
					<div className='quick-links'>
						<div className='one flex center-left'>
							<div
								className='flex center-left'
								style={{ gap: "10px" }}>
								<CiMoneyBill 
									className='color-secondary'
									style={{ height: "60px", width: "auto" }}
								/>
								<div>
									<h5 className='color-primary f-w-700 l-h-1-2'>
										{loanLoading ? <>Loading...</> : loanData && loanData?.data && loanData?.data?.totalLoanValue    ? `${formatAmountValue(loanData.data.totalLoanValue)}/- AED` : `0.00/- AED`}
									</h5>
									<p className='color-primary f-w-400 l-h-1-2'>
									    Rental Value
									</p>
								</div>
							</div>
						</div>
						<div className='one flex center-left'>
							<div
								className='flex center-left'
								style={{ gap: "10px" }}>
								<CiMemoPad
									className='color-secondary'
									style={{ height: "60px", width: "auto" }}
								/>
								<div>
									<h5 className='color-primary f-w-700 l-h-1-2'>
									{appLoading ? <>Loading...</> : appData && appData?.data && appData?.data?.total    ? appData.data.total : 0}
									</h5>
									<p className='color-primary f-w-400 l-h-1-2'>
									   Applications 
									   &nbsp;
									   {appData && appData?.data && appData?.data?.loanApprovedPercent    && "("+appData.data.loanApprovedPercent+"% )" }
									   
									</p>
								</div>
							</div>
						</div>
						<div className='one flex center-left'>
							<div
								className='flex center-left'
								style={{ gap: "10px" }}>
								<CiCreditCard1
									className='color-secondary'
									style={{ height: "60px", width: "auto" }}
								/>
								<div>
									<h5 className='color-primary f-w-700 l-h-1-2'>
									{loanLoading ? <>Loading...</> : loanData && loanData?.data && loanData.data?.disbursed ? loanData.data.disbursed : 0 }
									</h5>
									<p className='color-primary f-w-400 l-h-1-2'>
									   Disbursed
									</p>
								</div>
							</div>
						</div>
						<div className='one flex center-left'>
							<div
								className='flex center-left'
								style={{ gap: "10px" }}>
								<CiTimer
									className='color-secondary'
									style={{ height: "60px", width: "auto" }}
								/>
								<div>
									<h5 className='color-primary f-w-700 l-h-1-2'>
									{loanLoading ? <>Loading...</> : loanData && loanData?.data && loanData.data?.pendingApproval ?  loanData.data.pendingApproval : 0 }
									</h5>
									<p className='color-primary f-w-400 l-h-1-2'>
									   Pending Approval
									</p>
								</div>
							</div>
						</div>
						<div className='one flex center-left'>
							<div
								className='flex center-left'
								style={{ gap: "10px" }}>
								<CiTimer 
									className='color-secondary'
									style={{ height: "60px", width: "auto" }}
								/>
								<div>
									<h5 className='color-primary f-w-700 l-h-1-2'>
									{loanLoading ? <>Loading...</> : loanData && loanData?.data && loanData?.data?.pendingDisbursal    ?  loanData.data.pendingDisbursal : 0}
									</h5>
									<p className='color-primary f-w-400 l-h-1-2'>
									   Pending Disbursal
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className='grid-view'>
						<div className='one'>
							<div className='flex center-left'>
								<h6 className='color-primary f-w-700'>
								   Credit Score:
								</h6>
								<CiTimer
									className='color-primary mar-l-a'
									style={{ height: "28px", width: "auto" }}
								/>
							</div>
							<br />
							<div className='chart'>
							    <Suspense  fallback={<SectionLoader />}>
								  <CreditScoreChart />
								</Suspense>
							</div>
						</div>
						<div className='one'>
							<div className='flex center-left'>
								<h6 className='color-primary f-w-700'>
								   E/I Ratio:
								</h6>
								<CiPercent
									className='color-primary mar-l-a'
									style={{ height: "28px", width: "auto" }}
								/>
							</div>
							<br />
							<div className='chart'>
							    <Suspense  fallback={<SectionLoader />}>
							    	<EIRatioChart />
								</Suspense>
							</div>
						</div>
						<div className='one'>
							<div className='flex center-left'>
								<h6 className='color-primary f-w-700'>
								   Rental Value:
								</h6>
								<CiMoneyBill
									className='color-primary mar-l-a'
									style={{ height: "28px", width: "auto" }}
								/>
							</div>
							<br />
							<div className='chart'>
								<Suspense  fallback={<SectionLoader />}>
								    <RentalValueChart />
								</Suspense>
							</div>
						</div>
						<div className='one'>
							<div className='flex center-left'>
								<h6 className='color-primary f-w-700'>
									Tenants Signup:
								</h6>
								<CiLock
									className='color-primary mar-l-a'
									style={{ height: "28px", width: "auto" }}
								/>
							</div>
							<br />
							<div className='chart'>
							    <Suspense  fallback={<SectionLoader />}>
									<SampleChart />
								</Suspense>
							</div>
						</div>
					</div>
				</section>
			</section>
		</>
	);
};

export default CPSADashboard;
