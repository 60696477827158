import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import PageTitle from "../../components/common/PageTitle";
import LoaderIcon from "../../components/ui/LoaderIcon";
import { API_URL, RS_TAGLINE } from "../../utilities/constants";
import RentSavvyLogo from "../../components/common/RentSavvyLogo";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import ButtonTextOnly from "../../components/ui/ButtonTextOnly";
import { useUI } from "../../context/ui";


const FirmPendingDetails = () => {
	const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState("");
	const navigate = useNavigate();
	const { id } = useParams();
	const { addToastMessage } = useUI();

	const FormValidation = Yup.object().shape({
		address: Yup.string(),
		tradeName: Yup.string().required("Required"),
		tradeLicenseNumber: Yup.string().required("Required"),
		financialLicenseNumber: Yup.string().required("Required"),
	});

	const initial = {
		address: "",
		tradeName: "",
		tradeLicenseNumber: "",
		financialLicenseNumber: "",
	};

	// const checkIfNextURLExists = () => {
	// 	const params = new URLSearchParams(location.search);
	// 	const queryObj = {};
	// 	params.forEach((value, key) => {
	// 		queryObj[key] = value;
	// 	});
	// 	if (queryObj.next) {
	// 		return queryObj.next;
	// 	} else {
	// 		return false;
	// 	}
	// };

	const handleSubmit = async (values) => {
		setLoading(true);
		try {
			const request = {
				address: values.address,
				tradeName: values.tradeName,
				tradeLicenseNumber: values.tradeLicenseNumber,
				financialLicenseNumber: values.financialLicenseNumber,
			};
			const resp = await fetch(
				API_URL + `/api/1.0.0/credit-provider/update/license/${id}`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
						"X-JWT-Assertion": localStorage.getItem("token"),
					},
					body: JSON.stringify(request),
				}
			);

			if (resp.ok) {
				const response = await resp.json();
				addToastMessage({
					status: true,
					message:
						"You have successfully updated details, thank you.",
				});
				if (response) {
					localStorage.removeItem("flow"); // Handle well
					navigate(`/cpsa-dashboard`);
				}
			}
			else {
				const errorData = await resp.json();
				const errorMessage = errorData.responseDescription;
				addToastMessage({
					status: false,
					message:
						errorMessage ||
						"Something went wrong while updating details. Please try again.",
				});
			}
		} catch (e) {
			console.log("Error", e);
		} finally {
			setLoading(false);
		}
	};

	return (
		<section id='auth-page'>
			<PageTitle title='Enter Details' />
			<section className='auth-header'>
				<div className='logo'>
					<RentSavvyLogo />
				</div>
			</section>
			<section className='auth-content'>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='90.396'
					height='82.351'
					viewBox='0 0 90.396 82.351'
					className='svg-top'>
					<g transform='translate(-288.14 -169.729)'>
						<path
							d='M276.138,252.079V334.43h57.775s18.088-.5,24.874-10.241,12.908-27.337,0-41.4-31.156-30.71-31.156-30.71Z'
							transform='translate(654.483 504.159) rotate(180)'
							fill='#5dc6b3'
						/>
						<path
							d='M327.624,252.079V190.464l25.5,21.287V252.08Z'
							fill='#1e4ccb'
						/>
						<path
							d='M327.624,190.464l25.4-19.87,25.512,19.87L353.027,211.9Z'
							fill='#3b86b5'
						/>
					</g>
				</svg>
				<div className='inner'>
					<div className='col col-1-2 left'>
						<div className='text'>
							<h1 className='l-h-1 f-w-300 color-primary'>
								Enter
								<br />
								Details!
							</h1>
							<br />
							<h5 className='f-w-300 color-primary'>
								{RS_TAGLINE}
							</h5>
						</div>
					</div>
					<div className='col col-1-2 right pos-rel'>
						<div className='box pending-details'>
							<Formik
								initialValues={initial}
								validationSchema={FormValidation}
								onSubmit={async (values, { resetForm }) => {
									handleSubmit(values);
								}}>
								{({
									values,
									errors,
									touched,
									setFieldValue,
									resetForm,
								}) => (
									<Form>
										<h4 className='color-primary t-a-c'>
											Enter Firm Details
										</h4>
										<table>
											<thead></thead>
											<tbody>
												<tr>
													<td>
														<p className='title f-w-700 color-primary'>
															Address:
														</p>
														<Field
															name='address'
															type='text'
															placeholder='Eg: 12 Street, City, Country *'
															className={
																" " +
																(errors.address &&
																touched.address
																	? " error"
																	: "")
															}
														/>
													</td>
												</tr>
												<tr>
													<td>
														<p className='title f-w-700 color-primary'>
															Trade Name:
														</p>
														<Field
															name='tradeName'
															type='text'
															placeholder='Enter Trade Name'
															className={
																" " +
																(errors.tradeName &&
																touched.tradeName
																	? " error"
																	: "")
															}
														/>
													</td>
												</tr>
												<tr>
													<td>
														<p className='title f-w-700 color-primary'>
															Trade License
															Number:
														</p>
														<Field
															name='tradeLicenseNumber'
															type='text'
															placeholder='Enter Trade License No'
															className={
																" " +
																(errors.tradeLicenseNumber &&
																touched.tradeLicenseNumber
																	? " error"
																	: "")
															}
														/>
													</td>
												</tr>
												<tr>
													<td>
														<p className='title f-w-700 color-primary'>
															Financial License
															Number:
														</p>
														<Field
															name='financialLicenseNumber'
															type='text'
															placeholder='Enter Financial License No'
															className={
																" " +
																(errors.financialLicenseNumber &&
																touched.financialLicenseNumber
																	? " error"
																	: "")
															}
														/>
													</td>
												</tr>
												<tr>
													<td colSpan='2'>
														{loading ? (
															<ButtonTextIcon
																type='button'
																title='Updating...'
																disabled={
																	loading
																}
																icon={
																	<LoaderIcon />
																}
															/>
														) : (
															<ButtonTextOnly
																disabled={
																	loading
																}
																type='submit'
																title='Update Details'
															/>
														)}
													</td>
												</tr>
											</tbody>
										</table>
										{status && (
											<p className='status color-secondary f-w-300 l-h-1'>
												{status}
											</p>
										)}
									</Form>
								)}
							</Formik>
						</div>
					</div>
				</div>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='90.205'
					height='82.351'
					viewBox='0 0 90.205 82.351'
					className='svg-bottom'>
					<g transform='translate(-276.138 -252.079)'>
						<path
							d='M276.138,252.079V334.43h57.775s18.088-.5,24.874-10.241,12.908-27.337,0-41.4-31.156-30.71-31.156-30.71Z'
							fill='#5dc6b3'
						/>
						<path
							d='M290.953,334.43l43.512-.032a56.454,56.454,0,0,0,8.178-1.2,45.36,45.36,0,0,0,5.6-1.582v-32.64L320.021,269.53l-29.069,29.446Z'
							fill='#3b86b5'
						/>
						<path
							d='M276.138,298.387V269.279l44.181.244-28.911,28.864Z'
							fill='#1e4ccb'
						/>
					</g>
				</svg>
			</section>
		</section>
	);
};

export default FirmPendingDetails;
