import { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { NavLink, useNavigate } from "react-router-dom";
import { API_URL, DEFAULT_PER_PAGE } from "../../utilities/constants";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import ButtonTextOnly from "../../components/ui/ButtonTextOnly";
import FullPageLoader from "../../components/ui/FullPageLoader";
import PageTitle from "../../components/common/PageTitle";
import FileInput from "../../components/ui/FileInput";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import { CiCircleCheck, CiLocationArrow1 } from "react-icons/ci";
import LoaderIcon from "../../components/ui/LoaderIcon";
import { useUI } from "../../context/ui";
import FileInputMultiple from "../../components/ui/FileInputMultiple";
import { countries } from "../../utilities/countries";
import CountrySelector from "../../components/common/CountrySelector";

const CreateProperty = () => {
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const [uploadedDeedURL, setUploadedDeedURL] = useState("");
	const [deed, setDeed] = useState(null);
	const currentYear = new Date().getFullYear();
	const [allLocations, setAllLocations] = useState(null);
	const [regex, setRegex] = useState("");
	const years = Array.from(
		new Array(currentYear - 1971 + 1),
		(val, index) => currentYear - index
	);

	const [propertyImage, setPropertyImage] = useState(null);
	const [propertyImageNames, setPropertyImageNames] = useState(null);
	const [propertyimageUploadURL, setPropertyimageUploadURL] = useState("");
	const {
		setNewNotification,
		setShowNotification,
		addNotification,
		setSegmentTitle,
		addToastMessage,
	} = useUI();

	const FieldTitle = ({ title }) => {
		return (
			<p
				className='title f-w-700 color-primary'
				style={{ marginBottom: "5px", fontSize: "14px" }}>
				{title}
			</p>
		);
	};

	const getLocations = async () => {
		try {
			const resp = await fetch(API_URL + "/api/1.0.0/ref/locations", {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					"X-JWT-Assertion": localStorage.getItem("token"),
				},
			});
			if (resp.ok) {
				const response = await resp.json();
				setAllLocations(response.data);
			}
		} catch (e) {
			console.log("Error", e);
		}
	};

	useEffect(() => {
		getLocations();
		setSegmentTitle("Add Property");
	}, []);

	const getCities = (cityId) => {
		const e = allLocations.find((obj) => obj.code === cityId);
		console.log(e);
		return e ? e.subLocations : null;
	};

	const getRegexForCity = (selectedEmirate, selectedCity) => {
		const emirate = allLocations.find(
			(obj) => obj.code === selectedEmirate
		);
		const city = emirate.subLocations.find((o) => o.name === selectedCity);
		return city.regularExp;
	};

	const ValidationSchema = Yup.object().shape({
		propertyUrl: Yup.string(),
		description: Yup.string(),
		propertyName: Yup.string().required("Property name is required"),
		propertySize: Yup.string()
			.matches(
				/^\d{1,5}$/,
				"Property size must be a number with a maximum of 5 digits"
			)
			.required("Property size is required"),
		propertyAddress: Yup.string().required("Property address is required"),
		propertyType: Yup.string().required("Property type is required"),
		reraNumber: Yup.string()
			.matches(
				/^[\d\W]+$/,
				"RERA number must contain only digits and special characters"
			)
			.length(15, "RERA number must be exactly 15 characters long")
			.required("RERA number is required!"),
		fullName: Yup.string().required("Owner name is required"),
		address: Yup.string().required("Owner address is required"),
		email: Yup.string()
			.strict()
			.email("Invalid email format")
			.required("Owner email is required!")
			.matches(
				/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/,
				"The email must have at least 2 characters after the last dot"
			),
		phoneNumber: Yup.string()
			// .matches(
			// 	/^\+\d{1,3}[\s-]?\d{1,14}([\s-]?\d{1,15})?$/,
			// 	"The phone number is not valid. Please enter a valid international phone number."
			// )
			.required("Phone number is required!")
			.min(7, "The number is required")
			.max(15, "The number is required"),
		countryCode: Yup.string().required("Country code is required"),
		uaeNumber: Yup.string()
			.required("Emirates ID is required")
			.matches(
				/^784\d{12}$/,
				"Emirates ID must start with '784' and be 15 digits long"
			),
		certificateYear: Yup.string().required("Certificate year is required"),
		Emirates: Yup.string().required("Emirate is required"),
		City: Yup.string().required("Area is required"),
		numberOfBedrooms: Yup.string().required(
			"Number of bedrooms is required"
		),
		locationUrl: Yup.string().required("Location URL is required"),
	});

	const initial = {
		propertyUrl: "",
		propertyName: "",
		propertySize: "",
		propertyAddress: "",
		description: "",
		propertyType: "",
		reraNumber: "",
		fullName: "",
		address: "",
		email: "",
		phoneNumber: "",
		countryCode: "",
		uaeNumber: "",
		certificateYear: "",
		City: "",
		Emirates: "",
		numberOfBedrooms: "",
		locationUrl: "",
	};

	const handleSubmit = async (payload) => {
		setLoading(true);
		try {
			const resp = await fetch(API_URL + `/api/1.0.0/property`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"X-JWT-Assertion": localStorage.getItem("token"),
				},
				body: JSON.stringify(payload),
			});

			if (resp.ok) {
				addToastMessage({
					status: true,
					message:
						"You have successfully created a property, Thank You!",
				});
				setNewNotification(true);
				addNotification({
					id: Date.now(),
					message: "You have successfully enlisted a property!",
					status: true,
					archive: false,
				});
				navigate(
					`/ab-properties?pageNo=0&pageSize=${DEFAULT_PER_PAGE}`
				);
			} else {
				const errorData = await resp.json();
				const errorMessage = errorData.result.responseDescription;
				addToastMessage({
					status: false,
					message:
						errorMessage ||
						"Something went wrong while creating a property. Please try again!",
				});
				setNewNotification(true);
				addNotification({
					id: Date.now(),
					message:
						"Something went wrong while creating a property. Please try again",
					status: false,
					archive: false,
				});
			}
		} catch (errorCode) {
			setNewNotification(true);
			addNotification({
				id: Date.now(),
				message: "Something went wrong while creating a property!",
				status: false,
				archive: false,
			});
		} finally {
			setLoading(false);
		}
	};

	// ====== Property image

	const handleUploadPropertyImage = async () => {
		setLoading(true);
		try {
			const formdata = new FormData();
			propertyImage.forEach((file, index) => {
				formdata.append(`files`, file);
			});
			formdata.append("type", "PROPERTY_IMAGE");
			const resp = await fetch(API_URL + `/api/1.0.0/upload/files`, {
				method: "POST",
				headers: {
					"X-JWT-Assertion": localStorage.getItem("token"),
				},
				body: formdata,
				redirect: "follow",
			});
			if (resp.ok) {
				const responseData = await resp.json();
				if (responseData.result.responseCode === 200) {
					setPropertyimageUploadURL(responseData.data); ////Multiple Files Upload:
					addToastMessage({
						status: true,
						message:
							"Property image has been successfully uploaded, thank you!",
					});
				}
			} else {
				const errorData = await resp.json();
				const errorMessage = errorData.responseDescription;
				addToastMessage({
					status: false,
					message:
						errorMessage ||
						"Something went wrong while uploading the property image. Please try again.",
				});
				//REMOVE FILE
				setPropertyImage(null);
				setPropertyImageNames(null);
				setPropertyimageUploadURL("");
				//REMOVE FILE
			}
		} catch (e) {
			console.log("Error", e);
			setNewNotification(true);
			setShowNotification(true);
			addNotification({
				id: Date.now(),
				message: "Could not upload the property image at the moment.",
				status: false,
				archive: false,
			});
		} finally {
			setLoading(false);
		}
	};

	// ============================================
	// Delete Later Start
	// ============================================
	const [piFile, setPiFile] = useState(null);
	const [piUploadURL, setPiUploadURL] = useState(null);
	const __handleUploadPropertyImage = async () => {
		setLoading(true);
		try {
			const formdata = new FormData();
			formdata.append("file", piFile, piFile.name);
			formdata.append("type", "PROPERTY_IMAGE");
			const resp = await fetch(API_URL + `/api/1.0.0/uploadFile`, {
				method: "POST",
				headers: {
					"X-JWT-Assertion": localStorage.getItem("token"),
				},
				body: formdata,
				redirect: "follow",
			});
			if (resp.ok) {
				const responseData = await resp.json();
				if (responseData.result.responseCode === 200) {
					setPiUploadURL(responseData.data); ////Single Files Upload:
					addToastMessage({
						status: true,
						message:
							"Property image has been successfully uploaded, thank you!",
					});
				}
			} else {
				const errorData = await resp.json();
				const errorMessage = errorData.responseDescription;
				addToastMessage({
					status: false,
					message:
						errorMessage ||
						"Something went wrong while uploading the property image. Please try again.",
				});
			}
		} catch (e) {
			console.log("Error", e);
			setNewNotification(true);
			setShowNotification(true);
			addNotification({
				id: Date.now(),
				message: "Could not upload the property image at the moment.",
				status: false,
				archive: false,
			});
		} finally {
			setLoading(false);
		}
	};

	// ============================================
	// Delete Later
	// =

	// ============================================

	const handleUploadDeed = async () => {
		setLoading(true);
		try {
			const formdata = new FormData();
			formdata.append("file", deed, deed.name);
			formdata.append("type", "PROPERTY_DEED");
			const resp = await fetch(API_URL + `/api/1.0.0/uploadFile`, {
				method: "POST",
				headers: {
					"X-JWT-Assertion": localStorage.getItem("token"),
				},
				body: formdata,
				redirect: "follow",
			});
			if (resp.ok) {
				const responseData = await resp.json();
				if (responseData.result.responseCode === 200) {
					addToastMessage({
						status: true,
						message:
							"You have successfully uploaded a property deed, thank you!",
					});
					setUploadedDeedURL(responseData.data);
				}
			} else {
				const errorData = await resp.json();
				const errorMessage = errorData.responseDescription;
				addToastMessage({
					status: false,
					message:
						errorMessage ||
						"Something went wrong while uploading a property deed. Please try again.",
				});
			}
		} catch (e) {
			console.log("Error", e);
			setNewNotification(true);
			addNotification({
				id: Date.now(),
				message: "Could not upload the property deed at the moment.",
				status: false,
				archive: false,
			});
		} finally {
			setLoading(false);
		}
	};

	return (
		<section>
			<PageTitle title='Create Broker' />
			<FullPageLoader isActive={loading} />
			<section className='create-agency'>
				<h5 className='color-primary'>Property Details</h5>
				<p>Enter the Property details below:</p>
				<br />
				{/* <img src={getFlagImage('IN')} alt="India Flag" /> */}
				<section className='table-wrapper'>
					<Formik
						initialValues={initial}
						validationSchema={ValidationSchema}
						onSubmit={async (values, { validateForm }) => {
							const payload = {
								propertyDetails: {
									propertyName: values.propertyName,
									propertyDeedCopy: uploadedDeedURL,
									reraNumber: values.reraNumber,
									// pictureList: [uploadedURL],
									pictureList: API_URL === "https://be.rent-savvy.com" ? propertyimageUploadURL/*Multiple Files Upload*/ : [piUploadURL]/*Single File Upload*/,
									propertySize: values.propertySize,
									propertyType: values.propertyType,
									description: values.description,
									propertyAddress: values.propertyAddress,
									propertyUrl: values.propertyUrl,
									certificateYear: values.certificateYear,
									location: values.City,
									numberOfBedrooms: values.numberOfBedrooms,
									locationUrl: values.locationUrl,
								},
								ownerDetails: {
									fullName: values.fullName,
									address: values.address,
									email: values.email,
									phoneNumber: values.phoneNumber,
									countryCode: values.countryCode,
									uaeNumber: values.uaeNumber,
								},
							};
							await handleSubmit(payload);
						}}>
						{({
							values,
							errors,
							touched,
							validateForm,
							setFieldValue,
							resetForm,
							handleSubmit,
						}) => (
							<Form>
								<table>
									<thead></thead>
									<tbody>
										<tr>
											<td colSpan='6'>
												<h6 className='title f-w-400 color-light'>
													Property Details:
												</h6>
											</td>
										</tr>
										<tr>
											<td colSpan='3'>
												<p className='title f-w-700 color-primary'>
													Property Name
												</p>
												<Field
													name='propertyName'
													type='text'
													placeholder='Enter property Name *'
													className={
														"col-1-1 " +
														(errors.propertyName &&
															touched.propertyName
															? " error"
															: "")
													}
												/>
												<ErrorMessage
													name='propertyName'
													component='p'
													className='error-messages'
												/>
											</td>
										</tr>
										<tr>
											<td colSpan='6'>
												<p className='title f-w-700 color-primary'>
													Property URL
												</p>
												<Field
													name='propertyUrl'
													type='text'
													placeholder='Eg: https://website.com/property/123'
													className={
														"col-1-1 " +
														(errors.propertyUrl &&
															touched.propertyUrl
															? " error"
															: "")
													}
												/>
												<ErrorMessage
													name='propertyUrl'
													component='p'
													className='error-messages'
												/>
											</td>
										</tr>
										<tr>
											<td colSpan='6'>
												<p className='title f-w-700 color-primary'>
													Short Description
												</p>
												<Field
													name='description'
													type='textarea'
													placeholder='Eg: A 3BHK Located in the heart of the city...'
													className={
														"col-1-1 " +
														(errors.description &&
															touched.description
															? " error"
															: "")
													}
												/>
												<ErrorMessage
													name='description'
													component='p'
													className='error-messages'
												/>
											</td>
										</tr>
										<tr>
											<td colSpan='6'>
												<p className='title f-w-700 color-primary'>
													Address
												</p>
												<Field
													name='propertyAddress'
													type='textarea'
													placeholder='Eg: 12 Street, City, Country *'
													className={
														"col-1-1 " +
														(errors.propertyAddress &&
															touched.propertyAddress
															? " error"
															: "")
													}
												/>
												<ErrorMessage
													name='propertyAddress'
													component='p'
													className='error-messages'
												/>
											</td>
										</tr>
										<tr>
											<td colSpan='6'>
												<p className='title f-w-700 color-primary'>
													Emirates
												</p>
												<Field
													as='select'
													name='Emirates'
													className={
														"col-1-1 " +
														(errors.Emirates &&
															touched.Emirates
															? " error"
															: "")
													}>
													<option value=''>
														Select Emirates
													</option>
													{allLocations && (
														<>
															{allLocations.map(
																(a, i) => (
																	<option
																		value={
																			a.code
																		}
																		key={i}
																		disabled={
																			a.code !==
																				"L0002"
																				? "disabled"
																				: null
																		}>
																		{a.name}
																	</option>
																)
															)}
														</>
													)}
												</Field>
												<ErrorMessage
													name='Emirates'
													component='p'
													className='error-messages'
												/>
											</td>
											{/* <td colSpan='2'></td> */}
										</tr>
										{values.Emirates && (
											<tr>
												<td colSpan='6'>
													<p className='title f-w-700 color-primary'>
														Area
													</p>
													<Field
														as='select'
														name='City'
														className={
															"col-1-1 " +
															(errors.City &&
																touched.City
																? " error"
																: "")
														}
														onChange={(e) => {
															setFieldValue(
																"City",
																e.target.value
															);
															const regex =
																getRegexForCity(
																	values.Emirates,
																	e.target
																		.value
																);
															setRegex(regex);
														}}>
														<option value=''>
															Select Area
														</option>
														{getCities(
															values.Emirates
														).map((c, j) => (
															<option
																key={j}
																value={c.name}>
																{c.name}
															</option>
														))}
													</Field>
													<ErrorMessage
														name='City'
														component='p'
														className='error-messages'
													/>
												</td>
												{/* <td colSpan='2'></td> */}
											</tr>
										)}
										<tr></tr>
										<tr>
											<td colSpan='6'>
												<p className='title f-w-700 color-primary'>
													Location Url
												</p>
												<Field
													name='locationUrl'
													type='text'
													placeholder='Eg: https://website.com'
													className={
														"col-1-1 " +
														(errors.locationUrl &&
															touched.locationUrl
															? " error"
															: "")
													}
												/>
												<ErrorMessage
													name='locationUrl'
													component='p'
													className='error-messages'
												/>
											</td>
										</tr>

										<tr>
											<td colSpan='3'>
												<p className='title f-w-700 color-primary'>
													Property Image
												</p>
												{
													API_URL === "https://be.rent-savvy.com" ? (
														// {/* ============= Multiple Files Upload START ============= */}
														<FileInputMultiple
															defaultText={`Add Property Image`}
															accept='image/jpeg, image/jpg, image/png'
															callback={(arr) => {
																setPropertyImage(
																	arr
																);
																setPropertyImageNames(
																	arr.map(
																		(
																			file
																		) =>
																			file.name
																	)
																);
															}}
															maxSizeAllowed={
																2 * 1024 * 1024
															}
														/>
													) : (
														// {/* ============= Multiple Files Upload END ============= */}
														// {/* ============= DELETE START ============= */}
														<FileInput
															defaultText={`Add Property Image`}
															accept='image/jpeg, image/jpg, image/png'
															maxSizeAllowed={
																2 * 1024 * 1024
															}
															callback={(f) => {
																setPiFile(f);
															}}
														/>
													)
													// {/* ============= DELETE END ============= */}
												}
											</td>
											<td colSpan='3' className='t-a-r'>
												<p>&nbsp;</p>
												{
													API_URL === "https://be.rent-savvy.com"  ? (
														// {/* Multiple Files Upload START */}
														<ButtonTextIcon
															type='button'
															title={
																propertyimageUploadURL
																	? "Uploaded"
																	: "Click to Upload"
															}
															disabled={
																loading ||
																!propertyImage
															}
															icon={
																<>
																	{loading ? (
																		<LoaderIcon />
																	) : (
																		<>
																			{propertyimageUploadURL ? (
																				<CiCircleCheck />
																			) : (
																				<CiLocationArrow1 />
																			)}
																		</>
																	)}
																</>
															}
															onClick={
																handleUploadPropertyImage
															}
														/>
													
													) : (
														// {/*  Multiple Files Upload END */}
														// {/* ============= DELETE START ============= */}
														<ButtonTextIcon
															type='button'
															title={
																piUploadURL
																	? "Uploaded"
																	: "Click to Upload"
															}
															disabled={
																loading ||
																!piFile
															}
															icon={
																<>
																	{loading ? (
																		<LoaderIcon />
																	) : (
																		<>
																			{piUploadURL ? (
																				<CiCircleCheck />
																			) : (
																				<CiLocationArrow1 />
																			)}
																		</>
																	)}
																</>
															}
															onClick={
																__handleUploadPropertyImage
															}
														/>
													)
													// {/* ============= DELETE END ============= */}
												}
													<>
														{propertyImage && (
															<p
																style={{
																	fontSize:
																		"12px",
																	color: "red",
																	// marginTop:
																	// 	"10px",
																}}>
																*Click to upload
															</p>
														)}
														</>
														<>
														{piFile && (
															<p
																style={{
																	fontSize:
																		"12px",
																	color: "red",
																	// marginTop:
																	// 	"10px",
																}}>
																*Click to upload
															</p>
														)}
														</>
											</td>
										</tr>
										{propertyImageNames && (
											<tr>
												<td colSpan='2'>
													{propertyImageNames.map(
														(p, i) => (
															<p
																className='color-faded'
																key={i}
																style={{
																	fontSize:
																		"11px",
																}}>
																{p}
															</p>
														)
													)}
												</td>
											</tr>
										)}
										{propertyImage && (
											<tr>
												<td colSpan='2'>
													<p
														className='cursor-pointer'
														style={{
															fontSize: "12px",
															color: "red",
														}}
														onClick={() => {
															setPropertyImage(
																null
															);
															setPropertyImageNames(
																null
															);
															setPropertyimageUploadURL(
																""
															);
														}}>
														Delete image URL file
													</p>
												</td>
											</tr>
										)}
										{piFile && (
											<tr>
												<td colSpan='2'>
													<p
														className='cursor-pointer'
														style={{
															fontSize: "12px",
															color: "red",
														}}
														onClick={() => {
															setPiFile(null);
															setPiUploadURL("");
														}}>
														Delete image URL file
													</p>
												</td>
											</tr>
										)}

										<tr>
											<td colSpan='6'>
												<p className='status f-w-200 l-h-1 font-size-12'>
													NOTE:Please upload files in
													JPG, PNG, or JPEG format,{" "}
													{(API_URL === "https://be.rent-savvy.com") && "per"}{" "}
													up to 2MB. Files exceeding
													these limits won't be
													accepted.
												</p>
											</td>
										</tr>

										{/* <tr>
											<td colSpan='4'>
												<p className='title f-w-700 color-primary'>
													Property Image
												</p>
												<FileInput
													defaultText='Add Property Image'
													accept='image/jpeg, image/jpg, image/png'
													maxSizeAllowed={
														2 * 1024 * 1024
													}
													callback={(file) => {
														setImage(file);
													}}
												/>
											</td>
											<td colSpan='2' className='t-a-r'>
												<p>&nbsp;</p>
												<ButtonTextIcon
													type='button'
													title={
														uploadedURL
															? "Uploaded"
															: "Upload"
													}
													disabled={loading || !image}
													icon={
														loading ? (
															<LoaderIcon />
														) : uploadedURL ? (
															<CiCircleCheck />
														) : (
															<CiLocationArrow1 />
														)
													}
													onClick={handleUpload}
												/>
											</td>
										</tr>
										{image && (
											<tr>
												<td colSpan='2'>
													<p
														className='cursor-pointer'
														style={{
															fontSize: "12px",
															color: "red",
														}}
														onClick={() => {
															setImage(null);
															setUploadedURL("");
														}}>
														Delete image URL file
													</p>
												</td>
											</tr>
										)} */}
										{/* <tr>
											<td colSpan="6" >
												<p className="status f-w-200 l-h-1 font-size-12">
													NOTE: Please upload files in JPG, PNG, or JPEG format, up to 2MB. Files exceeding these limits won't be accepted.
												</p>
											</td>
										</tr> */}

										<tr>
											<td colSpan='3'>
												<FieldTitle title='Property Deed Copy' />
												<FileInput
													defaultText='Add Property Deed'
													accept=' application/pdf'
													maxSizeAllowed={
														2 * 1024 * 1024
													}
													callback={(f) => {
														setDeed(f);
													}}
												/>
												{deed && (
													<p
														className='cursor-pointer'
														style={{
															fontSize: "12px",
															color: "red",
															marginTop: "10px",
														}}
														onClick={() => {
															setDeed(null);
															setUploadedDeedURL(
																""
															);
														}}>
														Delete deed file
													</p>
												)}
											</td>
											<td colSpan='3' className='t-a-r'>
												<p>&nbsp;</p>
												<ButtonTextIcon
													type='button'
													title={
														uploadedDeedURL
															? "Uploaded"
															: "Click to Upload"
													}
													disabled={loading || !deed}
													icon={
														<>
															{loading ? (
																<LoaderIcon />
															) : (
																<>
																	{uploadedDeedURL ? (
																		<CiCircleCheck />
																	) : (
																		<CiLocationArrow1 />
																	)}
																</>
															)}
														</>
													}
													onClick={handleUploadDeed}
												/>

												{deed && (
													<p
														style={{
															fontSize: "12px",
															color: "red",
															marginTop: "10px",
														}}>
														*Click to upload
													</p>
												)}
											</td>
										</tr>

										<tr>
											<td colSpan='6'>
												<p className='status f-w-200 l-h-1 font-size-12'>
													NOTE: Please upload files in
													PDF format, up to 2MB. Files
													exceeding these limits won't
													be accepted.
												</p>
											</td>
										</tr>
										<tr>
											<td colSpan='3'>
												<p className='title f-w-700 color-primary'>
													Type
												</p>
												<Field
													name='propertyType'
													as='select'
													type='text'
													placeholder=''
													className={
														"col-1-1 " +
														(errors.propertyType &&
															touched.propertyType
															? " error"
															: "")
													}>
													<option value=''>
														Select Property Type
													</option>
													<option value='Apartment'>
														Apartment
													</option>
													<option value='Villa / Town House'>
														Villa / Town House
													</option>
												</Field>
												<ErrorMessage
													name='propertyType'
													component='p'
													className='error-messages'
												/>
											</td>
											<td colSpan='3'>
												<p className='title f-w-700 color-primary'>
													Property Size
												</p>
												<Field
													name='propertySize'
													placeholder='Eg: 2000 sq.ft *'
													type='text'
													maxLength='5'
													className={
														"col-1-1 " +
														(errors.propertySize &&
															touched.propertySize
															? " error"
															: "")
													}
												/>
												<ErrorMessage
													name='propertySize'
													component='p'
													className='error-messages'
												/>
											</td>
										</tr>
										<tr>
											<td colSpan='3'>
												<p className='title f-w-700 color-primary'>
													Number Of Bedrooms
												</p>
												<Field
													name='numberOfBedrooms'
													as='select'
													type='text'
													placeholder=''
													className={
														"col-1-1 " +
														(errors.numberOfBedrooms &&
															touched.numberOfBedrooms
															? " error"
															: "")
													}>
													<option value=''>
														Select Number of
														Bedrooms
													</option>
													<option value='1'>1</option>
													<option value='2'>2</option>
													<option value='3'>3</option>
													<option value='4'>4</option>
													<option value='5'>5</option>
													<option value='5+'>
														5+
													</option>
												</Field>
												<ErrorMessage
													name='numberOfBedrooms'
													component='p'
													className='error-messages'
												/>
											</td>
											<td colSpan='3'>
												<p className='title f-w-700 color-primary'>
													Certificate Year
												</p>
												<Field
													name='certificateYear'
													as='select'
													type='text'
													placeholder=''
													className={
														"col-1-1 " +
														(errors.certificateYear &&
															touched.certificateYear
															? " error"
															: "")
													}>
													<option value=''>
														Select Certificate Year
													</option>
													{years.map((year) => (
														<option
															key={year}
															value={year}>
															{year}
														</option>
													))}
												</Field>
												<ErrorMessage
													name='certificateYear'
													component='p'
													className='error-messages'
												/>
											</td>
										</tr>
										<tr>
											<td colSpan='6'>
												<p className='title f-w-700 color-primary'>
													Land Registration Number
												</p>
												<Field
													name='reraNumber'
													placeholder='Eg: 12000000000000 *'
													maxLength='15'
													type='text'
													className={
														"col-1-1 " +
														(errors.reraNumber &&
															touched.reraNumber
															? " error"
															: "")
													}
												/>
												<ErrorMessage
													name='reraNumber'
													component='p'
													className='error-messages'
												/>
											</td>
										</tr>
										<tr>
											<td colSpan='6'>
												<br />
												<h6 className='title f-w-400 color-light'>
													Owner Details:
												</h6>
												<br />
											</td>
										</tr>
										<tr>
											<td colSpan='3'>
												<p className='title f-w-700 color-primary'>
													Full name
												</p>
												<Field
													name='fullName'
													type='text'
													placeholder='Enter Fullname *'
													className={
														"col-1-1 " +
														(errors.fullName &&
															touched.fullName
															? " error"
															: "")
													}
												/>
												<ErrorMessage
													name='fullName'
													component='p'
													className='error-messages'
												/>
											</td>
											<td colSpan='3'>
												<p className='title f-w-700 color-primary'>
													Emirates ID
												</p>
												<Field
													name='uaeNumber'
													placeholder='Eg: 784************'
													type='text'
													maxLength='15'
													className={
														"col-1-1 " +
														(errors.uaeNumber &&
															touched.uaeNumber
															? " error"
															: "")
													}
												/>
												<ErrorMessage
													name='uaeNumber'
													component='p'
													className='error-messages'
												/>
											</td>
										</tr>
										<tr>
											<td colSpan='6'>
												<p className='title f-w-700 color-primary'>
													Address
												</p>
												<Field
													name='address'
													type='text'
													placeholder='Eg: 12 Street, City, Country *'
													className={
														"col-1-1 " +
														(errors.address &&
															touched.address
															? " error"
															: "")
													}
												/>
												<ErrorMessage
													name='address'
													component='p'
													className='error-messages'
												/>
											</td>
										</tr>
										<tr>
											<td colSpan='6'>
												<p className='title f-w-700 color-primary'>
													Email
												</p>
												<Field
													name='email'
													type='email'
													placeholder='Eg: john.doe@gmail.com *'
													className={
														"col-1-1 " +
														(errors.email &&
															touched.email
															? " error"
															: "")
													}
												/>
												<ErrorMessage
													name='email'
													component='p'
													className='error-messages'
												/>
											</td>
										</tr>
										<tr>
											<td colSpan='2' width='92'>
												<FieldTitle title='Phone number' />
												<CountrySelector
													selector={
														<p style={{ cursor: "pointer", fontSize: '13px' }}>Select Country</p>
													}
													selected={
														values.countryCode
													}
													callback={(code) => {
														setFieldValue(
															"countryCode",
															code
														);
													}}
													className={
														"col-1-1 " +
														(errors.countryCode &&
															touched.countryCode
															? "error"
															: "")}
												/>
												<ErrorMessage
													name='countryCode'
													component='p'
													className='error-messages'
												/>
												{/* <Field
													as='select'
													name='countryCode'
													className={
														"col-1-1 " +
														(errors.countryCode &&
														touched.countryCode
															? "error"
															: "")
													}>
													{countries.map(
														(country) => (
															<option
																key={
																	country.iso2Code
																}
																value={`+${country.dialingCode}`}>
																{country.name}
															</option>
														)
													)}
												</Field> */}
											</td>
											<td colSpan='4'>
												<FieldTitle title='&nbsp;' />
												<Field
													name='phoneNumber'
													type='text'
													placeholder='Eg: 500000000 *'
													maxLength='15'
													className={
														"col-1-1 " +
														(errors.phoneNumber &&
															touched.phoneNumber
															? " error"
															: "")
													}
												/>
												<ErrorMessage
													name='phoneNumber'
													component='p'
													className='error-messages'
												/>
											</td>
										</tr>
										<tr>
											<td
												colSpan='6'
												className='btns t-a-r'>
												<NavLink
													to={`/ab-properties?pageNo=0&pageSize=${DEFAULT_PER_PAGE}`}>
													<ButtonLightTextIcon
														type='button'
														title='Cancel'
													/>
												</NavLink>
												<ButtonTextOnly
													disabled={
														loading ||
														(API_URL === "https://be.rent-savvy.com" ? !propertyimageUploadURL : !piUploadURL) ||
														// !uploadedURL ||
														!uploadedDeedURL
													}
													type='submit'
													title='Add Now'
												/>
											</td>
										</tr>
									</tbody>
								</table>
							</Form>
						)}
					</Formik>
				</section>
			</section>
		</section>
	);
};
export default CreateProperty;
