import { useState } from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import RentSavvyLogo from "../../components/common/RentSavvyLogo";
import {
	API_URL,
	BASE_URL,
	RS_TAGLINE,
	SYSTEM_TOKEN,
} from "../../utilities/constants";
import { FaEye, FaEyeSlash, FaRegEyeSlash } from 'react-icons/fa';
import { decodeRolesFromToken } from "../../utilities/helpers";
import PageTitle from "../../components/common/PageTitle";
import LoaderIcon from "../../components/ui/LoaderIcon";
import { getDefaultURLForRole } from "../../services/roles";
import ButtonTextOnly from "../../components/ui/ButtonTextOnly";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import { useUI } from "../../context/ui";

const Login = () => {
	const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState("");
	const navigate = useNavigate();
	const location = useLocation();
	const { addToastMessage } = useUI();
	const [showPassword, setShowPassword] = useState(false);

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	const FormValidation = Yup.object().shape({
		username: Yup.string()
			.strict()
			.email("Invalid email format")
			.required("Email is required!")
			.matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/, "The email must have at least 2 characters after the last dot"),

		password: Yup.string().required("Password is required!"),
	});

	// ======== FOR DEV ONLY ====== //
	const SUPERADMIN = {
		username: "superadmin@rent-savvy.com",
		password: "",
	};
	const TENANT = {
		username: "ritikrppawar21@gmail.com",
		password: "",
	};
	const BROKER = {
		username: "agencyBroker007@octopi-labs.com",
		password: "",
	};
	const CREDIT_PROVIDER_ADMIN = {
		username: "testcp21@example.com",
		password: "",
	};
	const CREDIT_PROVIDER_APPROVER = {
		username: "ritik1@example.com",
		password: "",
	};

	const initial =
		process.env.NODE_ENV === "development"
			? SUPERADMIN
			: {
				username: "",
				password: "",
			};

	const checkIfNextURLExists = () => {
		const params = new URLSearchParams(location.search);
		const queryObj = {};
		params.forEach((value, key) => {
			queryObj[key] = value;
		});
		if (queryObj.next) {
			return queryObj.next;
		} else {
			return false;
		}
	};

	const handleLogin = async (username, password) => {
		setLoading(true);
		setStatus("");
		try {
			const resp = await fetch(API_URL + "/login", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"X-JWT-Assertion": SYSTEM_TOKEN,
				},
				body: JSON.stringify({
					username: username,
					password: password,
				}),
			});
			if (resp.ok) {
				const response = await resp.json();
				if (response.accessToken) {
					if (
						localStorage.setItem("token", response.accessToken) ===
						undefined
					) {
						const nextURL = checkIfNextURLExists();
						if (response.flow?.length > 0) {
							if (
								localStorage.setItem(
									"flow",
									JSON.stringify(response.flow)
								) === undefined
							) {
								if (nextURL) {
									navigate(
										response.flow[0] + "?next=" + nextURL
									);
								} else {
									navigate(response.flow[0]);
								}
							} else {
								setStatus("Could not store flow");
							}
						} else {
							const roles = decodeRolesFromToken();
							if (nextURL) {
								navigate(nextURL);
							} else {
								navigate(getDefaultURLForRole(roles[0]));
							}
						}
						setStatus("Welcome to rent-savvy.com");
					} else {
						setStatus("Could not store token");
					}
				} else {
					setStatus("No Token Received");
				}
			} else {
				setStatus("Invalid credentials, please try again.");
				addToastMessage({
					status: false,
					message: "Invalid username or password, please try again.",
				});
			}
		} catch {
			setStatus("An Error Occurred");
		} finally {
			setLoading(false);
		}
	};

	return (
		<section id='auth-page'>
			<PageTitle title='Login' />
			<section className='auth-header'>
				<div className='logo'>
					<RentSavvyLogo />
				</div>
				<div className='options flex center-center'>
					<p>
						{BASE_URL === "https://tenant.rent-savvy.com" && (
							<NavLink to='/register'>
								Don't have an account?
							</NavLink>
						)}
					</p>
					{/* <p>
						<NavLink to='/contact'>
							<span className='t-d-ul color-primary f-w-600'>
								Contact us
							</span>
						</NavLink>
					</p> */}
				</div>
			</section>
			<section className='auth-content'>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='90.396'
					height='82.351'
					viewBox='0 0 90.396 82.351'
					className='svg-top'>
					<g transform='translate(-288.14 -169.729)'>
						<path
							d='M276.138,252.079V334.43h57.775s18.088-.5,24.874-10.241,12.908-27.337,0-41.4-31.156-30.71-31.156-30.71Z'
							transform='translate(654.483 504.159) rotate(180)'
							fill='#5dc6b3'
						/>
						<path
							d='M327.624,252.079V190.464l25.5,21.287V252.08Z'
							fill='#1e4ccb'
						/>
						<path
							d='M327.624,190.464l25.4-19.87,25.512,19.87L353.027,211.9Z'
							fill='#3b86b5'
						/>
					</g>
				</svg>
				<div className='inner'>
					<div className='col col-1-2 left'>
						<div className='text'>
							<h1 className='l-h-1 f-w-300 color-primary'>
								Welcome
								<br />
								Partner!
							</h1>
							<br />
							<h5 className='f-w-300 color-primary'>
								{RS_TAGLINE}
							</h5>
						</div>
					</div>
					<div className='col col-1-2 right pos-rel'>
						<div className='box'>
							<Formik
								initialValues={initial}
								validationSchema={FormValidation}
								onSubmit={async (values, { resetForm }) => {
									handleLogin(
										values.username,
										values.password
									);
								}}>
								{({
									values,
									errors,
									touched,
									setFieldValue,
									resetForm,
								}) => (
									<Form>
										<h4 className='color-primary t-a-c'>
											Sign In
										</h4>
										<table>
											<thead></thead>
											<tbody>
												<tr>
													<td colSpan='2'>
														<p className='title f-w-700 color-primary'>
															Username:
														</p>
														<Field
															name='username'
															type='text'
															placeholder='Username *'
															className={
																" " +
																(errors.username &&
																	touched.username
																	? " error"
																	: "")
															}
														/>
													</td>
												</tr>
												<tr>
													<td colSpan="2">
														<p className="title f-w-700 color-primary">Password:</p>
														<div className="password-field-wrapper">
															<Field
																name="password"
																type={showPassword ? 'text' : 'password'}
																placeholder="Password *"
																className={
																	" " + (errors.password && touched.password ? "error" : "")
																}
															/>
															<span
																onClick={togglePasswordVisibility}
																className="eye-icon"
																aria-label={showPassword ? 'Hide password' : 'Show password'}
															>
																{showPassword ? <FaRegEyeSlash /> : <FaEye />}
															</span>
														</div>
													</td>
												</tr>
												{/* <tr>
													<td colSpan='2'>
														<p className='title f-w-700 color-primary'>
															Password:
														</p>
														<Field
															name='password'
															type='password'
															placeholder='Password *'
															className={
																" " +
																(errors.password &&
																	touched.password
																	? " error"
																	: "")
															}
														/>
													</td>

												</tr> */}
												<tr>
													<td colSpan='2'>
														<br />
													</td>
												</tr>
												<tr>
													<td>
														<div className='flex'>
															<label
																htmlFor='checkId'
																style={{
																	display:
																		"flex",
																	alignItems:
																		"center",
																	gap: "10px",
																	cursor: "pointer",
																}}>
																<input
																	type='checkbox'
																	id='checkId'
																/>
																<p
																	className='color-primary'
																	style={{
																		fontSize:
																			"16px",
																	}}>
																	Remember me
																</p>
															</label>
														</div>
													</td>
													<td>
														<p
															className='t-a-r color-primary'
															style={{
																fontSize:
																	"14px",
															}}>
															<NavLink to='/forgot-password'>
																<span className='transition hover-color-secondary'>
																	Forgot
																	Password?
																</span>
															</NavLink>
														</p>
													</td>
												</tr>
												<tr>
													<td colSpan='2'>
														{loading ? (
															<ButtonTextIcon
																type='button'
																title='Signing in...'
																disabled={
																	loading
																}
																icon={
																	<LoaderIcon />
																}
															/>
														) : (
															<ButtonTextOnly
																type='submit'
																title='Sign In'
															/>
														)}
													</td>
												</tr>
											</tbody>
										</table>
										{status && (
											<p className='status color-secondary f-w-300 l-h-1'>
												{status}
											</p>
										)}
									</Form>
								)}
							</Formik>
						</div>
					</div>
				</div>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='90.205'
					height='82.351'
					viewBox='0 0 90.205 82.351'
					className='svg-bottom'>
					<g transform='translate(-276.138 -252.079)'>
						<path
							d='M276.138,252.079V334.43h57.775s18.088-.5,24.874-10.241,12.908-27.337,0-41.4-31.156-30.71-31.156-30.71Z'
							fill='#5dc6b3'
						/>
						<path
							d='M290.953,334.43l43.512-.032a56.454,56.454,0,0,0,8.178-1.2,45.36,45.36,0,0,0,5.6-1.582v-32.64L320.021,269.53l-29.069,29.446Z'
							fill='#3b86b5'
						/>
						<path
							d='M276.138,298.387V269.279l44.181.244-28.911,28.864Z'
							fill='#1e4ccb'
						/>
					</g>
				</svg>
			</section>
		</section>
	);
};

export default Login;
