import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import PageTitle from "../../components/common/PageTitle";
import LoaderIcon from "../../components/ui/LoaderIcon";
import { jwtDecode } from "jwt-decode";
import { getDefaultURLForRole } from "../../services/roles";
import { API_URL, RS_TAGLINE } from "../../utilities/constants";
import RentSavvyLogo from "../../components/common/RentSavvyLogo";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import ButtonTextOnly from "../../components/ui/ButtonTextOnly";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import { CiCircleCheck, CiLocationArrow1 } from "react-icons/ci";
import FileInput from "../../components/ui/FileInput";
import { useUI } from "../../context/ui";

const AgencyAdminPending = () => {
	const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState("");
	const [trnVerified, setTrnVerified] = useState(false);
	const [ornVerified, setOrnVerified] = useState(false);
	const [trnVerifying, setTrnVerifying] = useState(false);
	const [ornVerifying, setOrnVerifying] = useState(false);
	const [logoFile, setLogoFile] = useState(null);
	const [logoUploadURL, setLogoUploadURL] = useState(null);
	const navigate = useNavigate();
	const location = useLocation();
	const {
		setNewNotification,
		setShowNotification,
		addNotification,
		setSegmentTitle,
		addToastMessage,
	} = useUI();

	const FormValidation = Yup.object().shape({
		trnNumber: Yup.string()
			.matches(/^\d+$/, "License number must contain only digits.")
			.length(15, "License  number must be exactly 15 digits long")
			.required("Required!"),
		ornNumber: Yup.string()
			.matches(/^\d+$/, "ORN number must contain only digits")
			.length(15, "ORN number must be exactly 15 digits long")
			.required("Required!"),
		brnNumber: Yup.string()
			.matches(/^\d+$/, "BRN number must contain only digits")
			.length(15, "BRN number must be exactly 15 digits long")
			.required("Required!"),
	});

	const handleLogoUpload = async () => {
		setLoading(true);
		try {
			const formdata = new FormData();
			formdata.append("file", logoFile, logoFile.name);
			formdata.append("type", "CREDIT_PROVIDER_LOGO");
			const resp = await fetch(API_URL + `/api/1.0.0/uploadFile`, {
				method: "POST",
				headers: {
					"X-JWT-Assertion": localStorage.getItem("token"),
				},
				body: formdata,
				redirect: "follow",
			});
			if (resp.ok) {
				const responseData = await resp.json();
				if (responseData.result.responseCode === 200) {
					addToastMessage({
						status: true,
						message:
							"Logo has been successfully uploaded.",
					});
					setLogoUploadURL(responseData.data);
				}
			} else {
				const errorData = await resp.json();
				const errorMessage = errorData.responseDescription;
				addToastMessage({
					status: false,
					message:
						errorMessage ||
						"Something went wrong while uploading a credit provider logo. Please try again.",
				});
			}
		} catch (e) {
			console.log("Error", e);
			setNewNotification(true);
			setShowNotification(true);
			addNotification({
				id: Date.now(),
				message:
					"Could not upload the credit provider logo at the moment.",
				status: false,
				archive: false,
			});
		} finally {
			setLoading(false);
		}
	};

	const initial = {
		trnNumber: "",
		ornNumber: "",
		brnNumber: "",
	};

	const checkIfNextURLExists = () => {
		const params = new URLSearchParams(location.search);
		const queryObj = {};
		params.forEach((value, key) => {
			queryObj[key] = value;
		});
		if (queryObj.next) {
			return queryObj.next;
		} else {
			return false;
		}
	};

	const handleSubmit = async (trnNumber, ornNumber, brnNumber) => {
		setStatus("");
		setLoading(true);
		const request = {
			trnNumber: trnNumber,
			ornNumber: ornNumber,
			brnNumber: brnNumber,
			logo: logoUploadURL,
		};

		try {
			const resp = await fetch(API_URL + "/api/1.0.0/brokerAgency", {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					"X-JWT-Assertion": localStorage.getItem("token"),
				},
				body: JSON.stringify(request),
			});
			if (resp.ok) {

				const response = await resp.json();
				if (response.result.responseCode === 200) {
					addToastMessage({
						status: true,
						message:
							"You have successfully updated agreement, Thank You!",
					});
					const nextURL = checkIfNextURLExists();
					const checkForFlow = localStorage.getItem("flow");
					if (checkForFlow) {
						const checkForFlowObj = JSON.parse(checkForFlow);
						const filteredFlows = checkForFlowObj.filter((el) => {
							return el !== "/basa-pending-details";
						});
						if (
							localStorage.setItem(
								"flow",
								JSON.stringify(filteredFlows)
							) === undefined
						) {
							if (filteredFlows.length) {
								if (nextURL) {
									navigate(
										filteredFlows[0] + "?next=" + nextURL
									);
								} else {
									navigate(filteredFlows[0]);
								}
							} else {
								localStorage.removeItem("flow");
								if (nextURL) {
									navigate(nextURL);
								} else {
									const decodedToken = jwtDecode(
										localStorage.getItem("token")
									);
									const roles = decodedToken.authorities;
									navigate(getDefaultURLForRole(roles[0]));
								}
							}
						} else {
							setStatus("Could not update the flow");
						}
					} else {
						if (nextURL) {
							navigate(nextURL);
						} else {
							const decodedToken = jwtDecode(
								localStorage.getItem("token")
							);
							const roles = decodedToken.authorities;
							navigate(getDefaultURLForRole(roles[0]));
						}
					}
				} else {
					const errorData = await resp.json();
					const errorMessage = errorData.responseDescription;
					addToastMessage({
						status: false,
						message:
							errorMessage ||
							"Something went wrong while updating agreement. Please try again.",
					});
					setStatus("Could not update agreement");
				}
			} else {

				setStatus("API Failed");
			}
		} catch (error) {
			setStatus("An Error Occurred");
		} finally {
			setLoading(false);
		}
	};

	const handleTRNVerify = async (val) => {
		setTrnVerifying(true);
		try {
			const resp = await fetch(API_URL + `/api/1.0.0/verify/trn/${val}`, {
				headers: {
					"Content-Type": "application/json",
					"X-JWT-Assertion": localStorage.getItem("token"),
				},
			});
			if (resp.ok) {
				const response = await resp.json();
				if (response.result.responseCode === 200) {
					setTrnVerified(true);
					setStatus("");
				} else {
					setStatus("Trade License is incorrect!");
				}
			} else {
				setStatus("Invalid Trade Registration Number!");
			}
		} catch {
			setStatus("Verify TRN API failed!");
		} finally {
			setTrnVerifying(false);
		}
	};

	const handleORNVerify = async (val) => {
		setOrnVerifying(true);
		try {
			const resp = await fetch(API_URL + `/api/1.0.0/verify/orn/${val}`, {
				headers: {
					"Content-Type": "application/json",
					"X-JWT-Assertion": localStorage.getItem("token"),
				},
			});
			if (resp.ok) {
				const response = await resp.json();
				if (response.result.responseCode === 200) {
					setOrnVerified(true);
					setStatus("");
				} else {
					setStatus("ORN is incorrect!");
				}
			} else {
				setStatus("Verify ORN API failed!");
			}
		} catch {
			setStatus("Verify ORN API failed!");
		} finally {
			setOrnVerifying(false);
		}
	};

	return (
		<section id='auth-page'>
			<PageTitle title='Enter Details' />
			<section className='auth-header'>
				<NavLink to='/login'>
					<div className='logo'>
						<RentSavvyLogo />
					</div>
				</NavLink>
			</section>
			<section className='auth-content'>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='90.396'
					height='82.351'
					viewBox='0 0 90.396 82.351'
					className='svg-top'>
					<g transform='translate(-288.14 -169.729)'>
						<path
							d='M276.138,252.079V334.43h57.775s18.088-.5,24.874-10.241,12.908-27.337,0-41.4-31.156-30.71-31.156-30.71Z'
							transform='translate(654.483 504.159) rotate(180)'
							fill='#5dc6b3'
						/>
						<path
							d='M327.624,252.079V190.464l25.5,21.287V252.08Z'
							fill='#1e4ccb'
						/>
						<path
							d='M327.624,190.464l25.4-19.87,25.512,19.87L353.027,211.9Z'
							fill='#3b86b5'
						/>
					</g>
				</svg>
				<div className='inner'>
					<div className='col col-1-2 left'>
						<div className='text'>
							<h1 className='l-h-1 f-w-300 color-primary'>
								Enter
								<br />
								Details!
							</h1>
							<br />
							<h5 className='f-w-300 color-primary'>
								{RS_TAGLINE}
							</h5>
						</div>
					</div>
					<div className='col col-1-2 right pos-rel'>
						<div className='box pending-details' style={{width:"440px"}}>
							<Formik
								initialValues={initial}
								validationSchema={FormValidation}
								onSubmit={async (values, { resetForm }) => {
									handleSubmit(
										values.trnNumber,
										values.ornNumber,
										values.brnNumber
									);
								}}>
								{({
									values,
									errors,
									touched,
									setFieldValue,
									resetForm,
								}) => (
									<Form>
										<h4 className='color-primary t-a-c'>
											Enter Details
										</h4>
										<table>
											<thead></thead>
											<tbody>
												<tr>
													<td colSpan='3'>
														<p className='title f-w-700 color-primary'>
															License No:
														</p>
														<Field
															name='trnNumber'
															type='text'
															placeholder='[Eg: 543************]'
															maxLength='15'
															className={
																" " +
																(errors.trnNumber &&
																	touched.trnNumber
																	? " error"
																	: "")
															}
														/>
														<ErrorMessage
															name='trnNumber'
															component='p'
															className='error-messages'
														/>
													</td>
													<td className='verify'>
														<p className='title f-w-700 color-primary'>
															&nbsp;
														</p>
														<>
															{trnVerified ? (
																<ButtonLightTextIcon
																	type='button'
																	style={{width:"150px"}}
																	data-name='verified'
																	title='Verified'
																	icon={
																		<CiCircleCheck />
																	}
																/>
															) : (
																<ButtonLightTextIcon
																	type='button'
																	style={{width:"150px"}}
																	disabled={
																		values
																			.trnNumber
																			.length < 15
																	}
																	title='Verify'
																	onClick={() =>
																		handleTRNVerify(
																			values.trnNumber
																		)
																	}
																	icon={
																		<>
																			{trnVerifying ? (
																				<LoaderIcon />
																			) : (
																				<svg
																					xmlns='http://www.w3.org/2000/svg'
																					width='18'
																					height='24'
																					viewBox='0 0 18 24'>
																					<path
																						d='M15.762,8.047l-4.381,4.475L9.166,10.4,7.93,11.638,11.381,15,17,9.285ZM12,2.544A11.888,11.888,0,0,0,19,4.96v9.575c0,3.042-1.686,3.827-7,7.107-5.309-3.278-7-4.065-7-7.107V4.96A11.884,11.884,0,0,0,12,2.544ZM12,0C8.629,2.866,6.516,3,3,3V14.535c0,4.6,3.2,5.8,9,9.465,5.8-3.661,9-4.862,9-9.465V3c-3.516,0-5.629-.134-9-3Z'
																						transform='translate(-3)'
																					/>
																				</svg>
																			)}
																		</>
																	}
																/>
															)}
														</>
													</td>
												</tr>
												<tr>
													<td colSpan="3">
														<p className='title f-w-700 color-primary'>
															ORN / RERA No:
														</p>
														<Field
															name='ornNumber'
															type='text'
															maxLength='15'
															placeholder='ORN: Eg: 12345'
															className={
																" " +
																(errors.ornNumber &&
																	touched.ornNumber
																	? " error"
																	: "")
															}
														/>
														<ErrorMessage
															name='ornNumber'
															component='p'
															className='error-messages'
														/>
													</td>
													<td className='verify'>
														<p className='title f-w-700 color-primary'>
															&nbsp;
														</p>
														<>
															{ornVerified ? (
																<ButtonLightTextIcon
																	type='button'
																	style={{width:"150px"}}
																	data-name='verified'
																	title='Verified'
																	icon={
																		<CiCircleCheck />
																	}
																/>
															) : (
																<ButtonLightTextIcon
																	type='button'
																	style={{width:"150px"}}
																	disabled={
																		values
																			.ornNumber
																			.length < 15
																	}
																	title='Verify'
																	onClick={() =>
																		handleORNVerify(
																			values.ornNumber
																		)
																	}
																	icon={
																		<>
																			{ornVerifying ? (
																				<LoaderIcon />
																			) : (
																				<svg
																					xmlns='http://www.w3.org/2000/svg'
																					width='18'
																					height='24'
																					viewBox='0 0 18 24'>
																					<path
																						d='M15.762,8.047l-4.381,4.475L9.166,10.4,7.93,11.638,11.381,15,17,9.285ZM12,2.544A11.888,11.888,0,0,0,19,4.96v9.575c0,3.042-1.686,3.827-7,7.107-5.309-3.278-7-4.065-7-7.107V4.96A11.884,11.884,0,0,0,12,2.544ZM12,0C8.629,2.866,6.516,3,3,3V14.535c0,4.6,3.2,5.8,9,9.465,5.8-3.661,9-4.862,9-9.465V3c-3.516,0-5.629-.134-9-3Z'
																						transform='translate(-3)'
																					/>
																				</svg>
																			)}
																		</>
																	}
																/>
															)}
														</>
													</td>
												</tr>
												<tr>
													<td colSpan="3">
														<p className='title f-w-700 color-primary'>
															BRN (Optional):
														</p>
														<Field
															name='brnNumber'
															type='text'
															maxLength='15'
															placeholder='BRN: Eg: 12345678'
															className={
																" " +
																(errors.brnNumber &&
																	touched.brnNumber
																	? " error"
																	: "")
															}
														/>
														<ErrorMessage
															name='brnNumber'
															component='p'
															className='error-messages'
														/>
													</td>
												</tr>
												<tr>
													<td colSpan="3">
														<p className='title f-w-700 color-primary'>
															Logo (Optional):
														</p>
														<FileInput
															defaultText="Select Logo"
															accept="image/jpeg, image/jpg, image/png"
															maxSizeAllowed={2 * 1024 * 1024}
															callback={(f) => {
																setLogoFile(f);
															}}
														/>
														{logoFile && (
															<p
																className="cursor-pointer"
																style={{
																	fontSize: "12px",
																	color: "red",
																	marginTop: "10px",
																}}
																onClick={() => {
																	setLogoFile(null);
																	setLogoUploadURL("");
																}}
															>
																Delete logo file
															</p>
														)}
													</td>
													<td className='verify'>
														<p className='title f-w-700 color-primary'>
															&nbsp;
														</p>
														<ButtonLightTextIcon
															type='button'
															style={{width:"140px"}}
															data-name={
																logoUploadURL
																	? "verified"
																	: ""
															}
															title={
																logoUploadURL
																	? "Uploaded"
																	: "Click to Upload"
															}
															disabled={
																loading ||
																!logoFile
															}
															icon={
																<>
																	{loading ? (
																		<LoaderIcon />
																	) : (
																		<>
																			{logoUploadURL ? (
																				<CiCircleCheck />
																			) : (
																				<CiLocationArrow1 />
																			)}
																		</>
																	)}
																</>
															}
															onClick={
																handleLogoUpload
															}
														/>

														{logoFile && (
															<p
																style={{
																	fontSize: "12px",
																	color: "red",
																	marginTop: "10px",
																}}
															>
																*Click to upload
															</p>
														)}
													</td>
												</tr>
												<tr>
													<td colSpan="6" >
														<p className="status f-w-200 l-h-1 font-size-12">
															NOTE: Please upload files in JPG, PNG, or JPEG format, up to 2MB. Files exceeding these limits won't be accepted.
														</p>
													</td>
												</tr>
												<tr>
													<td colSpan='4'>
														{loading ? (
															<ButtonTextIcon
																type='button'
																title='Updating...'
																disabled={
																	loading
																}
																icon={
																	<LoaderIcon />
																}
															/>
														) : (
															<ButtonTextOnly
																disabled={
																	!trnVerified ||
																	!ornVerified
																}
																type='submit'
																title='Update Details'
															/>
														)}
													</td>
												</tr>
											</tbody>
										</table>
										{status && (
											<p className='status color-secondary f-w-300 l-h-1'>
												{status}
											</p>
										)}
									</Form>
								)}
							</Formik>
						</div>
					</div>
				</div>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='90.205'
					height='82.351'
					viewBox='0 0 90.205 82.351'
					className='svg-bottom'>
					<g transform='translate(-276.138 -252.079)'>
						<path
							d='M276.138,252.079V334.43h57.775s18.088-.5,24.874-10.241,12.908-27.337,0-41.4-31.156-30.71-31.156-30.71Z'
							fill='#5dc6b3'
						/>
						<path
							d='M290.953,334.43l43.512-.032a56.454,56.454,0,0,0,8.178-1.2,45.36,45.36,0,0,0,5.6-1.582v-32.64L320.021,269.53l-29.069,29.446Z'
							fill='#3b86b5'
						/>
						<path
							d='M276.138,298.387V269.279l44.181.244-28.911,28.864Z'
							fill='#1e4ccb'
						/>
					</g>
				</svg>
			</section>
		</section>
	);
};

export default AgencyAdminPending;
