import { useEffect, useRef, useState } from "react";
import FullPageLoader from "../../components/ui/FullPageLoader";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import RSModal from "../../components/ui/RSModal";
import FileInput from "../../components/ui/FileInput";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import LoaderIcon from "../../components/ui/LoaderIcon";
import {
	API_URL,
	DEFAULT_PER_PAGE,
	LOAN_APPLICATION_STATUSES,
} from "../../utilities/constants";
import Pagination from "../../components/common/Pagination";
import PageTitle from "../../components/common/PageTitle";
import {
	decodeRolesFromToken,
	formatServerDate,
} from "../../utilities/helpers";
import ResultsPerPage from "../_common/ResultsPerPage";
import ResultsStatusFilter from "../_common/ResultsStatusFilter";
import { useUI } from "../../context/ui";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
	CiBag1,
	CiBarcode,
	CiBookmark,
	CiCalculator1,
	CiCircleCheck,
	CiCirclePlus,
	CiSearch,
	CiHospital1,
	CiLink,
	CiLocationOn,
	CiMail,
	CiPhone,
	CiStickyNote,
	CiUser,
	CiCircleRemove,
	CiLocationArrow1,
} from "react-icons/ci";
import { formatAmountValue } from "../../utilities/helpers"

const ConfirmAcceptChange = ({ loanApplicationID, callback, setShow }) => {
	const {
		setNewNotification,
		setShowNotification,
		addNotification,
		addToastMessage,
	} = useUI();
	const [loading, setLoading] = useState(false);
	const [downloadTenancyAgreementUrl, setDownloadTenancyAgreementUrl] =
		useState(null);
	const [tenancyAgreementUploadURL, setTenancyAgreementUploadURL] =
		useState("");
	const [tenancyAgreementFile, setTenancyAgreementFile] = useState(null);
	const FieldTitle = ({ title }) => {
		return (
			<p
				className='title f-w-700 color-primary'
				style={{ marginBottom: "5px", fontSize: "14px" }}>
				{title}
			</p>
		);
	};
	const handleReject = async (id) => {
		setLoading(true);
		try {
			const response = await fetch(
				`${API_URL}/api/1.0.0/loan-application/reject-loan/${id}`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
						"X-JWT-Assertion": localStorage.getItem("token"),
					},
				}
			);
			if (response.ok) {
				const data = await response.json();
				if (data) {
					setShow(false);
					callback();
				}
			} else {
				const errorData = await response.json();
				throw new Error(
					errorData.result.responseDescription || "API request failed"
				);
			}
		} catch (e) {
			console.error("Error:", e);
			setNewNotification(true);
			setShowNotification(true);
			addNotification({
				id: Date.now(),
				message:
					"An error occurred while processing your acceptance. Please try again.",
				status: false,
				archive: false,
			});
		} finally {
			setLoading(false);
		}
	};
	const handleAccept = async (id) => {
		setLoading(true);
		try {
			const request = {
				tenancyDocumentURL: tenancyAgreementUploadURL,
			};
			const response = await fetch(
				`${API_URL}/api/1.0.0/loan-application/agreement-documents/${id}`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
						"X-JWT-Assertion": localStorage.getItem("token"),
					},
					body: JSON.stringify(request),
				}
			);
			if (response.ok) {
				const data = await response.json();
				if (data) {
					setShow(false);
					callback();
				}
			} else {
				const errorData = await response.json();
				throw new Error(
					errorData.result.responseDescription || "API request failed"
				);
			}
		} catch (e) {
			console.error("Error:", e);
			setNewNotification(true);
			setShowNotification(true);
			addNotification({
				id: Date.now(),
				message:
					"An error occurred while processing your acceptance. Please try again.",
				status: false,
				archive: false,
			});
		} finally {
			setLoading(false);
		}
	};

	const downloadTenancyAgreementPDF = async (e, id) => {
		e.preventDefault();
		const apiUrl = `${API_URL}/api/1.0.0/loan-application/tenancy-document/${id}`;

		try {
			const response = await fetch(apiUrl, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					"X-JWT-Assertion": localStorage.getItem("token"),
				},
			});
			const data = await response.json();
			if (data.result.responseCode === 200) {
				setDownloadTenancyAgreementUrl(data.data);
				// Automatically trigger the download
				document.getElementById("download-link").click();
				// Cleanup after download
				setTimeout(() => {
					setDownloadTenancyAgreementUrl(null);
				}, 5000);
			} else {
				const errorData = await response.json();
				throw new Error(
					errorData.result.responseDescription || "API request failed"
				);
			}
		} catch (e) {
			console.log("Error", e);
			setNewNotification(true);
			setShowNotification(true);
			addNotification({
				id: Date.now(),
				message:
					"We are currently unable to download the tenancy agreement. Please try again later.",
				status: false,
				archive: false,
			});
		}
	};

	const handleTenancyAgreementUpload = async () => {
		setLoading(true);
		try {
			const formdata = new FormData();
			formdata.append(
				"file",
				tenancyAgreementFile,
				tenancyAgreementFile.name
			);
			formdata.append("type", "TENANCY_AGREEMENT");
			const resp = await fetch(API_URL + `/api/1.0.0/uploadFile`, {
				method: "POST",
				headers: {
					"X-JWT-Assertion": localStorage.getItem("token"),
				},
				body: formdata,
				redirect: "follow",
			});
			if (resp.ok) {
				const responseData = await resp.json();
				if (responseData.result.responseCode === 200) {
					setTenancyAgreementUploadURL(responseData.data);
					addToastMessage({
						status: true,
						message:
							"You have successfully uploaded tenancy agreement, Thank You!",
					});
				}
			} else {
				const errorData = await resp.json();
				const errorMessage = errorData.responseDescription;
				addToastMessage({
					status: false,
					message:
						errorMessage ||
						"Something went wrong while uploading a tenancy agreement. Please try again.",
				});
			}
		} catch (e) {
			console.log("Error", e);
			setNewNotification(true);
			setShowNotification(true);
			addNotification({
				id: Date.now(),
				message:
					"Could not upload the tenancy agreement at the moment.",
				status: false,
				archive: false,
			});
		} finally {
			setLoading(false);
		}
	};

	return (
		<div
			style={{
				height: "auto",
				width: "600px",
				padding: "40px",
			}}>
			<h4 className='color-primary'>
				Are you sure you want to proceed the application?
			</h4>
			<br />
			<p
				className=' font-size-14 color-primary '
				style={{ maxWidth: "320px" }}>
				NOTE: Please download the tenancy agreement, owner sign it, and
				upload it to complete the process.
			</p>
			<div>
				{" "}
				<a
					id='download-link'
					href={downloadTenancyAgreementUrl}
					download='Tenancy Agreement'
					style={{ display: "none" }}>
					Download PDF
				</a>
				<a
					href='#'
					onClick={(e) =>
						downloadTenancyAgreementPDF(e, loanApplicationID)
					}>
					<div className='flex center-left'>
						<CiLink className='color-primary' />
						&nbsp;
						<p className='transition font-size-14 color-faded hover-color-secondary'>
							Click here to download
						</p>
					</div>
					<br />
				</a>
			</div>
			{/* <p>
        Changing this might have some unexpected consequences, please make sure
        that you understand.
      </p> */}
			<br />

			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "flex-start",
					gap: "12px",
				}}>
				<div>
					<FieldTitle title='Tenancy Agreement' />
					<FileInput
						defaultText='Select Tenancy Agreement'
						accept='application/pdf'
						maxSizeAllowed={2 * 1024 * 1024}
						callback={(f) => {
							setTenancyAgreementFile(f);
						}}
					/>
					{tenancyAgreementFile && (
						<p
							className='cursor-pointer'
							style={{
								fontSize: "12px",
								color: "red",
							}}
							onClick={() => {
								setTenancyAgreementFile(null);
								setTenancyAgreementUploadURL("");
							}}>
							Delete tenancy agreement file
						</p>
					)}
				</div>
				<tr>
					<td colSpan="6" >
						<p className="status f-w-200 l-h-1 font-size-12">
							NOTE: Please upload files in PDF format, up to 2MB. Files exceeding these limits won't be accepted.
						</p>
					</td>
				</tr>
				<div>
					<p>&nbsp;</p>
					<ButtonTextIcon
						type='button'
						title={
							tenancyAgreementUploadURL ? "Uploaded" : "Upload"
						}
						disabled={loading || !tenancyAgreementFile}
						icon={
							<>
								{loading ? (
									<LoaderIcon />
								) : (
									<>
										{tenancyAgreementUploadURL ? (
											<CiCircleCheck />
										) : (
											<CiLocationArrow1 />
										)}
									</>
								)}
							</>
						}
						onClick={handleTenancyAgreementUpload}
					/>
					{tenancyAgreementFile && (
						<p
							style={{
								fontSize: "12px",
								color: "red",
								marginTop: "10px",
							}}
						>
							*Click to upload
						</p>
					)}
					{tenancyAgreementFile && <p>&nbsp;</p>}
				</div>
			</div>

			<br />
			<br />
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "flex-end",
					gap: "12px",
				}}>
				<ButtonLightTextIcon
					title='Go back'
					icon={
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='26.562'
							height='17.809'
							viewBox='0 0 26.562 17.809'>
							<path d='M0,8.88,10.686,0l1.937,2.293L6.453,7.4H26.562v3H6.454l6.166,5.108-1.934,2.3Z' />
						</svg>
					}
					onClick={() => setShow(false)}
				/>
				{loading ? (
					<ButtonTextIcon
						type='button'
						title='Updating...'
						disabled={loading}
						icon={<LoaderIcon />}
					/>
				) : (
					<ButtonTextIcon
						title='Reject'
						style={{ background: "red" }}
						disabled={loading}
						icon={<CiCircleRemove />}
						onClick={() => handleReject(loanApplicationID)}
					/>
				)}
				{loading ? (
					<ButtonTextIcon
						type='button'
						title='Updating...'
						disabled={loading}
						icon={<LoaderIcon />}
					/>
				) : (
					<ButtonTextIcon
						title='Accept'
						style={{ background: "green" }}
						disabled={loading || !tenancyAgreementUploadURL}
						icon={<CiCircleCheck />}
						onClick={() => handleAccept(loanApplicationID)}
					/>
				)}
			</div>
		</div>
	);
};

const AgencyBrokerLoanApplications = () => {
	const [loading, setLoading] = useState(false);
	const location = useLocation();
	const [data, setData] = useState(null);
	const { setSegmentTitle, addToastMessage } = useUI();
	const navigate = useNavigate();

	const callListApi = async () => {
		setLoading(true);
		const pageNo = new URLSearchParams(location.search).get("pageNo");
		const pageSize = new URLSearchParams(location.search).get("pageSize");
		const fromDate = new URLSearchParams(location.search).get("fromDate");
		const toDate = new URLSearchParams(location.search).get("toDate");
		const statusID = new URLSearchParams(location.search).get("statusID");
		try {
			let url =
				API_URL +
				`/api/1.0.0/loan-application?${statusID ? `statusID=${statusID}` : ""
				}&pageNo=${pageNo}&pageSize=${pageSize}&sortOrder=desc`;
			if (fromDate && toDate) {
				url += "&fromDate=" + fromDate + "&toDate=" + toDate;
			}

			const resp = await fetch(url, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					"X-JWT-Assertion": localStorage.getItem("token"),
				},
			});
			if (resp.ok) {
				const response = await resp.json();
				setData(response.data);
			}
		} catch (e) {
			console.log("Error", e);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (location.search) {
			callListApi();
			setSegmentTitle("Rental Applications");
		}
	}, [location.search]);

	const getStatusDetails = (id) => {
		const o = LOAN_APPLICATION_STATUSES.find((obj) => obj.id === id);
		return o
			? o
			: {
				title: "Not found",
				color: "#5dc6b3",
			};
	};

	const copyRef = useRef();

	const copyToClipboard = (str, ref) => {
		navigator.clipboard.writeText(str).then(
			function () {
				console.log(str);
				ref.current.innerHTML = "Copied.";
				setTimeout(() => {
					ref.current.innerHTML = "Click to copy";
				}, 400);
			},
			function (err) {
				console.error("Async: Could not copy text: ", err);
				ref.current.innerHTML = "Could not copy";
			}
		);
	};

	const initialValues = {
		fromDate: "",
		toDate: "",
	};

	const FormValidation = Yup.object().shape({
		fromDate: Yup.date().required("End date is required"),
		toDate: Yup.date()
			.required("End date is required")
			.min(Yup.ref("fromDate"), "End Date must be later than Start Date"),
	});

	const getPerPageValue = () => {
		const pageSize = new URLSearchParams(location.search).get("pageSize");
		return pageSize;
	};

	const getStatusIDValue = () => {
		const statusID = new URLSearchParams(location.search).get("statusID");
		return statusID;
	};

	return (
		<section className='pos-rel'>
			<PageTitle title='Rental Applications' />
			<FullPageLoader isActive={loading} />
			<section>
				<div
					className='flex center-left'
					style={{ padding: "20px", gap: "12px" }}>
					<ResultsPerPage
						targetURL={`/ab-loan-applications?pageNo=0&${getStatusIDValue()
							? `statusID=${getStatusIDValue()}`
							: ""
							}&pageSize=`}
					/>
					<ResultsStatusFilter
						targetURL={`/ab-loan-applications?pageNo=0&pageSize=${getPerPageValue()}&statusID=`}
						statusFilterArr={[6, 8, 11, 12, 13, 25]}
					/>

					<div>
						<Formik
							initialValues={initialValues}
							validationSchema={FormValidation}
							onSubmit={async (values, { resetForm }) => {
								const pageNo = new URLSearchParams(
									location.search
								).get("pageNo");
								const pageSize = new URLSearchParams(
									location.search
								).get("pageSize");
								const statusID = new URLSearchParams(
									location.search
								).get("statusID");
								navigate(
									`/ab-loan-applications?${statusID ? `statusID=${statusID}&` : ""
									}pageNo=${pageNo}&pageSize=${pageSize}&fromDate=${values.fromDate
									}&toDate=${values.toDate}`
								);
							}}>
							{({
								values,
								errors,
								touched,
								setFieldValue,
								resetForm,
							}) => (
								<Form>
									<div
										className='flex center-center'
										style={{ gap: "12px" }}>
										<Field
											name='fromDate'
											type='date'
											className={
												" " +
												(errors.fromDate &&
													touched.fromDate
													? " error"
													: "")
											}
										/>
										<Field
											name='toDate'
											type='date'
											className={
												" " +
												(errors.toDate && touched.toDate
													? " error"
													: "")
											}
										/>
										<ButtonTextIcon
											type='submit'
											icon={<CiSearch />}
										/>
									</div>
								</Form>
							)}
						</Formik>
					</div>
					{decodeRolesFromToken()[0] === 4 && (
						<div style={{ marginLeft: "auto" }}>
							<NavLink to='/ab-loan-applications/create'>
								<ButtonTextIcon
									title='New Loan'
									icon={<CiCirclePlus />}
								/>
							</NavLink>
						</div>
					)}
				</div>
				<div className='results'>
					<div className='rs-table-holder'>
						<table className='rs-table'>
							<thead>
								<tr>
									<th>Customer name</th>
									<th>Email</th>
									<th>Phone</th>
									<th>Loan Amount</th>
									<th>Status</th>
									<th>Date</th>
									<th>Action</th>
								</tr>
							</thead>
							{data && (
								<tbody>
									{data.loanApplications.map((d, i) => (
										<tr key={i}>
											<td>
												{d.tenant.firstName}{" "}
												{d.tenant.lastName}
											</td>
											<td>{d.tenant.email}</td>
											<td>{`(+971) ${d.tenant.phoneNo}`}</td>
											<td>
												{`${formatAmountValue(d.rentalValue)}/- AED`}
											</td>
											{/* <td>{`${parseFloat(
												d.rentalValue
											).toFixed(2)}/- AED`}</td> */}
											<td>
												<div
													className='status active'
													style={{
														background:
															getStatusDetails(
																d.applicationStatus
															).color,
													}}>
													{
														getStatusDetails(
															d.applicationStatus
														).title
													}
												</div>
											</td>
											<td>
												{formatServerDate(d.createdAt)}
											</td>
											<td>
												<div
													className='flex center-center'
													style={{
														gap: "12px",
													}}>
													<NavLink
														className='t-d-ul transition hover-color-secondary'
														to={`/ab-loan-applications/${d.id}`}>
														<svg
															xmlns='http://www.w3.org/2000/svg'
															width='20'
															height='14'
															viewBox='0 0 20 14'
															style={{
																height: "16px",
																width: "16px",
															}}>
															<path
																d='M12,5C7.92,5,4.256,8.093,2.145,11.483a1,1,0,0,0,0,1.034C4.256,15.907,7.92,19,12,19c4.143,0,7.8-3.09,9.864-6.493a1,1,0,0,0,0-1.014C19.794,8.09,16.141,5,12,5Zm8.413,7c-1.837,2.878-4.9,5.5-8.413,5.5-3.465,0-6.532-2.632-8.4-5.5C5.465,9.132,8.533,6.5,12,6.5,15.516,6.5,18.577,9.124,20.411,12ZM12,8a4,4,0,1,1-4,4A4,4,0,0,1,12,8Zm0,1.5A2.5,2.5,0,1,0,14.5,12,2.5,2.5,0,0,0,12,9.5Z'
																transform='translate(-2 -5)'
															/>
														</svg>
													</NavLink>
													<div
														className='flex center-center cursor-pointer'
														style={{
															gap: "4px",
														}}
														onClick={() => {
															copyToClipboard(
																d.applicationLink,
																copyRef
															);
														}}>
														<svg
															xmlns='http://www.w3.org/2000/svg'
															width='20'
															height='20'
															viewBox='0 0 20 20'
															style={{
																height: "12px",
																width: "12px",
															}}>
															<path
																d='M6,18H3a1,1,0,0,1-1-1V3A1,1,0,0,1,3,2H17a1,1,0,0,1,1,1V6h3a1,1,0,0,1,1,1V21a1,1,0,0,1-1,1H7a1,1,0,0,1-1-1ZM7.5,7.5v13h13V7.5Zm5.75,5.75h-2.5a.75.75,0,0,0,0,1.5h2.5v2.5a.75.75,0,0,0,1.5,0v-2.5h2.5a.75.75,0,0,0,0-1.5h-2.5v-2.5a.75.75,0,0,0-1.5,0ZM16.5,6V3.5H3.5v13H6V7A1,1,0,0,1,7,6Z'
																transform='translate(-2 -2)'
															/>
														</svg>
														<p
															className='font-small'
															style={{
																fontSize: "9px",
															}}
															ref={copyRef}>
															Click to copy
														</p>
													</div>
													{/* {d.actionRequired && (
                            <div
                              className="flex center-center cursor-pointer"
                              style={{
                                gap: "4px",
                              }}
                            >
                              <RSModal
                                selector={
                                  <p
                                    className="font-small"
                                    style={{
                                      fontSize: "9px",
                                    }}
                                    ref={copyRef}
                                  >
                                    Reject/Approve
                                  </p>
                                }
                                content={
                                  <ConfirmAcceptChange
                                    callback={() => navigate(0)}
                                    loanApplicationID={d.id}
                                  />
                                }
                              />
                            </div>
                          )} */}
												</div>
											</td>
										</tr>
									))}
								</tbody>
							)}
							{((data &&
								data?.loanApplications?.length === 0 &&
								!loading) ||
								(!data && !loading)) && (
									<tbody>
										<tr>
											<td
												colSpan={7}
												style={{
													textAlign: "center",
													padding: "10px",
												}}>
												<p> No records found. </p>
											</td>
										</tr>
									</tbody>
								)}
						</table>
					</div>
				</div>
				<br />
				{data && (
					<Pagination
						totalRecords={data.totalRecords}
						pageNo={data.pageNo}
						pageSize={data.pageSize}
						baseUrl='/ab-loan-applications'
					/>
				)}
			</section>
		</section>
	);
};

export default AgencyBrokerLoanApplications;
