// ====== APPLICATION CONSTANTS ====== //
// export const API_URL = "http://192.168.1.174:8080"; // "https://be.rent-savvy.com";// 
export const API_URL = process.env.REACT_APP_API_BASE_URL;
// export const BASE_URL = "http://localhost:3000";
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const PAYMENT_REDIRECT_URL = process.env.REACT_APP_PAYMENT_REDIRECT_URL;
export const SYSTEM_TOKEN = process.env.REACT_APP_SYSTEM_TOKEN;
export const RS_TAGLINE = "Rent-Savvy, A smarter way to rent";
export const FREQUENCY_TYPE_LIST = [
	{
		name: "monthly",
		value: "MONTHLY",
	},
	{
		name: "quarterly",
		value: "QUARTERLY",
	},
	{
		name: "yearly",
		value: "YEARLY",
	},
];
export const APP_ROLES = [
	{
		roleId: 1,
		title: "RS_SuperAdmin",
		context: "sa",
		defaultURL: "/sa-dashboard",
		titleBeautify: "Super Admin",
	},
	{
		roleId: 2,
		title: "RS_SubAdmin",
		context: "rsa",
		defaultURL: "/rsa-dashboard",
		titleBeautify: "Sub Admin",
	},
	{
		roleId: 3,
		title: "Agency_SuperAdmin",
		context: "basa",
		defaultURL: "/basa-dashboard",
		titleBeautify: "Agency Admin",
	},
	{
		roleId: 4,
		title: "Agency_Broker",
		context: "ab",
		defaultURL: "/ab-dashboard",
		titleBeautify: "Agency Broker",
	},
	{
		roleId: 5,
		title: "Individual_Broker",
		context: "ib",
		defaultURL: "/ib-dashboard",
		titleBeautify: "Individual Broker",
	},
	{
		roleId: 6,
		title: "CreditProvider_SuperAdmin",
		context: "cpsa",
		defaultURL: "/cpsa-dashboard",
		titleBeautify: "Credit Provider Admin",
	},
	{
		roleId: 7,
		title: "CreditProvider_Approver",
		context: "cpua",
		defaultURL: "/cpua-dashboard",
		titleBeautify: "Approver",
	},
	{
		roleId: 8,
		title: "Tenant",
		context: "te",
		defaultURL: "/te-dashboard",
		titleBeautify: "Tenant",
	},
	{
		roleId: 9,
		title: "CreditProvider_Reviewer",
		context: "cpur",
		defaultURL: "/cpur-dashboard",
		titleBeautify: "Reviewer",
	},
];

export const DEFAULT_PER_PAGE = 10;
export const RS_VERSION = "0.1.0";

export const LOAN_APPLICATION_APPLIED_STATUS = 13;
export const LOAN_APPLICATION_LOAN_INITIATED_STATUS = 19;
export const LOAN_APPLICATION_INITIAL_PAYMENT_STATUS = 26;
export const LOAN_APPLICATION_ACCEPTED_BY_TENANT_STATUS = 20;
export const LOAN_APPLICATION_ACCEPTED_BY_BROKER_STATUS = 22;
export const LOAN_APPLICATION_REJECTED_STATUS = 12;

export const LOAN_APPLICATION_STATUSES = [
	{
		id: 1,
		title: "Active",
		color: "orangered",
	},
	{
		id: 2,
		title: "Inactive",
		color: "blue",
	},
	{
		id: 3,
		title: "Redo",
		color: "blue",
	},
	{
		id: 4,
		title: "Draft",
		color: "blue",
	},
	{
		id: 5,
		title: "Pending",
		color: "violet",
	},
	{
		id: 6,
		title: "Approved",
		color: "green",
	},
	{
		id: 7,
		title: "Created",
		color: "blue",
	},
	{
		id: 8,
		title: "Initiated",
		color: "orange",
	},
	{
		id: 9,
		title: "Completed by Broker",
		color: "green",
	},
	{
		id: 10,
		title: "Completed by Customer",
		color: "pink",
	},
	{
		id: 11,
		title: "Accepted",
		color: "purple",
	},
	{
		id: 12,
		title: "Rejected",
		color: "red",
	},
	{
		id: 13,
		title: "Applied",
		color: "lightseagreen",
	},
	{
		id: 19,
		title: "Loan Initiated",
		color: "cadetblue",
	},
	{
		id: 20,
		title: "Accepted by Tenant",
		color: "mediumseagreen",
	},
	{
		id: 21,
		title: "Rejected by Tenant",
		color: "red",
	},
	{
		id: 22,
		title: "Accepted by Broker",
		color: "darkcyan",
	},
	{
		id: 23,
		title: "Rejected by Broker",
		color: "red",
	},
	{
		id: 24,
		title: "Loan Disbursed",
		color: "cornflowerblue",
	},
	{
		id: 25,
		title: "Tenant Updated",
		color: "orange",
	},
	{
		id: 26,
		title: "Initial Payment",
		color: "green",
	},
];

export const MS_CLARITY_KEY_ADMIN = process.env.MS_CLARITY_KEY_ADMIN;
export const MS_CLARITY_KEY_TENANT = process.env.MS_CLARITY_KEY_TENANT;
export const APP_VERSION = "1.0.0";
