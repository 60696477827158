import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import {
	CiCirclePlus,
	CiUser,
	CiLocationOn,
	CiHome,
	CiCircleRemove,
	CiCircleCheck,
} from "react-icons/ci";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import FullPageLoader from "../../components/ui/FullPageLoader";
import PageTitle from "../../components/common/PageTitle";
import { API_URL } from "../../utilities/constants";
import Pagination from "../../components/common/Pagination";
import ResultsPerPage from "../_common/ResultsPerPage";
import { useUI } from "../../context/ui";
import { decodeRolesFromToken } from "../../utilities/helpers";
import ImageSliders from "../../components/common/ImageSliders";

const SingleProperty = ({ data }) => {
	return (
		<div className='card'>
			<div className='has-slider'>
				{data.s3PicturesList.length > 0 ? (
					<ImageSliders
						images={data.s3PicturesList}
						showArrows={false}
					/>
				) : (
					<div
						className='default-image'
						style={{ height: "280px" }}
					/>
				)}
			</div>
			<NavLink to={`/ab-properties/${data.id}`}>
				<div className='desc transition'>
					<h6 className='_title color-primary transition'>
						{data.propertyName}
					</h6>
					<div style={{ marginTop: "10px" }}>
						<div
							className='flex center-left'
							style={{ gap: "8px" }}>
							<CiUser className='color-light' />
							<p className='small color-light'>
								Owner: {data.owner.fullName}
							</p>
						</div>
						<div
							className='flex center-left'
							style={{ gap: "8px" }}>
							<CiLocationOn className='color-light' />
							<p className='small color-light'>
								{data.propertyAddress}, {data.pinCode}
							</p>
						</div>
						<div
							className='flex center-left'
							style={{ gap: "8px" }}>
							<CiHome className='color-light' />
							<p className='small color-light'>
								{data.propertyType}: {data.propertySize} sqft.
							</p>
						</div>
						<div
							className='flex center-right'
							style={{ gap: "2px", marginTop: "5px" }}>
							{data.loanActive ? (
								<>
									<CiCircleRemove
										className='color-light'
										style={{ color: "red" }}
									/>
									<p
										className='small color-light'
										style={{ color: "red" }}>
										Unavailable
									</p>
								</>
							) : (
								<>
									<CiCircleCheck className='color-secondary' />
									<p className='small color-secondary'>
										Available
									</p>
								</>
							)}
						</div>
					</div>
				</div>
			</NavLink>
		</div>
	);
};

const AgencyBrokerPropertyListing = () => {
	const [loading, setLoading] = useState(false);
	const location = useLocation();
	const [data, setData] = useState(null);
	const { setSegmentTitle } = useUI();

	const callListApi = async () => {
		setLoading(true);
		const pageNo = new URLSearchParams(location.search).get("pageNo");
		const pageSize = new URLSearchParams(location.search).get("pageSize");
		try {
			const resp = await fetch(
				API_URL +
					`/api/1.0.0/property?pageNo=${pageNo}&pageSize=${pageSize}&sortOrder=DESC`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						"X-JWT-Assertion": localStorage.getItem("token"),
					},
				}
			);
			if (resp.ok) {
				const response = await resp.json();
				setData(response.data);
			}
		} catch (e) {
			console.log("Error", e);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (location.search) {
			callListApi();
			setSegmentTitle("Properties");
		}
	}, [location.search]);

	return (
		<section className='pos-rel'>
			<PageTitle title='Property' />
			<FullPageLoader isActive={loading} />
			<section>
				<div className='flex center-left' style={{ padding: "20px" }}>
					<ResultsPerPage
						targetURL={`/ab-properties?pageNo=0&pageSize=`}
					/>
					{decodeRolesFromToken()[0] === 4 && (
						<div style={{ marginLeft: "auto" }}>
							<NavLink to='/ab-properties/add'>
								<ButtonTextIcon
									title='Add a Property'
									icon={<CiCirclePlus />}
								/>
							</NavLink>
						</div>
					)}
				</div>
			</section>
			{data && (
				<section className='property-cards'>
					{data.properties.map((property) => (
						<SingleProperty key={property.id} data={property} />
					))}
				</section>
			)}
			<br />
			{data && (
				<Pagination
					totalRecords={data.totalRecords}
					pageNo={data.pageNo}
					pageSize={data.pageSize}
					baseUrl='/ab-properties'
				/>
			)}
			{((!data && !loading) ||
				(data && data.properties?.length === 0 && !loading)) && (
				<section className='tenant-applications'>
					<div className='single'>
						<p className='f-w-600 color-primary t-a-c'>
							No property found.
						</p>
					</div>
				</section>
			)}
		</section>
	);
};

export default AgencyBrokerPropertyListing;
