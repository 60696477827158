import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import PageTitle from "../../components/common/PageTitle";
import LoaderIcon from "../../components/ui/LoaderIcon";
import { jwtDecode } from "jwt-decode";
import { getDefaultURLForRole } from "../../services/roles";
import { API_URL, RS_TAGLINE } from "../../utilities/constants";
import RentSavvyLogo from "../../components/common/RentSavvyLogo";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import ButtonTextOnly from "../../components/ui/ButtonTextOnly";
import { useUI } from "../../context/ui";

const TnCAgreement = () => {
	const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState("");
	const navigate = useNavigate();
	const location = useLocation();
	const { addToastMessage } = useUI();

	const FormValidation = Yup.object().shape({
		check: Yup.string().required("Required!"),
	});

	const initial = {
		check: "",
	};

	const checkIfNextURLExists = () => {
		const params = new URLSearchParams(location.search);
		const queryObj = {};
		params.forEach((value, key) => {
			queryObj[key] = value;
		});
		if (queryObj.next) {
			return queryObj.next;
		} else {
			return false;
		}
	};

	const handleSubmit = async () => {
		setStatus("");
		setLoading(true);
		try {
			const resp = await fetch(API_URL + "/api/1.0.0/brokerAgency", {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					"X-JWT-Assertion": localStorage.getItem("token"),
				},
				body: JSON.stringify({
					termsAccepted: true,
				}),
			});
			if (resp.ok) {
				addToastMessage({
					status: true,
					message:
						"You have successfully agreed to t&c agreement, thank you.",
				});
				const response = await resp.json();
				if (response.result.responseCode === 200) {
					const nextURL = checkIfNextURLExists();
					const checkForFlow = localStorage.getItem("flow");
					if (checkForFlow) {
						const checkForFlowObj = JSON.parse(checkForFlow);
						const filteredFlows = checkForFlowObj.filter((el) => {
							return el !== "/basa-tnc-agreement";
						});
						if (
							localStorage.setItem(
								"flow",
								JSON.stringify(filteredFlows)
							) === undefined
						) {
							if (filteredFlows.length) {
								if (nextURL) {
									navigate(
										filteredFlows[0] + "?next=" + nextURL
									);
								} else {
									navigate(filteredFlows[0]);
								}
							} else {
								localStorage.removeItem("flow");
								if (nextURL) {
									navigate(nextURL);
								} else {
									const decodedToken = jwtDecode(
										localStorage.getItem("token")
									);
									const roles = decodedToken.authorities;
									navigate(getDefaultURLForRole(roles[0]));
								}
							}
						} else {
							setStatus("Could not update the flow");
						}
					} else {
						if (nextURL) {
							navigate(nextURL);
						} else {
							const decodedToken = jwtDecode(
								localStorage.getItem("token")
							);
							const roles = decodedToken.authorities;
							navigate(getDefaultURLForRole(roles[0]));
						}
					}
				} else {
					setStatus("Could not update agreement");
				}
			} else {
					const errorData = await resp.json();
					const errorMessage = errorData.responseDescription;
					addToastMessage({
						status: false,
						message:
							errorMessage ||
							"Something went wrong while agree to t&c agreement. Please try again.",
					});
				setStatus("API Failed");
			}
		} catch (error) {
			setStatus("An Error Occurred");
		} finally {
			setLoading(false);
		}
	};

	return (
		<section id='auth-page'>
			<PageTitle title='Terms & Conditions' />
			<section className='auth-header'>
				<div className='logo'>
					<RentSavvyLogo />
				</div>
			</section>
			<section className='auth-content'>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='90.396'
					height='82.351'
					viewBox='0 0 90.396 82.351'
					className='svg-top'>
					<g transform='translate(-288.14 -169.729)'>
						<path
							d='M276.138,252.079V334.43h57.775s18.088-.5,24.874-10.241,12.908-27.337,0-41.4-31.156-30.71-31.156-30.71Z'
							transform='translate(654.483 504.159) rotate(180)'
							fill='#5dc6b3'
						/>
						<path
							d='M327.624,252.079V190.464l25.5,21.287V252.08Z'
							fill='#1e4ccb'
						/>
						<path
							d='M327.624,190.464l25.4-19.87,25.512,19.87L353.027,211.9Z'
							fill='#3b86b5'
						/>
					</g>
				</svg>
				<div className='inner'>
					<div className='col col-1-2 left'>
						<div className='text'>
							<h1 className='l-h-1 f-w-300 color-primary'>
								Terms &
								<br />
								Conditions!
							</h1>
							<br />
							<h5 className='f-w-300 color-primary'>
								{RS_TAGLINE}
							</h5>
						</div>
					</div>
					<div className='col col-1-2 right pos-rel'>
						<div className='terms-and-conditions'>
							<h4 className='color-primary'>
								Terms & Conditions
							</h4>
							<div className='scrollable'>
								<div className='content'>
									<ul>
										<li>
											<p>
												A rental agreement is a document
												that acts as a contract between
												you and your tenant, defining
												the terms of the tenancy. You
												can have it written in a way
												that is favorable to you because
												you can decide what goes into
												the agreement.
											</p>
										</li>
										<li>
											<p>
												Most rental agreements are
												short-term agreements, such as
												month-to-month tenancies, while
												lease agreements are usually for
												longer rental periods, such as
												six months, a year, or more.
											</p>
										</li>
										<li>
											<p>
												A rental agreement is a good
												idea if you want to make sure
												your tenant is reliable or if
												you're renting a room in a house
												in which you're living. It's
												easier to terminate a
												month-to-month tenancy than a
												long lease.
											</p>
										</li>
										<li>
											<p>
												A month-to-month rental
												agreement should include certain
												provisions so that the agreement
												protects you. It's often helpful
												to have an attorney prepare a
												rental agreement for you, even
												if it's just a one-page
												document, especially if you're a
												first-time landlord.
											</p>
										</li>
										<li>
											<p>
												Numerous provisions can be
												included, but a basic rental
												agreement should include at
												least the following 10 terms:
											</p>
										</li>
										<li>
											<p>
												Identify the parties to the
												agreement and the address of the
												property you own. Make sure you
												include the name of every tenant
												living at the property and their
												contact information. Include
												your name and contact
												information and the address of
												the property. Describe the
												property if it doesn't have a
												number. For example, if it's a
												room in a house, you can state
												that the property is the
												"third-floor bedroom" if there's
												only one bedroom on that floor.
											</p>
										</li>
										<li>
											<p>
												The term of the tenancy and how
												it ends. List how long the term
												is, such as a month-to-month
												rental or a three-month rental.
												Start the rental term on the
												first of the month. Include how
												much notice you and the tenant
												must give if either of you wants
												to end the agreement. Check with
												an attorney or your local
												building department about
												specific laws governing how much
												notice of termination you and
												the tenant must give for
												short-term or month-to-month
												agreements.
											</p>
										</li>
										<li>
											<p>
												Rent and security deposit. State
												how much the rent is per month
												and where and how the tenant
												should pay the rent. If you'll
												take credit cards over the
												phone, state that. If you want
												the tenant to send a rent check
												every month, provide the
												address. Include the amount of
												any late fees, but make sure
												they're not excessive. Also,
												list the amount of the security
												deposit. Check with your local
												building department about limits
												on how much you can collect for
												a security deposit and late
												fees.
											</p>
										</li>
										<li>
											<p>
												What's included with the rental.
												State whether you're providing
												any utilities, such as electric,
												gas, heat, and cable.
												Alternatively, state the
												tenant's responsibility for
												utilities. Be clear about what's
												included in the rent and what
												isn't. If you're providing
												appliances and furniture, list
												them by name, such as a
												dishwasher, stove, refrigerator,
												bed, and couch.
											</p>
										</li>
										<li>
											<p>
												Pets. State whether pets are
												allowed, what types, how many,
												and what, if any, extra charges
												apply. State clearly that the
												tenant cannot bring any other
												type of pet if you want to limit
												the type of animal. You can also
												choose to have a no-pet policy.
												State that in the rental
												agreement.
											</p>
										</li>
										<li>
											<p>
												Each occupant's name and the
												number of occupants. If you
												don't want additional occupants,
												state that the tenant is the
												only person allowed to occupy
												the premises. List all occupants
												and state, for example, that no
												more than two people may occupy
												the rental. State that this
												agreement is between you and
												your tenant only and that the
												tenant may not sublease or
												assign the rental.
											</p>
										</li>
										<li>
											<p>
												Landlord's access to the
												property for repairs,
												maintenance, and inspection.
												State what notice you'll give to
												enter the premises for repairs
												other than emergency repairs.
												Many local communities have
												their own notice requirements,
												while some states have
												consistent requirements
												throughout the state, so discuss
												this with your attorney or local
												building department. State that
												the tenant's failure to give you
												access for needed repairs is a
												ground for termination. Also,
												state what the tenant is
												responsible for repairing.
											</p>
										</li>
										<li>
											<p>
												Rules of the tenancy. List what
												you expect of the tenant, such
												as no illegal activities, no
												smoking on the premises, and no
												noise after a particular hour.
												State that you can terminate the
												agreement if the tenant fails to
												abide by the tenancy rules and
												that the tenant is responsible
												for legal fees if you have to
												take the tenant to court to
												enforce the agreement.
											</p>
										</li>
										<li>
											<p>
												Damaged property. State that the
												tenant is responsible for
												damages other than regular wear
												and tear. Include that the
												tenant must return the premises
												in "broom-clean" condition.
												State that the tenant is
												responsible for legal fees if
												you take the tenant to court for
												damaged property.
											</p>
										</li>
										<li>
											<p>
												Signatures. You and the tenant
												must sign and date the agreement
												at the bottom.
											</p>
										</li>
										<li>
											<p>
												So long as you have these terms
												in your rental agreement, you're
												protecting yourself in the event
												your tenant is someone you no
												longer want to rent to. The
												rental agreement provides an
												easy way for you to get them to
												move out and shows what they're
												responsible for if they don't
												leave voluntarily.
											</p>
										</li>
										<li>
											<p>
												A rental agreement is a document
												that acts as a contract between
												you and your tenant, defining
												the terms of the tenancy. You
												can have it written in a way
												that is favorable to you because
												you can decide what goes into
												the agreement.
											</p>
										</li>
										<li>
											<p>
												Most rental agreements are
												short-term agreements, such as
												month-to-month tenancies, while
												lease agreements are usually for
												longer rental periods, such as
												six months, a year, or more.
											</p>
										</li>
										<li>
											<p>
												A rental agreement is a good
												idea if you want to make sure
												your tenant is reliable or if
												you're renting a room in a house
												in which you're living. It's
												easier to terminate a
												month-to-month tenancy than a
												long lease.
											</p>
										</li>
										<li>
											<p>
												A month-to-month rental
												agreement should include certain
												provisions so that the agreement
												protects you. It's often helpful
												to have an attorney prepare a
												rental agreement for you, even
												if it's just a one-page
												document, especially if you're a
												first-time landlord.
											</p>
										</li>
										<li>
											<p>
												Numerous provisions can be
												included, but a basic rental
												agreement should include at
												least the following 10 terms:
											</p>
										</li>
										<li>
											<p>
												Identify the parties to the
												agreement and the address of the
												property you own. Make sure you
												include the name of every tenant
												living at the property and their
												contact information. Include
												your name and contact
												information and the address of
												the property. Describe the
												property if it doesn't have a
												number. For example, if it's a
												room in a house, you can state
												that the property is the
												"third-floor bedroom" if there's
												only one bedroom on that floor.
											</p>
										</li>
										<li>
											<p>
												The term of the tenancy and how
												it ends. List how long the term
												is, such as a month-to-month
												rental or a three-month rental.
												Start the rental term on the
												first of the month. Include how
												much notice you and the tenant
												must give if either of you wants
												to end the agreement. Check with
												an attorney or your local
												building department about
												specific laws governing how much
												notice of termination you and
												the tenant must give for
												short-term or month-to-month
												agreements.
											</p>
										</li>
										<li>
											<p>
												Rent and security deposit. State
												how much the rent is per month
												and where and how the tenant
												should pay the rent. If you'll
												take credit cards over the
												phone, state that. If you want
												the tenant to send a rent check
												every month, provide the
												address. Include the amount of
												any late fees, but make sure
												they're not excessive. Also,
												list the amount of the security
												deposit. Check with your local
												building department about limits
												on how much you can collect for
												a security deposit and late
												fees.
											</p>
										</li>
										<li>
											<p>
												What's included with the rental.
												State whether you're providing
												any utilities, such as electric,
												gas, heat, and cable.
												Alternatively, state the
												tenant's responsibility for
												utilities. Be clear about what's
												included in the rent and what
												isn't. If you're providing
												appliances and furniture, list
												them by name, such as a
												dishwasher, stove, refrigerator,
												bed, and couch.
											</p>
										</li>
										<li>
											<p>
												Pets. State whether pets are
												allowed, what types, how many,
												and what, if any, extra charges
												apply. State clearly that the
												tenant cannot bring any other
												type of pet if you want to limit
												the type of animal. You can also
												choose to have a no-pet policy.
												State that in the rental
												agreement.
											</p>
										</li>
										<li>
											<p>
												Each occupant's name and the
												number of occupants. If you
												don't want additional occupants,
												state that the tenant is the
												only person allowed to occupy
												the premises. List all occupants
												and state, for example, that no
												more than two people may occupy
												the rental. State that this
												agreement is between you and
												your tenant only and that the
												tenant may not sublease or
												assign the rental.
											</p>
										</li>
										<li>
											<p>
												Landlord's access to the
												property for repairs,
												maintenance, and inspection.
												State what notice you'll give to
												enter the premises for repairs
												other than emergency repairs.
												Many local communities have
												their own notice requirements,
												while some states have
												consistent requirements
												throughout the state, so discuss
												this with your attorney or local
												building department. State that
												the tenant's failure to give you
												access for needed repairs is a
												ground for termination. Also,
												state what the tenant is
												responsible for repairing.
											</p>
										</li>
										<li>
											<p>
												Rules of the tenancy. List what
												you expect of the tenant, such
												as no illegal activities, no
												smoking on the premises, and no
												noise after a particular hour.
												State that you can terminate the
												agreement if the tenant fails to
												abide by the tenancy rules and
												that the tenant is responsible
												for legal fees if you have to
												take the tenant to court to
												enforce the agreement.
											</p>
										</li>
										<li>
											<p>
												Damaged property. State that the
												tenant is responsible for
												damages other than regular wear
												and tear. Include that the
												tenant must return the premises
												in "broom-clean" condition.
												State that the tenant is
												responsible for legal fees if
												you take the tenant to court for
												damaged property.
											</p>
										</li>
										<li>
											<p>
												Signatures. You and the tenant
												must sign and date the agreement
												at the bottom.
											</p>
										</li>
										<li>
											<p>
												So long as you have these terms
												in your rental agreement, you're
												protecting yourself in the event
												your tenant is someone you no
												longer want to rent to. The
												rental agreement provides an
												easy way for you to get them to
												move out and shows what they're
												responsible for if they don't
												leave voluntarily.
											</p>
										</li>
									</ul>
								</div>
							</div>
							<div>
								<Formik
									initialValues={initial}
									validationSchema={FormValidation}
									onSubmit={async (values, { resetForm }) => {
										if (values.check) {
											handleSubmit();
										} else {
											setStatus(
												"You have to agree to go ahead!"
											);
										}
									}}>
									{({
										values,
										errors,
										touched,
										setFieldValue,
										resetForm,
									}) => (
										<Form>
											<div className='flex'>
												<label
													className='flex center-left cursor-pointer'
													style={{
														gap: "10px",
													}}>
													<Field
														name='check'
														type='checkbox'
													/>
													<p
														style={{
															fontSize: "13px",
														}}>
														I agree to these terms
														and conditions
													</p>
												</label>
												<div
													style={{
														marginLeft: "auto",
													}}>
													{loading ? (
														<ButtonTextIcon
															type='button'
															title='Checking...'
															disabled={loading}
															icon={
																<LoaderIcon />
															}
														/>
													) : (
														<ButtonTextOnly
															type='submit'
															title='Agree'
														/>
													)}
												</div>
											</div>
											{status && (
												<p className='status color-secondary f-w-300 l-h-1'>
													{status}
												</p>
											)}
										</Form>
									)}
								</Formik>
							</div>
						</div>
					</div>
				</div>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='90.205'
					height='82.351'
					viewBox='0 0 90.205 82.351'
					className='svg-bottom'>
					<g transform='translate(-276.138 -252.079)'>
						<path
							d='M276.138,252.079V334.43h57.775s18.088-.5,24.874-10.241,12.908-27.337,0-41.4-31.156-30.71-31.156-30.71Z'
							fill='#5dc6b3'
						/>
						<path
							d='M290.953,334.43l43.512-.032a56.454,56.454,0,0,0,8.178-1.2,45.36,45.36,0,0,0,5.6-1.582v-32.64L320.021,269.53l-29.069,29.446Z'
							fill='#3b86b5'
						/>
						<path
							d='M276.138,298.387V269.279l44.181.244-28.911,28.864Z'
							fill='#1e4ccb'
						/>
					</g>
				</svg>
			</section>
		</section>
	);
};

export default TnCAgreement;
