import { jwtDecode } from "jwt-decode";

export const formatYYYYMMDD = (str) => {
	const months = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec",
	];
	const arr = str.split("-");
	return arr[2] + " " + months[parseInt(arr[1] - 1)] + " " + arr[0];
};

export const decodeRolesFromToken = () => {
	const ls = localStorage.getItem("token");
	if (ls) {
		const decodedToken = jwtDecode(ls);
		const roles = decodedToken.authorities;
		return roles;
	} else {
		return [];
	}
};

export const formatServerDate = (str) => {
	const months = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec",
	];
	const arr0 = str.split("T");
	const arr = arr0[0].split("-");
	return arr[2] + " " + months[parseInt(arr[1] - 1)] + " " + arr[0];
};

export function formatDateTime(dateString) {
	
	const date = new Date(dateString);
  
	const hours = String(date.getHours()).padStart(2, "0");
	const minutes = String(date.getMinutes()).padStart(2, "0");
	const seconds = String(date.getSeconds()).padStart(2, "0");
  
	const formattedDate = `${formatServerDate(dateString)} ${hours}:${minutes}:${seconds}`;
  
	return formattedDate;
  }
 
// const AED_TO_USD_RATE = 0.272;  
// const USD_TO_AED_RATE = 3.673;

// export const convertAEDToUSD = (amount) => {
//   return parseFloat(amount * AED_TO_USD_RATE).toFixed(2);
// };

// export const convertUSDToAED = (amount) => {
//   return parseFloat(amount * USD_TO_AED_RATE).toFixed(2);
// };

const AED_TO_USD_RATE = 0.27; 

export function convertAEDToUSD(amountInAED) {
    const amountInUSD = parseFloat(amountInAED) * AED_TO_USD_RATE;
    return amountInUSD; 
}

export function convertUSDToAED(amountInUSD) {
    const amountInAED = parseFloat(amountInUSD) / AED_TO_USD_RATE;
    return amountInAED.toFixed(2); 
}


export const CustomSelect = ({ options, ...props }) => {
	const handleChange = (event) => {
		if (props.onChange) {
			props.onChange(event);
		}
	};

	return (
		<div className="custom-select-container">
			<select
				{...props}
				onChange={handleChange}
				className={`custom-select ${props.error ? 'error' : ''}`}
			>
				{options.map((option) => (
					<option key={option.value} value={option.value}>
						{option.code}
					</option>
				))}
			</select>
			<div className="select-icon">
				<img
					src={options.find(option => option.value === props.value)?.flagUrl}
					alt="Country Flag"
				/>
			</div>
		</div>
	);
};

export const CountryOptions = [
	{ value: '+971', code: '+971', flagUrl: 'https://flagcdn.com/w320/ae.png' },
	// { value: '+1', code: '+1', flagUrl: 'https://flagcdn.com/w320/us.png' },
	// { value: '+91', code: '+91', flagUrl: 'https://flagcdn.com/w320/in.png' },
];

export const sleep = (m) => new Promise((r) => setTimeout(r, m));

export const formatAmountValue = (value) => {
	if (isNaN(value)) return value;
	return parseFloat(value).toLocaleString('en-US', {
	  minimumFractionDigits: 2,
	  maximumFractionDigits: 2,
	});
  };





export const ErrorFields ={}

