import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import PageTitle from "../../components/common/PageTitle";
import FullPageLoader from "../../components/ui/FullPageLoader";
import {
	CiBank,
	CiBitcoin,
	CiCircleCheck,
	CiLocationArrow1,
	CiUser,
} from "react-icons/ci";
import ButtonTextOnly from "../../components/ui/ButtonTextOnly";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import LoaderIcon from "../../components/ui/LoaderIcon";
import { NavLink, useNavigate } from "react-router-dom";
import { API_URL, DEFAULT_PER_PAGE } from "../../utilities/constants";
import { useUI } from "../../context/ui";
import FileInput from "../../components/ui/FileInput";
import { useParams } from "react-router-dom";
import { countries } from "../../utilities/countries";
import FileInputMultiple from "../../components/ui/FileInputMultiple";
import { useFetch } from "../../hooks/useFetch";

const TenantUpdateApplication = () => {
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const { id } = useParams();
	const [isAllowedFormUpdate, setIsAllowedFormUpdate] = useState(true);

	const [payslipFiles, setPayslipFiles] = useState(null);
	const [payslipFilesNames, setPayslipFilesNames] = useState(null);
	//  ====
	const [bankStatementFile, setBankStatementFile] = useState(null);
	const [salaryCertificateFile, setSalaryCertificateFile] = useState(null);
	const [payslipUploadURL, setPayslipUploadURL] = useState("");
	const [bankStatementUploadURL, setBankStatementUploadURL] = useState("");
	const [salaryCertificateUploadURL, setSalaryCertificateUploadURL] =
		useState("");

	const [workemailVerified, setworkemailVerified] = useState(false);
	const [workemailVerifing, setworkemailVerifing] = useState(false);
	const [status, setStatus] = useState("");
	const [data, setData] = useState(null);

	const {
		data: profileData,
		error: profileError,
		loading: profileLoading,
	} = useFetch(API_URL + "/api/1.0.0/rs/profile", {
		method: "GET",
	});

	const {
		setNewNotification,
		setShowNotification,
		addNotification,
		setSegmentTitle,
		addToastMessage,
	} = useUI();

	const [initialValues, setInitialValues] = useState({
		dateOfBirth: "",
		citizenshipStatus: "",
		maritalStatus: "",
		annualIncome: "",
		employmentType: "",
		companyType: "",
		companyName: "",
		title: "",
		workEmail: "",
		companyAddress: "",
		bankAccountNumber: "",
		swiftCode: "",
		bankName: "",
		uaePass: "",
		payslip: "",
		bankStatement: "",
		salaryCertificate: "",
		creditScore: "",
	});

	useEffect(() => {
		if (profileData) {
			setInitialValues((pre) => ({
				...pre,
				dateOfBirth: pre.dateOfBirth
					? pre.dateOfBirth
					: profileData?.data?.additionalDetails?.dateOfBirth
						? profileData?.data?.additionalDetails?.dateOfBirth
						: "",
				maritalStatus: pre.maritalStatus
					? pre.maritalStatus
					: profileData?.data?.additionalDetails?.maritalStatus
						? profileData?.data?.additionalDetails?.maritalStatus
						: false,
				citizenshipStatus: pre.citizenshipStatus
					? pre.citizenshipStatus
					: profileData?.data?.additionalDetails?.citizenshipStatus
						? profileData?.data?.additionalDetails?.citizenshipStatus
						: "",
				uaePass: pre.uaePass
					? pre.uaePass
					: profileData?.data?.additionalDetails?.uaePass
						? profileData?.data?.additionalDetails?.uaePass
						: "",
				annualIncome: pre.annualIncome
					? pre.annualIncome
					: profileData?.data?.additionalDetails?.annualIncome
						? profileData?.data?.additionalDetails?.annualIncome
						: "",
				employmentType: pre.employmentType
					? pre.employmentType
					: profileData?.data?.additionalDetails?.employmentType
						? profileData?.data?.additionalDetails?.employmentType
						: "",
				companyType: pre.companyType
					? pre.companyType
					: profileData?.data?.additionalDetails?.companyType
						? profileData?.data?.additionalDetails?.companyType
						: "",
				companyName: pre.companyName
					? pre.companyName
					: profileData?.data?.additionalDetails?.companyName
						? profileData?.data?.additionalDetails?.companyName
						: "",
				companyAddress: pre.companyAddress
					? pre.companyAddress
					: profileData?.data?.additionalDetails?.companyAddress
						? profileData?.data?.additionalDetails?.companyAddress
						: "",
				title: pre.title
					? pre.title
					: profileData?.data?.additionalDetails?.title
						? profileData?.data?.additionalDetails?.title
						: "",
				workEmail: pre.workEmail
					? pre.workEmail
					: profileData?.data?.additionalDetails?.workEmail
						? profileData?.data?.additionalDetails?.workEmail
						: "",
			}));
		}
	}, [profileData]);

	const callApi = async (id) => {
		setLoading(true);
		try {
			const resp = await fetch(
				API_URL + `/api/1.0.0/loan-application/${id}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						"X-JWT-Assertion": localStorage.getItem("token"),
					},
				}
			);
			if (resp.ok) {
				const response = await resp.json();
				setData(response.data);
				const d = response.data;
				setInitialValues((pre) => ({
					...pre,
					dateOfBirth: d.dob ? d.dob : pre.dateOfBirth,
					citizenshipStatus: d.citizenshipStatus
						? d.citizenshipStatus
						: pre.citizenshipStatus,
					maritalStatus: d.formFilled
						? d.maritalStatus
							? d.maritalStatus
							: false
						: pre.maritalStatus,
					annualIncome: d.annualIncome
						? d.annualIncome
						: pre.annualIncome,
					employmentType: d.employmentType
						? d.employmentType
						: pre.employmentType,
					companyType: d.companyType
						? d.companyType
						: pre.companyType,
					companyName: d.companyName
						? d.companyName
						: pre.companyName,
					title: d.title ? d.title : pre.title,
					workEmail: d.workEmail ? d.workEmail : pre.workEmail,
					companyAddress: d.companyAddress
						? d.companyAddress
						: pre.companyAddress,
					bankAccountNumber: d.bankAccountNumber
						? d.bankAccountNumber
						: "",
					swiftCode: d.swiftCode ? d.swiftCode : "",
					bankName: d.bankName ? d.bankName : "",
					uaePass: d.uaePass ? d.uaePass : pre.uaePass,
					creditScore: d.creditScore ? d.creditScore : "",
					payslip: d.payslip ? d.payslip : "",
					bankStatement: d.bankStatement ? d.bankStatement : "",
				}));
				if (!d.formFilled) {
					setIsAllowedFormUpdate(true);
				} else {
					setIsAllowedFormUpdate(false);
					if (API_URL === "https://be.rent-savvy.com") {
						setPayslipUploadURL(d.payslip); //Multiple Files Upload:
					} else {
						setPSUploadURL(d.payslip); //Single File Upload
					}
					setBankStatementUploadURL(d.bankStatement);
					setSalaryCertificateUploadURL(d.salaryCertificate);
				}
			}
		} catch (e) {
			console.log("Error", e);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (id) {
			callApi(id);
		}
	}, [id]);

	// ====== Payslip

	const handleUploadPayslip = async () => {
		setLoading(true);
		try {
			const formdata = new FormData();
			payslipFiles.forEach((file, index) => {
				formdata.append(`files`, file);
			});
			formdata.append("type", "PAYSLIP_IMAGE");
			const resp = await fetch(API_URL + `/api/1.0.0/upload/files`, {
				method: "POST",
				headers: {
					"X-JWT-Assertion": localStorage.getItem("token"),
				},
				body: formdata,
				redirect: "follow",
			});
			if (resp.ok) {
				const responseData = await resp.json();
				if (responseData.result.responseCode === 200) {
					setPayslipUploadURL(responseData.data); ////Multiple Files Upload:
					addToastMessage({
						status: true,
						message:
							"Payslip has been successfully uploaded, thank you.",
					});
				}
			} else {
				const errorData = await resp.json();
				const errorMessage = errorData.responseDescription;
				addToastMessage({
					status: false,
					message:
						errorMessage ||
						"Something went wrong while uploading the payslip. Please try again.",
				});
				//REMOVE FILE
				setPayslipFiles(null);
				setPayslipFilesNames(null);
				setPayslipUploadURL("");
				//REMOVE FILE
			}
		} catch (e) {
			console.log("Error", e);
			setNewNotification(true);
			addNotification({
				id: Date.now(),
				message: "Could not upload the payslip at the moment.",
				status: false,
				archive: false,
			});
		} finally {
			setLoading(false);
		}
	};

	// ============================================
	// Delete Later Start
	// ============================================
	const [psFile, setPSFile] = useState(null);
	const [psUploadURL, setPSUploadURL] = useState(null);
	const __handleUploadPayslip = async () => {
		setLoading(true);
		try {
			const formdata = new FormData();
			formdata.append("file", psFile, psFile.name);
			formdata.append("type", "PAYSLIP_IMAGE");
			const resp = await fetch(API_URL + `/api/1.0.0/uploadFile`, {
				method: "POST",
				headers: {
					"X-JWT-Assertion": localStorage.getItem("token"),
				},
				body: formdata,
				redirect: "follow",
			});
			if (resp.ok) {
				const responseData = await resp.json();
				if (responseData.result.responseCode === 200) {
					setPSUploadURL(responseData.data); ////Single Files Upload:
					addToastMessage({
						status: true,
						message: "Payslip has been successfully uploaded.",
					});
				}
			} else {
				const errorData = await resp.json();
				const errorMessage = errorData.responseDescription;
				addToastMessage({
					status: false,
					message:
						errorMessage ||
						"Something went wrong while uploading the payslip. Please try again.",
				});
			}
		} catch (e) {
			console.log("Error", e);
			setNewNotification(true);
			addNotification({
				id: Date.now(),
				message: "Could not upload the payslip at the moment.",
				status: false,
				archive: false,
			});
		} finally {
			setLoading(false);
		}
	};

	// ============================================
	// Delete Later
	// ============================================

	// ======== Bank Statement

	const handleBankStatementUpload = async () => {
		setLoading(true);
		try {
			const formdata = new FormData();
			formdata.append("file", bankStatementFile, bankStatementFile.name);
			formdata.append("type", "BANK_STATEMENT");
			const resp = await fetch(API_URL + `/api/1.0.0/uploadFile`, {
				method: "POST",
				headers: {
					"X-JWT-Assertion": localStorage.getItem("token"),
				},
				body: formdata,
				redirect: "follow",
			});
			if (resp.ok) {
				const responseData = await resp.json();
				if (responseData.result.responseCode === 200) {
					setBankStatementUploadURL(responseData.data);
					addToastMessage({
						status: true,
						message:
							"Bank statement has been successfully uploaded.",
					});
				}
			} else {
				const errorData = await resp.json();
				const errorMessage = errorData.responseDescription;
				addToastMessage({
					status: false,
					message:
						errorMessage ||
						"Something went wrong while uploading a bank statement. Please try again.",
				});
			}
		} catch (e) {
			console.log("Error", e);
			setNewNotification(true);
			addNotification({
				id: Date.now(),
				message: "Could not upload the bank statement at the moment.",
				status: false,
				archive: false,
			});
		} finally {
			setLoading(false);
		}
	};

	// Salary Certificate

	const handleSalaryCertificateUpload = async () => {
		setLoading(true);
		try {
			const formdata = new FormData();
			formdata.append(
				"file",
				salaryCertificateFile,
				salaryCertificateFile.name
			);
			formdata.append("type", "SALARY_CERTIFICATE");
			const resp = await fetch(API_URL + `/api/1.0.0/uploadFile`, {
				method: "POST",
				headers: {
					"X-JWT-Assertion": localStorage.getItem("token"),
				},
				body: formdata,
				redirect: "follow",
			});
			if (resp.ok) {
				const responseData = await resp.json();
				if (responseData.result.responseCode === 200) {
					setSalaryCertificateUploadURL(responseData.data);
					addToastMessage({
						status: true,
						message:
							"Salary Certificate has been successfully uploaded.",
					});
				}
			} else {
				const errorData = await resp.json();
				const errorMessage = errorData.responseDescription;
				addToastMessage({
					status: false,
					message:
						errorMessage ||
						"Something went wrong while uploading a salary certificate. Please try again.",
				});
			}
		} catch (e) {
			console.log("Error", e);
			setNewNotification(true);
			setShowNotification(true);
			addNotification({
				id: Date.now(),
				message:
					"Could not upload the salary certificate at the moment.",
				status: false,
				archive: false,
			});
		} finally {
			setLoading(false);
		}
	};

	// Email Verify

	const handleWorkEmailVerify = async (val) => {
		setworkemailVerifing(true);
		try {
			const resp = await fetch(API_URL + `/api/1.0.0/verify/brn/${val}`, {
				headers: {
					"Content-Type": "application/json",
					"X-JWT-Assertion": localStorage.getItem("token"),
				},
			});
			if (resp.ok) {
				const response = await resp.json();
				if (response.result.responseCode === 200) {
					setworkemailVerified(true);
					setStatus("");
				} else {
					setStatus("Work Email is incorrect!");
				}
			} else {
				setStatus("Invalid WorkEmail!");
			}
		} catch {
			setStatus("Verify WorkEmail API failed!");
		} finally {
			setworkemailVerifing(false);
		}
	};

	const Row = ({ children }) => {
		return (
			<div className='row b-r-8'>
				<div className='col-1-1 wrap flex'>{children}</div>
			</div>
		);
	};

	const SectionTitle = ({ title, subtitle, icon }) => {
		return (
			<div className='col-1-2'>
				<div
					className='flex center-left'
					style={{
						gap: "12px",
					}}>
					{icon}
					<div className='flex center-left'>
						<div>
							<p className='f-w-700 l-h-1-2 color-primary'>
								{title}
							</p>
							<p className='l-h-1-2 color-light small'>
								{subtitle}
							</p>
						</div>
					</div>
				</div>
			</div>
		);
	};

	const SectionForm = ({ children }) => {
		return (
			<div className='col-1-2'>
				<table>
					<thead></thead>
					<tbody>{children}</tbody>
				</table>
			</div>
		);
	};

	const FieldTitle = ({ title }) => {
		return (
			<p
				className='title f-w-700 color-primary'
				style={{ marginBottom: "5px", fontSize: "14px" }}>
				{title}
			</p>
		);
	};

	const LikeInput = ({ title }) => {
		return (
			<div className='ui-like-input col-1-1 cursor-not-allowed'>
				{title ? title : " "}
			</div>
		);
	};

	const FormValidation = Yup.object().shape({
		dateOfBirth: Yup.string()
			.required("Date of Birth is required")
			.max(new Date(), "Date of birth cannot be in the future.")
			.test(
				"age",
				"You must be at least 18 years old",
				function (value) {
					if (!value) return false;

					const today = new Date();
					const birthDate = new Date(value);

					const age = today.getFullYear() - birthDate.getFullYear();
					const monthDiff = today.getMonth() - birthDate.getMonth();
					const dayDiff = today.getDate() - birthDate.getDate();

					if (
						age > 18 ||
						(age === 18 && (monthDiff > 0 || (monthDiff === 0 && dayDiff >= 0)))
					) {
						return true;
					}

					return false;
				}
			),
		// dateOfBirth: Yup.string().required("Date of Birth is required")
		// .matches(/^(1[8]|[2-9]\d|1\d{2,})$/, "Date of Birth must be atleast 18 years"),
		citizenshipStatus: Yup.string().required("Nationality is required"),
		maritalStatus: Yup.string().required("Marital Status is required"),
		annualIncome: Yup.string()
			.matches(/^[1-9]\d{4,}$/, "Annual income must be a minimum of 5 digits and cannot start with zero")
			.required("Annual income is required"),
		employmentType: Yup.string().required("Employment Type is required"),
		companyType: Yup.string().required("Type is required"),
		companyName: Yup.string()
			.required("Name is required")
			.min(2, "Name must be at least 2 characters long")
			.max(50, "Name must be at most 50 characters long"),
		title: Yup.string().required("Is required")
			.min(2, "Title must be at least 2 characters long")
			.max(50, "Title must be at most 50 characters long"),
		workEmail: Yup.string()
			.strict()
			.email("Invalid email format")
			.required("Work email is required!")
			.matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/, "The email must have at least 2 characters after the last dot"),
		companyAddress: Yup.string().required("Address is required"),
		bankAccountNumber: Yup.string()
			.matches(/^AE\d{29}$/, "Bank account number must start with 'AE' followed by 29 digits")
			.required("Bank account number is required"),
		swiftCode: Yup.string()
			.matches(/^[A-Za-z]{8}$/, "Swift Code must be exactly 8 letters")
			.required("Swift Code is required"),
		bankName: Yup.string()
			.min(3, "Bank name must be at least 3 characters")
			.max(50, "Bank name must be at most 50 characters")
			.required("Bank name is required"),
		// bankName: Yup.string().required("Bank name is required"),
		uaePass: Yup.string().required("Emirates ID is required")
			.matches(/^784\d{12}$/, "Emirates ID must start with '784' and be 15 digits long"),
		payslip: Yup.string(),
		bankStatement: Yup.string(),
		salaryCertificate: Yup.string(),
		creditScore: Yup.string(),
	});

	const SingleStep = ({ title, subtitle, completion }) => {
		const getClassName = () => {
			const hasEmptyElement = completion.some(
				(element) => element === ""
			);
			if (hasEmptyElement) {
				return "incomplete";
			} else {
				return "complete";
			}
		};

		return (
			<div className={`step t-a-c ${getClassName()}`}>
				<p
					className='color-primary'
					style={{ fontSize: "12px", marginBottom: "5px" }}>
					<span className='f-w-700'>0{title}</span> {subtitle}
				</p>
				<div className={`b-r-100 icon`}>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='24'
						height='24'
						viewBox='0 0 24 24'>
						<path d='M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.25 16.518l-4.5-4.319 1.396-1.435 3.078 2.937 6.105-6.218 1.421 1.409-7.5 7.626z' />
					</svg>
				</div>
			</div>
		);
	};

	useEffect(() => {
		setSegmentTitle("Update Application");
	}, []);

	useEffect(() => {
		const onScroll = () => {
			const elem = document.getElementById("stepper-should-fix");
			const impact = document.getElementById("stepper-should-impact");
			if (elem && impact) {
				if (window.scrollY > 92) {
					elem.classList.add("loan-application-stepper-fixed-position");
					impact.classList.add("stepper-should-impact-fixed-position");
				} else {
					elem.classList.remove(
						"loan-application-stepper-fixed-position"
					);
					impact.classList.remove("stepper-should-impact-fixed-position");
				}
			}
		};
		window.removeEventListener("scroll", onScroll);
		window.addEventListener("scroll", onScroll, { passive: true });
		return () => window.removeEventListener("scroll", onScroll);
	});

	return (
		<section>
			<PageTitle title='Update Loan application' />
			<FullPageLoader isActive={loading} />
			<Formik
				initialValues={initialValues}
				validationSchema={FormValidation}
				enableReinitialize={true}
				onSubmit={async (
					values,
					{ setSubmitting, validateForm, setErrors }
				) => {
					setLoading(true);
					try {
						const request = {
							personalDetails: {
								dateOfBirth: values.dateOfBirth,
								maritalStatus: values.maritalStatus,
								citizenshipStatus: values.citizenshipStatus,
								uaePass: values.uaePass,
							},
							incomeDetails: {
								employmentType: values.employmentType,
								annualIncome: values.annualIncome,
								sector: "Finance",
								companyName: values.companyName,
								companyType: values.companyType,
								title: values.title,
								workEmail: values.workEmail,
								companyAddress: values.companyAddress,
								payslip: (API_URL === "https://be.rent-savvy.com") ? payslipUploadURL /*Multiple Files Upload*/ : [psUploadURL] /*Single File Upload*/,
								bankStatement: bankStatementUploadURL,
								salaryCertificate: salaryCertificateUploadURL,
							},
							bankDetails: {
								swiftCode: values.swiftCode,
								bankAccountNumber: values.bankAccountNumber,
								bankName: values.bankName,
							},
							creditScore: values.creditScore,
						};

						const resp = await fetch(
							API_URL + `/api/1.0.0/loan-application/${id}`,
							{
								method: "PUT",
								headers: {
									"Content-Type": "application/json",
									"X-JWT-Assertion":
										localStorage.getItem("token"),
								},
								body: JSON.stringify(request),
							}
						);

						if (resp.ok) {
							const response = await resp.json();
							addToastMessage({
								status: true,
								message:
									"You have successfully updated the application, thank you.",
							});
							if (response) {
								const checkForFlow =
									localStorage.getItem("flow");
								if (checkForFlow) {
									const checkForFlowObj =
										JSON.parse(checkForFlow);
									const filteredFlows =
										checkForFlowObj.filter((el) => {
											return (
												el !==
												"/te-my-applications?pageNo=0&pageSize=10" &&
												el !== "/te-pending-details"
											);
										});
									localStorage.setItem(
										"flow",
										JSON.stringify(filteredFlows)
									);
								}

								navigate(
									`/te-my-applications/${id}/credit-score`
								);
							}
						} else {
							const errorData = await resp.json();
							const errorMessage = errorData.responseDescription;
							addToastMessage({
								status: false,
								message:
									errorMessage ||
									"Something went wrong while updating the application. Please try again.",
							});
						}
					} catch (e) {
						console.log("Error", e);
					} finally {
						setLoading(false);
					}
				}}>
				{({
					values,
					errors,
					touched,
					validateForm,
					setFieldValue,
					resetForm,
					handleSubmit,
					isSubmitting,
				}) => (
					<Form>
						<section>
							<section
								id='stepper-should-fix'
								className='loan-application-stepper'>
								<div
									className='steps flex center-center'
									style={{ gap: "20px" }}>
									<SingleStep
										title='1'
										subtitle='Customer Info'
										completion={[data?.tenant + ""]}
									/>
									<SingleStep
										title='2'
										subtitle='Customer details'
										completion={[
											values.dateOfBirth,
											values.maritalStatus,
											values.citizenshipStatus,
											values.uaePass,
										]}
									/>
									<SingleStep
										title='3'
										subtitle='Income Details'
										completion={[
											values.annualIncome,
											values.employmentType,
											values.companyType,
											values.companyName,
											values.title,
											values.workEmail,
											values.companyAddress,
											//[psUploadURL].toString(),
											(API_URL === "https://be.rent-savvy.com") ? payslipUploadURL.toString() : [psUploadURL].toString(),//Multiple Files Upload:
											bankStatementUploadURL,
											salaryCertificateUploadURL,
										]}
									/>
									<SingleStep
										title='4'
										subtitle='Bank Details'
										completion={[
											values.bankAccountNumber,
											values.swiftCode,
											values.bankName,
										]}
									/>
								</div>
							</section>
							<section
								id='stepper-should-impact'
								className='pad-20 create-loan-application pos-rel'>
								{!isAllowedFormUpdate && (
									<div>
										<p
											className='t-a-c'
											style={{
												color: "red",
												position: "relative",
												zIndex: 9999,
											}}>
											Your details are under review and it
											cannot be changed at this moment.
										</p>
										<br />
										<br />
										<div
											className='block'
											style={{
												position: "absolute",
												inset: 0,
												background:
													"rgba(255,255,255,0.68)",
												zIndex: 999,
												cursor: "not-allowed",
											}}></div>
									</div>
								)}
								<Row>
									<SectionTitle
										title='Customer Information'
										subtitle='Customer information'
										icon={
											<CiUser
												className='color-primary'
												style={{
													height: "32px",
													width: "32px",
													strokeWidth: "1px",
												}}
											/>
										}
									/>
									<SectionForm>
										<tr>
											<td colSpan='2'>
												<FieldTitle title='Email' />
												<LikeInput
													title={data?.tenant?.email}
												/>
											</td>
										</tr>
										<tr>
											<td colSpan='2'>
												<FieldTitle title='Phone' />
												<LikeInput
													title={
														data?.tenant?.phoneNo
													}
												/>
											</td>
										</tr>
										<tr>
											<td colSpan='2'>
												<FieldTitle title='First name' />
												<LikeInput
													title={
														data?.tenant?.firstName
													}
												/>
											</td>
										</tr>
										<tr>
											<td colSpan='2'>
												<FieldTitle title='Last name' />
												<LikeInput
													title={
														data?.tenant?.lastName
													}
												/>
											</td>
										</tr>
									</SectionForm>
								</Row>
								<br />
								<Row>
									<SectionTitle
										title='Customer Details'
										subtitle='Enter basic customer details'
										icon={
											<CiUser
												className='color-primary'
												style={{
													height: "32px",
													width: "32px",
													strokeWidth: "1px",
												}}
											/>
										}
									/>
									<SectionForm>
										<tr>
											<td colSpan='2'>
												<FieldTitle title='DOB' />
												<Field
													name='dateOfBirth'
													type='date'
													placeholder='DOB *'
													className={
														"col-1-1 " +
														(errors.dateOfBirth &&
															touched.dateOfBirth
															? " error"
															: "")
													}
												/>
												<ErrorMessage
													name='dateOfBirth'
													component='p'
													className='error-messages'
												/>
											</td>
										</tr>
										<tr>
											<td colSpan='2'>
												<FieldTitle title='Marital Status' />
												<Field
													as='select'
													name='maritalStatus'
													className={
														"col-1-1 " +
														(errors.maritalStatus &&
															touched.maritalStatus
															? " error"
															: "")
													}>
													<option value=''>
														Select
													</option>
													<option value='true'>
														Married
													</option>
													<option value='false'>
														Unmarried
													</option>
												</Field>
												<ErrorMessage
													name='maritalStatus'
													component='p'
													className='error-messages'
												/>
											</td>
										</tr>
										<tr>
											<td colSpan='2'>
												<FieldTitle title='Nationality' />
												<Field
													as='select'
													name='citizenshipStatus'
													className={
														"col-1-1 " +
														(errors.citizenshipStatus &&
															touched.citizenshipStatus
															? " error"
															: "")
													}>
													<option value=''>
														Select Nationality
													</option>
													{countries.map((c, i) => (
														<option
															key={i}
															value={c.name}>
															{c.name}
														</option>
													))}
												</Field>
												<ErrorMessage
													name='citizenshipStatus'
													component='p'
													className='error-messages'
												/>
											</td>
										</tr>
										<tr>
											<td colSpan='2'>
												<FieldTitle title='Emirates ID' />
												<Field
													name='uaePass'
													type='text'
													maxLength='15'
													placeholder='Eg: 784************ '
												/>
												<ErrorMessage
													name='uaePass'
													component='p'
													className='error-messages'
												/>
											</td>
										</tr>
									</SectionForm>
								</Row>
								<br />
								<Row>
									<SectionTitle
										title='Income Details'
										subtitle='Enter Income information'
										icon={
											<CiBitcoin
												className='color-primary'
												style={{
													height: "32px",
													width: "32px",
													strokeWidth: "1px",
												}}
											/>
										}
									/>
									<SectionForm>
										<tr>
											<td colSpan='4'>
												<FieldTitle
													title={"Employment Type"}
												/>
												<Field
													as='select'
													name='employmentType'
													className={
														"col-1-1 " +
														(errors.employmentType &&
															touched.employmentType
															? " error"
															: "")
													}>
													<option value=''>
														Select
													</option>
													<option value='Self Employed'>
														Self Employed
													</option>
													<option value='Salaried'>
														Salaried
													</option>
												</Field>
												<ErrorMessage
													name='employmentType'
													component='p'
													className='error-messages'
												/>
											</td>
										</tr>
										<tr>
											<td colSpan='4'>
												<FieldTitle
													title={`${values.employmentType ===
														"Self Employed"
														? "Annual Revenue"
														: "Annual Income"
														}`}
												/>
												<Field
													name='annualIncome'
													placeholder='[120000] AED*'
													className={
														"col-1-1 " +
														(errors.annualIncome &&
															touched.annualIncome
															? " error"
															: "")
													}
												/>
												<ErrorMessage
													name='annualIncome'
													component='p'
													className='error-messages'
												/>
											</td>
										</tr>

										<tr>
											<td colSpan='4'>
												<FieldTitle
													title={`${values.employmentType ===
														"Self Employed"
														? "Business Type"
														: "Company Type"
														}`}
												/>
												<Field
													as='select'
													name='companyType'
													className={
														"col-1-1 " +
														(errors.companyType &&
															touched.companyType
															? " error"
															: "")
													}>
													<option value=''>
														Select
													</option>
													{values.employmentType ===
														"Self Employed" ? (
														<>
															<option value='Sole Proprietorship'>
																Sole
																Proprietorship
															</option>
															<option value='Partnership'>
																Partnership
															</option>
															<option value='Corporation'>
																Corporation
															</option>
														</>
													) : (
														<>
															<option value='Private'>
																Private
															</option>
															<option value='Government'>
																Government
															</option>
														</>
													)}
												</Field>
												<ErrorMessage
													name='companyType'
													component='p'
													className='error-messages'
												/>
											</td>
										</tr>
										<tr>
											<td colSpan='4'>
												<FieldTitle
													title={`${values.employmentType ===
														"Self Employed"
														? "Business"
														: "Company"
														} Name `}
												/>
												<Field
													name='companyName'
													type='text'
													maxLength='50'
													placeholder={`Enter ${values.employmentType ===
														"Self Employed"
														? "business"
														: "company"
														} name *`}
													className={
														"col-1-1 " +
														(errors.companyName &&
															touched.companyName
															? " error"
															: "")
													}
												/>
												<ErrorMessage
													name='companyName'
													component='p'
													className='error-messages'
												/>
											</td>
										</tr>
										<tr>
											<td colSpan='4'>
												<FieldTitle
													title={`${values.employmentType ===
														"Self Employed"
														? "Nature of Business"
														: "Job Title"
														}`}
												/>
												<Field
													name='title'
													type='text'
													maxLength='50'
													placeholder={`Eg [${values.employmentType ===
														"Self Employed"
														? "Software"
														: "Software Engineer"
														}] *`}
													className={
														"col-1-1 " +
														(errors.title &&
															touched.title
															? " error"
															: "")
													}
												/>
												<ErrorMessage
													name='title'
													component='p'
													className='error-messages'
												/>
											</td>
										</tr>
										<tr>
											<td colSpan='2'>
												<FieldTitle title='Work Email' />
												<Field
													name='workEmail'
													type='email'
													placeholder='Eg: octopi@company.com *'
													className={
														"col-1-1 " +
														(errors.workEmail &&
															touched.workEmail
															? " error"
															: "")
													}
												/>
												<ErrorMessage
													name='workEmail'
													component='p'
													className='error-messages'
												/>
											</td>
											<td className='verify t-a-r'>
												<p className='title f-w-700 color-primary'>
													&nbsp;
												</p>
												<>
													{workemailVerified ||
														data?.workEmail ? (
														<ButtonLightTextIcon
															type='button'
															data-name='verified'
															title='Verified'
															icon={
																<CiCircleCheck />
															}
														/>
													) : (
														<ButtonLightTextIcon
															type='button'
															disabled={
																values.workemailVerifing
															}
															title='Verify'
															onClick={() =>
																handleWorkEmailVerify(
																	values.workEmail
																)
															}
															icon={
																<>
																	{workemailVerifing ? (
																		<LoaderIcon />
																	) : (
																		<svg
																			xmlns='http://www.w3.org/2000/svg'
																			width='18'
																			height='24'
																			viewBox='0 0 18 24'>
																			<path
																				d='M15.762,8.047l-4.381,4.475L9.166,10.4,7.93,11.638,11.381,15,17,9.285ZM12,2.544A11.888,11.888,0,0,0,19,4.96v9.575c0,3.042-1.686,3.827-7,7.107-5.309-3.278-7-4.065-7-7.107V4.96A11.884,11.884,0,0,0,12,2.544ZM12,0C8.629,2.866,6.516,3,3,3V14.535c0,4.6,3.2,5.8,9,9.465,5.8-3.661,9-4.862,9-9.465V3c-3.516,0-5.629-.134-9-3Z'
																				transform='translate(-3)'
																			/>
																		</svg>
																	)}
																</>
															}
														/>
													)}
												</>
											</td>
										</tr>
										<tr>
											<td colSpan='4'>
												<FieldTitle
													title={`${values.employmentType ===
														"Self Employed"
														? "Business"
														: "Company"
														} Address`}
												/>
												<Field
													name='companyAddress'
													component='textarea'
													type='text'
													placeholder={`Enter ${values.employmentType ===
														"Self Employed"
														? "business"
														: "company"
														} address *`}
													maxLength='300'
													className={
														"col-1-1 " +
														(errors.companyAddress &&
															touched.companyAddress
															? " error"
															: "")
													}
												/>
												<ErrorMessage
													name='companyAddress'
													component='p'
													className='error-messages'
												/>
											</td>
										</tr>
										<tr>
											<td colSpan='2' >
												<FieldTitle
													title={`${values.employmentType ===
														"Self Employed"
														? "Recent Tax Returns"
														: "Payslip (Last 3 months)"
														}`}
												/>


												{(API_URL === "https://be.rent-savvy.com") ?
													// {/* ============= Multiple Files Upload START ============= */}
													<FileInputMultiple
														defaultText={`Select ${values.employmentType === "Self Employed" ? "Tax Returns" : "Payslip"}`}
														accept='application/pdf'
														callback={(arr) => {
															setPayslipFiles(arr);
															setPayslipFilesNames(
																arr.map(
																	(file) =>
																		file.name
																)
															);
														}}
														maxSizeAllowed={
															2 * 1024 * 1024
														}
													/>
													// {/* ============= Multiple Files Upload END ============= */}
													:
													// {/* ============= DELETE START ============= */}
													<FileInput
														defaultText={`Select ${values.employmentType ===
															"Self Employed"
															? "Tax Returns"
															: "Payslip"
															}`}
														accept='application/pdf'
														maxSizeAllowed={
															2 * 1024 * 1024
														}
														callback={(f) => {
															setPSFile(f);
														}}
													/>
													// {/* ============= DELETE END ============= */}
												}
											</td>
											<td  className='t-a-r'>
												<p>&nbsp;</p>
												{(API_URL === "https://be.rent-savvy.com") ?
													// {/* Multiple Files Upload START */}
													<ButtonTextIcon
														type='button'
														style={{width:"200px"}}
														title={
															payslipUploadURL
																? "Uploaded"
																: "Click to Upload"
														}
														disabled={
															loading || !payslipFiles
														}
														icon={
															<>
																{loading ? (
																	<LoaderIcon />
																) : (
																	<>
																		{payslipUploadURL ? (
																			<CiCircleCheck />
																		) : (
																			<CiLocationArrow1 />
																		)}
																	</>
																)}
															</>
														}
														onClick={
															handleUploadPayslip
														}
													/>
													
													// {/*  Multiple Files Upload END */}
													:
													// {/* ============= DELETE START ============= */}
													<ButtonTextIcon
														type='button'
														style={{width:"200px"}}
														title={
															psUploadURL
																? "Uploaded"
																: "Click to Upload"
														}
														disabled={
															loading || !psFile
														}
														icon={
															<>
																{loading ? (
																	<LoaderIcon />
																) : (
																	<>
																		{psUploadURL ? (
																			<CiCircleCheck />
																		) : (
																			<CiLocationArrow1 />
																		)}
																	</>
																)}
															</>
														}
														onClick={
															__handleUploadPayslip
														}
													/>
													// {/* ============= DELETE END ============= */}
												}
												{psFile && (
													<p
														style={{
															fontSize: "12px",
															color: "red",
														}}
													>
														*Click to upload
													</p>
												)}
												{payslipFiles && (
													<p
														style={{
															fontSize: "12px",
															color: "red",
															marginTop:"10px"
														}}
													>
														*Click to upload
													</p>
												)}
											</td>
										</tr>
										{payslipFilesNames && (
											<tr>
												<td colSpan='2'>
													{payslipFilesNames.map(
														(p, i) => (
															<p
																className='color-faded'
																key={i}
																style={{
																	fontSize:
																		"11px",
																}}>
																{p}
															</p>
														)
													)}
												</td>
											</tr>
										)}
										{payslipFiles && (
											<tr>
												<td colSpan='2'>
													<p
														className='cursor-pointer'
														style={{
															fontSize: "12px",
															color: "red",
														}}
														onClick={() => {
															setPayslipFiles(
																null
															);
															setPayslipFilesNames(
																null
															);
															setPayslipUploadURL(
																""
															);
														}}>
														Delete{" "}
														{`${values.employmentType ===
															"Self Employed"
															? "tax returns"
															: "payslip"
															}`}
														files
													</p>
												</td>
											</tr>
										)}
										{psFile && (
											<tr>
												<td colSpan='2'>
													<p
														className='cursor-pointer'
														style={{
															fontSize: "12px",
															color: "red",
														}}
														onClick={() => {
															setPSFile(null);
															setPSUploadURL("");
														}}>
														Delete{" "}
														{`${values.employmentType ===
															"Self Employed"
															? "tax returns"
															: "payslip"
															}`}
														file
													</p>
												</td>
											</tr>
										)}
										<tr>
											<td colSpan="6" >
												<p className="status f-w-200 l-h-1 font-size-12">
													NOTE: Please upload file in PDF format, {(API_URL === "https://be.rent-savvy.com") && "per"} up to 2MB. Files exceeding these limits won't be accepted.
												</p>
											</td>
										</tr>

										<tr>
											<td colSpan='2' >
												<FieldTitle title='Bank Statement (last 3 months)' />
												<FileInput
													defaultText='Select Bank statement'
													accept='application/pdf'
													maxSizeAllowed={
														2 * 1024 * 1024
													}
													callback={(f) => {
														setBankStatementFile(f);
													}}
												/>
												{bankStatementFile && (
													<p
														className="cursor-pointer"
														style={{
															fontSize: "12px",
															color: "red",
															marginTop: "10px",
														}}
														onClick={() => {
															setBankStatementFile(
																null
															);
															setBankStatementUploadURL(
																""
															);
														}}
													>
														Delete bank statement
														file
													</p>
												)}
											</td>
											<td  className="t-a-r">
												<p>&nbsp;</p>
												<p>&nbsp;</p>
												<ButtonTextIcon
													style={{width:"200px"}}
													type="button"
													title={bankStatementUploadURL ? "Uploaded" : "Click to Upload"}
													disabled={loading || !bankStatementFile}
													icon={
														<>
															{loading ? (
																<LoaderIcon />
															) : (
																<>
																	{bankStatementUploadURL ? <CiCircleCheck /> : <CiLocationArrow1 />}
																</>
															)}
														</>
													}
													onClick={handleBankStatementUpload}
												/>

												{bankStatementFile && (
													<p
														style={{
															fontSize: "12px",
															color: "red",
															marginTop:"10px"
														}}
													>
														*Click to upload
													</p>
												)}
											</td>
										</tr>
										{/* <tr>
											<td>
												<FieldTitle title='Bank Statement (last 3 months)' />
												<FileInput
													defaultText='Select Bank statement'
													accept='application/pdf'
													maxSizeAllowed={
														2 * 1024 * 1024
													}
													callback={(f) => {
														setBankStatementFile(f);
													}}
												/>
											</td>
											<td className='t-a-r'>
												<p>&nbsp;</p>
												<ButtonTextIcon
													type='button'
													title={
														bankStatementUploadURL
															? "Uploaded"
															: "Upload"
													}
													disabled={
														loading ||
														!bankStatementFile
													}
													icon={
														<>
															{loading ? (
																<LoaderIcon />
															) : (
																<>
																	{bankStatementUploadURL ? (
																		<CiCircleCheck />
																	) : (
																		<CiLocationArrow1 />
																	)}
																</>
															)}
														</>
													}
													onClick={
														handleSalaryCertificateUpload
													}
												/>
											</td>
										</tr>
										{bankStatementFile && (
											<tr>
												<td colSpan='2'>
													<p
														className='cursor-pointer'
														style={{
															fontSize: "12px",
															color: "red",
														}}
														onClick={() => {
															setBankStatementFile(
																null
															);
															setBankStatementUploadURL(
																""
															);
														}}>
														Delete bank statement
														file
													</p>
												</td>
											</tr>
										)} */}
										<tr>
											<td colSpan="6" >
												<p className="status f-w-200 l-h-1 font-size-12">
													NOTE: Please upload file in PDF format, file up to 2MB. Files exceeding these limits won't be accepted.
												</p>
											</td>
										</tr>
										<tr>
											<td colSpan='2'>
												<FieldTitle
													title={`${values.employmentType ===
														"Self Employed"
														? "Incorporation"
														: "Salary"
														} Certificate`}
												/>
												<FileInput
													defaultText={`Select ${values.employmentType ===
														"Self Employed"
														? "Incorparation"
														: "Salary"
														} Certificate`}
													accept='application/pdf'
													maxSizeAllowed={
														2 * 1024 * 1024
													}
													callback={(f) => {
														setSalaryCertificateFile(
															f
														);
													}}
												/>
												{salaryCertificateFile && (
													<p
														className="cursor-pointer"
														style={{
															fontSize: "12px",
															color: "red",
															marginTop: "10px",
														}}
														onClick={() => {
															setSalaryCertificateFile(
																null
															);
															setSalaryCertificateUploadURL(
																""
															);
														}}
													>
														Delete{" "}
														{`${values.employmentType ===
															"Self Employed"
															? "incorparation"
															: "salary"
															}`}
														certificate file
													</p>
												)}
											</td>
											<td  className="t-a-r">
												<p>&nbsp;</p>
												<ButtonTextIcon
													style={{width:"200px"}}
													type="button"
													title={salaryCertificateUploadURL ? "Uploaded" : "Click to Upload"}
													disabled={loading || !salaryCertificateFile}
													icon={
														<>
															{loading ? (
																<LoaderIcon />
															) : (
																<>
																	{salaryCertificateUploadURL ? <CiCircleCheck /> : <CiLocationArrow1 />}
																</>
															)}
														</>
													}
													onClick={handleSalaryCertificateUpload}
												/>

												{salaryCertificateFile && (
													<p
														style={{
															fontSize: "12px",
															color: "red",
															marginTop: "10px",
														}}
													>
														*Click to upload
													</p>
												)}
											</td>
										</tr>
										{/* <tr>
											<td>
												<FieldTitle
													title={`${values.employmentType ===
														"Self Employed"
														? "Incorparation"
														: "Salary"
														} Certificate`}
												/>
												<FileInput
													defaultText={`Select ${values.employmentType ===
														"Self Employed"
														? "Incorparation"
														: "Salary"
														} Certificate`}
													accept='application/pdf'
													maxSizeAllowed={
														2 * 1024 * 1024
													}
													callback={(f) => {
														setSalaryCertificateFile(
															f
														);
													}}
												/>
											</td>
											<td className='t-a-r'>
												<p>&nbsp;</p>
												<ButtonTextIcon
													type='button'
													title={
														salaryCertificateUploadURL
															? "Uploaded"
															: "Upload"
													}
													disabled={
														loading ||
														!salaryCertificateFile
													}
													icon={
														<>
															{loading ? (
																<LoaderIcon />
															) : (
																<>
																	{salaryCertificateUploadURL ? (
																		<CiCircleCheck />
																	) : (
																		<CiLocationArrow1 />
																	)}
																</>
															)}
														</>
													}
													onClick={
														handleSalaryCertificateUpload
													}
												/>
											</td>
										</tr>
										{salaryCertificateFile && (
											<tr>
												<td colSpan='2'>
													<p
														className='cursor-pointer'
														style={{
															fontSize: "12px",
															color: "red",
														}}
														onClick={() => {
															setSalaryCertificateFile(
																null
															);
															setSalaryCertificateUploadURL(
																""
															);
														}}>
														Delete{" "}
														{`${values.employmentType ===
															"Self Employed"
															? "incorparation"
															: "salary"
															}`}
														certificate file
													</p>
												</td>
											</tr>
										)} */}
										<tr>
											<td colSpan="6" >
												<p className="status f-w-200 l-h-1 font-size-12">
													NOTE: Please upload file in PDF format, up to 2MB. Files exceeding these limits won't be accepted.
												</p>
											</td>
										</tr>
									</SectionForm>
								</Row>
								<br />
								<Row>
									<SectionTitle
										title='Bank Detail'
										subtitle='Enter Bank details'
										icon={
											<CiBank
												className='color-primary'
												style={{
													height: "32px",
													width: "32px",
													strokeWidth: "1px",
												}}
											/>
										}
									/>
									<SectionForm>
										<tr>
											<td colSpan='2'>
												<FieldTitle title='Bank A/C Number(IBAN)' />
												<Field
													name='bankAccountNumber'
													type='text'
													maxLength='31'
													placeholder='Enter bank account no *'
													className={
														"col-1-1 " +
														(errors.bankAccountNumber &&
															touched.bankAccountNumber
															? " error"
															: "")
													}
												/>
												<ErrorMessage
													name='bankAccountNumber'
													component='p'
													className='error-messages'
												/>
											</td>
										</tr>
										<tr>
											<td colSpan='2'>
												<FieldTitle title='Swift Code' />
												<Field
													name='swiftCode'
													type='text'
													maxLength='8'
													placeholder='EG: ABCDEF *'
													className={
														"col-1-1 " +
														(errors.swiftCode &&
															touched.swiftCode
															? " error"
															: "")
													}
												/>
												<ErrorMessage
													name='swiftCode'
													component='p'
													className='error-messages'
												/>
											</td>
										</tr>
										<tr>
											<td colSpan='2'>
												<FieldTitle title='Bank Name' />
												<Field
													name='bankName'
													type='text'
													maxLength='50'
													placeholder='Enter bank name *'
													className={
														"col-1-1 " +
														(errors.bankName &&
															touched.bankName
															? " error"
															: "")
													}
												/>
												<ErrorMessage
													name='bankName'
													component='p'
													className='error-messages'
												/>
											</td>
										</tr>
									</SectionForm>
								</Row>
								<br />
								<div
									className='flex center-center'
									style={{ gap: "12px" }}>
									<NavLink
										to={`/te-my-applications?pageNo=0&pageSize=${DEFAULT_PER_PAGE}`}>
										<ButtonLightTextIcon
											type='button'
											title='Cancel'
										/>
									</NavLink>
									{loading ? (
										<ButtonTextIcon
											type='button'
											title='Update...'
											disabled={loading}
											icon={<LoaderIcon />}
										/>
									) : (
										<ButtonTextOnly
											disabled={
												loading ||
												((API_URL === "https://be.rent-savvy.com") ? !payslipUploadURL : !psUploadURL) ||
												// !payslipUploadURL || //Multiple Files Upload:
												// !psUploadURL || //Single File Upload
												!bankStatementUploadURL ||
												!salaryCertificateUploadURL
											}
											type='submit'
											title='Update'
										/>
									)}
								</div>
							</section>
						</section>
					</Form>
				)}
			</Formik>
		</section>
	);
};

export default TenantUpdateApplication;
