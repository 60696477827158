import { useLocation, useNavigate } from "react-router-dom";

const ResultsPerPage = ({ targetURL }) => {
	const location = useLocation();
	const navigate = useNavigate();

	const getPerPageValue = () => {
		const pageSize = new URLSearchParams(location.search).get("pageSize");
		return pageSize;
	};

	return (
		<select
			style={{ width: "160px" }}
			value={getPerPageValue()}
			onChange={(e) => {
				if (e.target.value) {
					navigate(`${targetURL}${e.target.value}`);
				}
			}}>
			<option value=''>No. of Results</option>
			<option value='10'>10 per page</option>
			<option value='25'>25 per page</option>
			<option value='50'>50 per page</option>
		</select>
	);
};

export default ResultsPerPage;
