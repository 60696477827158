import { useNavigate } from "react-router-dom";

const Pagination = ({ totalRecords, pageNo, pageSize, baseUrl }) => {
	const navigate = useNavigate();

	const getPaginationUI = () => {
		const v = Math.ceil(totalRecords / pageSize);
		if (v > 0) {
			let allData = [];
			for (let index = 0; index < v; index++) {
				allData.push(
					<li
						key={index}
						className={`${index === pageNo ? "active" : ""}`}
						onClick={() => {
							if (index !== pageNo) {
								navigate(
									`${baseUrl}?pageNo=${index}&pageSize=${pageSize}`
								);
							}
						}}>
						{index + 1}
					</li>
				);
			}
			return allData;
		} else {
			return [];
		}
	};

	return <ul className='rs-pagination'>{getPaginationUI()}</ul>;
};

export default Pagination;
