import React, { useState, useEffect } from "react";

const SemiCircleChart = ({ current, total }) => {
  const [offset, setOffset] = useState(282.6);
  const [color, setColor] = useState("#f8f8f9");
  const [message, setMessage] = useState("");

  useEffect(() => {
    const percentage = current / total;
    const circumference = 282.6; // Circumference of the semi-circle
    const newOffset = circumference * (1 - percentage);

    setTimeout(()=>{
      setOffset(newOffset);
    }, 100)

    let msg = "";
    let newColor = "#f8f8f9";

    if (current >= 750) {
      newColor = "#0B9547";
      msg = "Excellent";
    } else if (current >= 650) {
      newColor = "#00C04b";
      msg = "Good";
    } else if (current >= 550) {
      newColor = "#F19118";
      msg = "Fair";
    } else {
      newColor = "#E42127";
      msg = "Poor";
    }

    setColor(newColor);
    setMessage(msg);
  }, [current, total]);

  const containerStyle = {
    position: "relative",
    width: "100%",
  };

  const scoreTextStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    paddingTop: "70px",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  };

  const totalTextStyle = {
    fontSize: "1em",
    color: "#888",
  };

  const scoreStyle = {
    fontSize: "1.5em",
    fontWeight: "bold",
    color: color,
  };

  const svgStyle = {
    width: "100%",
    height: "auto",
  };

  const progressStyle = {
    transition: "stroke-dasharray 2s ease-out",
  };

  return (
    <>
      <div style={containerStyle}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 110" style={svgStyle}>
          <path
            d="M 10,100 A 90,90 0 1 1 190,100"
            fill="none"
            stroke="#f8f8f9"
            strokeWidth="20"
          />
          <path
            d="M 10,100 A 90,90 0 1 1 190,100"
            fill="none"
            stroke={color}
            strokeWidth="20"
            strokeLinecap="butt"
            strokeDasharray={`${282.6 - offset}, 282.6`}
            style={progressStyle}
          />
        </svg>
        <div style={scoreTextStyle}>
          <div style={totalTextStyle}>Total</div>
          <div style={scoreStyle}>{`${current}/${total}`}</div>
        </div>
      </div>
      <div className="t-a-c">
        <h6>{message}</h6>
      </div>
    </>
  );
};

export default SemiCircleChart;