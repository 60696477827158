import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import PageTitle from "../../components/common/PageTitle";
import LoaderIcon from "../../components/ui/LoaderIcon";
import { getDefaultURLForRole } from "../../services/roles";
import {
	API_URL,
	BASE_URL,
	RS_TAGLINE,
	SYSTEM_TOKEN,
} from "../../utilities/constants";
import { decodeRolesFromToken } from "../../utilities/helpers";
import RentSavvyLogo from "../../components/common/RentSavvyLogo";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import ButtonTextOnly from "../../components/ui/ButtonTextOnly";
import { FaEye, FaRegEyeSlash } from 'react-icons/fa';
import { useUI } from "../../context/ui";

const FormUI = ({ tokenString }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const { addToastMessage } = useUI();
	const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState("");
	const [newPasswordVisible, setNewPasswordVisible] = useState(false);
	const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

	const toggleNewPasswordVisibility = () => {
		setNewPasswordVisible(!newPasswordVisible);
	};

	const toggleConfirmPasswordVisibility = () => {
		setConfirmPasswordVisible(!confirmPasswordVisible);
	};

	const FormValidation = Yup.object().shape({
		tokenString: Yup.string().required(),
		newPassword: Yup.string()
			.required("Passcode is required!")
			.min(5, "Passcode must be at least 5 digits long!"),
		confirmPassword: Yup.string()
			.oneOf([Yup.ref("newPassword"), null], "Passwords must match")
			.required("Password is required!"),
	});

	const initial = {
		tokenString: tokenString,
		newPassword: "",
		confirmPassword: "",
	};

	const checkIfNextURLExists = () => {
		const params = new URLSearchParams(location.search);
		const queryObj = {};
		params.forEach((value, key) => {
			queryObj[key] = value;
		});
		if (queryObj.next) {
			return queryObj.next;
		} else {
			return false;
		}
	};

	const handleSetPassword = async (newPassword) => {
		setStatus("");
		setLoading(true);
		try {
			const resp = await fetch(API_URL + "/set-password", {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					"X-JWT-Assertion": SYSTEM_TOKEN,
				},
				body: JSON.stringify({
					passwordResetToken: tokenString,
					newPassword: newPassword,
				}),
			});
			if (resp.ok) {
				const responseFull = await resp.json();
				const response = responseFull.data;
				if (response.accessToken) {
					if (
						localStorage.setItem("token", response.accessToken) ===
						undefined
					) {
						const nextURL = checkIfNextURLExists();
						if (response.flow?.length > 0) {
							if (
								localStorage.setItem(
									"flow",
									JSON.stringify(response.flow)
								) === undefined
							) {
								if (nextURL) {
									navigate(
										response.flow[0] + "?next=" + nextURL
									);
								} else {
									navigate(response.flow[0]);
								}
							} else {
								setStatus("Could not store flow");
							}
						} else {
							const roles = decodeRolesFromToken();
							if (nextURL) {
								navigate(nextURL);
							} else {
								navigate(getDefaultURLForRole(roles[0]));
							}
						}
						setStatus("Welcome to rent-savvy.com");
						addToastMessage({
							status: true,
							message:
								"Your password has been successfully set, thank you.",
						});
					} else {
						setStatus("Could not store token");
					}
				} else {
					setStatus("No Token Received");
				}
			} else {
				setStatus("Your password could not be set at the moment.");
			}
		} catch (error) {
			setStatus("An Error Occurred");
		} finally {
			setLoading(false);
		}
	};

	return (
		<section id='auth-page'>
			<PageTitle title='Set Password' />
			<section className='auth-header'>
				<NavLink to='/login'>
					<div className='logo'>
						<RentSavvyLogo />
					</div>
				</NavLink>
				<div className='options flex center-center'>
					<p>
						{BASE_URL === "https://tenant.rent-savvy.com" && (
							<NavLink to='/register'>
								Don't have an account?
							</NavLink>
						)}
					</p>
				</div>
			</section>
			<section className='auth-content'>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='90.396'
					height='82.351'
					viewBox='0 0 90.396 82.351'
					className='svg-top'>
					<g transform='translate(-288.14 -169.729)'>
						<path
							d='M276.138,252.079V334.43h57.775s18.088-.5,24.874-10.241,12.908-27.337,0-41.4-31.156-30.71-31.156-30.71Z'
							transform='translate(654.483 504.159) rotate(180)'
							fill='#5dc6b3'
						/>
						<path
							d='M327.624,252.079V190.464l25.5,21.287V252.08Z'
							fill='#1e4ccb'
						/>
						<path
							d='M327.624,190.464l25.4-19.87,25.512,19.87L353.027,211.9Z'
							fill='#3b86b5'
						/>
					</g>
				</svg>
				<div className='inner'>
					<div className='col col-1-2 left'>
						<div className='text'>
							<h1 className='l-h-1 f-w-300 color-primary'>
								Set
								<br />
								Password!
							</h1>
							<br />
							<h5 className='f-w-300 color-primary'>
								{RS_TAGLINE}
							</h5>
						</div>
					</div>
					<div className='col col-1-2 right pos-rel'>
						<div className='box'>
							<Formik
								initialValues={initial}
								validationSchema={FormValidation}
								onSubmit={async (values, { resetForm }) => {
									handleSetPassword(values.newPassword);
								}}>
								{({
									values,
									errors,
									touched,
									setFieldValue,
									resetForm,
								}) => (
									<Form>
										<h4 className='color-primary t-a-c'>
											Set Password
										</h4>
										<table>
											<thead></thead>
											<tbody>
												<tr>
													<td>
														<p className="title f-w-700 color-primary">Enter Password:</p>
														<div className="password-field-wrapper">
															<Field
																name="newPassword"
																type={newPasswordVisible ? 'text' : 'password'}
																placeholder="**********"
																className={
																	" " + (errors.newPassword && touched.newPassword ? "error" : "")
																}
															/>
															<span
																onClick={toggleNewPasswordVisibility}
																className="eye-icon"
																aria-label={newPasswordVisible ? 'Hide password' : 'Show password'}
															>
																{newPasswordVisible ? <FaRegEyeSlash /> : <FaEye />}
															</span>
														</div>
													</td>
												</tr>
												<tr>
													<td>
														<p className="title f-w-700 color-primary">Confirm Password:</p>
														<div className="password-field-wrapper">
															<Field
																name="confirmPassword"
																type={confirmPasswordVisible ? 'text' : 'password'}
																placeholder="**********"
																className={
																	" " + (errors.confirmPassword && touched.confirmPassword ? "error" : "")
																}
															/>
															<span
																onClick={toggleConfirmPasswordVisibility}
																className="eye-icon"
																aria-label={confirmPasswordVisible ? 'Hide password' : 'Show password'}
															>
																{confirmPasswordVisible ? <FaRegEyeSlash /> : <FaEye />}
															</span>
														</div>
													</td>
												</tr>
												<tr>
													<td colSpan='2'>
														{loading ? (
															<ButtonTextIcon
																type='button'
																title='Setting...'
																disabled={loading}
																icon={<LoaderIcon />}
															/>
														) : (
															<ButtonTextOnly
																type='submit'
																title='Set Password'
															/>
														)}
													</td>
												</tr>
											</tbody>
										</table>
										{status && (
											<p className='status color-secondary f-w-300 l-h-1'>
												{status}
											</p>
										)}
									</Form>
								)}
							</Formik>
						</div>
					</div>
				</div>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='90.205'
					height='82.351'
					viewBox='0 0 90.205 82.351'
					className='svg-bottom'>
					<g transform='translate(-276.138 -252.079)'>
						<path
							d='M276.138,252.079V334.43h57.775s18.088-.5,24.874-10.241,12.908-27.337,0-41.4-31.156-30.71-31.156-30.71Z'
							fill='#5dc6b3'
						/>
						<path
							d='M290.953,334.43l43.512-.032a56.454,56.454,0,0,0,8.178-1.2,45.36,45.36,0,0,0,5.6-1.582v-32.64L320.021,269.53l-29.069,29.446Z'
							fill='#3b86b5'
						/>
						<path
							d='M276.138,298.387V269.279l44.181.244-28.911,28.864Z'
							fill='#1e4ccb'
						/>
					</g>
				</svg>
			</section>
		</section>
	);
};

const ResetPassword = () => {
	const [tokenString, setTokenString] = useState("");
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		const params = new URLSearchParams(location.search);
		const queryObj = {};
		params.forEach((value, key) => {
			queryObj[key] = value;
		});
		if (queryObj.t) {
			setTokenString(queryObj.t);
		} else {
			navigate("/not-authorized");
		}
	}, []);

	if (!tokenString) {
		return <></>;
	} else {
		return <FormUI tokenString={tokenString} />;
	}
};

export default ResetPassword;
