import { API_URL, APP_ROLES } from "../../utilities/constants";
import { useOutsideClick } from "../../utilities/hooks";
import { useEffect, useRef, useState } from "react";
import LoaderIcon from "../ui/LoaderIcon";

let debounceHandler = null;

const SearchCustomer = ({ callback, placeholder }) => {
	const didMount = useRef(false);
	const [loading, setLoading] = useState(false);
	const [value, setValue] = useState("");
	const [data, setData] = useState([]);
	const [show, setShow] = useState(false);

	useEffect(() => {
		if (didMount.current) {
			debounceHandler = setTimeout(() => {
				fetchData(value);
			}, 400);
			return () => {
				clearTimeout(debounceHandler);
			};
		} else {
			didMount.current = true;
		}
	}, [value]);

	const filterOnlyTenanets = (arr) => {
		const x = arr.filter((obj) => {
			return obj.roleID === 8 ? obj : null;
		});
		return x;
	};

	const fetchData = async (val) => {
		if (val !== "" && val.length > 2) {
			setShow(true);
			setLoading(true);
			setData([]);
			try {
				const resp = await fetch(
					API_URL + "/api/1.0.0/tenant?emailId=" + val,
					{
						method: "GET",
						headers: {
							"Content-Type": "application/json",
							"X-JWT-Assertion": localStorage.getItem("token"),
						},
					}
				);
				if (resp.ok) {
					const response = await resp.json();
					if (response.data) {
						setData(filterOnlyTenanets(response.data));
					}
				}
			} catch (e) {
				console.log("Error", e);
			} finally {
				setLoading(false);
			}
		} else {
			setShow(false);
		}
	};

	function handleInput(event) {
		const newSearchInput = event.target.value;
		setValue(newSearchInput);
	}

	const [searchBox] = useOutsideClick({
		state: true,
		callBack: () => {
			setShow(false);
		},
	});

	const getCustomerDefn = (roleId) => {
		const obj = APP_ROLES.find((o) => o.roleId === roleId);
		return obj ? ` - ${obj.titleBeautify}` : `Unknown Role`;
	};

	return (
		<div className='col-1-1 pos-rel'>
			<input
				className='col-1-1'
				placeholder={placeholder}
				value={value}
				onChange={(e) => handleInput(e)}
			/>
			{show && (
				<>
					<div className='search-results' ref={searchBox}>
						{loading && (
							<div
								className='flex center-center'
								style={{ height: "200px" }}>
								<LoaderIcon style={{ height: "24px" }} />
							</div>
						)}
						{data.length > 0 && (
							<ul>
								{data.map((d, i) => (
									<li
										className='transition cursor-pointer'
										key={i}
										onClick={() => {
											setShow(false);
											setData([]);
											callback(d);
										}}>
										{d.email} [{d.firstName} {d.lastName}]
										{getCustomerDefn(d.roleID)}
										{d.id}
									</li>
								))}
							</ul>
						)}
					</div>
				</>
			)}
		</div>
	);
};

export default SearchCustomer;
