import { useEffect, useState, useRef } from "react";
import FullPageLoader from "../../components/ui/FullPageLoader";
import { useLocation, useNavigate } from "react-router-dom";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import { API_URL, BASE_URL } from "../../utilities/constants";
import Pagination from "../../components/common/Pagination";
import PageTitle from "../../components/common/PageTitle";
import ResultsPerPage from "../_common/ResultsPerPage";
import { useUI } from "../../context/ui";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import RSModal from "../../components/ui/RSModal";
import {
  CiCircleCheck,
  CiCircleRemove,
  CiTimer,
  CiSearch,
} from "react-icons/ci";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { convertUSDToAED, formatDateTime, formatAmountValue } from "../../../src/utilities/helpers";
const TransactionHistory = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [data, setData] = useState(null);
  const { setSegmentTitle } = useUI();
  const navigate = useNavigate();

  const callListApi = async () => {
    setLoading(true);
    const pageNo = new URLSearchParams(location.search).get("pageNo");
    const pageSize = new URLSearchParams(location.search).get("pageSize");
    const fromDate = new URLSearchParams(location.search).get("fromDate");
    const toDate = new URLSearchParams(location.search).get("toDate");
    try {
      let url =
        API_URL +
        `/api/1.0.0/transaction?pageNo=${pageNo}&pageSize=${pageSize}&sortOrder=desc`;
      if (fromDate && toDate) {
        url += "&fromDate=" + fromDate + "&toDate=" + toDate;
      }

      const resp = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-JWT-Assertion": localStorage.getItem("token"),
        },
      });
      if (resp.ok) {
        const response = await resp.json();
        setData(response.data);
      }
    } catch (e) {
      console.log("Error", e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (location.search) {
      callListApi();
      setSegmentTitle("Transaction History");
    }
  }, [location.search]);

  const initialValues = {
    fromDate: "",
    toDate: "",
  };

  const FormValidation = Yup.object().shape({
    fromDate: Yup.date().required("End date is required"),
    toDate: Yup.date()
      .required("End date is required")
      .min(Yup.ref("fromDate"), "End Date must be later than Start Date"),
  });

  return (
    <section className="pos-rel">
      <PageTitle title="Transaction History" />
      <FullPageLoader isActive={loading} />
      <section>
        <div
          className="flex center-left"
          style={{ padding: "20px", gap: "12px" }}
        >
          <ResultsPerPage
            targetURL={`/transaction-history?pageNo=0&pageSize=`}
          />
          <div>
            <Formik
              initialValues={initialValues}
              validationSchema={FormValidation}
              onSubmit={async (values, { resetForm }) => {
                const pageNo = new URLSearchParams(location.search).get(
                  "pageNo"
                );
                const pageSize = new URLSearchParams(location.search).get(
                  "pageSize"
                );
                navigate(
                  `/transaction-history?pageNo=${pageNo}&pageSize=${pageSize}&fromDate=${values.fromDate}&toDate=${values.toDate}`
                );
              }}
            >
              {({ values, errors, touched, setFieldValue, resetForm }) => (
                <Form>
                  <div className="flex center-center" style={{ gap: "12px" }}>
                    <Field
                      name="fromDate"
                      type="date"
                      className={
                        " " +
                        (errors.fromDate && touched.fromDate ? " error" : "")
                      }
                    />
                    <Field
                      name="toDate"
                      type="date"
                      className={
                        " " + (errors.toDate && touched.toDate ? " error" : "")
                      }
                    />
                    <ButtonTextIcon type="submit" icon={<CiSearch />} />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <div className="results">
          <div className="rs-table-holder">
            <table className="rs-table">
              <thead>
                <tr>
                  <th>Transaction Number</th>
                  <th>Sender's Name</th>
                  <th>Amount</th>
                  <th>Description</th>
                  <th>Transaction At</th>
                  <th>Status</th>
                  <th>View</th>
                </tr>
              </thead>
              {data && (
                <tbody>
                  {data?.transactionHistoryList?.map((d, i) => (
                    <tr key={i}>
                      <td>
                        <p
                          style={{
                            width: "180px",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {d.uniqueTransactionNumber}
                        </p>
                      </td>
                      <td>{d.customerName}</td>
                      <td>
                      {`${formatAmountValue(convertUSDToAED(d.amount))}/- AED`}
                      </td>
                      <td>{d.reference}</td>
                      <td>{formatDateTime(d.createdAt)}</td>
                      <td>
                        <div className="flex center-left">
                          {d.transactionStatus === "INITIATED" && (
                            <div
                              className="status b-r-20"
                              style={{
                                background: "#f7d635",
                              }}
                            >
                              Pending
                            </div>
                          )}
                          {d.transactionStatus === "SUCCESS" && (
                            <div
                              className="status b-r-20"
                              style={{
                                background: "green",
                              }}
                            >
                             Successfull
                            </div>
                          )}
                          {d.transactionStatus === "FAILED" && (
                            <div
                              className="status b-r-20"
                              style={{
                                background: "red",
                              }}
                            >
                              Failed
                            </div>
                          )}
                        </div>
                      </td>
                      <td>
                        <div
                          className="flex center-center"
                          style={{
                            gap: "12px",
                          }}
                        >
                          <RSModal
                            selector={
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="14"
                                viewBox="0 0 20 14"
                                style={{
                                  height: "16px",
                                  width: "16px",
                                }}
                              >
                                <path
                                  d="M12,5C7.92,5,4.256,8.093,2.145,11.483a1,1,0,0,0,0,1.034C4.256,15.907,7.92,19,12,19c4.143,0,7.8-3.09,9.864-6.493a1,1,0,0,0,0-1.014C19.794,8.09,16.141,5,12,5Zm8.413,7c-1.837,2.878-4.9,5.5-8.413,5.5-3.465,0-6.532-2.632-8.4-5.5C5.465,9.132,8.533,6.5,12,6.5,15.516,6.5,18.577,9.124,20.411,12ZM12,8a4,4,0,1,1-4,4A4,4,0,0,1,12,8Zm0,1.5A2.5,2.5,0,1,0,14.5,12,2.5,2.5,0,0,0,12,9.5Z"
                                  transform="translate(-2 -5)"
                                />
                              </svg>
                            }
                            content={<ReceiptView data={d} />}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
              {((data &&
                data?.transactionHistoryList?.length === 0 &&
                !loading) ||
                (!data && !loading)) && (
                <tbody>
                  <tr>
                    <td
                      colSpan={7}
                      style={{ textAlign: "center", padding: "10px" }}
                    >
                      <p> No records found. </p>
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </div>
        {data && (
          <Pagination
            totalRecords={data.totalRecords}
            pageNo={data.pageNo}
            pageSize={data.pageSize}
            baseUrl="/transaction-history"
          />
        )}
      </section>
    </section>
  );
};

const ReceiptView = ({ data, setShow }) => {
  const receiptTemplateRef = useRef(null);

  const handleGeneratePdf = () => {
    const input = receiptTemplateRef.current;
    const margin = 40;
    const logoBase64 = `${BASE_URL}/images/logo/1x.png`;
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4");
        
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth() - 2 * margin;
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        const positionX = margin;
        const positionY = margin;

        pdf.addImage(logoBase64, "PNG", 10, 10, 50/2, 20/2); // Adjust size as needed

        pdf.addImage(imgData, "PNG", positionX, positionY + 30, pdfWidth, pdfHeight);
        pdf.save("receipt.pdf");
      })
      .catch((error) => {
        console.error("Error generating PDF:", error);
      });
  };

  return (
    <div className="pos-rel" style={{ width: "480px", padding: "40px" }}>
      <CiCircleRemove
        className="cursor-pointer transition hover-color-secondary"
        onClick={() => setShow(false)}
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          fontSize: "32px",
        }}
      />
      <h4 className="color-primary">Transaction Receipt</h4>
      <br />
      <div ref={receiptTemplateRef}>
        <br />
        <section
          className="mar-20-0"
          style={{
            border: "solid 1px",
            borderRadius: "10px",
            padding: "15px",
          }}
        >
          <div className="flex center-center">
            <div className="t-a-c">
              {data.transactionStatus === "INITIATED" && (
                <>
                  <CiTimer
                    style={{
                      fontSize: "40px",
                      marginTop: "10px",
                      color: "#f7d635",
                    }}
                  />
                  <h5 style={{ color: "#f7d635" }}>Transaction Pending</h5>
                </>
              )}
              {data.transactionStatus === "SUCCESS" && (
                <>
                  <CiCircleCheck
                    style={{
                      fontSize: "40px",
                      marginTop: "10px",
                      color: "green",
                    }}
                  />
                  <h5 style={{ color: "green" }}>Transaction Successfull</h5>
                </>
              )}
              {data.transactionStatus === "FAILED" && (
                <>
                  <CiCircleRemove
                    style={{
                      fontSize: "40px",
                      marginTop: "10px",
                      color: "red",
                    }}
                  />
                  <h5 style={{ color: "red" }}>Transaction Failed</h5>
                </>
              )}
            </div>
          </div>
          <br />
          <hr />
          <br />
          <div>
            <p className="t-a-c color-faded" style={{ fontSize: "14px" }}>
              Total Payment
            </p>
            <p
              className="t-a-c"
              style={{ fontSize: "20px", fontWeight: "bold" }}
            >
               {`${formatAmountValue(convertUSDToAED(data.amount))}/- AED`}
               {/* {convertUSDToAED(data.amount)} AED */}
            </p>
          </div>
          <br />
          <div className="flex center-left">
            <div className="t-a-l">
              <p className="font-size-12 color-faded">Reference Number:</p>
            </div>
            <div className="t-a-r" style={{ marginLeft: "auto" }}>
              <p className="font-size-12 color-primary">
                {data.uniqueReferenceNumber}
              </p>
            </div>
          </div>
          <br />
          <div className="flex center-left">
            <div className="t-a-l">
              <p className="font-size-12 color-faded">Payment Date & Time:</p>
            </div>
            <div className="t-a-r" style={{ marginLeft: "auto" }}>
              <p className="font-size-12 color-primary">
                {" "}
                {formatDateTime(data.createdAt)}{" "}
              </p>
            </div>
          </div>
          <br />
          <div className="flex center-left">
            <div className="t-a-l">
              <p className="font-size-12 color-faded">Payment Method:</p>
            </div>
            <div className="t-a-r" style={{ marginLeft: "auto" }}>
              <p className="font-size-12 color-primary">Online Payment</p>
            </div>
          </div>
          <br />
          <div className="flex center-left">
            <div className="t-a-l">
              <p className="font-size-12 color-faded">Payment Description:</p>
            </div>
            <div className="t-a-r" style={{ marginLeft: "auto" }}>
              <p className="font-size-12 color-primary">{data.reference}</p>
            </div>
          </div>
          <br />
          <div className="flex center-left">
            <div className="t-a-l">
              <p className="font-size-12 color-faded">Sender Name:</p>
            </div>
            <div className="t-a-r" style={{ marginLeft: "auto" }}>
              <p className="font-size-12 color-primary">{data.customerName}</p>
            </div>
          </div>
          <br />
        </section>
        <br />
      </div>
      <div className="flex center-center">
        <button onClick={handleGeneratePdf}>Download</button>
      </div>
    </div>
  );
};

export default TransactionHistory;
