import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FullPageLoader from "../../components/ui/FullPageLoader";
import PageTitle from "../../components/common/PageTitle";
import { API_URL } from "../../utilities/constants";
import { useUI } from "../../context/ui";
import ButtonTextOnly from "../../components/ui/ButtonTextOnly";
import {
	CiCalculator1,
	CiCircleCheck,
	CiCircleRemove,
	CiFileOn,
	CiHome,
	CiLink,
	CiLocationOn,
	CiMail,
	CiPhone,
	CiUser,
} from "react-icons/ci";
import { decodeRolesFromToken } from "../../utilities/helpers";
import ImageSliders from "../../components/common/ImageSliders";

const AgencyBrokerPropertyDetail = () => {
	const { id } = useParams();
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState(null);
	const { setSegmentTitle } = useUI();
	const navigate = useNavigate();

	const callApi = async (id) => {
		setLoading(true);
		try {
			const resp = await fetch(API_URL + `/api/1.0.0/property/${id}`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					"X-JWT-Assertion": localStorage.getItem("token"),
				},
			});
			if (resp.ok) {
				const response = await resp.json();
				setData(response.data);
			}
		} catch (e) {
			console.log("Error", e);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (id) {
			callApi(id);
			setSegmentTitle("Property Details");
		}
	}, [id]);
	const images =
		data?.s3PicturesList?.map((imgUrl) => ({ url: imgUrl })) || [];

	return (
		<section className='pos-rel'>
			<PageTitle title='Property' />
			<FullPageLoader isActive={loading} />
			{data && (
				<section className='property-detail pad-20'>
					<div className='wrap b-r-5 of-hid'>
						<div className='sliders'>
							{data?.s3PicturesList.length > 0 && (
								<ImageSliders
									images={data.s3PicturesList}
									showArrows={true}
								/>
							)}
						</div>
						<div className='default-image of-hid'>{/*  */}</div>
						<div className='pad-20'>
							<table className='col-1-1'>
								<thead></thead>
								<tbody>
									<tr>
										<td colSpan='2'>
											<h6 className='color-primary'>
												{data.propertyName}
											</h6>
										</td>
									</tr>
									<tr>
										<td colSpan='2'>
											<p className='font-size-14 color-faded'>
												{data.description}
											</p>
										</td>
									</tr>
									<tr>
										<td colSpan='2'>
											<hr />
										</td>
									</tr>
									<tr>
										<td colSpan='2'>
											<div className='flex center-left'>
												<CiCalculator1 className='color-primary' />
												&nbsp;
												<p className='font-size-14 color-primary'>
													#RERA
												</p>
												&nbsp;
												<p className='font-size-14 color-faded'>
													{data.reraNumber}
												</p>
											</div>
										</td>
									</tr>
									<tr>
										<td colSpan='2'>
											<div className='flex center-left'>
												<CiLocationOn className='color-primary' />
												&nbsp;
												<p className='font-size-14 color-primary'>
													Address
												</p>
												&nbsp;
												<p className='font-size-14 color-faded'>
													{data.propertyAddress}
												</p>
											</div>
										</td>
									</tr>
									<tr>
										<td>
											<div className='flex center-left'>
												<CiHome className='color-primary' />
												&nbsp;
												<p className='font-size-14 color-primary'>
													Size
												</p>
												&nbsp;
												<p className='font-size-14 color-faded'>
													{data.propertySize} sq.ft.
												</p>
											</div>
										</td>
										<td>
											<div className='flex center-left'>
												<CiHome className='color-primary' />
												&nbsp;
												<p className='font-size-14 color-primary'>
													Type
												</p>
												&nbsp;
												<p className='font-size-14 color-faded'>
													{data.propertyType}
												</p>
											</div>
										</td>
									</tr>
									<tr>
										<td>
											<div className='flex center-left'>
												<CiCircleCheck className='color-primary' />
												&nbsp;
												<p className='font-size-14 color-primary'>
													Number of Bedrooms
												</p>
												&nbsp;
												<p className='font-size-14 color-faded'>
													{data.numberOfBedrooms || "-" }
												</p>
											</div>
										</td>
										<td>
											<div className='flex center-left'>
												<CiFileOn className='color-primary' />
												&nbsp;
												<p className='font-size-14 color-primary'>
													Certificate Year
												</p>
												&nbsp;
												<p className='font-size-14 color-faded'>
													{data.certificateYear || "-"}
												</p>
											</div>
										</td>
									</tr>
									{data.locationUrl && (
									<tr>
										<td>
											<p className='color-light font-size-10'>
												<a
													className='color-secondary transition hover-color-primary'
													href={data.locationUrl}
													target='_blank'
													rel='noreferrer'>
													<div className='flex center-left'>
														<CiLocationOn className='color-primary' />
														&nbsp;
														<p className='font-size-14 t-d-ul t-a-c color-secondary transition hover-color-primary'>
															Click to open
															location URL
														</p>
													</div>
												</a>
											</p>
										</td>
									</tr>
									)}
									<tr>
										<td colSpan='2'>
											<hr />
										</td>
									</tr>
									<tr>
										<td colSpan='2'>
											<div className='flex center-left'>
												<CiUser className='color-primary' />
												&nbsp;
												<p className='font-size-14 color-primary'>
													Owner
												</p>
												&nbsp;
												<p className='font-size-14 color-faded'>
													{data.owner.fullName}
												</p>
											</div>
										</td>
									</tr>
									<tr>
										<td colSpan='2'>
											<div className='flex center-left'>
												<CiLocationOn className='color-primary' />
												&nbsp;
												<p className='font-size-14 color-faded'>
													{data.owner.address}
												</p>
												<p className='font-size-14 color-faded'>
													,
												</p>
												&nbsp;
												<p className='font-size-14 color-faded'>
													{data.owner.pinCode}
												</p>
											</div>
										</td>
									</tr>
									<tr>
										<td colSpan='2'>
											<div className='flex center-left'>
												<CiMail className='color-primary' />
												&nbsp;
												<p className='font-size-14 color-faded'>
													{data.owner.email}
												</p>
											</div>
										</td>
									</tr>
									<tr>
										<td colSpan='2'>
											<div className='flex center-left'>
												<CiPhone className='color-primary' />
												&nbsp;
												<p className='font-size-14 color-faded'>
													{data.owner.countryCode}{" "}
													{data.owner.phoneNumber}
												</p>
											</div>
										</td>
									</tr>
									<tr>
										<td colSpan='2'>
											<hr />
										</td>
									</tr>
									<tr>
										<td>
											<a
												href={data.deedCopySignUrl}
												target='_blank'
												rel='noreferrer'>
												<div className='flex center-left'>
													<CiLink className='color-primary' />
													&nbsp;
													<p className='transition font-size-14 color-faded hover-color-secondary'>
														Deed Copy
													</p>
												</div>
											</a>
										</td>
										<td>
											<a
												href={data.propertyUrl}
												target='_blank'
												rel='noreferrer'>
												<div className='flex center-right'>
													<CiLink className='color-primary' />
													&nbsp;
													<p className='transition font-size-14 color-faded hover-color-secondary'>
														Open URL
													</p>
												</div>
											</a>
										</td>
									</tr>
									{decodeRolesFromToken()[0] === 4 && (
										<>
											<tr>
												<td colSpan='2'>
													<br />
												</td>
											</tr>
											<tr>
												<td colSpan='2'>
													<div className='flex bottom-right'>
														{data.loanActive ? (
															<div
																className='flex center-right'
																style={{
																	gap: "2px",
																}}>
																<CiCircleRemove
																	className='color-light'
																	style={{
																		color: "red",
																	}}
																/>
																<p
																	className='small color-light'
																	style={{
																		color: "red",
																	}}>
																	Property is
																	Unavailable
																</p>
															</div>
														) : (
															<div
																className='flex bottom-right'
																style={{
																	gap: "20px",
																}}>
																<ButtonTextOnly
																	title='Update Property'
																	onClick={() => {
																		navigate(
																			`/ab-properties/update/${id}`,
																			{
																				state: {
																					propertyDetails:
																						data,
																				},
																			}
																		);
																	}}
																/>
																<ButtonTextOnly
																	title='New Application'
																	onClick={() => {
																		navigate(
																			`/ab-loan-applications/create`,
																			{
																				state: {
																					propertyDetails:
																						data,
																				},
																			}
																		);
																	}}
																/>
															</div>
														)}
													</div>
												</td>
											</tr>
										</>
									)}
								</tbody>
							</table>
						</div>
					</div>
				</section>
			)}
		</section>
	);
};

export default AgencyBrokerPropertyDetail;
